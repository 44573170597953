import type { PayloadAction } from '@reduxjs/toolkit';
import type { State } from './state';
import { initialState } from './state';

export const reducers = {
  'set-redirect-path': (
    state: State,
    action: PayloadAction<{
      path: string;
      params?: { [key: string]: string };
    }>
  ) => {
    state.redirectPath = action.payload;
  },
  'toggle-sidebar': (state: State, action: PayloadAction<boolean>) => {
    state.isSidebarPinned === true ? (state.isSidebarToggled = true) : (state.isSidebarToggled = action.payload);
  },
  'pin-sidebar': (state: State) => {
    state.isSidebarPinned = !state.isSidebarPinned;
    state.isSidebarToggled = true;
  },
  'reset-sidebar': (state: State) => {
    state.isSidebarPinned = initialState.isSidebarPinned;
    state.isSidebarToggled = initialState.isSidebarToggled;
    state.isSidebarChanged = initialState.isSidebarChanged;
    state.redirectPath = initialState.redirectPath;
  },
  'set-sidebar-changed': (state: State) => {
    state.isSidebarChanged = true;
  },
  'set-user-agent': (state: State, action: PayloadAction<'mobile' | 'tablet' | 'desktop'>) => {
    state.userAgent = action.payload;
  },
  'set-language': (state: State, action: PayloadAction<'sv' | 'en'>) => {
    state.language = action.payload;
  },
};
