import type { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const style = css`
  list-style: disc;
  margin: 15px;

  li {
    margin-bottom: 7px;
  }
`;

export const UnorderedList = styled(({ children, ...rest }: PropsWithChildren) => <ul {...rest}>{children}</ul>)`
  ${style}
`;
