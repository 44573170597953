import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetAvailableUserRolesQuery } from 'api/users/users';

export const useGetUserRoles = (userId?: number) => {
  const { activeUserId } = useGetActiveUserId();
  const { data, ...rest } = useGetAvailableUserRolesQuery({ userId: userId ?? activeUserId }, { skip: !userId && !activeUserId });

  return {
    roles: data ?? [],
    ...rest,
  };
};
