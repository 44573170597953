import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box } from 'components/basic-components';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { isAdminSelector, isSwedsecAdminSelector } from 'store/selectors/identity';
import styled from 'styled-components';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import { UserProfile } from './EditProfile/UserProfile';
import { UserProfileBookingPermissions } from './EditProfile/UserProfileBookingPermissions';
import { UserProfileCompany } from './EditProfile/UserProfileCompany';
import { UserProfileConnectCompany } from './EditProfile/UserProfileConnectCompany';
import { UserProfileEmails } from './EditProfile/UserProfileEmails';
import { UserProfileExtraBookingPermissions } from './EditProfile/UserProfileExtraBookingPermissions';
import { UserProfileRoles } from './EditProfile/UserProfileRoles';

export const StyledFieldWrapper = styled(Box)`
  justify-content: end;
  width: 100%;

  @media screen and (min-width: 40em) {
    width: 50%;

    &:nth-of-type(2n) {
      padding-left: 10px;
    }
  }
`;

export const View = () => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = useAppSelector(isAdminSelector);
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const isIntegrated = useIsIntegrated();
  const { activeUserId: userId } = useGetActiveUserId();

  const handleBack = () => {
    if (isIntegrated === true) {
      dispatch(actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId } }));
    } else {
      navigate(-1);
    }
  };

  return (
    <Box width="100%" height="100%">
      <PageHeader title={t('profile.profile.title')} onBack={handleBack} />
      <UserProfile />
      <UserProfileEmails />
      <UserProfileCompany />
      {(isAdmin || isSwedsecAdmin) && <UserProfileRoles />}
      {(isAdmin || isSwedsecAdmin) && <UserProfileBookingPermissions />}
      {isSwedsecAdmin && <UserProfileExtraBookingPermissions />}
      <UserProfileConnectCompany />
    </Box>
  );
};
