import type { ColumnDef } from '@tanstack/react-table';
import type { Method } from 'api/oauth2/oauth2';
import { useGetMethodsQuery } from 'api/oauth2/oauth2';
import { ExpandButton } from 'components/basic-components/Table/ExpandButton';
import { Table } from 'components/basic-components/Table/Table';
import { usePagination } from 'components/basic-components/Table/hooks/usePagination';
import { ContentLoader } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';

export const Methods = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { pageSize, page, onPagination } = usePagination({});
  const { data, isLoading, isError } = useGetMethodsQuery({ page, size: pageSize });
  const methods = data?.methods ?? [];

  const columns = useMemo<ColumnDef<Method>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => t('oauth2.method.name'),
        size: undefined,
      },
      {
        accessorKey: 'expand',
        header: () => '',
        cell: ({ row }) => row.original.roles.length > 0 && <ExpandButton row={row} />,
        size: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageHeader title={t('oauth2.method.title')} onBack={() => navigate('/')} />
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={methods}
          rowCount={data?.total}
          pageSize={pageSize}
          onPagination={onPagination}
          page={page}
          emptyText={t('oauth2.method.noResponse')}
          failedFetch={isError}
        />
      </ContentLoader>
    </>
  );
};
