import { BASE_URL } from 'api/base';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import en from './en/en.json';
import sv from './sv/sv.json';

export type Languages = 'sv' | 'en';

export type NestedTranslationObject = {
  [key: string]: string | Array<string | Array<string>> | NestedTranslationObject;
};

export const flattenTranslationObject = (
  translationObject: NestedTranslationObject,
  parentKey: string = '',
  result: { [key: string]: string } = {},
  parentIsArray = false
): { [key: string]: string } => {
  for (const key in translationObject) {
    if (typeof translationObject[key] === 'object' && !Array.isArray(translationObject[key])) {
      flattenTranslationObject(translationObject[key] as NestedTranslationObject, parentKey ? `${parentKey}.${key}` : key, result);
    } else if (Array.isArray(translationObject[key])) {
      const translationArray = translationObject[key] as Array<string | Array<string>>;
      translationArray.forEach((item, index) => {
        if (Array.isArray(item)) {
          flattenTranslationObject({ [index]: item }, parentKey ? `${parentKey}.${key}` : key, result, true);
        } else {
          if (parentIsArray) {
            result[parentKey ? `${parentKey}[${key}][${index}]` : `${key}[${index}]`] = item as string;
          } else {
            result[parentKey ? `${parentKey}.${key}[${index}]` : `${key}[${index}]`] = item as string;
          }
        }
      });
    } else {
      result[parentKey ? `${parentKey}.${key}` : key] = translationObject[key] as string;
    }
  }
  return result;
};

const getLangFromI18N = (language: string | Array<string>) => {
  let lang = Array.isArray(language) ? language[0] : language;
  if (lang && lang.length > 2) {
    lang = lang.split('-')[0];
  }

  return lang;
};

const getTranslationLoadPath = (language: string | Array<string>, namespace: string) => {
  // This is a workaround to prevent the localTranslation namespace from being loaded from the server
  if (namespace.includes('localTranslation')) {
    return null;
  }

  return `${BASE_URL}/localizations/*/${getLangFromI18N(language)}`;
};

const getTranslationAddPath = (language: string | Array<string>) => `${BASE_URL}/localizations#${getLangFromI18N(language)}`;

const addTranslation = async (content: string, key: string, lang: string, addUrl: string) => {
  const token = getLoggedInUser()?.userToken;

  if (!token) {
    return;
  }

  await fetch(addUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      key,
      lang,
      content: content ?? key,
    }),
  });
};

const addArrayTranslation = async (content: Array<string>, key: string, lang: string, addUrl: string) => {
  const token = getLoggedInUser()?.userToken;
  if (!token) {
    return;
  }
  for (const contentItemIndex in content) {
    const contentItem = content[contentItemIndex];
    if (Array.isArray(contentItem)) {
      await addArrayTranslation(contentItem, `${key}.${contentItemIndex}`, lang, addUrl);
    } else {
      await addTranslation(contentItem, `${key}.${contentItemIndex}`, lang, addUrl);
    }
  }
};

// Here we specify the localTranslation namespace for each language
const bundledResources = {
  en: {
    localTranslation: en,
  },
  sv: {
    localTranslation: sv,
  },
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    saveMissing: true,
    saveMissingTo: 'all',
    supportedLngs: ['sv', 'en'],
    // If the translation is not found in translation (database) it will look in localTranslation (json files)
    ns: ['localTranslation', 'translation'],
    defaultNS: 'translation',
    fallbackNS: 'localTranslation',
    detection: {
      caches: ['localStorage'],
      order: ['localStorage', 'sessionStorage', 'navigator'],
    },
    backend: {
      backends: [HttpBackend, resourcesToBackend(bundledResources)],
      backendOptions: [
        {
          loadPath: getTranslationLoadPath,
          addPath: getTranslationAddPath,
          request: async (
            _: Record<string, string>,
            url: string,
            payload: Record<string, string>,
            callback: (err?: Error, res?: { status: number; data?: Record<string, string> }) => void
          ) => {
            try {
              const decodedToken = getLoggedInUser()?.decodedToken;

              if (payload) {
                if (!decodedToken?.authorities.includes('ROLE_SYS_ADMIN') && !decodedToken?.authorities.includes('ROLE_SWEDSEC_ADMIN')) {
                  return callback(undefined, { status: 404 });
                }

                const addUrl = url.split('#')[0];
                const lang = url.split('#')[1];
                const key = Object.keys(payload)[0];
                const splitKey = key.split('.');
                let content;

                switch (lang) {
                  case 'sv':
                    content = splitKey.reduce((translation, key) => translation?.[key], sv as Record<string, any>);
                    break;
                  case 'en':
                    content = splitKey.reduce((translation, key) => translation?.[key], en as Record<string, any>);
                    break;
                  default:
                    content = key;
                    break;
                }

                if (Array.isArray(content)) {
                  await addArrayTranslation(content, key, lang, addUrl);
                } else {
                  await addTranslation(content as string, key, lang, addUrl);
                }
              } else {
                const response = await fetch(url, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
                if (response.ok) {
                  const data = await response.json();
                  callback(undefined, { status: 200, data });
                } else {
                  callback(undefined, { status: response.status });
                }
              }
            } catch (e) {
              callback(undefined, { status: 500 });
            }
          },
        },
      ],
    },
  });

export default i18n;
