import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box } from 'components/basic-components';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import { DiagnosticTest } from './DiagnosticTest';
import { DigitalTestPolicy } from './DigitalTestPolicy';
import { LicensingTest } from './LicensingTest';
import { TestSubjects } from './TestSubjects';
import { TestTypes } from './TestTypes';

export const Book = () => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const disableSubmit = useAppSelector(selectors.articles.disableBookTestSubmit);
  const { activeUserId: userId } = useGetActiveUserId();
  const isIntegrated = useIsIntegrated();

  const handleBack = () => {
    if (isIntegrated === true) {
      dispatch(actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId } }));
    } else {
      navigate('/');
    }
  };

  return (
    <Box alignItems="flex-start" left={0}>
      <PageHeader title={t('booking.booking.title')} onBack={handleBack} />
      <Formik
        initialValues={{
          testSubject: '',
          tests: [],
          examMonitoringType: '',
          ort: '',
        }}
        onSubmit={async () => {
          navigate(`/users/${userId}/tests/confirm`);
        }}
      >
        {() => (
          <Form style={{ width: '100%' }}>
            <Box flexDirection={['column', 'column', 'column', 'row-reverse']} gap="loose">
              <DigitalTestPolicy />
              <Box flex="1" flexWrap="wrap" gap="loose">
                <Box alignItems="flex-start">
                  <TestSubjects />
                  <TestTypes />
                  <LicensingTest />
                  <DiagnosticTest />
                </Box>
                <Box variant="sectionWrapper">
                  <ActionButtonRow disabled={disableSubmit} primaryText={t('booking.booking.title')} />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
