import { privateApi } from 'api/base';
import type { Languages } from 'translations/i18next';

type GetTranslationsApiResponse = {
  [key: string]: string;
};

export type Translation = {
  lang: Languages;
  key: string;
  content: string;
};

type GetTranslationsRequest = {
  key: string;
  lang: Languages;
};

type DeleteTranslationRequest = {
  key: string;
  lang: Languages;
};

export const translationsApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getTranslations: builder.query<Array<Translation>, GetTranslationsRequest>({
      query: ({ key, lang }) => `localizations/*${key}*/${lang}`,
      transformResponse: (data: GetTranslationsApiResponse, _, { lang }) =>
        Object.keys(data).map((key) => ({
          key,
          lang,
          content: data[key],
        })),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ key, lang }) => ({ type: 'Translations' as const, id: `${key}_${lang}` })),
              { type: 'Translations' as const, id: 'LIST' },
            ]
          : [{ type: 'Translations' as const, id: 'LIST' }],
    }),
    createTranslations: builder.mutation<void, Translation>({
      query: ({ key, lang, content }) => ({
        url: `localizations`,
        method: 'POST',
        body: { key, lang, content },
      }),
      invalidatesTags: [{ type: 'Translations' as const, id: 'LIST' }],
    }),
    updateTranslations: builder.mutation<void, Translation>({
      query: ({ key, lang, content }) => ({
        url: `localizations`,
        method: 'PUT',
        body: { key, lang, content },
      }),
      invalidatesTags: (_, __, { key, lang }) => [{ type: 'Translations' as const, id: `${key}_${lang}` }],
    }),
    deleteTranslations: builder.mutation<void, DeleteTranslationRequest>({
      query: ({ key, lang }) => ({
        url: `localizations/${key}/${lang}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Translations' as const, id: 'LIST' }],
    }),
  }),
});

export const { useGetTranslationsQuery, useCreateTranslationsMutation, useUpdateTranslationsMutation, useDeleteTranslationsMutation } =
  translationsApi;
