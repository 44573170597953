import { combineSlices } from '@reduxjs/toolkit';
import { akuApi } from 'api/aku/aku';
import { authApi } from 'api/auth/auth';
import { bankIdApi } from 'api/auth/bankId';
import { educateITApi } from 'api/educateIT/educateIT';
import { examsApi } from 'api/exams/exams';
import { metaApi } from 'api/meta/meta';
import { systemsApi } from 'api/system/system';
import { todosApi } from 'api/todos/todos';
import { translationsApi } from 'api/translations/translations';
import { usersApi } from 'api/users/users';
import { slice as generalSlice } from './stores/general';
import { slice as identitySlice } from './stores/identity';
import { slice as reservationSlice } from './stores/reservation';

export const rootReducer = combineSlices(
  reservationSlice,
  generalSlice,
  identitySlice,
  examsApi,
  authApi,
  bankIdApi,
  usersApi,
  translationsApi,
  systemsApi,
  todosApi,
  educateITApi,
  akuApi,
  metaApi
);
