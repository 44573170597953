import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { isAdminSelector } from 'store/selectors/identity';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import { AkuTable } from './AkuTable';

export const View = () => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeUserId } = useGetActiveUserId();
  const isAdmin = useAppSelector(isAdminSelector);
  const isIntegrated = useIsIntegrated();
  const { isMember } = useGetUser();

  const handleBack = () => {
    if (isIntegrated) {
      dispatch(
        actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
      );
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <PageHeader title={t('profile.aku.title')} onBack={handleBack} />
      <AkuTable />
      {isAdmin && (
        <ActionButtonRow
          primaryOnClick={() => navigate(`/users/${activeUserId}/aku/edit?fromView=true`)}
          primaryText={t('profile.aku.edit')}
          disabled={!isMember}
        />
      )}
    </>
  );
};
