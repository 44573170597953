import { useGetSystemMessages } from 'api/system/hooks/useGetSystemMessages';
import type { SystemMessage } from 'api/system/system';
import { Box, Typography } from 'components/basic-components';
import { Icon } from 'components/misc';
import type * as CSS from 'csstype';
import { X } from 'lucide-react';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import type { ThemeColors } from 'theme';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';

type SystemMessageWrapperProps = {
  $color: string;
  $animation: ReturnType<typeof keyframes>;
};

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
`;

const SystemMessageWrapper = styled.div<SystemMessageWrapperProps>`
  width: 100%;
  text-align: center;
  z-index: 5;
  animation: ${({ $animation }) => $animation} 0.1s ease forwards;
  background-color: ${({ $color }) => $color};
  display: flex;
`;

type SystemMessageBannerProps = {
  message: SystemMessage;
};

export const SystemMessageBanner = ({ message }: SystemMessageBannerProps) => {
  const { language } = useTranslations();
  const [isOpen, setIsOpen] = useState(true);
  const [animation, setAnimation] = useState(slideDown);
  const readSystemMessages = JSON.parse(localStorage.getItem('READ_SYSTEM_MESSAGES') ?? '[]');
  const messageBody =
    language === 'en' ? message.englishMessage ?? message.swedishMessage : message.swedishMessage ?? message.englishMessage;

  const handleCloseSystemMessage = () => {
    const currentReadSystemMessages = JSON.parse(localStorage.getItem('READ_SYSTEM_MESSAGES') ?? '[]');
    setAnimation(slideUp);
    setTimeout(() => setIsOpen(false), 100);

    localStorage.setItem('READ_SYSTEM_MESSAGES', JSON.stringify([...currentReadSystemMessages, message.id]));
  };

  const messageColor: {
    color: CSS.Property.Color;
    text: keyof ThemeColors;
    interactive: keyof ThemeColors;
  } =
    message?.type === 'ERROR'
      ? {
          color: theme.colors.error,
          text: 'light',
          interactive: 'message-error-enhanced',
        }
      : message?.type === 'SUCCESS'
        ? {
            color: theme.colors.success,
            text: 'light',
            interactive: 'message-success-enhanced',
          }
        : {
            color: theme.colors.warning,
            text: 'message-info-text',
            interactive: 'message-info-enhanced',
          };

  useEffect(() => {
    if (message) {
      setAnimation(slideDown);
    }
  }, [message, isOpen]);

  return (
    <>
      {isOpen && !readSystemMessages.includes(message.id) && (
        <SystemMessageWrapper $color={messageColor.color} $animation={animation}>
          <Typography.Paragraph alignSelf="center" padding="base" color={messageColor.text} justifyContent="center" display="flex">
            {messageBody}
          </Typography.Paragraph>
          <Box width="48px" backgroundColor={messageColor.interactive}>
            <Icon onClick={handleCloseSystemMessage} size="20" marginRight="none" padding="base">
              <X />
            </Icon>
          </Box>
        </SystemMessageWrapper>
      )}
    </>
  );
};

export const SystemMessages = () => {
  const { systemMessages } = useGetSystemMessages({
    options: {
      pollingInterval: 60 * 1000,
    },
  });

  return (
    <>
      {systemMessages.map((message, index) => (
        <SystemMessageBanner key={`system_message_${index}`} message={message} />
      ))}
    </>
  );
};
