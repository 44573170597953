import { privateApi } from 'api/base';

type ExamType = 'Bolån' | 'Informationsgivare' | 'Ledning och kontroll' | 'Rådgivare' | 'Specialister';

export type AkuExam = {
  exam: ExamType;
  completed: boolean;
};

export type Aku = {
  id: number;
  year: number;
  completed: boolean;
  status: 'NOT_REQUIRED' | 'COMPLETED' | 'NOT_COMPLETED';
  exams: Array<AkuExam>;
};

type ApiAku = {
  id: number;
  year: number;
  completed: boolean;
  status: 'NOT_REQUIRED' | 'COMPLETED' | 'NOT_COMPLETED';
  exams: {
    [exam in ExamType]: boolean;
  };
};

type GetAkuRequest = {
  userId: number;
};

export type UpdateAkuRequest = {
  userId: number;
  year: number;
  exams?: Array<AkuExam>;
  generalAkuStatus?: 'TRUE' | 'FALSE';
};

export const akuApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getAKU: builder.query<Aku[], GetAkuRequest>({
      query: ({ userId }) => `aku/users/${userId.toString()}`,
      transformResponse: (data: ApiAku[]) =>
        data.map((aku) => ({
          id: aku.id,
          year: aku.year,
          completed: aku.completed,
          status: aku.status,
          exams: Object.keys(aku.exams).map((examKey) => ({
            exam: examKey as ExamType,
            completed: aku.exams[examKey as ExamType],
          })),
        })),
      providesTags: ['Aku'],
    }),
    updateAKU: builder.mutation<void, UpdateAkuRequest>({
      query: ({ userId, exams, year, generalAkuStatus }) => ({
        url: `/aku`,
        method: 'PATCH',
        body: {
          user_id: userId, // eslint-disable-line camelcase
          // eslint-disable-next-line camelcase
          aku_status: exams?.reduce(
            (akuStatus, exam) => {
              akuStatus[exam.exam] = exam.completed;

              return akuStatus;
            },
            {} as {
              [exam in ExamType]?: boolean;
            }
          ),
          year: year,
          general_aku_status: generalAkuStatus, // eslint-disable-line camelcase
        },
      }),
      async onQueryStarted({ userId, exams, year, generalAkuStatus }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          akuApi.util.updateQueryData('getAKU', { userId }, (cachedAkuResponse) => {
            cachedAkuResponse = cachedAkuResponse.map((cachedAku) =>
              cachedAku.year === year
                ? {
                    ...cachedAku,
                    completed: exams === undefined ? Boolean(generalAkuStatus) : exams.some((exam) => exam.completed),
                    status: (exams === undefined ? generalAkuStatus : exams.some((exam) => exam.completed)) ? 'COMPLETED' : 'NOT_COMPLETED',
                    exams: cachedAku.exams.map((cachedExam) => ({
                      ...cachedExam,
                      completed: exams?.find((updatedExam) => updatedExam.exam === cachedExam.exam)?.completed ?? cachedExam.completed,
                    })),
                  }
                : cachedAku
            );

            return cachedAkuResponse;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetAKUQuery, useUpdateAKUMutation } = akuApi;
