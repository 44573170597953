import { localApi } from 'api/base';

export type Meta = {
  hash: string;
};

export const metaApi = localApi.injectEndpoints({
  endpoints: (builder) => ({
    getMeta: builder.query<Meta, void>({
      query: () => 'meta.json',
      providesTags: ['Meta'],
    }),
  }),
});

export const { useGetMetaQuery } = metaApi;
