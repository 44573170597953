import { useGetTestResultsQuery } from 'api/exams/exams';
import { useGetUser } from 'api/users/hooks/useGetUser';

export const useGetTestResults = (userId?: number) => {
  const { activeUserId } = useGetUser();
  const { data: testResults, ...rest } = useGetTestResultsQuery({ userId: userId ?? activeUserId }, { skip: !userId && !activeUserId });

  return {
    testResults: (testResults?.results ?? []).toSorted((a, b) => (new Date(a.completed) > new Date(b.completed) ? -1 : 1)),
    detailedResultLink: testResults?.detailedResultLink ?? '',
    ...rest,
  };
};
