import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { Box, Button, Typography } from 'components/basic-components';
import { ChevronLeft } from 'lucide-react';
import { useTranslations } from 'translations/hooks/useTranslations';

type PageHeaderProps = {
  title?: string;
  onBack?: () => void;
  buttonOnClick?: () => void;
  buttonText?: string;
  disabledButton?: boolean;
};

export const PageHeader = ({ title, onBack, buttonText, buttonOnClick, disabledButton }: PageHeaderProps) => {
  const { activeUserId: userId, loggedInUserId } = useGetActiveUserId();
  const { user } = useGetUser();
  const { t } = useTranslations();

  return (
    <Box width="100%" flexDirection="row">
      <Box width="100%">
        {onBack && (
          <Button onClick={onBack} variant="secondary" overflowX="initial" style={{ inlineSize: 'fit-content' }}>
            <ChevronLeft />
            {t('booking.booking.back')}
          </Button>
        )}
        <Box width="100%" justifyContent="space-between" flexDirection="row" marginY="base">
          <Box>
            {title && (
              <Typography.H1 alignSelf="flex-start" textAlign="start" color="main">
                {title}
              </Typography.H1>
            )}
            {title && userId && loggedInUserId !== userId && (
              <Typography.H3 color="main" marginTop="none">
                {user?.firstName || ''} {user?.lastName || ''}
              </Typography.H3>
            )}
          </Box>

          {buttonOnClick && buttonText && (
            <Button type="button" justifyContent="center" alignSelf="flex-end" onClick={buttonOnClick} disabled={disabledButton}>
              {buttonText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
