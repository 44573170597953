import { useCreateTranslationsMutation, type Translation } from 'api/translations/translations';
import { Box } from 'components/basic-components';
import { RadioButton, TextField } from 'components/form-components';
import { Modal } from 'components/misc/Modal/Modal';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslations } from 'translations/hooks/useTranslations';
import * as Yup from 'yup';

type CreateTranslationProps = {
  show: boolean;
  onClose: () => void;
};
export const CreateTranslation = ({ show, onClose }: CreateTranslationProps) => {
  const { t } = useTranslations();
  const [createTranslation, { isSuccess }] = useCreateTranslationsMutation();

  const handleSubmit = async (values: Translation) => {
    await createTranslation(values);
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{ lang: 'sv', key: '', content: '' }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        content: Yup.string().required('translations.modal.noContent'),
        key: Yup.string().required('translations.modal.noKey'),
        lang: Yup.string().required('translations.modal.noLang'),
      })}
      enableReinitialize
    >
      {({ isSubmitting, submitForm }) => (
        <Modal
          key="start"
          isShown={show}
          hide={onClose}
          onConfirm={submitForm}
          onCancel={onClose}
          headerText={t('translations.modal.createHeader')}
          confirmText={t('translations.modal.save')}
          cancelText={t('translations.modal.cancel')}
          disableConfirm={isSubmitting}
        >
          <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', minWidth: '300px' }}>
            <Box flexDirection="row" gap="base" marginBottom="base">
              <RadioButton name="lang" label={t('general.languages.sv')} value="sv" />
              <RadioButton name="lang" label={t('general.languages.en')} value="en" />
            </Box>
            <TextField
              required
              type="text"
              name="key"
              placeholder={t('translations.key')}
              label={t('translations.key')}
              autoComplete="on"
            />
            <TextField
              required
              type="text"
              name="content"
              placeholder={t('translations.content')}
              label={t('translations.content')}
              autoComplete="on"
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
