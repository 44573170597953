import { GlobalStyles } from 'components/misc';
import { ImpersonationBanner } from 'components/misc/ImpersonationBanner/ImpersonationBanner';
import { SystemMessages } from 'components/misc/SystemMessage/SystemMessages';
import { SystemUpdate } from 'components/misc/SystemUpdate/SystemUpdate';
import { PXThemeContextProvider } from 'context/theme-context/PXThemeContextProvider';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootRouter } from 'router/rootRouter';
import { actions, useAppDispatch } from 'store';
import { theme } from 'theme';
import { useI18n } from 'translations/hooks/useI18n';
import { featureFlags } from 'utilities/featureFlags';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import packageJSON from '../package.json';

export const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const i18n = useI18n();
  const isIntegrated = useIsIntegrated();

  useEffect(() => {
    console.log(`Phoenix v.${packageJSON.version}`);
    console.log(`Build number ${process.env.BUILD_NUMBER}`);
    return () => {
      console.log('Logout');
      dispatch(actions.identity.logout(navigate));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <PXThemeContextProvider>
        <GlobalStyles theme={theme} />
        <ImpersonationBanner />
        {featureFlags.systemMessage && !isIntegrated && <SystemMessages />}
        <SystemUpdate />
        <RootRouter />
      </PXThemeContextProvider>
    </I18nextProvider>
  );
};
