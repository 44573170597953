import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useEffect, useState, type PropsWithChildren } from 'react';
import { useAppSelector } from 'store';
import { isAdminSelector } from 'store/selectors/identity';
import { ThemeProvider } from 'styled-components';
import { modesPalette, theme } from 'theme';

export const PXThemeContextProvider = ({ children }: PropsWithChildren) => {
  const { activeUserId, loggedInUserId } = useGetActiveUserId();
  const isAdmin = useAppSelector(isAdminSelector);
  const isAdminView = isAdmin && loggedInUserId && loggedInUserId !== activeUserId;
  const [mode, setMode] = useState<'licensee' | 'admin'>('licensee');

  useEffect(() => {
    setMode(isAdminView ? 'admin' : 'licensee');
  }, [isAdminView]);

  const themeObject = {
    ...theme,
    colors: modesPalette[mode] ?? theme.colors,
  };
  return <ThemeProvider theme={themeObject}>{children}</ThemeProvider>;
};
