import type { ColumnDef, ExpandedState, OnChangeFn, Row, RowSelectionState } from '@tanstack/react-table';
import { flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import { Icon } from 'components/misc';
import { EmptyComponent } from 'components/misc/EmptyComponent/EmptyComponent';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp, ChevronsLeft, ChevronsRight, RotateCw } from 'lucide-react';
import type { ReactElement } from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { StyledTable } from '.';
import Box from '../Box/Box';
import { IconButton } from '../IconButton/IconButton';
import { Checkbox } from '../Input/Checkbox';
import { Typography } from '../Typography/Typography';
import { TableCell, TableRow } from './Body';
import { TableHeader } from './Headers';

type TableProps<T> = {
  columns: Array<ColumnDef<T>>;
  data: Array<T>;
  rowCount?: number;
  pageSize?: number;
  page?: number;
  emptyText?: string;
  enableRowSelection?: boolean | ((row: Row<T>) => boolean);
  rowSelection?: RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  columnVisibility?: { [column: string]: boolean };
  failedFetch?: boolean;
  renderExpandedRow?: (row: Row<T>) => ReactElement;
  onRowClick?: (row: Row<T>) => void;
  onPagination?: (page: number) => void;
};

export const Table = <T,>({
  columns,
  data,
  renderExpandedRow,
  rowCount,
  pageSize,
  page,
  columnVisibility,
  onPagination,
  onRowClick,
  enableRowSelection = false,
  rowSelection,
  setRowSelection,
  emptyText,
  failedFetch,
}: TableProps<T>) => {
  const { t } = useTranslations();
  const defaultPageSize = pageSize ?? 20;
  const defaultRowCount = rowCount ?? data?.length ?? 0;
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const maxPage = Math.ceil(defaultRowCount / defaultPageSize) - 1;
  const currentPage = page ?? 0;
  const canDoPreviousPage = currentPage > 0;
  const canDoNextPage = currentPage < maxPage;

  const selectionColumns = useMemo<ColumnDef<T>[]>(
    () => [
      {
        id: 'select',
        header: () => null,
        cell: ({ row }) => (
          <div className="px-1">
            <Box flex="1" alignItems="center">
              <Box width="28px">
                {row.getCanSelect() && (
                  <Checkbox name="selectedRows" onChange={row.getToggleSelectedHandler()} checked={row.getIsSelected()} />
                )}
              </Box>
            </Box>
          </div>
        ),
        size: 50,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const table = useReactTable({
    data,
    columns: enableRowSelection ? [...selectionColumns, ...columns] : columns,
    state: {
      expanded,
      rowSelection,
    },
    defaultColumn: {
      size: 200,
      minSize: 20,
      maxSize: 500,
      enableSorting: false,
    },
    initialState: {
      columnVisibility,
    },
    manualPagination: true,
    enableRowSelection,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    if (onPagination) {
      onPagination(currentPage);
    }
  }, [currentPage, onPagination]);

  useEffect(() => {
    if (currentPage !== undefined && onPagination) {
      if (currentPage > Math.floor(rowCount ?? 0 / (pageSize ?? 0))) {
        onPagination(Math.floor(rowCount ?? 0 / (pageSize ?? 0)));
      }
    }
  }, [rowCount, pageSize, currentPage, onPagination]);

  return (
    <>
      <StyledTable marginBottom="loose">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: `${header.getSize()}px`, cursor: header.column.getCanSort() ? 'pointer' : 'initial' }}
                  className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                  onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : () => {}}
                  title={
                    header.column.getCanSort()
                      ? header.column.getNextSortingOrder() === 'asc'
                        ? 'Sort ascending'
                        : header.column.getNextSortingOrder() === 'desc'
                          ? 'Sort descending'
                          : 'Clear sort'
                      : undefined
                  }
                >
                  {header.isPlaceholder ? null : (
                    <Box flexDirection="row" alignItems="center">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <ChevronUp height="18" />,
                        desc: <ChevronDown height="18" />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </Box>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </TableHeader>
        <tbody>
          {failedFetch ? (
            <>
              <tr>
                <td colSpan={table.getVisibleFlatColumns().length}>
                  <Box justifyContent="center" alignItems="center" padding="base-tight">
                    <Icon color="complementary-color-2">
                      <RotateCw color={theme.colors['complementary-color-2']} />
                    </Icon>
                    <Typography.Paragraph textAlign="center" color="complementary-color-2" marginTop="base-tight">
                      {t('general.table.failedFetch')}
                    </Typography.Paragraph>
                  </Box>
                </td>
              </tr>
            </>
          ) : (
            <>
              {table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow variant="default" onClick={onRowClick ? () => onRowClick(row) : undefined}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </TableRow>
                  {row.getIsExpanded() && renderExpandedRow && <>{renderExpandedRow(row)}</>}
                </Fragment>
              ))}
              {table.getRowModel().rows.length === 0 && emptyText && (
                <tr>
                  <td colSpan={table.getVisibleFlatColumns().length}>
                    <EmptyComponent title={emptyText} />
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </StyledTable>
      {defaultRowCount > defaultPageSize && onPagination && (
        <Box flexDirection="row" justifyContent="center" gap="2rem" paddingY="loose" width="auto">
          <IconButton onClick={() => onPagination(0)} disabled={!canDoPreviousPage} icon={<ChevronsLeft />} />
          <IconButton onClick={() => onPagination(currentPage - 1)} disabled={!canDoPreviousPage} icon={<ChevronLeft />} />
          <Typography.SmallParagraph alignContent="center">
            <strong>{currentPage + 1}</strong> {t('general.table.of')} <strong>{maxPage + 1}</strong>
          </Typography.SmallParagraph>
          <IconButton onClick={() => onPagination(currentPage + 1)} disabled={!canDoNextPage} icon={<ChevronRight />} />
          <IconButton onClick={() => onPagination(maxPage)} disabled={!canDoNextPage} icon={<ChevronsRight />} />
        </Box>
      )}
    </>
  );
};
