import type { Exam } from 'api/exams/exams';
import { examErrorHandler, useStartExamMutation } from 'api/exams/exams';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box, Button, Typography } from 'components/basic-components';
import { ContentLoader, Modal, Section, useModal } from 'components/misc';
import { EmptyComponent } from 'components/misc/EmptyComponent/EmptyComponent';
import { useEffect, useMemo } from 'react';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { errorHandler } from 'utilities/errorHandling';
import { useGetExams } from './hooks/useGetBookings';

const StartTestModal = ({ booking, buttonText }: { booking: Exam; buttonText: string }) => {
  const { t } = useTranslations();
  const { isShown, toggle } = useModal();
  const { activeUserId, loggedInUserId } = useGetActiveUserId();
  const [startTestBooking, { isSuccess, data, error }] = useStartExamMutation();

  const onStartTestConfirm = async () => {
    await startTestBooking({ examId: booking.id });

    toggle();
  };

  const onStartTestCanel = () => {
    toggle();
  };

  useEffect(() => {
    if (isSuccess && data) {
      window.open(data);
    }
  }, [isSuccess, data]);

  // TODO: This needs a better implementation, its not in the API layer because different views using the same request handles errors in different ways.
  useEffect(() => {
    if (error) {
      if ('status' in error) {
        switch (error.status) {
          case 409:
            examErrorHandler(error);
            break;
          case 502:
          case 503:
          case 504:
            publish(CustomEventName.API_ERROR, { message: 'REMOTE_PROCTOR_SERVER_UNAVAILABLE' });
            break;
          default:
            errorHandler(error);
            break;
        }
      }
    }
  }, [error]);

  return (
    <>
      <Button justifyContent="center" marginRight="base-tight" onClick={toggle} disabled={activeUserId !== loggedInUserId}>
        {buttonText}
      </Button>
      <Modal
        key="start"
        isShown={isShown}
        hide={toggle}
        onConfirm={onStartTestConfirm}
        onCancel={onStartTestCanel}
        message={t('booking.myBookedTest.startTestMessage', { name: booking.title })}
        headerText={t('booking.myBookedTest.startTestModalHeader')}
        confirmText={t('general.general.yes')}
        cancelText={t('general.general.no')}
        invertButtons
      />
    </>
  );
};

export const DiagnosticExams = () => {
  const { t } = useTranslations();
  const { diagnosticExams, isLoading, error } = useGetExams();
  const subjects = useMemo(
    () =>
      diagnosticExams.reduce<Array<{ title: string; bookings: Array<Exam> }>>((subjects, booking) => {
        const subjectIndex = subjects.findIndex((subject) => subject.title === booking.subject);

        if (subjectIndex === -1) {
          subjects.push({ title: booking.subject, bookings: [booking] });
        } else {
          subjects[subjectIndex].bookings.push(booking);
        }

        return subjects;
      }, []),
    [diagnosticExams]
  );

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <Section title={t('booking.myBookedTest.diagnoseTitle')}>
      <Box>
        <ContentLoader status={isLoading ? 'loading' : 'idle'} template="examBooking">
          {subjects.length === 0 ? (
            <EmptyComponent title={t('booking.myBookedTest.noTestsBooked')} />
          ) : (
            subjects.map((subject) => (
              <Box key={subject.title}>
                <Typography.Paragraph margin="base" fontWeight="regular">
                  {t('booking.myBookedTest.groupTitle', { subject: t(`general.subjects.${subject.title}`) })}
                </Typography.Paragraph>

                {subject.bookings.map((booking, index) => (
                  <Box
                    key={index}
                    flexDirection="row"
                    alignItems="center"
                    borderWidth="extra-thin"
                    padding="base"
                    borderColor="main"
                    borderBottomStyle="solid"
                    borderTopStyle="solid"
                    width="100%"
                    $wrap
                  >
                    <Typography.Paragraph color="main">{booking.title}</Typography.Paragraph>
                    <StartTestModal
                      booking={booking}
                      buttonText={
                        booking.status === 'NOT_STARTED' ? t('booking.myBookedTest.startTest') : t('booking.myBookedTest.continue')
                      }
                    />
                  </Box>
                ))}
              </Box>
            ))
          )}
        </ContentLoader>
      </Box>
    </Section>
  );
};
