import { examErrorHandler, useCancelExamMutation, useStartExamMutation, type Exam } from 'api/exams/exams';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { Box, Button, Typography } from 'components/basic-components';
import { ContentLoader, Modal, Section, useModal } from 'components/misc';
import { EmptyComponent } from 'components/misc/EmptyComponent/EmptyComponent';
import moment from 'moment';
import { useEffect } from 'react';
import { selectors, useAppSelector } from 'store';
import styled from 'styled-components';
import { breakpoints } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { errorHandler, getErrorMessage } from 'utilities/errorHandling';
import { useGetExams } from './hooks/useGetBookings';

const StyledButtonWrapper = styled(Box)`
  flex-direction: row;
  justify-content: right;

  @media only screen and (max-width: ${breakpoints.md}) {
    flex-direction: column;
    gap: 8px;

    > button {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 0.5em;
      box-sizing: border-box;
    }
  }
`;

const getTestBookingButtonText = (exam: Exam, examStart: Date, isDigital: boolean) => {
  if (exam.status === 'NOT_STARTED') {
    if (!isDigital || new Date() > examStart) {
      return 'booking.myBookedTest.startTest';
    } else {
      return 'booking.myBookedTest.prepareTest';
    }
  } else {
    return 'booking.myBookedTest.continue';
  }
};

const StartTestModal = ({ exam }: { exam: Exam }) => {
  const { t } = useTranslations();
  const { isShown, toggle } = useModal();
  const { activeUserId, loggedInUserId } = useGetActiveUserId();
  const [startTestBooking, { isSuccess, data, error }] = useStartExamMutation();
  const isDigital = exam.location.toLowerCase() === 'digital testlokal';
  const fifteenMinutesBeforeExamStart = new Date(exam.start);
  fifteenMinutesBeforeExamStart.setMinutes(fifteenMinutesBeforeExamStart.getMinutes() - 15);

  const onStartTestConfirm = async () => {
    await startTestBooking({ examId: exam.id });

    toggle();
  };

  const onStartTestCanel = () => {
    toggle();
  };

  useEffect(() => {
    if (isSuccess && data) {
      window.open(data);
    }
  }, [isSuccess, data]);

  // TODO: This needs a better implementation, its not in the API layer because different views using the same request handles errors in different ways.
  useEffect(() => {
    if (error) {
      if ('status' in error) {
        switch (error.status) {
          case 409:
            examErrorHandler(error);
            break;
          case 502:
          case 503:
          case 504:
            publish(CustomEventName.API_ERROR, { message: 'REMOTE_PROCTOR_SERVER_UNAVAILABLE' });
            break;
          default:
            errorHandler(error);
            break;
        }
      }
    }
  }, [error]);

  return (
    <>
      <Button
        justifyContent="center"
        marginRight="base-tight"
        onClick={toggle}
        disabled={(!isDigital && new Date() < fifteenMinutesBeforeExamStart) || activeUserId !== loggedInUserId}
      >
        {t(getTestBookingButtonText(exam, fifteenMinutesBeforeExamStart, isDigital))}
      </Button>
      <Modal
        key="start"
        isShown={isShown}
        hide={toggle}
        onConfirm={onStartTestConfirm}
        onCancel={onStartTestCanel}
        message={t('booking.myBookedTest.startTestMessage', { name: exam.title })}
        headerText={t('booking.myBookedTest.startTestModalHeader')}
        confirmText={t('general.general.yes')}
        cancelText={t('general.general.no')}
        invertButtons
      />
    </>
  );
};

const testHasPassedLastCancellationDate = (exam: Exam) => {
  // TODO: A better way to check if its digital or not
  if (exam.location.toLowerCase() === 'digital testlokal') {
    const fifteenDaysInTheFuture = new Date();
    fifteenDaysInTheFuture.setDate(fifteenDaysInTheFuture.getDate() + 8);

    return new Date(exam.start) < fifteenDaysInTheFuture;
  }

  const eightDaysInTheFuture = new Date();
  eightDaysInTheFuture.setDate(eightDaysInTheFuture.getDate() + 15);

  return new Date(exam.start) < eightDaysInTheFuture;
};

const CancelBookingModal = ({ exam }: { exam: Exam }) => {
  const isSwedsecAdmin = useAppSelector(selectors.identity.isSwedsecAdminSelector);
  const { t } = useTranslations();
  const [cancelExam, { isSuccess, error }] = useCancelExamMutation();
  const { user } = useGetUser();
  const { isShown, toggle } = useModal();
  const { isShown: isWarningModalShown, toggle: toggleWarningModal } = useModal();

  const onConfirm = () => {
    if (!user || !user.defaultCompany) {
      return;
    }

    if (testHasPassedLastCancellationDate(exam) && !isSwedsecAdmin) {
      toggleWarningModal();
      toggle();

      return;
    }

    cancelExam({ examId: exam.id, userId: user.id, companyId: user.defaultCompany.companyId });
  };

  const onConfirmExpirationWarningModal = () => {
    if (!user || !user.defaultCompany) {
      return;
    }

    cancelExam({ examId: exam.id, userId: user.id, companyId: user.defaultCompany.companyId });

    if (isWarningModalShown) {
      toggleWarningModal();
    }

    if (isShown) {
      toggle();
    }
  };

  const onCancel = () => {
    if (isShown) {
      toggle();
    } else {
      toggleWarningModal();
    }
  };

  useEffect(() => {
    if (isSuccess && isShown) {
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // TODO: This needs a better implementation, its not in the API layer because different views using the same request handles errors in different ways.
  useEffect(() => {
    if (error) {
      if ('status' in error) {
        let errorMessage = '';
        switch (error.status) {
          case 400:
            errorMessage = getErrorMessage(error);
            if (errorMessage === 'REMOTE_PROCTOR_AUTHORIZATION_FALIURE') {
              publish(CustomEventName.API_ERROR, { message: 'REMOTE_PROCTOR_AUTHORIZATION_FALIURE' });
            } else {
              publish(CustomEventName.API_ERROR, { message: '400' });
            }
            break;
          case 409:
            examErrorHandler(error);
            break;
          case 503:
          case 504:
            publish(CustomEventName.API_ERROR, { message: 'REMOTE_PROCTOR_SERVER_UNAVAILABLE' });
            break;
          default:
            errorHandler(error);
            break;
        }
      }
    }
  }, [error]);

  return (
    <>
      <Button justifyContent="center" variant="secondary" onClick={toggle}>
        {t('booking.myBookedTest.cancelBooking')}
      </Button>
      <Modal
        key="cancelBooking"
        isShown={isShown}
        hide={toggle}
        onConfirm={onConfirm}
        onCancel={onCancel}
        message={t('booking.myBookedTest.cancelTestMessage', { name: exam.title })}
        headerText={t('booking.myBookedTest.cancelBookingModalHeader')}
        confirmText={t('general.general.yes')}
        cancelText={t('general.general.no')}
        invertButtons
      />
      <Modal
        key="cancelBookingWarning"
        isShown={isWarningModalShown}
        hide={toggleWarningModal}
        onConfirm={onConfirmExpirationWarningModal}
        onCancel={onCancel}
        // TODO: A better way to check if its digital or not
        message={
          exam.location.toLowerCase() === 'digital testlokal'
            ? t('booking.myBookedTest.cancelDigitalTestWarning')
            : t('booking.myBookedTest.cancelPhysicalTesWarning')
        }
        headerText={t('booking.myBookedTest.cancelBookingModalHeader')}
        confirmText={t('general.general.yes')}
        cancelText={t('general.general.no')}
        invertButtons
      />
    </>
  );
};

export const LicensingExams = () => {
  const { t } = useTranslations();
  const { licensingExams, isLoading, error } = useGetExams();
  const filteredLicensingExams = licensingExams
    .filter((exam) => {
      const startDate = new Date(exam.start);
      startDate.setMinutes(startDate.getMinutes() + exam.duration);

      return startDate > new Date();
    })
    .filter((exam) => exam.status !== 'COMPLETED');

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <Section title={t('booking.myBookedTest.licenseTitle')}>
      <Box alignContent="center">
        <ContentLoader status={isLoading ? 'loading' : 'idle'} template="examBooking">
          {filteredLicensingExams.length === 0 ? (
            <EmptyComponent title={t('booking.myBookedTest.noTestsBooked')} />
          ) : (
            filteredLicensingExams.map((exam, index) => (
              <Box key={index} flexDirection="row" alignItems="center" padding="base" $wrap>
                <Typography.Paragraph color="main" marginRight="base-tight">
                  <b>
                    {exam.title}, {moment(exam.start).format('YYYY-MM-DD, HH:mm')}
                  </b>
                  <br />
                  {exam.location}
                </Typography.Paragraph>
                <StyledButtonWrapper>
                  <StartTestModal exam={exam} />
                  <CancelBookingModal exam={exam} />
                </StyledButtonWrapper>
              </Box>
            ))
          )}
        </ContentLoader>
      </Box>
    </Section>
  );
};
