import type { TemplatesType } from 'components/misc/ContentLoader/ContentSkeleton';
import ContentLoader from 'components/misc/ContentLoader/ContentSkeleton';
import type { AsyncObject } from 'store/utilities';
import { useTranslations } from 'translations/hooks/useTranslations';
type ContentLoaderWrapperProps = {
  children: any;
  template: TemplatesType;
  errorComponent?: any;
} & AsyncObject;

const ContentLoaderWrapper = ({ children, template, status, errorComponent }: ContentLoaderWrapperProps) => {
  const { t } = useTranslations();

  if (status === 'idle') {
    return children === null ? <ContentLoader template={template} /> : children;
  } else if (status === 'loading') {
    return <ContentLoader template={template} />;
  } else if (status === 'finished') return children;
  else if (status === 'error') {
    return errorComponent || <p>{t('general.contentLoader.failedToLoad')}</p>;
  } else return <p>{t('unknownError')}</p>;
};

export default ContentLoaderWrapper;
