import type { ColumnDef } from '@tanstack/react-table';
import { useGetTranslations } from 'api/translations/hooks/useGetTranslations';
import { useDeleteTranslationsMutation, type Translation } from 'api/translations/translations';
import { Box } from 'components/basic-components';
import { IconButton } from 'components/basic-components/IconButton/IconButton';
import { Input } from 'components/basic-components/Input/Input';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader, Modal, Select } from 'components/misc';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { Trash2 } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { isSysAdminSelector } from 'store/selectors/identity';
import styled from 'styled-components';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import type { Languages } from 'translations/i18next';
import { useDebounce } from 'utilities/useDebounce';
import { CreateTranslation } from './CreateTranslation';
import { UpdateTranslation } from './UpdateTranslation';

const StyledIconButton = styled(IconButton)`
  justify-content: end;
`;

export const Translations = () => {
  const navigate = useNavigate();
  const { t, getMissmatchingTranslations } = useTranslations();
  const [translationToDelete, setTranslationToDelete] = useState<Translation>();
  const isSysAdmin = useAppSelector(isSysAdminSelector);
  const [key, setKey] = useState('');
  const [lang, setLang] = useState<Languages>('sv');
  const [selectedTranslation, setSelectedTranslation] = useState<Translation>();
  const [showCreateTranslation, setShowCreateTranslation] = useState(false);
  const debouncedKey = useDebounce<string>(key, 200);
  const { translations, isLoading, isError } = useGetTranslations({ key: debouncedKey, lang });
  const [deleteTranslation, { isSuccess }] = useDeleteTranslationsMutation();

  const columns = useMemo<ColumnDef<Translation>[]>(
    () => [
      {
        accessorKey: 'lang',
        header: () => t('translations.lang'),
        size: 50,
      },
      {
        accessorKey: 'key',
        header: () => t('translations.key'),
        size: undefined,
      },
      {
        accessorKey: 'content',
        header: () => t('translations.content'),
        size: undefined,
      },
      {
        accessorKey: 'delete',
        header: () => '',
        cell: ({ row }) => (
          <Box flex="1">
            <StyledIconButton
              icon={<Trash2 color={theme.colors['error-enhanced']} />}
              onClick={(e: any) => {
                e.stopPropagation();
                setTranslationToDelete(row.original);
              }}
            />
          </Box>
        ),
        size: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const downloadTranslationsReport = () => {
    const report = getMissmatchingTranslations();

    const jsonString = JSON.stringify(report);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.href = URL.createObjectURL(blob);
    link.download = `translations_report_${process.env.REACT_APP_ENVIRONMENT}_${new Date().toISOString().split('T')[0]}.json`;
    link.click();

    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (isSuccess) {
      setTranslationToDelete(undefined);
    }
  }, [isSuccess]);

  return (
    <>
      <PageHeader title={t('translations.title')} onBack={() => navigate('/')} />
      {translationToDelete && (
        <Modal
          isShown={Boolean(translationToDelete)}
          hide={() => setTranslationToDelete(undefined)}
          onConfirm={() => deleteTranslation(translationToDelete)}
          onCancel={() => setTranslationToDelete(undefined)}
          message={t('translations.modal.deleteMessage', { key: translationToDelete.key })}
          headerText={t('translations.modal.deleteHeader')}
          confirmText={t('general.general.yes')}
          cancelText={t('general.general.no')}
          invertButtons
        />
      )}
      <Box gap="base" flexDirection="row" marginBottom="loose">
        <Box flex="2">
          <Input placeholder={t('translations.searchInput')} value={key} onChange={(value: string) => setKey(value)} />
        </Box>
        <Box flex="1" alignItems="end">
          <Select
            width="100% !important"
            height="100%"
            variant="select"
            defaultValue={lang}
            onChange={(e) => setLang(e.currentTarget.value as Languages)}
          >
            <option value="sv">{t('general.languages.sv')}</option>
            <option value="en">{t('general.languages.en')}</option>
          </Select>
        </Box>
      </Box>
      {showCreateTranslation && <CreateTranslation show={showCreateTranslation} onClose={() => setShowCreateTranslation(false)} />}
      {selectedTranslation && <UpdateTranslation translation={selectedTranslation} setTranslation={setSelectedTranslation} />}
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={isError ? [] : translations}
          onRowClick={(row) => setSelectedTranslation(row.original)}
          emptyText={t('translations.noResponse')}
          failedFetch={isError}
        />
      </ContentLoader>
      <ActionButtonRow
        primaryOnClick={() => setShowCreateTranslation(true)}
        primaryText={t('translations.create')}
        secondaryOnClick={isSysAdmin ? downloadTranslationsReport : () => {}}
        secondaryText={isSysAdmin ? t('translations.getReport') : ''}
      />
    </>
  );
};
