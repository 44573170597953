import { authApi } from 'api/auth/auth';
import { educateITBaseApi, onlineApi, privateApi, publicApi } from 'api/base';
import { type NavigateFunction } from 'react-router-dom';
import type { AppDispatch } from 'store';
import { actions } from 'store';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import { clearSession, getLoggedInUser } from './tokenStorage';

export const logout = (navigate?: NavigateFunction, redirectPath?: string) => {
  return async (dispatch: AppDispatch) => {
    const isIntegrated = useIsIntegrated();
    try {
      const impersonatedID = sessionStorage.getItem('ACTIVE_USER_ID');
      localStorage.removeItem('READ_SYSTEM_MESSAGES');

      const currentUserID = getLoggedInUser()?.userId;
      const userId = parseInt(impersonatedID ?? '') ?? currentUserID;

      dispatch(actions.identity['reset']());
      dispatch(onlineApi.util.resetApiState());
      dispatch(privateApi.util.resetApiState());
      dispatch(educateITBaseApi.util.resetApiState());
      dispatch(publicApi.util.resetApiState());
      dispatch(actions.general['reset-sidebar']());
      dispatch(actions.reservation['reset']());
      dispatch(authApi.endpoints.logout.initiate());

      if (navigate) {
        if (isIntegrated) {
          dispatch(actions.general.GoToView(navigate, { isIntegrated, postMessageOptions: { target: 'VIEW_PROFILE', userId } }));
        } else {
          navigate(`/login${redirectPath && redirectPath !== '/' && redirectPath !== '/login' ? `?location=${redirectPath}` : ''}`, {
            replace: true,
            state: {
              redirectTo: redirectPath,
            },
          });
        }
      }

      clearSession();
    } catch (err) {}
  };
};
