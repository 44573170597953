import ReactContentLoader from 'react-content-loader';

const templates = {
  radioButton: {
    heigh: 150,
    width: 500,
    svg: (
      <>
        <circle cx="302" cy="110" r="14" />
        <rect x="325" y="19" rx="11" ry="11" width="160" height="22" />
        <rect x="325" y="59" rx="11" ry="11" width="160" height="22" />
        <rect x="325" y="99" rx="11" ry="11" width="160" height="22" />
        <circle cx="301" cy="30" r="14" />
        <circle cx="302" cy="70" r="14" />
        <circle cx="30" cy="110" r="14" />
        <rect x="53" y="19" rx="11" ry="11" width="160" height="22" />
        <rect x="53" y="59" rx="11" ry="11" width="160" height="22" />
        <rect x="53" y="99" rx="11" ry="11" width="160" height="22" />
        <circle cx="29" cy="30" r="14" />
        <circle cx="30" cy="70" r="14" />
      </>
    ),
  },
  applications: {
    heigh: 150,
    width: '100%',
    svg: (
      <>
        <rect x="1%" y="102" rx="3" ry="3" width="98%" height="22" />
        <rect x="1%" y="16" rx="3" ry="3" width="98%" height="22" />
        <rect x="1%" y="58" rx="3" ry="3" width="98%" height="22" />
      </>
    ),
  },
  checkbox: {
    heigh: 150,
    width: 500,
    svg: (
      <>
        <rect x="291" y="102" rx="3" ry="3" width="22" height="22" />
        <rect x="291" y="16" rx="3" ry="3" width="22" height="22" />
        <rect x="291" y="58" rx="3" ry="3" width="22" height="22" />
        <rect x="326" y="102" rx="3" ry="3" width="167" height="22" />
        <rect x="326" y="16" rx="3" ry="3" width="167" height="22" />
        <rect x="326" y="58" rx="3" ry="3" width="167" height="22" />
        <rect x="7" y="102" rx="3" ry="3" width="22" height="22" />
        <rect x="7" y="16" rx="3" ry="3" width="22" height="22" />
        <rect x="7" y="58" rx="3" ry="3" width="22" height="22" />
        <rect x="42" y="102" rx="3" ry="3" width="167" height="22" />
        <rect x="42" y="16" rx="3" ry="3" width="167" height="22" />
        <rect x="42" y="58" rx="3" ry="3" width="167" height="22" />
      </>
    ),
  },
  dots: {
    heigh: 15,
    width: 50,
    svg: (
      <>
        <circle cx="7" cy="8" r="6" />
        <circle cx="43" cy="8" r="6" />
        <circle cx="25" cy="8" r="6" />
      </>
    ),
  },
  swedsecMark: {
    heigh: 155.3,
    width: 200,
    svg: (
      <>
        <path d="M 192.59 31.4 h -15.68 c -0.83 0 -1.19 1.07 -0.52 1.56 c 4.21 3.01 6.07 7 3.49 13.51 l -13.73 34.62 c -0.29 0.74 -1.35 0.73 -1.62 -0.02 l -12.47 -34.45 c -2.44 -6.61 -1.34 -11.05 2.01 -13.64 c 0.67 -0.51 0.34 -1.57 -0.5 -1.57 h -31.96 c -0.81 0 -1.17 1 -0.55 1.53 c 3.78 3.24 5.68 8.2 8.13 13.38 l 2.15 5.45 l -14.2 30.58 c -0.31 0.68 -1.28 0.67 -1.58 -0.02 L 99.97 46.62 c -2.92 -6.6 -1.64 -11.04 1.81 -13.63 c 0.68 -0.51 0.35 -1.58 -0.5 -1.58 H 79.02 c -18.94 0 -19.6 -2.88 -31.96 -2.88 c -24.49 0 -43.02 14.61 -43.02 35.05 c 0 19.14 18.27 30.04 34.8 38.41 c 14.93 7.56 29.04 14.85 29.04 29.27 s -9.09 21.5 -25.59 21.5 s -31 -17.74 -36.3 -25.5 c -0.86 -1.27 -2.82 -0.86 -3.13 0.64 L 0.07 141.92 c -0.28 1.42 0.31 2.88 1.52 3.65 c 9.67 6.17 25.42 9.73 40.87 9.73 c 27.28 0 45.41 -14.6 45.41 -36.19 S 68.65 87.79 51.2 79.26 c -14.67 -7.17 -28.53 -14.08 -28.53 -28.54 c 0 -12.64 9.63 -19.69 23.73 -19.69 s 27.69 10.5 35.31 25.85 l 23.44 52.27 c 0.61 1.35 2.51 1.37 3.15 0.03 l 24.99 -52.69 l 22.08 52.4 c 0.6 1.42 2.62 1.41 3.2 -0.02 l 26.04 -64.09 c 1.42 -3.77 3.29 -8.43 8.44 -11.8 c 0.72 -0.47 0.39 -1.59 -0.47 -1.59 l 0.01 0.01 z" />
      </>
    ),
  },
  calendar: {
    heigh: 270,
    width: 270,
    svg: (
      <>
        <rect x="9" y="62" rx="5" ry="5" width="100" height="5" />
        <circle cx="157" cy="34" r="14" />
        <rect x="178" y="23" rx="11" ry="11" width="85" height="22" />
        <circle cx="15" cy="34" r="14" />
        <rect x="37" y="23" rx="11" ry="11" width="85" height="22" />
        <rect x="9" y="3" rx="5" ry="5" width="100" height="5" />
        <rect x="1" y="85" rx="16" ry="16" width="265" height="175" />
      </>
    ),
  },
  occasions: {
    heigh: 270,
    width: 270,
    svg: (
      <>
        <rect rx="5" stroke="null" id="svg_14" height="54" width="196.00001" y="84" x="4.34897" />
        <rect rx="5" stroke="null" id="svg_15" height="54" width="196.00001" y="145" x="4.34897" />
        <rect rx="5" stroke="null" id="svg_16" height="54" width="196.00001" y="204.83772" x="4.34897" />
        <rect rx="5" stroke="null" id="svg_17" height="7" width="177" y="2" x="2.84897" />
        <rect rx="5" stroke="null" id="svg_18" height="7" width="177" y="33.40064" x="2.84897" />
        <rect rx="5" stroke="null" id="svg_19" height="3.9548" width="100.00001" y="18.29706" x="14.84897" />
        <rect rx="5" stroke="null" id="svg_20" height="3.9548" width="100.00001" y="53.35415" x="14.84897" />
      </>
    ),
  },
  examBooking: {
    heigh: 80,
    width: 720,
    svg: (
      <>
        <rect x="3" y="45" rx="2" ry="2" width="381" height="32" />
        <rect x="3" y="2" rx="2" ry="2" width="381" height="32" />
        <rect x="528" y="18" rx="6" ry="6" width="189" height="44" />
        <rect x="390" y="18" rx="6" ry="6" width="129" height="44" />
      </>
    ),
  },
  homeCertificates: {
    heigh: 80.4,
    width: 300,
    svg: (
      <>
        <rect x="0" y="25" rx="3" ry="3" width="300" height="30" />
      </>
    ),
  },
  homeLicenses: {
    heigh: 46,
    width: 300,
    svg: (
      <>
        <rect x="0" y="8" rx="3" ry="3" width="300" height="30" />
      </>
    ),
  },
  homeAku: {
    heigh: 75,
    width: 300,
    svg: (
      <>
        <rect x="0" y="0" rx="3" ry="3" width="300" height="75" />
      </>
    ),
  },
  homeTodos: {
    heigh: 128,
    width: 300,
    svg: (
      <>
        <rect x="0" y="0" rx="10" ry="10" width="300" height="128" />
      </>
    ),
  },
};

export type TemplatesType = keyof typeof templates;

type ContentSkeletonProps = {
  template?: TemplatesType;
};

export const ContentSkeleton = ({ template = 'radioButton' }: ContentSkeletonProps) => (
  <ReactContentLoader
    speed={2}
    width={templates[template].width}
    height={templates[template].heigh}
    viewBox={`0 0 ${templates[template].width} ${templates[template].heigh}`}
    backgroundColor="#fff"
    foregroundColor="#d7e0e9"
  >
    {templates[template].svg}
  </ReactContentLoader>
);

export default ContentSkeleton;
