import { Box, Typography, UnorderedList } from 'components/basic-components';
import { useAppSelector } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';

const EIT_PRECONDITION_LINK = process.env.REACT_APP_EIT_PRECONDITION_LINK;

export const DigitalTestPolicy = () => {
  const { t } = useTranslations();
  const licensingTest = useAppSelector((state) => state.reservation.licensingExam);
  const examMonitoringType = useAppSelector((state) => state.reservation)?.bookingDetails?.examMonitoringType || null;

  return (
    examMonitoringType &&
    licensingTest && (
      <>
        {examMonitoringType.type === 'Digital' && (
          <Box flex={1}>
            <Typography.H2 color="main">{t('booking.booking.precondition')}</Typography.H2>
            <Box backgroundColor="neutral-color-2" borderRadius="m" padding="base" $wrap>
              <Typography.Paragraph textAlign="start" display="inline">
                {t('booking.booking.preconditionDetails')}
              </Typography.Paragraph>
              <UnorderedList>
                <li>
                  <Typography.Paragraph>{t('booking.booking.preconditionsList.0')}</Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>{t('booking.booking.preconditionsList.1')}</Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>{t('booking.booking.preconditionsList.2')}</Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>{t('booking.booking.preconditionsList.3')}</Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>
                    <span>
                      {t('booking.booking.preconditionsList.4.0')}
                      <a href={EIT_PRECONDITION_LINK} target="_blank" style={{ display: 'inline' }} rel="noreferrer">
                        {t('booking.booking.preconditionsList.4.1')}
                      </a>
                      {t('booking.booking.preconditionsList.4.2')}
                    </span>
                  </Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>{t('booking.booking.preconditionsList.5')}</Typography.Paragraph>
                </li>
                <li>
                  <Typography.Paragraph>{t('booking.booking.preconditionsList.6')}</Typography.Paragraph>
                </li>
              </UnorderedList>
            </Box>
          </Box>
        )}
      </>
    )
  );
};
