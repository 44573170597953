import type { Subject } from 'api/exams/exams';
import { Box } from 'components/basic-components';
import { RadioButton } from 'components/form-components';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import SectionWrapper from './SectionWrapper';
import { useGetArticles } from './hooks/useGetArticles';

export const TestSubjects = () => {
  const { t } = useTranslations();
  const { subjects, isLoading } = useGetArticles();
  const selectedSubject = useAppSelector((state) => state.reservation.bookingDetails.selectedSubject);
  const dispatch = useAppDispatch();

  const handleOnChange = async (subject: Subject) => {
    await dispatch(actions.reservation['reset']());
    await dispatch(actions.reservation['set-subject'](subject));
  };

  return (
    <Box alignItems="flex-start">
      <SectionWrapper
        externalLinkLabel={t('booking.booking.topicsExtLink')}
        externalLink="https://www.swedsec.se/tester/"
        title={t('booking.booking.t-families-title')}
        loading={subjects.length === 0}
        status={isLoading ? 'loading' : 'idle'}
      >
        {subjects.map((subject) => (
          <RadioButton
            key={subject.title}
            name="testSubject"
            value={subject.title}
            label={subject.title}
            disabled={!subject.exam_specifications ? true : false}
            onChange={() => handleOnChange(subject)}
            checked={subject.title === selectedSubject?.title}
          />
        ))}
      </SectionWrapper>
    </Box>
  );
};
