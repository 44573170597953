import type React from 'react';
import type { AnchorHTMLAttributes } from 'react';
import { fontFamilies } from '../../../theme';
import type { StyledSystemProps } from '../../DynamicStyledSystemComponent';
import { DynamicStyledSystemComponent } from '../../DynamicStyledSystemComponent';

export interface TypographyStyles {
  H1: StyledSystemProps;
  H2: StyledSystemProps;
  H3: StyledSystemProps;
  H4: StyledSystemProps;
  Paragraph: StyledSystemProps;
  SmallParagraph: StyledSystemProps;
  Label: StyledSystemProps;
  Anchor: StyledSystemProps;
}

export const typographyStyles: TypographyStyles = {
  H1: {
    fontSize: '2em',
    marginY: 'tight',
    marginX: 'none',
    color: 'main',
    fontWeight: 'semi-bold',
    fontFamily: fontFamilies['Sans-Serif'],
    as: 'h1',
  },
  H2: {
    fontSize: '1.5em',
    marginY: 'base-tight',
    marginX: 'none',
    fontWeight: 'semi-bold',
    color: 'main',
    fontFamily: fontFamilies['Sans-Serif'],
    as: 'h2',
  },
  H3: {
    fontSize: '1.17em',
    fontWeight: 'semi-bold',
    marginY: 'base',
    marginX: 'none',
    color: 'main',
    fontFamily: fontFamilies['Sans-Serif'],
    as: 'h3',
  },
  H4: {
    fontSize: '1em',
    fontWeight: 'semi-bold',
    marginY: 'loose',
    marginX: 'none',
    color: 'main',
    fontFamily: fontFamilies['Sans-Serif'],
    as: 'h4',
  },
  Paragraph: {
    fontSize: [16],
    fontWeight: 'light',
    fontFamily: fontFamilies['Sans-Serif'],
    alignSelf: 'flex-start',
    width: '100%',
    as: 'p',
  },
  SmallParagraph: {
    fontSize: [13, 14, 14, 15],
    fontWeight: 'light',
    fontFamily: fontFamilies['Sans-Serif'],
    display: 'inline',
    as: 'p',
  },
  Label: {
    fontWeight: 'regular',
    color: 'main',
    fontFamily: fontFamilies['Sans-Serif'],
    fontSize: ['16px'],
    textAlign: 'left',
    alignSelf: 'flex-start',
    as: 'label',
  },
  Anchor: {
    fontWeight: 'light',
    color: 'main',
    fontSize: [14, 15, 15, 16],
    display: 'inline',
    fontFamily: fontFamilies['Sans-Serif'],
  },
};

type AnchorProps = { target?: AnchorHTMLAttributes<unknown> } & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Anchor: React.FC<AnchorProps> = ({ href, target, children, onClick, className }) => (
  <a
    target={target}
    href={href}
    onClick={onClick}
    className={className}
    style={{
      cursor: onClick && 'pointer',
    }}
  >
    <DynamicStyledSystemComponent textStyle="anchor" display="inline">
      {children}
    </DynamicStyledSystemComponent>
  </a>
);

interface TypographyComponentProps {
  H1: React.FC<StyledSystemProps>;
  H2: React.FC<StyledSystemProps>;
  H3: React.FC<StyledSystemProps>;
  H4: React.FC<StyledSystemProps>;
  Paragraph: React.FC<StyledSystemProps>;
  SmallParagraph: React.FC<StyledSystemProps>;
  Label: React.FC<StyledSystemProps>;
  Anchor: React.FC<AnchorProps>;
}

const createComponent: (textStyle: any, displayName: string) => React.FC<StyledSystemProps> = (textStyle, displayName) => {
  const component: React.FC<StyledSystemProps> = (props) => <DynamicStyledSystemComponent {...textStyle} {...props} />;
  component.displayName = displayName;
  return component;
};

export const Typography: TypographyComponentProps = {
  H1: createComponent(typographyStyles.H1, 'H1'),
  H2: createComponent(typographyStyles.H2, 'H2'),
  H3: createComponent(typographyStyles.H3, 'H3'),
  H4: createComponent(typographyStyles.H4, 'H4'),
  Paragraph: createComponent(typographyStyles.Paragraph, 'Paragraph'),
  SmallParagraph: createComponent(typographyStyles.SmallParagraph, 'SmallParagraph'),
  Label: createComponent(typographyStyles.Label, 'Label'),
  Anchor,
};
