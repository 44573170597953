import { ForgetPassword, ResetPassword } from 'pages/public/password';
import { Route, Routes } from 'react-router-dom';

export const PasswordRoutes = () => {
  return (
    <Routes>
      <Route path="reset" element={<ResetPassword />} />
      <Route path="forgot-password" element={<ForgetPassword />} />
    </Routes>
  );
};
