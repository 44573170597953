import { Box, Button } from 'components/basic-components';
import { Section } from 'components/misc';

type ActionButtonRowProps = {
  primaryOnClick?: () => void;
  secondaryOnClick?: () => void;
  primaryText?: string | React.ReactNode;
  secondaryText?: string;
  disabled?: boolean;
};

export const ActionButtonRow = ({ primaryOnClick, secondaryOnClick, primaryText, secondaryText, disabled }: ActionButtonRowProps) => (
  <Section>
    <Box padding="base-tight" flexDirection="row-reverse" gap="base">
      {(primaryText || primaryOnClick) && (
        <Button type="submit" justifyContent="center" alignSelf="flex-end" onClick={primaryOnClick} disabled={disabled}>
          {primaryText}
        </Button>
      )}
      {secondaryOnClick && (
        <Button type="button" variant="secondary" justifyContent="center" alignSelf="flex-end" onClick={secondaryOnClick}>
          {secondaryText}
        </Button>
      )}
    </Box>
  </Section>
);
