import { Box, Typography } from 'components/basic-components';
import { Icon } from 'components/misc';
import { ArchiveX } from 'lucide-react';
import { theme } from 'theme';

type EmptyComponentProps = {
  title: string;
};

export const EmptyComponent = ({ title }: EmptyComponentProps) => (
  <Box justifyContent="center" alignItems="center" padding="base-tight">
    <Icon color="complementary-color-2">
      <ArchiveX color={theme.colors['complementary-color-2']} />
    </Icon>
    <Typography.Paragraph textAlign="center" color="complementary-color-2" marginTop="base-tight">
      {title}
    </Typography.Paragraph>
  </Box>
);
