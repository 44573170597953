import type { UpdateSystemMessageRequest } from 'api/system/system';
import { useUpdateSystemMessagesMutation, type SystemMessage } from 'api/system/system';
import { Box, Typography } from 'components/basic-components';
import { RadioButton, TextField } from 'components/form-components';
import { Modal } from 'components/misc/Modal/Modal';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import * as Yup from 'yup';

type UpdateSystemMessageProps = {
  systemMessage: SystemMessage;
  setSystemMessage: (systemMessage: SystemMessage | undefined) => void;
};

const StyledDatePicker = styled(DatePicker)`
  padding: ${theme.space['base-tight']};
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  border-color: ${theme.colors.main};
  ${() => theme.textStyles['text-input']};
`;

export const UpdateSystemMessage = ({ systemMessage, setSystemMessage }: UpdateSystemMessageProps) => {
  const { t, language } = useTranslations();
  const [updateSystemMessage, { isSuccess }] = useUpdateSystemMessagesMutation();

  const handleSubmit = async (values: UpdateSystemMessageRequest) => {
    await updateSystemMessage(values);
  };

  useEffect(() => {
    if (isSuccess) {
      setSystemMessage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{
        swedishMessage: systemMessage.swedishMessage,
        englishMessage: systemMessage.englishMessage,
        type: systemMessage.type,
        fromDate: new Date(systemMessage.fromDate),
        toDate: new Date(systemMessage.toDate),
        id: systemMessage.id,
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        swedishMessage: Yup.string().required('systemMessage.modal.noMessage'),
        englishMessage: Yup.string().required('systemMessage.modal.noenglishMessage'),
        type: Yup.string().required('translations.modal.noType'),
      })}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <Modal
          key="start"
          isShown={Boolean(systemMessage)}
          hide={() => setSystemMessage(undefined)}
          onConfirm={submitForm}
          onCancel={() => setSystemMessage(undefined)}
          headerText={t('systemMessage.modal.updateHeader')}
          confirmText={t('systemMessage.modal.save')}
          cancelText={t('systemMessage.modal.cancel')}
          disableConfirm={isSubmitting}
        >
          <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', minWidth: '300px' }}>
            <Box flexDirection="row" gap="base" marginBottom="base">
              <RadioButton name="type" label={t('systemMessage.ERROR')} value="ERROR" />
              <RadioButton name="type" label={t('systemMessage.SUCCESS')} value="SUCCESS" />
              <RadioButton name="type" label={t('systemMessage.INFO')} value="INFO" />
            </Box>
            <TextField
              required
              type="text"
              name="swedishMessage"
              placeholder={t('systemMessage.modal.swedishMessage')}
              label={t('systemMessage.modal.swedishMessage')}
              autoComplete="on"
            />
            <TextField
              required
              type="text"
              name="englishMessage"
              placeholder={t('systemMessage.modal.englishMessage')}
              label={t('systemMessage.modal.englishMessage')}
              autoComplete="on"
            />
            <Box marginBottom="base">
              <Typography.Label>{t('systemMessage.modal.fromDate')}</Typography.Label>
              <StyledDatePicker
                selected={values.fromDate}
                onChange={(date) => setFieldValue('fromDate', date)}
                adjustDateOnChange
                disabledKeyboardNavigation
                locale={language}
                dateFormat="YYYY-MM-dd"
                onKeyDown={(e) => e.preventDefault()}
              />
            </Box>
            <Box marginBottom="base">
              <Typography.Label>{t('systemMessage.modal.toDate')}</Typography.Label>
              <StyledDatePicker
                selected={values.toDate}
                onChange={(date) => setFieldValue('toDate', date)}
                adjustDateOnChange
                disabledKeyboardNavigation
                locale={language}
                dateFormat="YYYY-MM-dd"
                onKeyDown={(e) => e.preventDefault()}
              />
            </Box>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
