import { Box, Button, Typography } from 'components/basic-components';
import { TriangleAlert } from 'lucide-react';
import { useTranslations } from 'translations/hooks/useTranslations';
import { Icon } from '..';

type FallbackProps = {
  reset: () => void;
};

const Fallback = ({ reset }: FallbackProps) => {
  const { t } = useTranslations();

  return (
    <Box alignContent="center" justifyContent="center" height="100%">
      <Icon>
        <TriangleAlert color="#ea515a" />
      </Icon>
      <Typography.H3 alignSelf="center" color="main" fontWeight="bold">
        {t('general.apiResponses.error_boundary_txt')}
      </Typography.H3>
      <Button variant="transparent" onClick={reset} type="button" alignSelf="center" color="main">
        {t('general.apiResponses.try_again')}
      </Button>
    </Box>
  );
};

export default Fallback;
