import styled from 'styled-components';
import type {
  BackgroundImageProps,
  BackgroundProps,
  BorderColorProps,
  BorderProps,
  BorderStyleProps,
  BorderWidthProps,
  ColorProps,
  FlexProps,
  FlexboxProps,
  GridAreaProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
} from 'styled-system';
import {
  background,
  backgroundImage,
  border,
  borderColor,
  borderStyle,
  borderWidth,
  color,
  flex,
  flexbox,
  gridArea,
  layout,
  position,
  space,
} from 'styled-system';
import type { ThemeSchema } from 'theme';
import { theme } from 'theme';

type StyledTableProps = BackgroundProps &
  BackgroundImageProps &
  BorderProps<ThemeSchema> &
  BorderColorProps<ThemeSchema> &
  BorderStyleProps &
  BorderWidthProps &
  ColorProps<ThemeSchema> &
  FlexProps<ThemeSchema> &
  FlexboxProps<ThemeSchema> &
  GridAreaProps &
  LayoutProps<ThemeSchema> &
  PositionProps &
  SpaceProps<ThemeSchema>;

export const StyledTable = styled.table<StyledTableProps>`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: solid 1px ${theme.colors['neutral-color-1']} !important;
  ${theme.textStyles['body-light']};

  ${background}
  ${backgroundImage} 
  ${border} 
  ${borderColor}
  ${borderStyle}
  ${borderWidth} 
  ${color} 
  ${flex} 
  ${flexbox} 
  ${gridArea}
  ${layout}
  ${position} 
  ${space}
`;
