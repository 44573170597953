import type { AsyncObject } from 'store/utilities';
import { asyncInitialValue } from 'store/utilities';

export type UserRoles =
  | 'ROLE_SYS_ADMIN'
  | 'ROLE_ADMIN'
  | 'ROLE_TEST_LEADER'
  | 'ROLE_SUBCONTRACTOR'
  | 'ROLE_COMPLIANCE'
  | 'ROLE_SWEDSEC_ADMIN'
  | 'ROLE_AKU_REPORTER'
  | 'ROLE_USER';

export type ImpersonantedProfile = {
  id: string;
};

export interface State {
  access: AsyncObject;
  profile: {
    userId?: number;
    username?: string;
    company?: number;
    authorities?: Array<UserRoles>;
    jti?: string;
  };
  impersonatedUserId?: number;
}

export const initialState: State = {
  access: asyncInitialValue,
  profile: {
    userId: undefined,
    username: undefined,
    company: undefined,
    authorities: undefined,
    jti: undefined,
  },
};
