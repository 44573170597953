import { createGlobalStyle } from 'styled-components';
import type { ThemeSchema } from 'theme';

export const GlobalStyles = createGlobalStyle<{ theme: ThemeSchema }>`
  html, body {
    margin: 0;
    padding: 0;
    overflow-y: auto
  }
  *, *::after, *::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
   #root {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  body {
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    min-height: 100vh;
    justify-content: center;
    text-rendering: optimizeLegibility;
    overflow: hidden;
  }
  small {
    display: block;
  }
  small, p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
    overflow: hidden;
    max-width: 100%;
    white-space: normal;
  }
  `;
