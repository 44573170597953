import type React from 'react';
import styled, { css } from 'styled-components';
import type {
  BackgroundImageProps,
  BackgroundProps,
  BorderProps,
  BorderWidthProps,
  ColorProps,
  FlexProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
} from 'styled-system';
import { background, backgroundImage, border, borderWidth, color, flex, flexbox, grid, layout, position, space } from 'styled-system';
import type { ThemeSchema } from '../../../theme';
import { breakpoints } from '../../../theme';

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  isOwner?: boolean;
}
type Props = BoxProps &
  BackgroundProps &
  BackgroundImageProps &
  BorderProps<ThemeSchema> &
  BorderWidthProps &
  ColorProps<ThemeSchema> &
  FlexProps<ThemeSchema> &
  FlexboxProps<ThemeSchema> &
  GridProps &
  LayoutProps<ThemeSchema> &
  PositionProps &
  SpaceProps<ThemeSchema>;

const GridBox = styled.div<Props>(
  {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
  },
  background,
  backgroundImage,
  border,
  borderWidth,
  color,
  grid,
  flex,
  flexbox,
  layout,
  position,
  space,
  css`
    grid-auto-flow: row;
    @media only screen and (max-width: ${breakpoints.sm}) {
      grid-template-columns: 1fr !important;
    }
    @media only screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
      grid-template-columns: 1fr 1fr !important;
    }
  `
);

GridBox.defaultProps = {
  display: 'grid',
  width: '100%',
};

export default GridBox;
