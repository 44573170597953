import { useGetUser } from 'api/users/hooks/useGetUser';
import { Box, Typography } from 'components/basic-components';
import { Checkbox } from 'components/form-components';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { CompanyPicker } from 'components/misc/CompanyPicker/CompanyPicker';
import { Modal } from 'components/misc/Modal/Modal';
import { UserPicker } from 'components/misc/UserPicker/UserPicker';
import { Form, Formik } from 'formik';
import { SquarePen } from 'lucide-react';
import { useState } from 'react';
import { useAppSelector } from 'store';
import { isSwedsecAdminSelector } from 'store/selectors/identity';
import type { UserRoles } from 'store/stores/identity/state';
import { useTranslations } from 'translations/hooks/useTranslations';
import { featureFlags } from 'utilities/featureFlags';
import { userConnectCompanyValidationSchema } from 'utilities/validationSchema';

export const UserProfileConnectCompany = () => {
  const { t } = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const [isSelectAdministratorOpen, setIsSelectAdministratorOpen] = useState<boolean>(false);
  const [isSelectCompanyOpen, setIsSelectCompanyOpen] = useState(false);
  const { user, activeUserId } = useGetUser();
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const isLoading = false;

  const handleSubmit = async ({ companyId, userId, role }: { companyId?: number; userId?: number; role?: UserRoles }) => {
    if (!activeUserId || !user) {
      return;
    }

    console.log({ companyId, userId, role });

    setIsEditing(false);
  };

  if (!user) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        companyId: undefined,
        companyName: undefined,
        userId: undefined,
        userName: undefined,
        role: undefined,
      }}
      onSubmit={handleSubmit}
      validationSchema={userConnectCompanyValidationSchema({
        required: 'profile.profile.errors.required',
      })}
    >
      {({ isSubmitting, errors, values, initialValues, submitForm }) => (
        <Form>
          {featureFlags.useConnectCompanies && isSwedsecAdmin && (
            <ActionButtonRow secondaryText={t('profile.profile.connectCompany')} secondaryOnClick={() => setIsEditing(true)} />
          )}
          <Modal
            draggable
            isShown={isEditing}
            headerText={t('profile.profile.companyInfo')}
            hide={() => setIsEditing(false)}
            onCancel={() => setIsEditing(false)}
            onConfirm={submitForm}
            disableConfirm={isLoading || isSubmitting || values === initialValues || Object.values(errors).some((error) => error)}
            confirmText={t('profile.profile.save')}
            cancelText={t('profile.profile.cancel')}
          >
            <Box flexDirection="column" flexWrap="wrap" gap="10px" minWidth="320px">
              <Typography.Label>
                {t('profile.profile.company')}
                <Typography.Paragraph display="flex">
                  {values.companyName ?? 'Ingen vald'}
                  <Box marginLeft="base" maxWidth="24px" maxHeight="24px" alignSelf="center" $clickable>
                    <SquarePen onClick={() => setIsSelectCompanyOpen(true)} />
                  </Box>
                </Typography.Paragraph>
              </Typography.Label>
              <CompanyPicker isOpen={isSelectCompanyOpen} setIsOpen={setIsSelectCompanyOpen} />
              <Typography.Label>
                {t('profile.profile.admin')}
                <Typography.Paragraph display="flex">
                  {values.userName ?? 'Ingen vald'}
                  {values.companyId && (
                    <Box marginLeft="base" maxWidth="24px" maxHeight="24px" alignSelf="center" $clickable>
                      <SquarePen onClick={() => setIsSelectAdministratorOpen(true)} />
                    </Box>
                  )}
                </Typography.Paragraph>
              </Typography.Label>
              <UserPicker
                userRole="ROLE_ADMIN"
                userCompanyId={values.companyId}
                isOpen={isSelectAdministratorOpen}
                setIsOpen={setIsSelectAdministratorOpen}
              />
              <Typography.Label>{t('profile.profile.roles')}</Typography.Label>
              <Checkbox name="roles" label={t('general.roles.ROLE_ADMIN')} value="ROLE_ADMIN" />
              <Checkbox name="roles" label={t('general.roles.ROLE_TEST_LEADER')} value="ROLE_TEST_LEADER" />
            </Box>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
