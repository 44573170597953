import type { ColumnDef } from '@tanstack/react-table';
import type { Endpoint } from 'api/oauth2/oauth2';
import { useGetEndpointsQuery } from 'api/oauth2/oauth2';
import { Box } from 'components/basic-components';
import { Input } from 'components/basic-components/Input/Input';
import { Table } from 'components/basic-components/Table/Table';
import { usePagination } from 'components/basic-components/Table/hooks/usePagination';
import { ContentLoader } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';

export const Endpoints = () => {
  const { t } = useTranslations();
  const [pattern, setPattern] = useState('');
  const navigate = useNavigate();
  const { pageSize, page, onPagination } = usePagination({ pageSize: 500 });
  const { data, isLoading, isError } = useGetEndpointsQuery({ page, size: pageSize });
  const [filteredData, setFilteredData] = useState<Array<Endpoint>>([]);

  const columns = useMemo<ColumnDef<Endpoint>[]>(
    () => [
      {
        accessorKey: 'method',
        header: () => t('oauth2.endpoints.method'),
        size: undefined,
      },
      {
        accessorKey: 'url',
        header: () => t('oauth2.endpoints.url'),
        size: undefined,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (Array.isArray(data?.endpoints)) {
      const newFilteredData = data?.endpoints.filter((endpoint) => endpoint.url.includes(pattern)).sort((a, b) => (a.url > b.url ? 1 : -1));

      setFilteredData(newFilteredData);
    }
  }, [data, pattern]);

  return (
    <>
      <PageHeader title={t('oauth2.endpoints.title')} onBack={() => navigate('/')} />
      <Box gap="base" flexDirection="row" marginBottom="loose">
        <Box flex="2">
          <Input placeholder={t('oauth2.matcher.pattern')} value={pattern} onChange={(value: string) => setPattern(value)} />
        </Box>
      </Box>
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={filteredData}
          rowCount={filteredData?.length}
          pageSize={pageSize}
          onPagination={onPagination}
          page={page}
          emptyText={t('oauth2.endpoints.noResponse')}
          failedFetch={isError}
        />
      </ContentLoader>
    </>
  );
};
