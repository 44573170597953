import { useResetPasswordEmailMutation } from 'api/users/users';
import { Box, Button, Typography } from 'components/basic-components';
import { TextField } from 'components/form-components/';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { errorHandler } from 'utilities/errorHandling';
import * as Yup from 'yup';

export const ForgotPasswordForm = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const [resetPasswordEmail, { isSuccess, reset, error }] = useResetPasswordEmailMutation();

  const handleCancel = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'preprod') {
      window.location.href = process.env.REACT_APP_ASTRUM_HOST ?? '';
    } else {
      navigate('/login');
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    if (error) {
      if ('status' in error) {
        switch (error.status) {
          case 404:
            publish(CustomEventName.API_ERROR, { message: 'USER_NOT_FOUND' });
            break;
          default:
            errorHandler(error);
            break;
        }
      }
    }
  }, [error]);

  return (
    <>
      {!isSuccess ? (
        <>
          <Typography.Paragraph flexWrap="wrap" marginBottom="base" color="main">
            {t('onboarding.forgotPassword.description')}
          </Typography.Paragraph>
          <Formik
            initialValues={{ username: '' }}
            onSubmit={resetPasswordEmail}
            validationSchema={Yup.object().shape({
              username: Yup.string().required('onboarding.forgotPassword.errors.accountIsRequired'),
            })}
          >
            {({ isSubmitting }) => (
              <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px' }}>
                <TextField
                  type="text"
                  name="username"
                  placeholder={t('onboarding.forgotPassword.username')}
                  label={t('onboarding.forgotPassword.usernameLabel')}
                  autoComplete="on"
                  paddingTop="20px"
                />
                <Box display="flex" flexDirection="row" justifyContent="space-between" paddingTop="loose">
                  <Button variant="secondary" onClick={handleCancel}>
                    {t('onboarding.forgotPassword.cancel')}
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    {t('onboarding.forgotPassword.submitButton')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <Typography.H2 color="main" marginBottom="base" marginTop="none">
            {t('onboarding.forgotPassword.resetEmailSent')}
          </Typography.H2>
          <Box gap="20px">
            <Typography.Paragraph color="main">{t('onboarding.forgotPassword.checkEmail')}</Typography.Paragraph>
            <Typography.Paragraph color="main">{t('onboarding.forgotPassword.linkIsValid')}</Typography.Paragraph>
            <Box display="flex" flexDirection="row" justifyContent="space-between" paddingTop="loose">
              <Button variant="secondary" onClick={reset}>
                {t('onboarding.forgotPassword.resend')}
              </Button>{' '}
              <Button onClick={handleLogin}>{t('onboarding.forgotPassword.login')}</Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ForgotPasswordForm;
