import { useState } from 'react';

export const usePagination = ({ pageSize = 20 }) => {
  const [page, setPage] = useState(0);

  const onPagination = (newPage: number) => {
    if (page !== newPage) {
      setPage(newPage);
    }
  };

  return {
    onPagination,
    page,
    pageSize,
  };
};
