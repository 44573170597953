import { privateApi } from 'api/base';

type GetApplicationsRequest = {
  userId: number;
};

type GetApplicationRequest = {
  applicationId: number;
};

export type ApplicationStatus = 'PENDING' | 'NOT_VERIFIED' | 'APPROVED' | 'REJECTED';

export type ApplicationType = 'LICENSE_APPLICATION' | 'EXEMPTION_APPLICATION' | 'DEREGISTRATION';

export type Application = {
  applicationId: number;
  type: 'LICENSE_APPLICATION';
  title: string;
  companyName: string;
  status: ApplicationStatus;
  createDate: Date;
};

type ApiApplication = {
  application_id: number;
  type: 'LICENSE_APPLICATION';
  title: string;
  company_name: string;
  status: 'PENDING' | 'NOT_VERIFIED' | 'APPROVED' | 'REJECTED';
  create_date: Date;
};

type ApiApplications = Array<ApiApplication>;

type ApiApplicationTest = {
  provider_test_result_id: string;
  test_session_id: number;
  test_resource_id: number;
  test_resource: string;
  user_id: number;
  score: number;
  cut_off: number;
  status: number;
  test_date: string;
  is_passed: number;
  is_valid: number;
  is_deleted: number;
  id: number;
};

type ApplicationTest = {
  providerTestResultId: string;
  testSessionId: number;
  testResourceId: number;
  testResource: string;
  userId: number;
  score: number;
  cutOff: number;
  status: number;
  testDate: string;
  isPassed: number;
  isValid: number;
  isDeleted: number;
  id: number;
};

type SingleApiApplication = {
  application_id: number;
  type: ApplicationType;
  title: string;
  company_name: string;
  create_date: Date;
  status: ApplicationStatus;
  user_id: number;
  company_id: number;
  company_is_member: true;
  manually_registered: boolean;
  verbose_licensee_transcript: boolean;
  created_by: number;
  confirm_date: Date;
  approve_date: Date;
  reject_date: null;
  modify_date: Date;
  modify_by: number;
  version: number;
  submitted_values: {
    [key: string]: string;
  };
  view_info: {
    has_no_license_check: boolean;
    valid_license_tests: Array<ApiApplicationTest>;
    latest_test: ApiApplicationTest;
    full_name: string;
    has_passed_license_test: boolean;
    passed_license_test_check: boolean;
    check_errors: Array<string>;
    user_full_name: string;
    show_date: boolean;
    used_days: Array<number>;
    exemption_type_options: {
      [key: number]: string;
    };
    exemption_types: [
      {
        id: 1;
        title: 'license';
        active: boolean;
        deleted: boolean;
      },
      {
        id: 2;
        title: 'license new';
        active: boolean;
        deleted: boolean;
      },
      {
        id: 3;
        title: 'knowledge_updat';
        active: boolean;
        deleted: boolean;
      },
      {
        id: 4;
        title: 'licensetest';
        active: boolean;
        deleted: boolean;
      },
    ];
    is_member_check: boolean;
    has_days_left: boolean;
    exemption_history: Array<string>;
    required_aku_years: Array<number>;
  };
};

type ConfirmApplicationRequest = {
  applicationId: number;
};

export type SingleApplication = {
  applicationId: number;
  type: ApplicationType;
  title: string;
  companyName: string;
  createDate: Date;
  status: ApplicationStatus;
  userId: number;
  companyId: number;
  companyIsMember: true;
  manuallyRegistered: boolean;
  verboseLicenseeTranscript: boolean;
  createdBy: number;
  confirmDate: Date;
  approveDate: Date;
  rejectDate: null;
  modifyDate: Date;
  modifyBy: number;
  version: number;
  submittedValues: {
    [key: string]: string | number;
  };
  viewInfo: {
    hasNoLicenseCheck: boolean;
    validLicenseTests: Array<ApplicationTest>;
    latestTest: ApplicationTest;
    fullName: string;
    hasPassedLicenseTest: boolean;
    passedLicenseTestCheck: boolean;
    checkErrors: Array<string>;
    userFullName: string;
    showDate: boolean;
    usedDays: Array<number>;
    exemptionTypeOptions: {
      [key: number]: string;
    };
    exemptionTypes: [
      {
        id: 1;
        title: 'license';
        active: boolean;
        deleted: boolean;
      },
      {
        id: 2;
        title: 'license new';
        active: boolean;
        deleted: boolean;
      },
      {
        id: 3;
        title: 'knowledge_updat';
        active: boolean;
        deleted: boolean;
      },
      {
        id: 4;
        title: 'licensetest';
        active: boolean;
        deleted: boolean;
      },
    ];
    isMemberCheck: boolean;
    hasDaysLeft: boolean;
    exemptionHistory: Array<string>;
    requiredAkuYears: Array<number>;
  };
};

export const applicationApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getApplications: builder.query<Array<Application>, GetApplicationsRequest>({
      query: ({ userId }) => `forms/users/${userId}`,
      transformResponse: (data: ApiApplications) =>
        data?.map((application) => ({
          applicationId: application.application_id,
          type: application.type,
          title: application.title,
          companyName: application.company_name,
          status: application.status,
          createDate: application.create_date,
        })),
      providesTags: ['Applications'],
    }),
    getApplication: builder.query<SingleApplication, GetApplicationRequest>({
      query: ({ applicationId }) => `forms/${applicationId}/details`,
      transformResponse: (application: SingleApiApplication) => ({
        applicationId: application.application_id,
        type: application.type,
        title: application.title,
        companyName: application.company_name,
        createDate: application.create_date,
        status: application.status,
        userId: application.user_id,
        companyId: application.company_id,
        companyIsMember: application.company_is_member,
        manuallyRegistered: application.manually_registered,
        verboseLicenseeTranscript: application.verbose_licensee_transcript,
        createdBy: application.created_by,
        confirmDate: application.confirm_date,
        approveDate: application.approve_date,
        rejectDate: application.reject_date,
        modifyDate: application.modify_date,
        modifyBy: application.modify_by,
        version: application.version,
        submittedValues: Object.keys(application.submitted_values).reduce(
          (submittedValues, submittedValueKey) => ({
            ...submittedValues,
            [submittedValueKey]: application.submitted_values[submittedValueKey],
          }),
          {}
        ),
        viewInfo: {
          hasNoLicenseCheck: application.view_info.has_no_license_check,
          validLicenseTests: (application.view_info.valid_license_tests ?? []).map((test) => ({
            providerTestResultId: test.provider_test_result_id,
            testSessionId: test.test_session_id,
            testResourceId: test.test_resource_id,
            testResource: test.test_resource,
            userId: test.user_id,
            score: test.score,
            cutOff: test.cut_off,
            status: test.status,
            testDate: test.test_date,
            isPassed: test.is_passed,
            isValid: test.is_valid,
            isDeleted: test.is_deleted,
            id: test.id,
          })),
          latestTest: {
            providerTestResultId: application.view_info.latest_test?.provider_test_result_id,
            testSessionId: application.view_info.latest_test?.test_session_id,
            testResourceId: application.view_info.latest_test?.test_resource_id,
            testResource: application.view_info.latest_test?.test_resource,
            userId: application.view_info.latest_test?.user_id,
            score: application.view_info.latest_test?.score,
            cutOff: application.view_info.latest_test?.cut_off,
            status: application.view_info.latest_test?.status,
            testDate: application.view_info.latest_test?.test_date,
            isPassed: application.view_info.latest_test?.is_passed,
            isValid: application.view_info.latest_test?.is_valid,
            isDeleted: application.view_info.latest_test?.is_deleted,
            id: application.view_info.latest_test?.id,
          },
          fullName: application.view_info.full_name,
          hasPassedLicenseTest: application.view_info.has_passed_license_test,
          passedLicenseTestCheck: application.view_info.passed_license_test_check,
          checkErrors: application.view_info.check_errors,
          userFullName: application.view_info.user_full_name,
          showDate: application.view_info.show_date,
          usedDays: application.view_info.used_days,
          exemptionTypeOptions: application.view_info.exemption_type_options,
          exemptionTypes: application.view_info.exemption_types,
          isMemberCheck: application.view_info.is_member_check,
          hasDaysLeft: application.view_info.has_days_left,
          exemptionHistory: application.view_info.exemption_history,
          requiredAkuYears: application.view_info.required_aku_years,
        },
      }),
      providesTags: ['Applications'],
    }),
    confirmApplication: builder.mutation<void, ConfirmApplicationRequest>({
      query: ({ applicationId }) => {
        return {
          url: `forms/${applicationId}/confirm`,
          method: 'POST',
          body: {
            signature: true,
          },
        };
      },
      async onQueryStarted({ applicationId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationApi.util.updateQueryData('getApplication', { applicationId }, (application) => {
            application.confirmDate = new Date();
            application.status = 'NOT_VERIFIED';

            return application;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetApplicationsQuery, useGetApplicationQuery, useConfirmApplicationMutation } = applicationApi;
