import type { ColumnDef, Row } from '@tanstack/react-table';
import { type Application } from 'api/applications/applications';
import { useGetApplications } from 'api/applications/hooks/useGetApplications';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

export const View = () => {
  const { t } = useTranslations();
  const { applications, isLoading, isError } = useGetApplications();
  const { activeUserId } = useGetActiveUserId();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isIntegrated = useIsIntegrated();

  const handleBack = () => {
    if (isIntegrated) {
      dispatch(
        actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
      );
    } else {
      dispatch(actions.general.GoToView(navigate, { route: '/', options: { replace: true } }));
    }
  };

  const columns = useMemo<ColumnDef<Application>[]>(
    () => [
      {
        accessorKey: 'title',
        header: () => t('profile.applications.title'),
        size: undefined,
      },
      {
        accessorKey: 'companyName',
        header: () => t('profile.applications.company'),
        size: undefined,
      },
      {
        accessorKey: 'status',
        header: () => t('profile.applications.status'),
        size: undefined,
        cell: ({ row }) => <>{t(`profile.applications.${row.original.status}`)}</>,
      },
      {
        accessorKey: 'createDate',
        header: () => t('profile.applications.date'),
        size: undefined,
        cell: ({ row }) => <>{new Date(row.original.createDate).toLocaleDateString('sv-SE')}</>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onRowClick = (row: Row<Application>) => {
    navigate(`/users/${activeUserId}/applications/${row?.original?.applicationId}`);
  };

  return (
    <>
      <PageHeader title={t('profile.applications.title')} onBack={handleBack} />
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={applications}
          emptyText={t('profile.applications.noApplications')}
          onRowClick={onRowClick}
          failedFetch={isError}
        />
      </ContentLoader>
    </>
  );
};
