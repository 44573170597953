import type { Company } from 'api/companies/companies';
import { useGetCompanies } from 'api/companies/hooks/useGetCompanies';
import { Box, Typography } from 'components/basic-components';
import { Input } from 'components/basic-components/Input/Input';
import Loader from 'components/basic-components/Loader/Loader';
import { Icon } from 'components/misc';
import { Backdrop } from 'components/misc/Modal/Modal.style';
import { useFormikContext } from 'formik';
import { X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useDebounce } from 'utilities/useDebounce';

interface CompanyPickerValues {
  companyId: number;
  companyName: number;
}

const CompaniesWrapper = styled(Box)`
  & > :not(:last-child) {
    border-bottom: 1px solid ${theme.colors['neutral-color-1']};
  }
`;

const StyledCompanyPicker = styled(Box)`
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
`;

const BoldSpan = styled('span')`
  font-weight: bold;
`;

type CompanyPickerProps = {
  defaultCompanyId?: number;
  defaultCompanyName?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

type CompanyRowProps = {
  setSelectedCompany: (company: SelectedCompany) => void;
  company: Company;
  onClose: () => void;
};

type SelectedCompany = { id?: number; name?: string };

const CompanyRow = ({ setSelectedCompany, company, onClose }: CompanyRowProps) => {
  const { t } = useTranslations();

  return (
    <Box
      padding="base-tight"
      $clickable
      onClick={() => {
        setSelectedCompany({
          id: company.id,
          name: company.name,
        });
        onClose();
      }}
    >
      <Typography.Paragraph marginBottom="base">
        <BoldSpan>{company.name}</BoldSpan>
      </Typography.Paragraph>
      <Typography.SmallParagraph>{company.orgNo}</Typography.SmallParagraph>
      <Typography.SmallParagraph>{company.homePage}</Typography.SmallParagraph>
      <Box flexDirection={['row-reverse', 'row']} flexWrap="wrap">
        {company.ceo?.firstName ? (
          <Box width={['100%', '50%']}>
            <Typography.H4> {t('profile.profile.ceo')}</Typography.H4>
            <Typography.SmallParagraph>
              {company.ceo.firstName} {company.ceo.lastName}
            </Typography.SmallParagraph>
            <Typography.SmallParagraph>{company.ceo.phone}</Typography.SmallParagraph>
          </Box>
        ) : (
          <Box width={['100%', '50%']}>
            <Typography.H4> {t('profile.profile.compliance')}</Typography.H4>
            <Typography.SmallParagraph>
              {company.compliance?.firstName} {company.compliance?.lastName}
            </Typography.SmallParagraph>
            <Typography.SmallParagraph>{company.compliance?.phone}</Typography.SmallParagraph>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const CompanyPicker = ({ defaultCompanyId, defaultCompanyName, isOpen, setIsOpen }: CompanyPickerProps) => {
  const { t } = useTranslations();
  const { values, setFieldValue } = useFormikContext<CompanyPickerValues>();
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebounce<string>(searchString ?? '', 500);
  const [page, setPage] = useState(0);
  const { ref: bottomRef, inView } = useInView();
  const [selectedCompany, setSelectedCompany] = useState<SelectedCompany>({
    id: defaultCompanyId,
    name: defaultCompanyName,
  });
  const { companies, isLoading, total, count } = useGetCompanies({
    freeText: debouncedSearchString,
    page,
  });
  const [paginatedCompanies, setPaginatedCompanies] = useState(companies);
  const hasMore = total > count;

  useEffect(() => {
    if (values.companyId !== selectedCompany.id) {
      setFieldValue('companyId', selectedCompany.id);
      setFieldValue('companyName', selectedCompany.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, setFieldValue]);

  useEffect(() => {
    if (inView && !isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, isLoading]);

  useEffect(() => {
    setPage(0);
    setPaginatedCompanies([]);
  }, [searchString]);

  useEffect(() => {
    if (companies && companies.length > 0) {
      setPaginatedCompanies([...paginatedCompanies, ...companies]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  return (
    <>
      <Box maxWidth="24px" maxHeight="24px" alignSelf="center" $clickable>
        {isOpen &&
          createPortal(
            <>
              <Backdrop onClick={() => setIsOpen(false)} />
              <StyledCompanyPicker
                position="fixed"
                top="50px"
                left="50%"
                minWidth="200px"
                maxWidth="750px"
                maxHeight="80vh"
                backgroundColor="light"
                borderRadius="s"
                borderWidth="normal"
                borderColor="neutral-color-enhanced-1"
                borderStyle="solid"
                zIndex={1000}
              >
                <Box padding="base" height="100%" display="flex" flexDirection="column" overflow="hidden">
                  <Box width="100%" height="20px" flexDirection="row-reverse" marginBottom="base">
                    <Icon onClick={() => setIsOpen(false)} size="20" marginRight="none">
                      <X />
                    </Icon>
                  </Box>
                  <Input placeholder={t('profile.companyPicker.searchForUser')} onChange={setSearchString} />
                  <Box flex={1} overflowY="auto">
                    <CompaniesWrapper>
                      {paginatedCompanies.map((company) => (
                        <CompanyRow
                          key={`companyPicker_companyRow_${company.id}`}
                          company={company}
                          onClose={() => setIsOpen(false)}
                          setSelectedCompany={setSelectedCompany}
                        />
                      ))}
                      <span ref={bottomRef}>
                        <Box padding="extra-loose" justifyContent="center" alignItems="center">
                          {isLoading && <Loader width="64px" height="64px" />}
                          {!hasMore && companies.length > 0 && (
                            <Typography.SmallParagraph color="main">{t('profile.companyPicker.noMoreResults')}</Typography.SmallParagraph>
                          )}
                          {!isLoading && companies.length === 0 && (
                            <Typography.SmallParagraph color="main">{t('profile.companyPicker.noResults')}</Typography.SmallParagraph>
                          )}
                        </Box>
                      </span>
                    </CompaniesWrapper>
                  </Box>
                </Box>
              </StyledCompanyPicker>
            </>,
            document.body
          )}
      </Box>
    </>
  );
};
