import type { ColumnDef } from '@tanstack/react-table';
import { HOST } from 'api/base';
import { useDeleteCostsMutation, type Costs } from 'api/cost/cost';
import { useGetCosts } from 'api/cost/hooks/useGetCosts';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Typography } from 'components/basic-components';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader, Modal } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { isSwedsecAdminSelector } from 'store/selectors/identity';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

export const View = () => {
  const { t } = useTranslations();
  const { costs, isLoading } = useGetCosts();
  const { activeUserId } = useGetActiveUserId();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isIntegrated = useIsIntegrated();
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const [rowSelection, setRowSelection] = useState({});
  const [deleteCost, { isSuccess }] = useDeleteCostsMutation();

  const handleBack = () => {
    if (isIntegrated) {
      dispatch(
        actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
      );
    } else {
      navigate('/');
    }
  };

  const handleSubmit = (selectedRows: number[]) => {
    for (const costId of selectedRows) {
      deleteCost({ costId, userId: activeUserId });
    }
  };

  const columns = useMemo<ColumnDef<Costs>[]>(
    () => [
      {
        accessorKey: 'invoiceDate',
        header: () => t('profile.costs.invoiceDate'),
        size: undefined,
      },
      {
        accessorKey: 'articleNo',
        header: () => t('profile.costs.articleNo'),
        size: undefined,
      },
      {
        accessorKey: 'description',
        header: () => t('profile.costs.description'),
        size: undefined,
      },
      {
        accessorKey: 'company',
        header: () => t('profile.costs.company'),
        size: undefined,
      },
      {
        accessorKey: 'invoiceNo',
        header: () => t('profile.costs.invoiceNo'),
        size: undefined,
        cell: ({ row }) => (
          <>
            {row.original.invoiceNo ? (
              <>
                {isSwedsecAdmin ? (
                  <Typography.Anchor
                    onClick={() => {
                      if (window.top) {
                        window.top.location.href = `${HOST}/invoice/view/${row.original.invoiceId}`;
                      }
                    }}
                  >
                    {row.original.invoiceNo}
                  </Typography.Anchor>
                ) : (
                  <>{row.original.invoiceNo}</>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSwedsecAdmin]
  );

  useEffect(() => {
    if (isSuccess) {
      setShowDeleteModal(false);
    }
  }, [isSuccess]);

  return (
    <>
      {isSwedsecAdmin ? (
        <>
          <PageHeader
            title={t('profile.costs.title')}
            onBack={handleBack}
            buttonOnClick={() => setShowDeleteModal(true)}
            buttonText={t('profile.costs.delete')}
            disabledButton={Object.keys(rowSelection).length === 0}
          />
          {showDeleteModal && (
            <Modal
              isShown={showDeleteModal}
              hide={() => setShowDeleteModal(false)}
              onConfirm={() => {
                const selectedCostIndexes = Object.entries(rowSelection)
                  .filter(([, selected]) => selected)
                  .map(([key]) => key);

                handleSubmit(costs.filter((_, index) => selectedCostIndexes.includes(index.toString())).map((cost) => cost.id));

                setRowSelection({});
              }}
              onCancel={() => setShowDeleteModal(false)}
              message={t('profile.costs.modal.deleteMessage')}
              headerText={t('profile.costs.modal.deleteHeader')}
              confirmText={t('general.general.delete')}
              cancelText={t('profile.costs.modal.cancel')}
            />
          )}
          <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
            <Table
              columns={columns}
              data={costs}
              emptyText={t('profile.costs.noResponse')}
              enableRowSelection={(row) => !row.original.invoiceNo}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
            />
          </ContentLoader>
        </>
      ) : (
        <>
          <PageHeader title={t('profile.costs.title')} onBack={handleBack} />
          <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
            <Table columns={columns} data={costs} emptyText={t('profile.costs.noResponse')} />
          </ContentLoader>
        </>
      )}
    </>
  );
};
