import type { PayloadAction } from '@reduxjs/toolkit';
import type { TimeSlot } from 'api/educateIT/educateIT';
import type { ExamSpecification, Occasion, Subject } from 'api/exams/exams';
import type { RequireAtLeastOne } from 'utilities/atLeastOne';
import type { BookingDetails, LicensingExam, OccasionsFilter, State } from './state';
import { initialState } from './state';

export const reducers = {
  reset: () => {
    return initialState;
  },
  'set-subject': (state: State, action: PayloadAction<Subject>) => {
    state.bookingDetails.selectedSubject = action.payload;
  },
  'set-occasion-filter': (state: State, action: PayloadAction<Partial<OccasionsFilter>>) => {
    state.bookingDetails.filter = {
      ...state.bookingDetails.filter,
      ...action.payload,
    };
  },
  'reset-occasion-filter': (state: State) => {
    state.bookingDetails.filter = initialState.bookingDetails.filter;
  },
  'set-occasion': (state: State, action: PayloadAction<Occasion | TimeSlot>) => {
    state.bookingDetails.selectedOccasion = action.payload;
  },
  'add-audio': (state: State) => {
    state.bookingDetails.audio = true;
  },
  'remove-audio': (state: State) => {
    state.bookingDetails.audio = false;
  },
  'add-license-reservation': (state: State, action: PayloadAction<Partial<LicensingExam>>) => {
    try {
      state.licensingExam = {
        ...state.licensingExam,
        ...action.payload,
      };
    } catch (e) {
      console.error(e);
    }
  },
  'add-diagnostic-reservation': (state: State, action: PayloadAction<ExamSpecification>) => {
    try {
      state.diagnosticExam = {
        examSpecification: action.payload,
      };
    } catch (e) {
      console.error(e);
    }
  },
  'edit-reservation': (state: State, action: PayloadAction<RequireAtLeastOne<BookingDetails>>) => {
    try {
      state.bookingDetails = {
        ...state?.bookingDetails,
        ...action.payload,
      };
    } catch (e) {
      console.error(e);
    }
  },
  'remove-license-reservation': (state: State) => {
    delete state.licensingExam;
  },
  'remove-diagnostic-reservation': (state: State) => {
    delete state.diagnosticExam;
  },
};
