import { privateApi } from 'api/base';
import { paginationStringGenerator } from 'api/helpers/pagination';
import { queryStringGenerator } from 'api/helpers/queryString';
import type { UserRoles } from 'store/stores/identity/state';

type GetUserRequest = {
  userId: number;
};

export type GetUsersRequest = {
  socialNo?: string;
  userId?: number;
  licenseStatus?: string;
  companyId?: number;
  freeText?: string;
  userRole?: UserRoles;
  page?: number;
  size?: number;
};

type LatestAku = {
  id: number;
  year: number;
  completed: boolean;
};

type GetUsersResponse = {
  count: number;
  total: number;
  licensees: Array<UserProfile>;
};

type GetAPIUsersResponse = {
  count: number;
  total: number;
  licensees: Array<APIUserProfile>;
};

type CompanyInformation = {
  companyId: number;
  companyName: string;
  orgNo: string;
  clearing: string;
  department: string;
  isMember: boolean;
  companyGroupName: string;
  invoiceEmail: string;
  city: string;
  permissionKey: number;
  roleKey: number;
  companyGroup: boolean;
  isDefault: boolean;
};

type APICompanyInformation = {
  company_id: number;
  company_name: string;
  org_no: string;
  clearing: string;
  department: string;
  is_member: boolean;
  company_group_name: string;
  invoice_email: string;
  city: string;
  permission_key: number;
  role_key: number;
  company_group: boolean;
  is_default: boolean;
};

type APIUserProfile = {
  id: number;
  license_number: number;
  administrator_id: number;
  administrator_name: string;
  administrator_email: string;
  company_information: Array<APICompanyInformation>;
  license_status: 'ACTIVE' | 'RESTING' | 'TEMPORARY_ACTIVE' | 'EXPIRED' | 'INACTIVE' | 'NONE' | 'EXPIRED_07' | 'TEMPORARY_REVOKED';
  license_status_id: string;
  license_expiration_date: Date;
  account_is_locked: boolean;
  mobilephonenr: string;
  phonenr: string;
  username: string;
  first_name: string;
  last_name: string;
  nationality: string;
  social_no: string;
  protected_identity: boolean;
  email: string;
  email2: string;
  latest_aku: LatestAku;
  exemptions: Array<{ id: number }>;
  roles: {
    [role in UserRoles]: boolean;
  };
  certificates: Array<{
    exam_family: string;
    certificate_number: string;
    social_no: string;
    exam_date: Date;
    issued: Date;
    certififcate_id: number;
  }>;
};

export type UserProfile = {
  id: number;
  licenseNumber: number;
  administratorId: number;
  administratorName: string;
  administratorEmail: string;
  companyInformation: Array<CompanyInformation>;
  defaultCompany?: CompanyInformation;
  licenseStatus: 'ACTIVE' | 'RESTING' | 'TEMPORARY_ACTIVE' | 'EXPIRED' | 'INACTIVE' | 'NONE' | 'EXPIRED_07' | 'TEMPORARY_REVOKED';
  licenseStatusId: string;
  licenseExpirationDate: Date;
  accountIsLocked: boolean;
  mobilephonenr: string;
  phonenr: string;
  username: string;
  firstName: string;
  lastName: string;
  nationality: string;
  socialNo: string;
  protectedIdentity: boolean;
  exemptions: Array<{ id: number }>;
  email: string;
  email2: string;
  latestAku: LatestAku;
  certificates: Array<{
    examFamily: string;
    certificateNumber: string;
    socialNo: string;
    examDate: Date;
    issued: Date;
    certififcateId: number;
  }>;
  roles: {
    [role in UserRoles]: boolean;
  };
};

type ChangePasswordRequest = {
  userId: number;
  oldPassword: string;
  password: string;
};

type ResetPasswordRequest = {
  newPassword: string;
  token: string;
};

type ResetPasswordEmailRequest = {
  username: string;
};

export type UpdateUserRequest = {
  userId: number;
  socialNo?: string;
  administrator?: {
    id: number;
    name: string;
  };
  username?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  nationality?: string;
  accountIsLocked?: boolean;
  clearing?: string;
  department?: string;
  protectedIdentity?: boolean;
};

export type UpdateRolesRequest = {
  userId: number;
  roles: {
    [role in UserRoles]: boolean;
  };
};

type GetSparRequest = {
  socialNo: string;
};

type GetSparApiResponse = {
  avregistreringsorsakKod: string;
  createDate: Date;
  datumFrom: Date;
  datumTill: Date;
  efternamn: string;
  fornamn: string;
  fysiskPersonId: string;
  hanvisningsPersonNrByttFran: string;
  hanvisningsPersonNrByttTill: string;
  id: number;
  kon: 'N' | 'K';
  sekretessmarkering: boolean;
};

type GetSparResponse = {
  lastName: string;
  firstName: string;
  socialNumber: string;
  protectedIdentity: boolean;
};

type Permission = {
  art_no: string;
  title: string;
  granted: boolean;
};

type AddPermissionRequest = {
  userId: number;
  permission: Permission;
};

type GetPermissionRequest = {
  userId: number;
};

type GetApiPermissionResponse = {
  Permissions: Array<Permission>;
};

type GetUserEmailRequest = {
  userId: number;
};

type AddUserEmailRequest = {
  userId: number;
  email: string;
};

type RemoveUserEmailRequest = {
  userId: number;
  email: string;
};

type SetDefaultUserEmailRequest = {
  userId: number;
  emailId: number;
};

type UpdateUserEmailRequest = {
  userId: number;
  emailId: number;
  email: string;
};

type GetUserApiEmailResponse = {
  emails: Array<Email>;
};

type GetExamSettingsResponse = {
  needAudioSupport: boolean;
  canWriteTestsFromDate: string;
};

type GetApiExamSettingsResponse = {
  needAudioSupport: boolean;
  canWriteTestsFromDate: Array<number> | Date;
};

type GetExamSettingsRequest = {
  userId: number;
};

type GetUserRolesRequest = {
  userId: number;
};

type GetUserRolesResponse = Array<{
  assignable: boolean;
  name: UserRoles;
  user_has_role: boolean;
}>;

export type UpdateExamSettingsRequest = {
  userId: number;
  needAudioSupport: boolean;
  canWriteTestsFromDate: string;
};

export type Email = {
  id: number;
  address: string;
  default: boolean;
};

const transformUserResponse = (user: APIUserProfile) => {
  const defaultCompany = user.company_information.find((company) => company.is_default);

  const companyTransform = (companyInformation: APICompanyInformation) => ({
    companyId: companyInformation.company_id,
    companyName: companyInformation.company_name,
    orgNo: companyInformation.org_no,
    clearing: companyInformation.clearing,
    department: companyInformation.department,
    isMember: companyInformation.is_member,
    companyGroupName: companyInformation.company_group_name,
    invoiceEmail: companyInformation.invoice_email,
    city: companyInformation.city,
    permissionKey: companyInformation.permission_key,
    roleKey: companyInformation.role_key,
    companyGroup: companyInformation.company_group,
    isDefault: companyInformation.is_default,
  });

  return {
    ...user,
    id: user.id,
    licenseNumber: user.license_number,
    administratorId: user.administrator_id,
    administratorName: user.administrator_name,
    administratorEmail: user.administrator_email,
    companyInformation: user.company_information?.map(companyTransform) ?? [],
    defaultCompany: defaultCompany && companyTransform(defaultCompany),
    licenseStatus: user.license_status,
    licenseStatusId: user.license_status_id,
    licenseExpirationDate: user.license_expiration_date,
    accountIsLocked: user.account_is_locked,
    mobilephonenr: user.mobilephonenr,
    phonenr: user.phonenr,
    username: user.username,
    firstName: user.first_name,
    lastName: user.last_name,
    nationality: user.nationality,
    socialNo: user.social_no,
    protectedIdentity: user.protected_identity,
    exemptions: user.exemptions,
    roles: user.roles,
    email: user.email,
    email2: user.email2,
    latestAku: user.latest_aku,
    certificates: user.certificates.map((certifiacte) => ({
      certificateNumber: certifiacte.certificate_number,
      certififcateId: certifiacte.certififcate_id,
      examDate: certifiacte.exam_date,
      examFamily: certifiacte.exam_family,
      issued: certifiacte.issued,
      socialNo: certifiacte.social_no,
    })),
  };
};

export const usersApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserProfile, GetUserRequest>({
      query: ({ userId }) => {
        return {
          url: `users/${userId}`,
        };
      },
      providesTags: ['Users'],
      transformResponse: transformUserResponse,
    }),
    getUserEmails: builder.query<Array<Email>, GetUserEmailRequest>({
      query: ({ userId }) => `users/${userId}/emailaddresses`,
      providesTags: ['UserEmails'],
      transformResponse: (data: GetUserApiEmailResponse) => data?.emails,
    }),
    addUserEmail: builder.mutation<void, AddUserEmailRequest>({
      query: ({ userId, email }) => ({
        url: `users/${userId}/emailaddresses`,
        method: 'POST',
        body: {
          address: email,
        },
      }),
      invalidatesTags: ['UserEmails'],
    }),
    removeUserEmail: builder.mutation<void, RemoveUserEmailRequest>({
      query: ({ email }) => ({
        url: `emailaddresses?emailAddress=${encodeURIComponent(email)}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ userId, email }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUserEmails', { userId }, (cachedUserEmails) =>
            cachedUserEmails.filter((cachedEmail) => cachedEmail.address !== email)
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    setDefaultUserEmail: builder.mutation<void, SetDefaultUserEmailRequest>({
      query: ({ emailId }) => ({
        url: `emailaddresses/${emailId}/default`,
        method: 'PUT',
      }),
      async onQueryStarted({ emailId, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUserEmails', { userId }, (cachedUserEmailsResponse) =>
            cachedUserEmailsResponse.map((cachedEmail) => ({ ...cachedEmail, default: cachedEmail.id === emailId }))
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateUserEmail: builder.mutation<void, UpdateUserEmailRequest>({
      query: ({ emailId, email }) => ({
        url: `emailaddresses/${emailId}`,
        method: 'PUT',
        body: {
          address: email,
        },
      }),
      async onQueryStarted({ emailId, email, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUserEmails', { userId }, (cachedUserEmailsResponse) => {
            const emailIndex = cachedUserEmailsResponse.findIndex((cachedUserEmail) => cachedUserEmail.id === emailId);

            if (emailIndex === -1) {
              cachedUserEmailsResponse.push({ id: emailId, address: email, default: false });
            } else {
              cachedUserEmailsResponse[emailIndex].address = email;
            }

            return cachedUserEmailsResponse;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getUsers: builder.query<GetUsersResponse, GetUsersRequest>({
      query: ({ userId, page, size, socialNo, licenseStatus, companyId, freeText, userRole }) => {
        const paginationString = paginationStringGenerator({ page, size });
        const queryString = queryStringGenerator({ socialNo, userId, licenseStatus, companyId, freeText, userRole });

        return {
          url: `users?${paginationString}${paginationString && '&'}${queryString}`,
        };
      },
      providesTags: ['Users'],
      transformResponse: (data: GetAPIUsersResponse) => ({
        ...data,
        licensees: data.licensees.map(transformUserResponse),
      }),
    }),
    getSpar: builder.query<GetSparResponse, GetSparRequest>({
      query: ({ socialNo }) => `/spar/personsok/${socialNo}`,
      providesTags: ['Users'],
      transformResponse: (data: GetSparApiResponse) => ({
        lastName: data.efternamn,
        firstName: data.fornamn,
        socialNumber: data.fysiskPersonId,
        protectedIdentity: data.sekretessmarkering,
      }),
    }),
    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: ({ userId, oldPassword, password }) => {
        return {
          url: `users/${userId}/password_change`,
          method: 'POST',
          body: {
            old_password: oldPassword,
            password,
          },
        };
      },
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: ({ newPassword, token }) => {
        return {
          url: `users/password_reset`,
          method: 'POST',
          body: {
            new_password: newPassword,
            token,
          },
        };
      },
    }),
    resetPasswordEmail: builder.mutation<void, ResetPasswordEmailRequest>({
      query: ({ username }) => {
        return {
          url: `users/password_reset_email`,
          method: 'POST',
          body: {
            account: username,
          },
        };
      },
    }),
    updateUser: builder.mutation<void, UpdateUserRequest>({
      query: ({
        userId,
        username,
        phoneNumber,
        mobileNumber,
        socialNo,
        firstName,
        lastName,
        clearing,
        department,
        nationality,
        administrator,
        accountIsLocked,
        protectedIdentity,
      }) => ({
        url: `/users/${userId}`,
        method: 'PATCH',
        body: {
          user_administrator: administrator?.id ? administrator.id : undefined,
          username: username ? username : undefined,
          first_name: firstName ? firstName : undefined,
          last_name: lastName ? lastName : undefined,
          phone_number: phoneNumber ? phoneNumber : undefined,
          mobile_number: mobileNumber ? mobileNumber : undefined,
          nationality,
          department,
          account_is_locked: accountIsLocked === null ? undefined : accountIsLocked,
          social_no: socialNo ? socialNo : undefined,
          clearing: clearing ? clearing : undefined,
          protected_identity: protectedIdentity === null ? undefined : protectedIdentity,
        },
      }),
      async onQueryStarted(
        {
          userId,
          username,
          firstName,
          lastName,
          phoneNumber,
          mobileNumber,
          nationality,
          accountIsLocked,
          socialNo,
          administrator,
          clearing,
          department,
        },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUser', { userId }, (cachedUserResponse) => {
            cachedUserResponse = {
              ...cachedUserResponse,
              firstName: firstName ?? cachedUserResponse.firstName,
              lastName: lastName ?? cachedUserResponse.lastName,
              socialNo: socialNo ?? cachedUserResponse.socialNo,
              nationality: nationality ?? cachedUserResponse.nationality,
              administratorId: administrator?.id ?? cachedUserResponse.administratorId,
              administratorName: administrator?.name ?? cachedUserResponse.administratorName,
              companyInformation: cachedUserResponse.companyInformation?.map((companyInformation) => ({
                ...companyInformation,
                clearing: clearing ?? companyInformation.clearing,
                department: department ?? companyInformation.department,
              })),
            };

            cachedUserResponse.username = username ?? cachedUserResponse.username;
            cachedUserResponse.phonenr = phoneNumber ?? cachedUserResponse.phonenr;
            cachedUserResponse.mobilephonenr = mobileNumber ?? cachedUserResponse.mobilephonenr;

            cachedUserResponse.accountIsLocked =
              accountIsLocked === null || accountIsLocked === undefined ? cachedUserResponse.accountIsLocked : accountIsLocked;

            return cachedUserResponse;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateRoles: builder.mutation<void, UpdateRolesRequest>({
      query: ({ userId, roles }) => ({
        url: `/users/${userId}/roles`,
        method: 'PATCH',
        body: {
          roles,
        },
      }),
      async onQueryStarted({ userId, roles }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUser', { userId }, (cachedUserResponse) => {
            Object.keys(roles).forEach((role) => {
              cachedUserResponse.roles[role as UserRoles] = roles[role as UserRoles];
            });

            return cachedUserResponse;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getPermissions: builder.query<Array<Permission>, GetPermissionRequest>({
      query: ({ userId }) => {
        return {
          url: `users/${userId}/permissions`,
        };
      },
      transformResponse: (data: GetApiPermissionResponse) => data?.Permissions,
      providesTags: ['Users'],
    }),
    addPermission: builder.mutation<void, AddPermissionRequest>({
      query: ({ userId, permission }) => ({
        url: `/users/${userId}/permissions`,
        method: 'POST',
        body: {
          art_no: permission.art_no,
        },
      }),
      async onQueryStarted({ userId, permission }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getPermissions', { userId }, (cachedUserResponse) => {
            const permissionExistsIndex = cachedUserResponse.findIndex((cachedPermission) => cachedPermission.art_no === permission.art_no);

            if (permissionExistsIndex === -1) {
              cachedUserResponse.push(permission);
            } else {
              cachedUserResponse[permissionExistsIndex].granted = true;
            }

            return cachedUserResponse;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    removePermission: builder.mutation<void, AddPermissionRequest>({
      query: ({ userId, permission }) => ({
        url: `/users/${userId}/permissions`,
        method: 'DELETE',
        body: {
          art_no: permission.art_no,
        },
      }),
      async onQueryStarted({ userId, permission }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getPermissions', { userId }, (cachedUserResponse) => {
            const existingPermissionIndex = cachedUserResponse.findIndex(
              (cachedPermission) => cachedPermission.art_no === permission.art_no
            );

            if (existingPermissionIndex !== -1) {
              cachedUserResponse[existingPermissionIndex].granted = false;
            }

            return cachedUserResponse;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getExamSettings: builder.query<GetExamSettingsResponse, GetExamSettingsRequest>({
      query: ({ userId }) => {
        return {
          url: `users/${userId}/examsettings`,
        };
      },
      transformResponse: (data: GetApiExamSettingsResponse) => ({
        canWriteTestsFromDate: Array.isArray(data?.canWriteTestsFromDate)
          ? new Date(data.canWriteTestsFromDate[0], data.canWriteTestsFromDate[1] - 1, data.canWriteTestsFromDate[2]).toLocaleDateString(
              'sv-SE'
            )
          : new Date(data?.canWriteTestsFromDate).toLocaleDateString('sv-SE'),
        needAudioSupport: data.needAudioSupport,
      }),
      providesTags: ['Users'],
    }),
    updateExamSettings: builder.mutation<void, UpdateExamSettingsRequest>({
      query: ({ userId, needAudioSupport, canWriteTestsFromDate }) => {
        return {
          url: `users/${userId}/examsettings`,
          method: 'PUT',
          body: {
            needAudioSupport,
            canWriteTestsFromDate,
          },
        };
      },
      async onQueryStarted({ userId, needAudioSupport, canWriteTestsFromDate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getExamSettings', { userId }, (cachedExamSettings) => {
            if (canWriteTestsFromDate !== null && canWriteTestsFromDate !== undefined) {
              cachedExamSettings.canWriteTestsFromDate = canWriteTestsFromDate;
            }

            if (needAudioSupport !== null && needAudioSupport !== undefined) {
              cachedExamSettings.needAudioSupport = needAudioSupport;
            }

            return cachedExamSettings;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getAvailableUserRoles: builder.query<GetUserRolesResponse, GetUserRolesRequest>({
      query: ({ userId }) => {
        return {
          url: `users/${userId}/roles`,
        };
      },
      providesTags: ['Users'],
    }),
  }),
});

export const {
  useGetUserQuery,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useResetPasswordEmailMutation,
  useGetUsersQuery,
  useGetUserEmailsQuery,
  useUpdateRolesMutation,
  useUpdateUserMutation,
  useGetPermissionsQuery,
  useAddPermissionMutation,
  useRemovePermissionMutation,
  useAddUserEmailMutation,
  useUpdateUserEmailMutation,
  useGetSparQuery,
  useRemoveUserEmailMutation,
  useSetDefaultUserEmailMutation,
  useGetExamSettingsQuery,
  useUpdateExamSettingsMutation,
  useGetAvailableUserRolesQuery,
} = usersApi;
