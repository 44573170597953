import { useGetAKUQuery } from 'api/aku/aku';
import { useGetUser } from 'api/users/hooks/useGetUser';

export const useGetAKU = (userId?: number) => {
  const { activeUserId } = useGetUser();
  const { data: aku, ...rest } = useGetAKUQuery({ userId: userId ?? activeUserId }, { skip: !userId && !activeUserId });

  return {
    aku: (aku ?? []).toSorted((a, b) => (a.year > b.year ? -1 : 1)),
    ...rest,
  };
};
