import { Icon, NavLink } from 'components/misc';
import type { ReactElement } from 'react';
import { actions, useAppDispatch } from 'store';

export type MenuProps = {
  to: string;
  name: string;
  icon?: ReactElement;
};

export const MenuLink = ({ to, icon, name }: MenuProps) => {
  const dispatch = useAppDispatch();

  const handleToggleMenu = async (toggleState: boolean) => {
    await dispatch(actions.general['toggle-sidebar'](toggleState));
  };

  return (
    <NavLink
      onClick={() => handleToggleMenu(false)}
      to={to}
      end
      color="neutral-color-2"
      textStyle="body-light"
      display="flex"
      alignContent="center"
      marginLeft={!icon && '25px'}
    >
      {icon && <Icon size="20">{icon}</Icon>}
      {name}
    </NavLink>
  );
};
