import styled from 'styled-components';
import type { ColorProps, LayoutProps, SpaceProps } from 'styled-system';
import { color, layout, space, variant } from 'styled-system';
import type { ThemeSchema } from 'theme';
import { theme } from 'theme';

type TRProps = { expanded?: boolean; variant?: 'noLine' | 'default' } & ColorProps<ThemeSchema> &
  LayoutProps<ThemeSchema> &
  SpaceProps<ThemeSchema>;

export const TableRow = styled.tr<TRProps>`
  ${color}
  ${layout}
  ${space}
  ${variant({
    variants: {
      default: {
        borderTop: `solid 1px ${theme.colors['neutral-color-1']}`,
        td: {
          padding: `${theme.space['base-tight']} ${theme.space['base']}`,
          height: '20px',
        },
        '.expand': {
          width: '10%',
          textAlign: 'center',
        },
      },
      noLine: {
        td: {
          padding: `${theme.space['base-tight']} ${theme.space['base']}`,
          height: '20px',
        },
        '.expand': {
          width: '10%',
          textAlign: 'center',
        },
      },
    },
  })} {
    ${({ onClick }) => onClick && 'cursor: pointer'}
`;

export const TableCell = styled.td<SpaceProps>`
  padding: ${theme.space.base};
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  ${space}
`;
