import type { BoxProps } from 'components/basic-components/Box/Box';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from 'theme';

export type MainProps = {
  isPinned?: boolean;
  isToggled?: boolean;
} & BoxProps;

const StyledMain = styled.main<MainProps>`
  height: 100%;
  grid-area: main;
  margin: 0px;
  transition: all 0.4s;
  padding: 1em;

  @media only screen and (min-device-width: ${breakpoints.md}) {
    margin: 0.5em 1em 0.5em ${theme.space.sidebar};
    ${(props) =>
      props.isPinned &&
      css`
        margin: 0 0 0 17.25em;
      `}
  }
`;

export default StyledMain;
