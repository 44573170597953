import { useGetUser } from 'api/users/hooks/useGetUser';
import { useGetSparQuery } from 'api/users/users';

export const useGetSpar = ({ socialNo, skip }: { socialNo?: string; skip?: boolean }) => {
  const { user } = useGetUser();
  const { data: spar, ...rest } = useGetSparQuery({ socialNo: socialNo ?? user?.socialNo ?? '' }, { skip: skip || (!socialNo && !user) });

  return {
    spar,
    ...rest,
  };
};
