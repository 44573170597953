import { useGetUser } from 'api/users/hooks/useGetUser';
import { useUpdateUserMutation } from 'api/users/users';
import { Box, Typography } from 'components/basic-components';
import { Section } from 'components/misc';
import { Modal } from 'components/misc/Modal/Modal';
import { UserPicker } from 'components/misc/UserPicker/UserPicker';
import { Form, Formik } from 'formik';
import { SquarePen } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { isAdminSelector, isSwedsecAdminSelector } from 'store/selectors/identity';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';

export const UserProfileCompany = () => {
  const [updateUser, { isSuccess, isError, isLoading }] = useUpdateUserMutation();
  const { t } = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user, isMember, activeUserId } = useGetUser();
  const isAdmin = useAppSelector(isAdminSelector);
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);

  const handleSubmit = async ({ userId, userName }: { userId: number; userName: string }) => {
    if (!activeUserId || !user) {
      return;
    }

    updateUser({
      userId: activeUserId,
      socialNo: user.socialNo,
      administrator: {
        id: userId,
        name: userName,
      },
    });
  };

  useEffect(() => {
    if (isError) {
      publish(CustomEventName.API_ERROR, {
        message: 'UPDATE_USER_ERROR',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  if (!user) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        userId: user.administratorId,
        userName: user.administratorName,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, errors, values, initialValues, submitForm, resetForm }) => (
        <Form>
          <Section
            title={t('profile.profile.companyInfo')}
            clickableIcon={(isAdmin || isSwedsecAdmin) && <SquarePen onClick={() => setIsEditing(true)} />}
          >
            <Box padding="base-tight" gap="10px">
              <Typography.Label>
                {t('profile.profile.company')}
                <Typography.Paragraph>{user?.defaultCompany?.companyName ?? ''}</Typography.Paragraph>
              </Typography.Label>
              <Typography.Label>
                {t('profile.profile.admin')}
                <Typography.Paragraph>{user?.administratorName ?? ''}</Typography.Paragraph>
              </Typography.Label>
              <Typography.Label>
                {t('profile.profile.affiliate')}
                <Typography.Paragraph>{isMember ? t('profile.profile.yes') : t('profile.profile.no')}</Typography.Paragraph>
              </Typography.Label>
            </Box>
          </Section>
          <Modal
            draggable
            isShown={isEditing}
            headerText={t('profile.profile.companyInfo')}
            hide={() => {
              resetForm();
              setIsEditing(false);
            }}
            onCancel={() => {
              resetForm();
              setIsEditing(false);
            }}
            onConfirm={submitForm}
            disableConfirm={
              isLoading ||
              isSubmitting ||
              JSON.stringify(values) === JSON.stringify(initialValues) ||
              Object.values(errors).some((error) => error)
            }
            confirmText={t('profile.profile.save')}
            cancelText={t('profile.profile.cancel')}
          >
            <Box flexDirection="column" flexWrap="wrap" gap="10px" minWidth="320px">
              <Typography.Label marginBottom="base">
                {t('profile.profile.company')}
                <Typography.Paragraph marginBottom="base">{user?.defaultCompany?.companyName}</Typography.Paragraph>
              </Typography.Label>
              <Typography.Label>
                {t('profile.profile.admin')}
                <Typography.Paragraph display="flex">
                  {values.userName}
                  <Box marginLeft="base" maxWidth="24px" maxHeight="24px" alignSelf="center" $clickable>
                    <SquarePen onClick={() => setIsOpen(true)} />
                  </Box>
                </Typography.Paragraph>
              </Typography.Label>
              <UserPicker
                userRole="ROLE_ADMIN"
                userCompanyId={user?.defaultCompany?.companyId}
                defaultUserId={user?.administratorId}
                defaultUserName={user?.administratorName}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </Box>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
