import { publicApi } from 'api/base';

type BankIdAuthenticationResponse = {
  transactionId: string;
  autoStartToken: string;
};

type BankIdCheckResponse = {
  transactionId?: string;
  hintCode?: string;
  status?: 'PENDING' | 'FAILED' | 'SUCCESS' | 'CANCELLED' | 401;
  qrCode?: string;
};

export const bankIdApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    authentication: builder.query<BankIdAuthenticationResponse, { userVisibleData: string }>({
      query: ({ userVisibleData }) => ({
        url: 'bankid/authentication',
        method: 'POST',
        body: {
          userVisibleData,
          userVisibleDataFormat: 'NONE',
          userNonVisibleData: 'test',
          allowFingerprint: true,
        },
      }),
    }),
    check: builder.query<BankIdCheckResponse, string>({
      query: () => ({
        url: 'bankid/check',
        method: 'POST',
      }),
    }),
  }),
});

export const { useAuthenticationQuery, useCheckQuery } = bankIdApi;
