import { useGetUser } from 'api/users/hooks/useGetUser';
import type { UpdateExamSettingsRequest } from 'api/users/users';
import { useGetExamSettingsQuery, useUpdateExamSettingsMutation } from 'api/users/users';
import { Box, Typography } from 'components/basic-components';
import { Checkbox } from 'components/form-components';
import { Section } from 'components/misc';
import { Modal } from 'components/misc/Modal/Modal';
import { Form, Formik } from 'formik';
import { SquarePen } from 'lucide-react';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { breakpoints, theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';

const StyledBox = styled(Box)`
  @media only screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    gap: 5px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  padding: ${theme.space['base-tight']};
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  border-color: ${theme.colors.main};
  ${() => theme.textStyles['text-input']};
`;

export const UserProfileExtraBookingPermissions = () => {
  const [updateExamSettings, { isError, isSuccess, isLoading }] = useUpdateExamSettingsMutation();
  const { t } = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const { activeUserId: userId } = useGetUser();
  const { data: examSettings } = useGetExamSettingsQuery({ userId });

  const handleSubmit = async (updatedExamSettings: Omit<UpdateExamSettingsRequest, 'userId'>) => {
    if (JSON.stringify(examSettings) !== JSON.stringify(updatedExamSettings)) {
      updateExamSettings({ userId, ...updatedExamSettings });
    }
  };

  useEffect(() => {
    if (isError) {
      publish(CustomEventName.API_ERROR, {
        message: 'UPDATE_USER_EXTRA_BOOKING_PERMISSIONS_ERROR',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{
        canWriteTestsFromDate: examSettings?.canWriteTestsFromDate ?? '',
        needAudioSupport: examSettings?.needAudioSupport ?? false,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, errors, values, initialValues, submitForm, setFieldValue, resetForm }) => (
        <Form>
          <Section title={t('profile.profile.extraBookingPermissions')} clickableIcon={<SquarePen onClick={() => setIsEditing(true)} />}>
            <Box padding="base-tight" gap="10px">
              {!examSettings?.needAudioSupport && !examSettings?.canWriteTestsFromDate && (
                <Typography.Paragraph>{t('profile.profile.userHasNoExtraBookingPermissions')}</Typography.Paragraph>
              )}
              {examSettings?.needAudioSupport && <Typography.Paragraph>{t('profile.profile.userNeedsAudioSupport')}</Typography.Paragraph>}
              {examSettings?.canWriteTestsFromDate && (
                <Typography.Paragraph>
                  {t('profile.profile.userCanWriteTestFrom', { date: examSettings?.canWriteTestsFromDate })}
                </Typography.Paragraph>
              )}
            </Box>
          </Section>
          <Modal
            draggable
            isShown={isEditing}
            headerText={t('profile.profile.extraBookingPermissions')}
            hide={() => {
              resetForm();
              setIsEditing(false);
            }}
            onCancel={() => {
              resetForm();
              setIsEditing(false);
            }}
            onConfirm={submitForm}
            disableConfirm={
              isLoading ||
              isSubmitting ||
              JSON.stringify(values) === JSON.stringify(initialValues) ||
              Object.values(errors).some((error) => error)
            }
            confirmText={t('profile.profile.save')}
            cancelText={t('profile.profile.cancel')}
          >
            <StyledBox flexDirection="row" flexWrap="wrap">
              <Box width={['100%', '50%']} gap="5px">
                <Checkbox name="needAudioSupport" label={t('general.userRights.HAS_NEED_FOR_AUDIO_SUPPORT')} />
                <Typography.Label marginTop="base">{t('profile.profile.canWriteTestFrom')}</Typography.Label>
                <StyledDatePicker
                  onChange={(e: Date) => setFieldValue('canWriteTestsFromDate', new Date(e).toLocaleDateString('sv-SE'))}
                  value={values.canWriteTestsFromDate}
                  adjustDateOnChange
                  locale={'sv'}
                  monthsShown={1}
                />
              </Box>
            </StyledBox>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
