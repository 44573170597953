import { Box, Button } from 'components/basic-components';
import { X } from 'lucide-react';
import type { FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslations } from 'translations/hooks/useTranslations';
import { Backdrop, CloseButton, Content, DraggableWrapper, Footer, Header, HeaderText, StyledModal, Wrapper } from './Modal.style';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  onConfirm: (...arg: any[]) => void;
  onCancel: (...arg: any[]) => void;
  message?: string;
  headerText: string;
  confirmText?: string;
  cancelText?: string;
  disableCancel?: boolean;
  disableConfirm?: boolean;
  disableCross?: boolean;
  children?: React.ReactNode;
  draggable?: boolean;
  invertButtons?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  message,
  onCancel,
  onConfirm,
  headerText,
  confirmText,
  cancelText,
  disableCancel,
  disableConfirm,
  disableCross,
  children,
  draggable,
  invertButtons,
}) => {
  const { t } = useTranslations();
  const onHide = disableCross ? () => {} : hide;
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    setPosition({ x: 0, y: 0 });
  }, [isShown]);

  const ModalWrapper = draggable ? DraggableWrapper : Wrapper;

  const modal = (
    <React.Fragment>
      <Backdrop onClick={onHide} onMouseMove={draggable ? handleMouseMove : undefined} />
      <ModalWrapper
        aria-modal
        aria-labelledby={headerText}
        tabIndex={-1}
        role="dialog"
        style={{
          transform: `translate(-50%, -50%) translate(${position.x}px, ${position.y}px)`,
        }}
        onMouseUp={draggable ? handleMouseUp : undefined}
        onMouseMove={draggable ? handleMouseMove : undefined}
      >
        <StyledModal>
          <Header
            onMouseDown={draggable ? handleMouseDown : undefined}
            style={{ cursor: draggable ? (isDragging ? 'grabbing' : 'grab') : 'default' }}
          >
            <HeaderText>{headerText}</HeaderText>
            <CloseButton
              disabled={disableCross}
              onClick={(e) => {
                e.stopPropagation();
                onHide();
              }}
            >
              <X display="flex" />
            </CloseButton>
          </Header>
          <Content>{children ?? message}</Content>
          <Footer>
            <Box flexDirection={invertButtons ? 'row' : 'row-reverse'} gap="10px" justifyContent="end">
              <Button disabled={disableConfirm} variant="primary" onClick={disableConfirm ? undefined : onConfirm}>
                {confirmText ?? t('general.general.yes')}
              </Button>
              <Button disabled={disableCancel} variant="secondary" onClick={disableCancel ? undefined : onCancel}>
                {cancelText ?? t('general.general.no')}
              </Button>
            </Box>
          </Footer>
        </StyledModal>
      </ModalWrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
