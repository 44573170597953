import { useGetApplication } from 'api/applications/hooks/useGetApplication';
import { Box, Typography } from 'components/basic-components';
import { TextField } from 'components/form-components';
import { DatePicker } from 'components/form-components/DatePicker/DatePicker';
import { ContentLoader, Section } from 'components/misc';
import { EnumExemptionType } from 'enums/EnumExemptionTypeEnum';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';
import { printLocaleString } from 'utilities/printLocaleString';
import * as Yup from 'yup';
import { ControlItem } from './Application';

export const ExemptionApplication = () => {
  const { t, language } = useTranslations();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { application, findValueFromField, isLoading } = useGetApplication(parseInt(applicationId ?? ''));
  const isMember = Boolean(application?.viewInfo.isMemberCheck);

  return (
    <>
      <Formik
        initialValues={{
          motivation: findValueFromField('message'),
          decision: findValueFromField('decision'),
          fromDate: new Date(findValueFromField('from_date') as string),
          toDate: new Date(findValueFromField('to_date') as string),
        }}
        onSubmit={() => {}}
        validationSchema={Yup.object().shape({
          confirm: Yup.boolean().required('profile.applications.confirmRequired'),
        })}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Section title={t('profile.applications.control')}>
              <ContentLoader status={isLoading ? 'loading' : 'idle'} template="applications">
                <Box padding="base-tight" gap="base-tight">
                  <ControlItem
                    isTrue={isMember}
                    text={
                      isMember
                        ? t('profile.applications.userBelongsToMemberCompany')
                        : t('profile.applications.userDoesNotBelongToMemberCompany')
                    }
                  />
                  {application?.viewInfo?.checkErrors.map((error) => (
                    <ControlItem key={error} isTrue={false} text={t(`profile.applications.${error}`)} />
                  ))}
                </Box>
              </ContentLoader>
            </Section>
            <Section title={t('profile.applications.status')}>
              <Box padding="base-tight" gap="base-tight">
                {application?.createDate && (
                  <Typography.Paragraph>
                    {printLocaleString(application.createDate)} {t('profile.applications.applicationCreated')}
                  </Typography.Paragraph>
                )}
                {!application?.approveDate && !application?.rejectDate && (
                  <Typography.Paragraph color="error">{t('profile.applications.applicationPending')}</Typography.Paragraph>
                )}
                {application?.approveDate && (
                  <Typography.Paragraph>
                    {printLocaleString(application.approveDate)} {t('profile.applications.applicationApproved')}
                  </Typography.Paragraph>
                )}
                {application?.rejectDate && (
                  <Typography.Paragraph>
                    {printLocaleString(application.rejectDate)} {t('profile.applications.applicationRejected')}
                  </Typography.Paragraph>
                )}
              </Box>
            </Section>
            {findValueFromField('exemption_type') === EnumExemptionType.LICENSE_NEW && (
              <>
                {application?.viewInfo?.checkErrors.length === 0 && (
                  <Section title={t('profile.applications.exemptionApplication')}>
                    <Box padding="base-tight">
                      <Typography.H4 margin="none" paddingBottom="loose">
                        {t('profile.applications.exemptionTitleChapter-7-Section-2')}
                      </Typography.H4>
                      <Typography.Paragraph marginY="loose">
                        {t('profile.applications.exemptionDescriptionChapter-7-Section-2', {
                          name: application?.viewInfo.userFullName,
                          company: application?.companyName,
                        })}
                      </Typography.Paragraph>
                      <TextField
                        disabled
                        name="motivation"
                        as="textarea"
                        label={t('profile.applications.motivation')}
                        rows="7"
                        value={values.motivation}
                      />
                      <Typography.Label>{t('profile.applications.fromDate')}</Typography.Label>
                      <DatePicker
                        selected={values.fromDate}
                        disabled
                        locale={language}
                        dateFormat="YYYY-MM-dd"
                        onChange={(date) => setFieldValue('fromDate', date)}
                      />
                      {application?.approveDate && (
                        <>
                          <Typography.Label marginTop="base">{t('profile.applications.toDate')}</Typography.Label>
                          <DatePicker
                            selected={values.toDate}
                            disabled
                            locale={language}
                            dateFormat="YYYY-MM-dd"
                            onChange={(date) => setFieldValue('toDate', date)}
                          />
                        </>
                      )}
                    </Box>
                  </Section>
                )}
              </>
            )}
            {findValueFromField('exemption_type') === EnumExemptionType.LICENSETEST && (
              <>
                {application?.viewInfo?.checkErrors.length === 0 && (
                  <Section title={t('profile.applications.exemptionApplication')}>
                    <Box padding="base-tight">
                      <Typography.H4 margin="none" paddingBottom="loose">
                        {t('profile.applications.exemptionTitleChapter-7-Section-1')}
                      </Typography.H4>
                      {findValueFromField('exemption_license_test_family_id') && (
                        <Typography.Paragraph>
                          {t(`profile.applications.exemptionTest.${findValueFromField('exemption_license_test_family_id')}`)}
                        </Typography.Paragraph>
                      )}
                      {findValueFromField('type_reason') && (
                        <Typography.Paragraph>
                          {t(`profile.applications.exemptionReason.${findValueFromField('type_reason')}`)}
                        </Typography.Paragraph>
                      )}
                      <Typography.Paragraph marginY="loose">
                        {t('profile.applications.exemptionDescriptionChapter-7-Section-1', {
                          name: application?.viewInfo.userFullName,
                          company: application?.companyName,
                        })}
                      </Typography.Paragraph>
                      <TextField
                        disabled
                        name="motivation"
                        as="textarea"
                        label={t('profile.applications.motivation')}
                        rows="7"
                        value={values.motivation}
                      />
                      <Typography.Label>{t('profile.applications.fromDate')}</Typography.Label>
                      <DatePicker
                        selected={values.fromDate}
                        disabled
                        locale={language}
                        dateFormat="YYYY-MM-dd"
                        onChange={(date) => setFieldValue('fromDate', date)}
                      />
                      {application?.approveDate && (
                        <>
                          <Typography.Label marginTop="base">{t('profile.applications.toDate')}</Typography.Label>
                          <DatePicker
                            selected={values.toDate}
                            disabled
                            locale={language}
                            dateFormat="YYYY-MM-dd"
                            onChange={(date) => setFieldValue('toDate', date)}
                          />
                        </>
                      )}
                    </Box>
                  </Section>
                )}
              </>
            )}
            {(application?.rejectDate || application?.approveDate) && (
              <Section title={t('profile.applications.decision')}>
                <Box padding="base-tight">
                  <TextField disabled name="decision" as="textarea" rows="7" value={values.decision} />
                </Box>
              </Section>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
