import type { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import type { BorderProps, BorderWidthProps, ColorProps, FlexProps, FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { border, borderWidth, color, flex, flexbox, layout, space } from 'styled-system';

import type { ThemeSchema, ThemeTextStyles } from 'theme';
import { theme } from 'theme';

export type NavLinkProps = { textStyle?: ThemeTextStyles } & BorderProps<ThemeSchema> &
  BorderWidthProps &
  ColorProps<ThemeSchema> &
  FlexProps &
  FlexboxProps &
  LayoutProps<ThemeSchema> &
  SpaceProps<ThemeSchema> &
  FunctionComponent<any>;

export const StyledLink = styled<NavLinkProps>(NavLink).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})`
  display: inline-block;
  position: relative;
  padding: 0.05em 0;
  overflow: hidden;
  ${border}
  ${borderWidth}
  ${color}
  ${flex}
  ${flexbox}
  ${layout}
  ${space}
  ${({ textStyle, theme }) => theme.textStyles[textStyle]}
    &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    border-radius: 1px;
    background-color: ${theme.colors['light']};
    transition:
      opacity 300ms,
      transform 300ms;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  &:hover {
    color: ${theme.colors['info']};
  }
  &:hover::after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.active {
    color: ${theme.colors['info']};
  }
`;

StyledLink.defaultProps = {
  alignContent: 'flex-end',
  justifyContent: 'center',
  color: 'main',
  textStyle: 'body',
};

export default StyledLink;
