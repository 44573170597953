import { useGetUser } from 'api/users/hooks/useGetUser';
import { Box, Typography } from 'components/basic-components';
import { IconButton } from 'components/basic-components/IconButton/IconButton';
import { X } from 'lucide-react';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import { theme } from '../../../theme';

export const ImpersonationBanner = () => {
  const { t } = useTranslations();
  const isIntegrated = useIsIntegrated();
  const { user, setImpersonatedUserId } = useGetUser();
  const loggedInUserId = getLoggedInUser()?.userId;

  if (!loggedInUserId || !user || user.id === loggedInUserId || isIntegrated) {
    return null;
  }

  return (
    <Box backgroundColor="main" flexDirection="row" alignItems="center">
      <Typography.H4 textAlign="center" color="neutral-color-2" padding="base-tight" margin="none" width="100%">
        {t('general.integrated.loggedInAs', { name: `${user?.firstName} ${user?.lastName}` })}
      </Typography.H4>
      <IconButton size="32px" icon={<X color={theme.colors['neutral-color-2']} size="32px" />} onClick={() => setImpersonatedUserId()} />
    </Box>
  );
};
