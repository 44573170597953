import { Icon } from 'components/misc';
import type { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

const StyledIconButton = styled.button`
  display: flex;
  align-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

type IconButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: ReactNode;
  disabled?: boolean;
  className?: string;
  size?: string;
};

export const IconButton = ({ onClick, icon, className, disabled, size }: IconButtonProps) => (
  <StyledIconButton onClick={onClick} color="gray" className={className} disabled={disabled}>
    <Icon size={size ?? '20px'}>{icon}</Icon>
  </StyledIconButton>
);
