import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useChangePasswordMutation } from 'api/users/users';
import { Box, Button, Typography } from 'components/basic-components';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { ChangePasswordForm } from 'components/misc/Profile/ChangePasswordForm';
import { PasswordInformation } from 'components/misc/Profile/PasswordInformation';
import { Form, Formik } from 'formik';
import { ChevronLeft } from 'lucide-react';
import SectionWrapper from 'pages/private/tests/Book/SectionWrapper';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { errorHandler, getErrorMessage } from 'utilities/errorHandling';
import { changePasswordValidationSchema } from 'utilities/validationSchema';

const ChangePassword = () => {
  const navigate = useNavigate();
  const { activeUserId } = useGetActiveUserId();
  const [changePassword, { isSuccess, error }] = useChangePasswordMutation();
  const { t } = useTranslations();

  const handleSubmit = async (values: { oldPassword: string; newPassword: string; confirmPassword: string }) =>
    changePassword({ oldPassword: values['oldPassword'], password: values['newPassword'], userId: activeUserId });

  const handleBack = () => {
    navigate('..');
  };

  useEffect(() => {
    if (isSuccess) {
      publish(CustomEventName.SUCCESS, {
        message: 'password_updated',
      });
    }
  }, [isSuccess]);

  // TODO: This needs a better implementation, its not in the API layer because different views using the same request handles errors in different ways.
  useEffect(() => {
    if (error) {
      if ('status' in error) {
        let errorMessage = '';
        switch (error.status) {
          case 400:
            errorMessage = getErrorMessage(error);
            if (errorMessage === 'INVALID_PASSWORD') {
              publish(CustomEventName.API_ERROR, { message: 'INVALID_PASSWORD' });
            } else if (errorMessage === 'PASSWORD_HAS_BEEN_USED_PREVIOUSLY') {
              publish(CustomEventName.API_ERROR, { message: 'PASSWORD_HAS_BEEN_USED_PREVIOUSLY' });
            } else if (errorMessage === 'ACCOUNT_OLD_PASSWORD_REQUIRED ') {
              publish(CustomEventName.API_ERROR, { message: 'ACCOUNT_OLD_PASSWORD_REQUIRED' });
            } else if (errorMessage === 'ACCOUNT_404') {
              publish(CustomEventName.API_ERROR, { message: 'ACCOUNT_404' });
            } else if (errorMessage === 'MUST_PROVIDE_OLD_PASSWORD') {
              publish(CustomEventName.API_ERROR, { message: 'ACCOUNT_OLD_PASSWORD_REQUIRED' });
            } else {
              publish(CustomEventName.API_ERROR, { message: 'invalid_format' });
            }
            break;
          default:
            errorHandler(error);
            break;
        }
      }
    }
  }, [error]);

  return (
    <>
      <Button variant="secondary" onClick={handleBack}>
        <ChevronLeft />
        {t('profile.changePassword.back')}
      </Button>
      <Typography.H2
        color="main"
        marginBottom="base"
        onClick={() =>
          handleSubmit({
            oldPassword: '',
            confirmPassword: '',
            newPassword: '',
          })
        }
      >
        {t('profile.changePassword.title')}
      </Typography.H2>
      <Formik
        initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
        onSubmit={handleSubmit}
        validationSchema={changePasswordValidationSchema({
          required: 'onboarding.resetPassword.errors.required',
          passwordMustMatch: 'onboarding.resetPassword.errors.passwordMustMatch',
          tooShort: 'onboarding.resetPassword.errors.tooShort',
          tooLong: 'onboarding.resetPassword.errors.tooLong',
          capitalLetter: 'onboarding.resetPassword.errors.capitalLetter',
          smallLetter: 'onboarding.resetPassword.errors.smallLetter',
          number: 'onboarding.resetPassword.errors.number',
          specialCharacter: 'onboarding.resetPassword.errors.specialCharacter',
          whiteSpaces: 'onboarding.resetPassword.errors.whiteSpaces',
        })}
      >
        {({ isSubmitting, errors, values, initialValues }) => (
          <>
            <SectionWrapper status={'idle'}>
              <Form>
                <ChangePasswordForm />
              </Form>
              <PasswordInformation />
            </SectionWrapper>
            <Box variant="sectionWrapper">
              <ActionButtonRow
                primaryText={t('profile.changePassword.change')}
                disabled={
                  isSubmitting ||
                  Boolean(errors['oldPassword']) ||
                  Boolean(errors['newPassword']) ||
                  Boolean(errors['confirmPassword']) ||
                  values == initialValues
                }
                secondaryOnClick={() => navigate('..')}
                secondaryText={t('profile.changePassword.cancel')}
              />
            </Box>
          </>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
