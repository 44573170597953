import styled from 'styled-components';
import { background, border, borderWidth, color, display, flex, flexbox, layout, space } from 'styled-system';
import { theme } from 'theme';
import Box from '../Box/Box';
import { Typography } from '../Typography/Typography';

const StyledInput = styled.input`
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid ${() => theme.colors['neutral-color-1']};
  align-self: stretch;
  padding: 0.5em 0.625em;
  width: 100%;
  ${background}
  ${border}
  ${borderWidth}
  ${color}
  ${display}
  ${flex}
  ${flexbox}
  ${layout}
  ${space}
  ::placeholder {
    ${() => theme.textStyles['place-holder']}
  }
  ${() => theme.textStyles['text-input']}
`;

type InputProps = {
  onChange: (value: string) => void;
  label?: string;
  name?: string;
  value?: string;
  type?: string;
  placeholder?: string;
};

export const Input = ({ name, type, value, label, placeholder, onChange }: InputProps) => (
  <>
    {label ? (
      <Box marginBottom="tight" display="flex" width="100%">
        <Typography.Label width="100%" position="relative">
          {label}
          <br />
          <StyledInput name={name} type={type} value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} />
        </Typography.Label>
      </Box>
    ) : (
      <StyledInput name={name} type={type} value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} />
    )}
  </>
);
