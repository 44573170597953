import { privateApi } from 'api/base';

type GetViolationsRequest = {
  userId: number;
};

export type Violation = {
  type: string;
  date: string;
  id: number;
};

type ApiViolations = {
  entries: Array<Violation>;
  showPrintViolationsPDF: boolean;
};

type DeleteViolationsRequest = {
  violationId: number;
  userId: number;
};

export const violationApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getViolations: builder.query<ApiViolations, GetViolationsRequest>({
      query: ({ userId }) => `violations/users/${userId.toString()}`,
      transformResponse: (data: ApiViolations) => ({
        entries: data?.entries.map((violation) => ({
          type: violation.type,
          date: new Date(violation.date).toISOString(),
          id: violation.id,
        })),
        showPrintViolationsPDF: data.showPrintViolationsPDF,
      }),
      providesTags: ['Violations'],
    }),
    deleteViolations: builder.mutation<void, DeleteViolationsRequest>({
      query: ({ violationId }) => ({
        url: `violations/${violationId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ userId, violationId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          violationApi.util.updateQueryData('getViolations', { userId }, (violations) => {
            violations.entries = violations.entries.filter((violation) => violation.id !== violationId);

            return violations;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetViolationsQuery, useDeleteViolationsMutation } = violationApi;
