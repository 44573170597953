import { LandingPage } from 'components/layout-components/Public/LandingPage';
import ForgotPasswordForm from 'components/misc/Login/ForgotPasswordForm';
import { useTranslations } from 'translations/hooks/useTranslations';

const ForgetPassword = () => {
  const { t } = useTranslations();

  return (
    <LandingPage title={t('onboarding.forgotPassword.title')}>
      <ForgotPasswordForm />
    </LandingPage>
  );
};
export default ForgetPassword;
