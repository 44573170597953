import { Box, Typography } from 'components/basic-components';
import { useTranslations } from 'translations/hooks/useTranslations';

export const PasswordInformation = () => {
  const { t } = useTranslations();

  return (
    <Box marginBottom="base" flex={1}>
      <Typography.Paragraph fontWeight="bold">{t('onboarding.resetPassword.passwordMust')}</Typography.Paragraph>
      <Typography.Paragraph> &bull; {t('onboarding.resetPassword.8to20')}</Typography.Paragraph>
      <Typography.Paragraph> &bull; {t('onboarding.resetPassword.oneSmall')}</Typography.Paragraph>
      <Typography.Paragraph> &bull; {t('onboarding.resetPassword.oneCapital')}</Typography.Paragraph>
      <Typography.Paragraph> &bull; {t('onboarding.resetPassword.oneNumber')}</Typography.Paragraph>
      <Typography.Paragraph> &bull; {t('onboarding.resetPassword.oneSpecial')}</Typography.Paragraph>
      <Typography.Paragraph> &bull; {t('onboarding.resetPassword.noWhiteSpaces')}</Typography.Paragraph>
    </Box>
  );
};
