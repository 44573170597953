import { privateApi } from 'api/base';
import { paginationStringGenerator } from 'api/helpers/pagination';
import { queryStringGenerator } from 'api/helpers/queryString';

type GetCompanyRequest = {
  companyId: number;
};

type ApiCompany = {
  id: number;
  name: string;
  org_no: string;
  street: string;
  city: string;
  zip_code: string;
  country: string;
  home_page: string;
  notification_email: string;
  is_affiliate: boolean;
  ceo: {
    id: number;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  };
  compliance: {
    id: number;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  };
};

export type Company = {
  id: number;
  name: string;
  orgNo: string;
  street: string;
  city: string;
  zipCode: string;
  country: string;
  homePage: string;
  notificationEmail: string;
  isAffiliate: boolean;
  ceo: {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  compliance: {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
};

export type GetCompaniesRequest = {
  companyId?: number;
  freeText?: string;
  page?: number;
  size?: number;
};

type GetCompaniesResponse = {
  count: number;
  total: number;
  companies: Array<Company>;
};

type GetApiCompaniesResponse = {
  count: number;
  total: number;
  companies: Array<ApiCompany>;
};

const transformCompanyResponse = (company: ApiCompany) => ({
  id: company.id,
  name: company.name,
  orgNo: company.org_no,
  street: company.street,
  city: company.city,
  zipCode: company.zip_code,
  country: company.country,
  homePage: company.home_page,
  notificationEmail: company.notification_email,
  isAffiliate: company.is_affiliate,
  ceo: {
    id: company.ceo?.id,
    firstName: company.ceo?.first_name,
    lastName: company.ceo?.last_name,
    phone: company.ceo?.phone,
    email: company.ceo?.email,
  },
  compliance: {
    id: company.compliance?.id,
    firstName: company.compliance?.first_name,
    lastName: company.compliance?.last_name,
    phone: company.compliance?.phone,
    email: company.compliance?.email,
  },
});

export const companiesApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query<Company, GetCompanyRequest>({
      query: ({ companyId }) => {
        return {
          url: `companies/${companyId}`,
        };
      },
      transformResponse: transformCompanyResponse,
      providesTags: ['Companies'],
    }),
    getCompanies: builder.query<GetCompaniesResponse, GetCompaniesRequest>({
      query: ({ freeText, page, size }) => {
        const paginationString = paginationStringGenerator({ page, size });
        const queryString = queryStringGenerator({ freeText });

        return {
          url: `companies?${paginationString}${paginationString && '&'}${queryString}`,
        };
      },
      transformResponse: (data: GetApiCompaniesResponse) => ({
        ...data,
        companies: data?.companies.map(transformCompanyResponse),
      }),
      providesTags: ['Companies'],
    }),
  }),
});

export const { useGetCompanyQuery, useGetCompaniesQuery } = companiesApi;
