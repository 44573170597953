import { HOST } from 'api/base';
import type { NavigateFunction, NavigateOptions } from 'react-router-dom';

export type NavigationOptions = {
  options?: NavigateOptions;
  route?: string;
  postMessageOptions?: {
    userId?: number;
    target?: string;
  };
  isIntegrated?: boolean;
};

export const GoToView = (navigate: NavigateFunction, navigationOptions?: NavigationOptions) => {
  return async () => {
    const isIntegrated = window.self !== window.top;

    if (isIntegrated) {
      const postOptions = navigationOptions?.postMessageOptions;

      if (window.top) {
        window.top.postMessage(
          {
            target: postOptions?.target,
            user_id: postOptions?.userId,
          },
          HOST
        );
      }
    } else {
      navigate(navigationOptions?.route || '/', navigationOptions?.options);
    }
  };
};
