export const priceCalculator = (values?: { amount_excl_vat: number; vat: number; sum_discounts: number | 0 }) => {
  if (!values) {
    return 0;
  }

  const {
    amount_excl_vat, // eslint-disable-line camelcase
    vat,
    sum_discounts, // eslint-disable-line camelcase
  } = values;
  const price = amount_excl_vat - sum_discounts + (amount_excl_vat - sum_discounts) * vat; // eslint-disable-line camelcase
  return price;
};
