import { useGetApplication } from 'api/applications/hooks/useGetApplication';
import { Box, Typography } from 'components/basic-components';
import { Checkbox, RadioButton } from 'components/form-components';
import { ContentLoader, Section } from 'components/misc';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';
import { printLocaleString } from 'utilities/printLocaleString';
import * as Yup from 'yup';
import { ControlItem, StyledUl } from './Application';

export const DeregistrationApplication = () => {
  const { t } = useTranslations();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { application, findValueFromField, isLoading } = useGetApplication(parseInt(applicationId ?? ''));
  const isMember = Boolean(application?.companyIsMember);

  const handleSubmit = () => {};

  return (
    <Formik
      initialValues={{
        type: findValueFromField('type') === true ? '1' : findValueFromField('type'),
        followedRules: findValueFromField('followed_rules') ? '1' : '0',
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        confirm: Yup.boolean().required('profile.applications.confirmRequired'),
      })}
      enableReinitialize
    >
      {() => (
        <Form>
          <Section title={t('profile.applications.control')}>
            <ContentLoader status={isLoading ? 'loading' : 'idle'} template="applications">
              <Box padding="base-tight" gap="base-tight">
                <ControlItem
                  isTrue={isMember}
                  text={
                    isMember
                      ? t('profile.applications.userBelongsToMemberCompany')
                      : t('profile.applications.userDoesNotBelongToMemberCompany')
                  }
                />
              </Box>
            </ContentLoader>
          </Section>
          <Section title={t('profile.applications.status')}>
            <Box padding="base-tight" gap="base-tight">
              {application?.createDate && (
                <Typography.Paragraph>
                  {printLocaleString(application.createDate)} {t('profile.applications.deregistrationApplicationCreated')}
                </Typography.Paragraph>
              )}
              {!application?.approveDate && !application?.rejectDate && (
                <Typography.Paragraph color="error">{t('profile.applications.applicationPending')}</Typography.Paragraph>
              )}
              {application?.approveDate && (
                <Typography.Paragraph>
                  {printLocaleString(application.approveDate)} {t('profile.applications.applicationApproved')}
                </Typography.Paragraph>
              )}
              {application?.rejectDate && (
                <Typography.Paragraph>
                  {printLocaleString(application.rejectDate)} {t('profile.applications.applicationRejected')}
                </Typography.Paragraph>
              )}
            </Box>
          </Section>
          <Section title={t('profile.applications.deregistrationActivateRestingLicenseBecauseTitle')}>
            <Box padding="base-tight" gap="base-tight">
              <RadioButton disabled name="type" label={t('profile.applications.deregistrationType.1')} value="1" />
              <RadioButton disabled name="type" label={t('profile.applications.deregistrationType.2')} value="2" />
              <RadioButton disabled name="type" label={t('profile.applications.deregistrationType.3')} value="3" />
            </Box>
          </Section>
          {findValueFromField('type') === '2' && (
            <Section title={t('profile.applications.deregistrationUserAtWarTitle')}>
              <Box padding="base-tight" gap="base-tight">
                <RadioButton disabled name="followedRules" label={t('profile.applications.deregistrationFollowedRules')} value="1" />
                <RadioButton disabled name="followedRules" label={t('profile.applications.deregistrationDidnotFollowRules')} value="0" />
                <RadioButton
                  disabled
                  name="followedRules"
                  label={t('profile.applications.missingDeregistrationFollowedRules')}
                  value="-1"
                />
              </Box>
            </Section>
          )}
          <Section title={t('profile.applications.approval')}>
            <Box padding="base-tight">
              <Box flexDirection="row" gap="base-tight">
                <Box width="24px">
                  <Checkbox disabled name="confirm" checked />
                </Box>
                <Typography.Paragraph alignSelf="center">{t('profile.applications.approvalDescription.0')}</Typography.Paragraph>
              </Box>
              <StyledUl>
                <Typography.Paragraph>
                  <li>{t('profile.applications.approvalDescription.1')}</li>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <li>{t('profile.applications.approvalDescription.2')}</li>
                </Typography.Paragraph>
              </StyledUl>
            </Box>
          </Section>
        </Form>
      )}
    </Formik>
  );
};
