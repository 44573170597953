import type { TimeSlot } from 'api/educateIT/educateIT';
import type { Occasion } from 'api/exams/exams';
import { Box, Typography } from 'components/basic-components';
import { Icon } from 'components/misc';
import { Headphones } from 'lucide-react';
import moment from 'moment';
import { useTranslations } from 'translations/hooks/useTranslations';

export type OccasionOverviewProps = {
  occasion?: Occasion | TimeSlot;
  date?: string;
  selected?: boolean;
  audio?: boolean;
  timeLength: number;
  onSelect?: (occasion: any) => void;
};

const OccasionOverview = ({ selected = false, audio, date, occasion, timeLength, onSelect }: OccasionOverviewProps) => {
  moment.locale('sv');
  const location = occasion && 'location' in occasion ? `, ${occasion?.location}` : '';
  const noAvailableSeats = (occasion?.availableSeats ?? 0) <= 0;
  const { t } = useTranslations();

  const handleOnClick = () => {
    if (!noAvailableSeats && onSelect) {
      onSelect(occasion);
    }
  };

  return (
    <Box
      role="button"
      backgroundColor={selected ? 'main' : 'light'}
      onClick={handleOnClick}
      color={selected ? 'light' : noAvailableSeats ? 'neutral-color-1' : 'main'}
      borderColor={selected ? 'main' : noAvailableSeats ? 'neutral-color-enhanced-2' : 'main-enhanced'}
      borderStyle="solid"
      borderWidth="extra-thin"
      alignItems="flex-start"
      padding="base-tight"
      marginTop="base-tight"
      style={{ cursor: `${noAvailableSeats ? `not-allowed` : `pointer`}` }}
      borderRadius="xs"
      $wrap
    >
      {moment(date).format('YYYY-MM-DD, HH:mm')}-{moment(date).add(timeLength, 'm').format('HH:mm')}
      {location}
      <br />
      {noAvailableSeats ? (
        <Typography.Paragraph>Fullbokat</Typography.Paragraph>
      ) : (
        audio && (
          <Typography.Paragraph display="flex" alignItems="center">
            <Icon size="20" marginLeft="none" color="main">
              <Headphones />
            </Icon>
            {t('booking.booking.audioTooltip')}
          </Typography.Paragraph>
        )
      )}
    </Box>
  );
};

export default OccasionOverview;
