export const PhoenixRoutes: { [key: string]: string } = {
  BOOKING: '/users/:userId/tests/book',
  BOOKED_TESTS: '/users/:userId/tests/booked',
  CONFIRM_BOOKING: '/users/:userId/tests/confirm',
  VIEW_TEST_RESULTS: '/users/:userId/tests/results',
  PROFILE: '/users/:userId/profile',
  VIEW_AKU: '/users/:userId/aku',
  EDIT_AKU: '/users/:userId/aku/edit',
  MY_COSTS: '/users/:userId/costs',
  MY_HISTORY: '/users/:userId/history',
  MY_VIOLATIONS: '/users/:userId/violations',
  MY_APPLICATIONS: '/users/:userId/applications',
  HOME: '/users/:userId',
  HELP: '/help',
  SYSTEM_MESSAGES: '/system-messages',
};

type Route = {
  path: string;
  params?: {
    [k: string]: string;
  };
};

export function createPath(arg: Route) {
  // Save some CPU power for routes without params
  // eslint-disable-next-line no-prototype-builtins
  if (arg.hasOwnProperty('params') === false || !arg.params) return arg.path;

  // Create a path by replacing params in the route definition
  return Object.entries(arg.params).reduce(
    (previousValue: string, [param, value]) => previousValue?.replace(`:${param}`, '' + value),
    arg.path
  );
}
