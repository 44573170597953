import type { ReactElement } from 'react';
import { MenuGroupHeader } from './MenuGroupHeader';
import { MenuLink } from './MenuLink';

export type LinkGroup = {
  name: string;
  enable: boolean;
  links: Array<Link>;
  icon?: ReactElement;
};

type Link = {
  identifier: string;
  name: any;
  to: string;
  enable?: boolean | true;
};

type MenuGroupProps = {
  group: LinkGroup;
};

export const MenuGroup = ({ group }: MenuGroupProps) => {
  if (!group.enable) {
    return null;
  }

  return (
    <>
      <MenuGroupHeader icon={group.icon} name={group.name} />
      {group.links.map(
        (link, index) => link.enable && <MenuLink key={`menu_group_${group.name}_${index}`} to={link.to} name={link.name} />
      )}
    </>
  );
};
