import { Box, Typography } from 'components/basic-components';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import { isAdminSelector, isTestLeaderSelector } from 'store/selectors/identity';
import { useTranslations } from 'translations/hooks/useTranslations';

export const Help = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const isTestLeader = useAppSelector(isTestLeaderSelector);
  const isAdmin = useAppSelector(isAdminSelector);

  return (
    <Box width="100%" height="100%">
      <PageHeader title={t('home.help.title')} onBack={() => navigate('/')} />
      <Typography.Paragraph>{t('home.help.contactSupport')}</Typography.Paragraph>
      <Typography.Paragraph marginTop="loose">
        {t('home.help.questionsAboutLicensing')} <Typography.Anchor href="mailto:info@swedsec.se">info@swedsec.se</Typography.Anchor>
      </Typography.Paragraph>
      <Typography.H2 marginTop="extra-loose">{t('home.help.manuals')}</Typography.H2>
      {isAdmin && (
        <Typography.Anchor
          href="https://www.swedsec.se/wp-content/uploads/2022/09/manual_administrator_swedsec_2022-01-17.pdf"
          target="_blank"
        >
          {t('home.help.adminManual')}
        </Typography.Anchor>
      )}
      {isTestLeader && (
        <Typography.Anchor href="https://www.swedsec.se/wp-content/uploads/2023/08/Testledarmanual-2023-08-24.pdf" target="_blank">
          {t('home.help.testLeaderManual')}
        </Typography.Anchor>
      )}
      <Typography.Anchor
        href="https://www.swedsec.se/wp-content/uploads/2022/09/manual_anvandare_testtagare_2021-01-13.pdf"
        target="_blank"
      >
        {t('home.help.userManual')}
      </Typography.Anchor>
      <Box marginTop="extra-loose">
        <Typography.Anchor href="https://www.swedsec.se/fragor-svar" target="_blank">
          {t('home.help.faq')}
        </Typography.Anchor>
      </Box>
    </Box>
  );
};
