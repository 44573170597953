import * as Sentry from '@sentry/react';
import { BASE_URL } from 'api/base';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import packageJSON from '../package.json';

export const sentryConfig: Sentry.BrowserOptions = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tunnel: `${BASE_URL}/sentry`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  release: packageJSON.version,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /(swedsec\.com\/api\/int)/, /(swedsec\.se\/api\/int)/, /(vux\.yourvoice\.se)/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT || 'default',
};
