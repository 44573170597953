import styled from 'styled-components';
import type { ColorProps, FlexProps, FlexboxProps } from 'styled-system';
import { color, flex, flexbox } from 'styled-system';
import type { ThemeSchema } from 'theme';
import { breakpoints, theme } from 'theme';

type HeaderProps = ColorProps<ThemeSchema> & FlexProps & FlexboxProps;

export const StyledHeader = styled.header<HeaderProps>`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  padding: 7px;
  background-color: ${theme.colors['main']};
  /*if screen bigger than tablet*/
  @media only screen and (min-device-width: ${breakpoints.md}) {
    background-color: ${theme.colors['light']};
    justify-content: flex-end;
    button {
      visibility: hidden;
    }
  }
  ${color}
  ${flex}
  ${flexbox}
`;

export default StyledHeader;
