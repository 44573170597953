import { Box, Typography } from 'components/basic-components';
import { Tooltip } from 'components/basic-components/Tooltip/Tooltip';
import { Icon } from 'components/misc';
import type { FieldAttributes } from 'formik';
import { Field, useFormikContext } from 'formik';
import { Eye, EyeOff } from 'lucide-react';
import type React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import type {
  BackgroundProps,
  BorderProps,
  BorderWidthProps,
  ColorProps,
  DisplayProps,
  FlexProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
} from 'styled-system';
import { background, border, borderWidth, color, display, flex, flexbox, layout, space } from 'styled-system';
import type { ThemeSchema, ThemeTextStyles } from 'theme';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

export type TextInputProps = {
  placeholderStyle?: ThemeTextStyles;
  inputStyle?: ThemeTextStyles;
} & BackgroundProps &
  BorderProps<ThemeSchema> &
  BorderWidthProps &
  ColorProps<ThemeSchema> &
  DisplayProps &
  FlexProps &
  FlexboxProps &
  LayoutProps<ThemeSchema> &
  SpaceProps<ThemeSchema> &
  FieldAttributes<any>;

const StyledTextField = styled<TextInputProps>(Field).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})`
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  ${background}
  ${border}
  ${borderWidth}
  ${color}
  ${display}
  ${flex}
  ${flexbox}
  ${layout}
  ${space}
  ::placeholder {
    ${() => theme.textStyles['place-holder']}
  }
  ${() => theme.textStyles['text-input']}
  :disabled {
    border-color: rgb(180, 180, 180);
    background-color: rgb(240, 240, 240);
    cursor: not-allowed;
  }
`;

StyledTextField.defaultProps = {
  paddingX: 'base',
  paddingY: 'base-tight',
  marginY: 'tight',
  borderColor: 'main',
  borderWidth: 'extra-thin',
  borderStyle: 'solid',
  borderRadius: 'xs',
  width: '100%',
};

const PasswordToggle = styled.button`
  appearance: 'none';
  border: 0;
  webkitappearance: 'none';
  mozappearance: 'none';
  white-space: nowrap;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 3px;
  cursor: pointer;
  background-color: transparent;
`;

type TextFieldProps = {
  label: string;
  name: string;
  type?: string;
  description?: string;
  withErrorPlaceholder?: boolean;
  onFocusRender?: React.ReactNode;
} & TextInputProps;

export const TextField = ({ label, name, type, description, required, withErrorPlaceholder, onFocusRender, ...rest }: TextFieldProps) => {
  const { t } = useTranslations();
  const [showPassword, setShowPassword] = useState(false);
  const { errors } = useFormikContext<{ [key: string]: string }>();
  const error = errors[name];
  const [isFocused, setIsFocused] = useState(false);
  const togglePasswordVisibility = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <Box marginBottom="tight" display="flex" width={rest?.width ?? '100%'}>
      <Typography.Label width="100%" position="relative">
        {label}{' '}
        {required && <Typography.SmallParagraph color="error-enhanced">({t('general.general.required')})</Typography.SmallParagraph>}
        {description && (
          <>
            <br />
            <Typography.SmallParagraph>{description}</Typography.SmallParagraph>
          </>
        )}
        {label && <br />}
        <StyledTextField
          name={name}
          type={type === 'password' ? (showPassword === true ? 'text' : 'password') : type}
          alignSelf="stretch"
          required={required}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...rest}
        />
        {type === 'password' && (
          <PasswordToggle onClick={togglePasswordVisibility} type="button" tabIndex={-1}>
            <Icon size="20px">{showPassword ? <Eye /> : <EyeOff />}</Icon>
          </PasswordToggle>
        )}
      </Typography.Label>
      {onFocusRender && <Tooltip content={onFocusRender} isOpen={isFocused} />}
      {error ? <ErrorMessage error={error} /> : withErrorPlaceholder && <Box height="20px" />}
    </Box>
  );
};
