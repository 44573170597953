import { useReserveExamMutation } from 'api/exams/exams';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { Box, Typography } from 'components/basic-components';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { errorHandler, getErrorMessage } from 'utilities/errorHandling';
import { priceCalculator } from 'utilities/priceCalculator';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

const TestMoment = () => {
  const { t } = useTranslations();
  const bookingDetails = useAppSelector((state) => state.reservation.bookingDetails);
  const licensingTest = useAppSelector((state) => state.reservation.licensingExam);
  const selectedSubject = useAppSelector((state) => state.reservation.bookingDetails.selectedSubject);
  const address = bookingDetails.selectedOccasion && 'id' in bookingDetails.selectedOccasion ? bookingDetails.selectedOccasion.address : '';

  return (
    licensingTest && (
      <>
        <Typography.H2 alignSelf="flex-start" color="main">
          {t('booking.confirm.t-test-moment')}
        </Typography.H2>
        <Box variant="section">
          <Typography.Paragraph fontWeight="semi-bold">{t('booking.confirm.t-test-topic')}</Typography.Paragraph>
          <Typography.Paragraph>{t('booking.confirm.license-template', { topic: selectedSubject?.title ?? '' })}</Typography.Paragraph>
          <Typography.Paragraph fontWeight="semi-bold">{t('booking.confirm.t-date-time')}</Typography.Paragraph>
          <Typography.Paragraph>{moment(bookingDetails?.selectedOccasion?.startTimeUtc).format('YYYY-MM-DD HH:mm')}</Typography.Paragraph>
          <Typography.Paragraph fontWeight="semi-bold">{t('booking.confirm.t-place')}</Typography.Paragraph>
          <Typography.Paragraph>
            {bookingDetails?.examMonitoringType?.type === 'Digital' ? t('booking.confirm.digital') : address}
          </Typography.Paragraph>
          {licensingTest?.examSpecification?.type === 'Audio' && (
            <>
              <Typography.Paragraph fontWeight="semi-bold">{t('booking.confirm.t-extra-info')}</Typography.Paragraph>
              <Typography.Paragraph>{t('booking.confirm.extra-time-info')}</Typography.Paragraph>
              <Typography.Paragraph>{t('booking.confirm.audio-info')}</Typography.Paragraph>
            </>
          )}
        </Box>
      </>
    )
  );
};

const DiagnosticTest = () => {
  const { t } = useTranslations();
  const diagnosticTest = useAppSelector((state) => state.reservation.diagnosticExam);

  return (
    <>
      <Typography.H2 alignSelf="flex-start" color="main">
        {t('booking.confirm.t-diagnostic-test')}
      </Typography.H2>
      <Box variant="section" $wrap>
        <Typography.Paragraph fontWeight="semi-bold">{t('booking.confirm.diagnosticModules')}</Typography.Paragraph>
        {diagnosticTest?.examSpecification?.contains?.map((contain) => (
          <Typography.Paragraph key={contain.art_no}>{contain.description}</Typography.Paragraph>
        ))}
        <Typography.Paragraph fontWeight="semi-bold">{t('booking.confirm.t-place')}</Typography.Paragraph>
        <Typography.Paragraph>{t('booking.confirm.digital')}</Typography.Paragraph>
      </Box>
    </>
  );
};

const Summery = () => {
  const { t } = useTranslations();
  const { licensingExam, diagnosticExam } = useAppSelector((state) => state.reservation);
  const examMonitoringType = useAppSelector((state) => state.reservation.bookingDetails.examMonitoringType);
  const selectedSubject = useAppSelector((state) => state.reservation.bookingDetails.selectedSubject);
  const isLicense = useAppSelector(selectors.articles.hasLicensingTestReservation);
  const isDiagnose = useAppSelector(selectors.articles.hasDiagnosticTestReservation);

  const licensePrice = licensingExam?.examSpecification?.art_no ? priceCalculator(licensingExam?.examSpecification?.price) : 0;
  const diagnosePrice = diagnosticExam?.examSpecification?.art_no ? priceCalculator(diagnosticExam?.examSpecification?.price) : 0;
  const proctorPrice = licensingExam ? priceCalculator(examMonitoringType?.price) : 0;

  const total = licensePrice + diagnosePrice + proctorPrice;

  return (
    <Box>
      <Typography.H2 alignSelf="flex-start" color="main">
        {t('booking.confirm.t-summery')}
      </Typography.H2>
      <Box variant="section" $wrap>
        <Typography.Paragraph fontWeight="semi-bold">{t('booking.confirm.t-test')}</Typography.Paragraph>
        <Typography.Paragraph>
          {isLicense &&
            `${t('booking.confirm.license-template', { topic: selectedSubject?.title ?? '' })}, ${licensePrice} ${t('booking.confirm.currency-sek')}`}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {isDiagnose &&
            `${t('booking.confirm.diagnostic-template', {
              topic: selectedSubject?.title ?? '',
            })}, ${diagnosePrice} ${t('booking.confirm.currency-sek')}`}
        </Typography.Paragraph>
        {examMonitoringType?.type === 'Fysisk' && (
          <>
            <Typography.Paragraph fontWeight="semi-bold">Lokal</Typography.Paragraph>
            <Typography.Paragraph>
              Fysisk, +{priceCalculator(examMonitoringType.price)} {t('booking.confirm.currency-sek')}
            </Typography.Paragraph>
          </>
        )}

        <Typography.Paragraph fontWeight="semi-bold">
          Totalt {total} {t('booking.confirm.currency-sek')}
        </Typography.Paragraph>
      </Box>
    </Box>
  );
};

const CancellationPolicy = () => {
  const { t } = useTranslations();
  const isLicenseTest = useAppSelector(selectors.articles.hasLicensingTestReservation);
  const examMonitoringType = useAppSelector((state) => state.reservation)?.bookingDetails?.examMonitoringType;

  return (
    examMonitoringType &&
    isLicenseTest && (
      <>
        <Typography.H2 color="main">{t('booking.confirm.t-cancel')}</Typography.H2>
        <Box backgroundColor="neutral-color-2" borderRadius="m" padding="base" $wrap>
          {examMonitoringType.type === 'Digital' && (
            <>
              <Typography.Paragraph fontWeight="medium">{t('booking.confirm.t-digital')}</Typography.Paragraph>
              <Typography.Paragraph textAlign="start">{t('booking.confirm.policy-digital')}</Typography.Paragraph>
            </>
          )}

          {examMonitoringType.type === 'Fysisk' && (
            <>
              <Typography.Paragraph fontWeight="medium">{t('booking.confirm.t-physical')}</Typography.Paragraph>
              <Typography.Paragraph textAlign="start">{t('booking.confirm.policy-physical')}</Typography.Paragraph>
            </>
          )}
        </Box>
      </>
    )
  );
};

const MoreInfo = () => {
  const { t } = useTranslations();
  return (
    <>
      <Typography.H2 color="main">{t('booking.confirm.readMoreTitle')}</Typography.H2>
      <Box backgroundColor="neutral-color-2" borderRadius="m" padding="base" $wrap>
        <Typography.Paragraph textAlign="start">{t('booking.confirm.readMore')}</Typography.Paragraph>
      </Box>
    </>
  );
};

export const Confirm = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { activeUserId } = useGetActiveUserId();
  const { user } = useGetUser();
  const { bookingDetails, licensingExam, diagnosticExam } = useAppSelector((state) => state.reservation);
  const isIntegrated = useIsIntegrated();
  const [reserveExam, { isSuccess, isLoading, error }] = useReserveExamMutation();
  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    await reserveExam({
      userId: activeUserId,
      companyId: user?.defaultCompany?.companyId ?? 0,
      bookingDetails,
      licensingExam,
      diagnosticExam,
    });
  };

  const handleCancel = () => {
    if (isIntegrated) {
      dispatch(
        actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
      );
    } else {
      navigate('/');
    }
  };

  const onBack = () => {
    if (isIntegrated) {
      navigate(-1);
    } else {
      navigate(`/users/${activeUserId}/tests/book`);
    }
  };

  useEffect(() => {
    if (!diagnosticExam && !licensingExam) {
      if (isIntegrated) {
        dispatch(
          actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'BOOKED_TESTS', userId: activeUserId } })
        );
      } else {
        dispatch(actions.general.GoToView(navigate, { route: `/tests/book/${activeUserId}`, options: { replace: true } }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      publish(CustomEventName.SUCCESS, {
        message: licensingExam && diagnosticExam ? 'reservation_success.1' : 'reservation_success.0',
      });

      if (isIntegrated) {
        dispatch(
          actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
        );
      } else {
        dispatch(actions.general.GoToView(navigate, { route: `/users/${activeUserId}/tests/booked`, options: { replace: true } }));
      }

      dispatch(actions.reservation['reset']());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // TODO: This needs a better implementation, its not in the API layer because different views using the same request handles errors in different ways.
  useEffect(() => {
    if (error) {
      if ('status' in error) {
        let errorMessage = '';
        switch (error.status) {
          case 400:
            errorMessage = getErrorMessage(error);
            if (errorMessage === 'PROCTOR_FOUND_ON_DIAGNOSTICS_TEST') {
              publish(CustomEventName.API_ERROR, { message: 'TEST_SESSION_CANCELLED' });
            } else if (errorMessage === 'PROCTOR_NOT_FOUND_ON_LICENSE_TEST') {
              publish(CustomEventName.API_ERROR, { message: 'TEST_SESSION_ILLEGAL_STATE_NOT_BOOKED' });
            } else if (errorMessage === 'NOT_A_DIAGNOSTIC_EXAM') {
              publish(CustomEventName.API_ERROR, { message: 'NOT_A_DIAGNOSTIC_EXAM ' });
            } else if (errorMessage === 'NOT_A_LICENSE_EXAM') {
              publish(CustomEventName.API_ERROR, { message: 'NOT_A_LICENSE_EXAM ' });
            } else if (errorMessage === 'PREMISE_NOT_FOUND_ON_LICENSE_TEST') {
              publish(CustomEventName.API_ERROR, { message: 'PREMISE_NOT_FOUND_ON_LICENSE_TEST' });
            } else {
              publish(CustomEventName.API_ERROR, { message: '400' });
            }
            break;
          case 409:
            errorMessage = getErrorMessage(error);
            if (errorMessage === 'FULLY_BOOKED') {
              publish(CustomEventName.API_ERROR, { message: 'TEST_SESSION_CANCELLED' });
            } else if (errorMessage === 'BOOKING_ALREADY_EXIST') {
              publish(CustomEventName.API_ERROR, { message: 'TEST_SESSION_ILLEGAL_STATE_NOT_BOOKED' });
            } else {
              publish(CustomEventName.API_ERROR, { message: '409' });
            }
            break;
          case 503:
          case 504:
            publish(CustomEventName.API_ERROR, { message: 'REMOTE_PROCTOR_SERVER_UNAVAILABLE' });
            break;
          default:
            errorHandler(error);
            break;
        }
      }
    }
  }, [error]);

  return (
    <>
      <PageHeader title={t('booking.confirm.title')} onBack={onBack} />
      <Box flexDirection={['column-reverse', 'column-reverse', 'row', 'row']} gap="loose">
        <Box flexDirection="column" width="initial" flexWrap="wrap" gap="loose">
          <Box alignItems="flex-start" flexDirection="row">
            <Box flexDirection="column" flex="1" flexWrap="wrap">
              <TestMoment />
              {diagnosticExam && <DiagnosticTest />}
              <Summery />
            </Box>
          </Box>
          <Box variant="sectionWrapper">
            <ActionButtonRow
              disabled={isLoading}
              primaryText={t('booking.confirm.confirm')}
              primaryOnClick={handleConfirm}
              secondaryOnClick={handleCancel}
              secondaryText={t('booking.confirm.cancel')}
            />
          </Box>
        </Box>
        <Box flex={1}>
          <CancellationPolicy />
          <MoreInfo />
        </Box>
      </Box>
    </>
  );
};
