import { privateApi } from 'api/base';

type GetCostsRequest = {
  userId: number;
};

export type Costs = {
  articleNo: string;
  company: string;
  description: string;
  invoiceDate: string;
  invoiceNo: number;
  invoiceId: number;
  id: number;
};

type ApiCosts = {
  article_no: string;
  company: string;
  description: string;
  invoice_date: string;
  invoice_no: number;
  cost_id: number;
  invoice_id: number;
};

type DeleteCostsRequest = {
  costId: number;
  userId: number;
};

export const costApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getCosts: builder.query<Array<Costs>, GetCostsRequest>({
      query: ({ userId }) => `costs/${userId.toString()}`,
      transformResponse: (data: ApiCosts[]) =>
        data.map((cost) => ({
          articleNo: cost.article_no,
          company: cost.company,
          description: cost.description,
          invoiceDate: new Date(cost.invoice_date.replace('[UTC]', '')).toLocaleDateString('se-SE'),
          invoiceNo: cost.invoice_no,
          invoiceId: cost.invoice_id,
          id: cost.cost_id,
        })),
      providesTags: ['Costs'],
    }),
    deleteCosts: builder.mutation<void, DeleteCostsRequest>({
      query: ({ costId }) => ({
        url: `costs/${costId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ costId, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          costApi.util.updateQueryData('getCosts', { userId }, (costs) => costs.filter((cost) => cost.id !== costId))
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const { useGetCostsQuery, useDeleteCostsMutation } = costApi;
