import type { Middleware, SerializedError } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { CustomEventName } from './customEvents';
import { publish } from './event';

type RejectedAction = {
  error: { message: string };
  meta: {
    arg: {
      endpointName: string;
      originalArgs: any;
    };
    requestId: string;
    rejectedWithValue: boolean;
    requestStatus: string;
    aborted: boolean;
    condition: boolean;
  };
  payload?: any;
};

export const rtkQueryErrorHandler: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const rejectedAction = action as RejectedAction;

    console.error({ rejectedAction });
  }

  return next(action);
};

export const errorHandler = (error: FetchBaseQueryError | SerializedError) => {
  if ('status' in error) {
    switch (error.status) {
      case 500:
        publish(CustomEventName.NETWORK_ERROR, { message: '500' });
        break;
      case 502:
      case 503:
      case 504:
        publish(CustomEventName.NETWORK_ERROR, { message: '503' });
        break;
      case 'FETCH_ERROR':
        publish(CustomEventName.NETWORK_ERROR, { message: 'NETWORK_ERROR' });
        break;
    }
  }
};

export const getErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
  if ('status' in error) {
    return (error.data as { message: string }).message;
  }

  return '';
};
