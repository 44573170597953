import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export const hasDiagnosticTestReservation = createSelector(
  (state: RootState) => state.reservation,
  (reservation) => Boolean(reservation?.diagnosticExam)
);

export const hasLicensingTestReservation = createSelector(
  (state: RootState) => state.reservation,
  (reservation) => Boolean(reservation?.licensingExam)
);

export const disableBookTestSubmit = createSelector(
  (state: RootState) => state.reservation,
  hasLicensingTestReservation,
  hasDiagnosticTestReservation,
  (reservation, isLicense, isDiagnose) => {
    if (isLicense) {
      const occasion = reservation.bookingDetails?.selectedOccasion;
      if (occasion && 'occasionId' in occasion) {
        return !occasion?.occasionId;
      } else {
        return !occasion?.locationId;
      }
    }

    return !isDiagnose;
  }
);

export const showBookTestSubmit = createSelector(
  hasLicensingTestReservation,
  hasDiagnosticTestReservation,
  (isLicenseTest, isDiagnoseTest) => {
    return isLicenseTest || isDiagnoseTest;
  }
);
