export interface AsyncObject {
  status: 'idle' | 'loading' | 'finished' | 'error';
}

export const asyncInitialValue: AsyncObject = {
  status: 'idle',
};

export const getStatusFromBooleans = (isError: boolean, isSuccess: boolean, isLoading: boolean): AsyncObject['status'] => {
  if (isSuccess) {
    return 'finished';
  }
  if (isLoading) {
    return 'loading';
  }

  return 'idle';
};
