import type { ColumnDef } from '@tanstack/react-table';
import type { Role } from 'api/oauth2/oauth2';
import { useGetRolesQuery } from 'api/oauth2/oauth2';
import { Table } from 'components/basic-components/Table/Table';
import { usePagination } from 'components/basic-components/Table/hooks/usePagination';
import { ContentLoader } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';

export const Roles = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { pageSize, page, onPagination } = usePagination({});
  const { data, isLoading, isError } = useGetRolesQuery({ page, size: pageSize });
  const roles = data?.roles ?? [];

  const columns = useMemo<ColumnDef<Role>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => t('oauth2.role.name'),
        size: undefined,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageHeader title={t('oauth2.role.title')} onBack={() => navigate('/')} />
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={roles}
          rowCount={data?.total}
          pageSize={pageSize}
          onPagination={onPagination}
          page={page}
          emptyText={t('oauth2.role.noResponse')}
          failedFetch={isError}
        />
      </ContentLoader>
    </>
  );
};
