import type { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { getLoggedInUser, saveLoggedInUser } from 'store/stores/identity/tokenStorage';
import { CustomEventName, publish } from 'utilities';
import type { LoginApiResponse } from './auth/auth';

export const HOST: string = process.env.REACT_APP_ASTRUM_HOST ? process.env.REACT_APP_ASTRUM_HOST : '';
if (HOST === '') throw new Error('REACT_APP_ASTRUM_HOST is not defined');

export const API: string = process.env.REACT_APP_ASTRUM_VERSION ? process.env.REACT_APP_ASTRUM_VERSION : '';
if (API === '') throw new Error('REACT_APP_ASTRUM_VERSION is not defined');

export const BASE_URL = `${HOST}${API}`;
export const ONLINE_HOST = `${HOST}`;

export const EDUCATEIT_BASE_URL: string = process.env.REACT_APP_EIT_BASE_URL ? process.env.REACT_APP_EIT_BASE_URL : '';
if (EDUCATEIT_BASE_URL === '') throw new Error('REACT_APP_EIT_BASE_URL is not defined');

const EIT_AUTH_ENDPOINT: string = process.env.REACT_APP_EIT_AUTH ? process.env.REACT_APP_EIT_AUTH : '';
if (EIT_AUTH_ENDPOINT === '') throw new Error('REACT_APP_EIT_AUTH is not  defined');

const EIT_X_VUX_SYSTEMID: string = process.env.REACT_APP_EIT_X_VUX_SYSTEMID ? process.env.REACT_APP_EIT_X_VUX_SYSTEMID : '';
if (EIT_X_VUX_SYSTEMID === '') throw new Error('REACT_APP_EIT_X_VUX_SYSTEMID is not defined');

const EIT_CLIENT_ID: string = process.env.REACT_APP_EIT_CLIENT_ID ? process.env.REACT_APP_EIT_CLIENT_ID : '';
if (EIT_CLIENT_ID === '') throw new Error('REACT_APP_EIT_CLIENT_ID is not defined');

const EIT_CLIENT_SECRET: string = process.env.REACT_APP_EIT_CLIENT_SECRET ? process.env.REACT_APP_EIT_CLIENT_SECRET : '';
if (EIT_CLIENT_SECRET === '') throw new Error('REACT_APP_EIT_CLIENT_SECRET is not defined');

const TIMEOUT: number = process.env.REACT_APP_TIMEOUT ? Number(process.env.REACT_APP_TIMEOUT) : 5000;

const mutex = new Mutex();
export const onlineApi = createApi({
  reducerPath: 'online-api',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: ONLINE_HOST,
      timeout: TIMEOUT,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    }),
    {
      maxRetries: 1,
    }
  ),
  endpoints: () => ({}),
});

export const refreshToken = async () => {
  try {
    const refreshResult = await fetch(`${ONLINE_HOST}/api/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    });

    const refreshResponse = await refreshResult.json();
    if (!refreshResponse) {
      return null;
    }

    const accessToken = (refreshResponse as LoginApiResponse)?.data?.access_token;

    if (accessToken) {
      saveLoggedInUser(accessToken);

      return accessToken;
    }
  } catch (e) {
    return null;
  }
};

export type GetResponse = {
  count: number;
  total: number;
  _links: {
    self: {
      href: string;
    };
    next?: {
      href: string;
    };
    last?: {
      href: string;
    };
  };
};

export type PaginationRequest = {
  page?: number;
  size?: number;
};

const astrumPrivateQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { retryCount?: number },
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  const maxRetries = 1;
  const retryCount = extraOptions?.retryCount ?? 0;

  await mutex.waitForUnlock();
  const result = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getLoggedInUser()?.userToken;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
    timeout: TIMEOUT,
  })(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (retryCount >= maxRetries) {
      return result;
    }

    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const accessToken = await refreshToken();
        release();

        if (accessToken) {
          return await astrumPrivateQuery(args, api, { ...extraOptions, retryCount: retryCount + 1 });
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      return await astrumPrivateQuery(args, api, { ...extraOptions, retryCount: retryCount + 1 });
    }

    publish(CustomEventName.AUTH_ERROR);
  }

  return result;
};

export const privateApi = createApi({
  reducerPath: 'private-api',
  baseQuery: retry(astrumPrivateQuery, {
    maxRetries: 1,
  }),
  tagTypes: [
    'Applications',
    'Articles',
    'Companies',
    'Users',
    'TestResults',
    'SystemMessages',
    'Todos',
    'Exams',
    'Certificates',
    'Occasions',
    'Aku',
    'Translations',
    'OAuth2',
    'Costs',
    'History',
    'Violations',
    'UserEmails',
  ],
  endpoints: () => ({}),
});

export const publicApi = createApi({
  reducerPath: 'public-api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    timeout: TIMEOUT,
  }),
  tagTypes: ['BankId', 'SystemMessages'],
  endpoints: () => ({}),
});

const educateITBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta> = async (
  args,
  api,
  extraOptions
) => {
  const tokenResponse = await fetch(`${EDUCATEIT_BASE_URL}${EIT_AUTH_ENDPOINT}`, {
    method: 'POST',
    headers: {
      'X-VUX-SYSTEMID': EIT_X_VUX_SYSTEMID,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify([
      {
        clientId: EIT_CLIENT_ID,
        clientSecret: EIT_CLIENT_SECRET,
      },
    ]),
  });
  const token = await (await tokenResponse.blob()).text();

  if (token) {
    return fetchBaseQuery({
      baseUrl: EDUCATEIT_BASE_URL,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })(args, api, extraOptions);
  }

  publish(CustomEventName.API_ERROR, { message: 'EIT_UNAVAILABLE' });
  return {
    error: { status: 500, data: 'EIT_UNAVAILABLE' } as FetchBaseQueryError,
  };
};

export const localApi = createApi({
  reducerPath: 'local',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  tagTypes: ['Meta'],
  endpoints: () => ({}),
});

export const educateITBaseApi = createApi({
  reducerPath: 'educateIT-api',
  baseQuery: educateITBaseQuery,
  tagTypes: ['TimeSlots'],
  endpoints: () => ({}),
});

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError & { data: { status: string | number } } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in (error as object) &&
    'data' in (error as object) &&
    typeof (error as any).data === 'object' &&
    (error as any).data !== null &&
    'status' in (error as any).data
  );
}
