import styled from 'styled-components';
import { theme } from 'theme';

export const TableHeader = styled.thead`
  border-bottom: solid 1px ${theme.colors['neutral-color-1']} !important;
  border-top: solid 1px ${theme.colors['neutral-color-1']} !important;
  background-color: ${theme.colors['neutral-color-2']};
  th {
    text-align: left;
    padding: ${theme.space['base-tight']} ${theme.space['base']};
    font-weight: ${theme.fontWeights.medium};
  }
`;
