import type { PayloadAction } from '@reduxjs/toolkit';
import type { State } from './state';
import { initialState } from './state';

export const reducers = {
  'login/start': (state: State) => {
    state.access = {
      status: 'loading',
    };
  },
  'login/fulfilled': (
    state: State,
    action: PayloadAction<{
      profile: State['profile'];
    }>
  ) => {
    state.access = {
      status: 'finished',
    };

    state.profile = {
      ...action.payload.profile,
    };
  },
  'identity/fill': (
    state: State,
    action: PayloadAction<{
      profile: State['profile'];
    }>
  ) => {
    state.profile = {
      ...action.payload.profile,
    };
  },
  'login/failed': (state: State) => {
    state.access = {
      status: 'error',
    };
  },
  impersonate: (state: State, action: PayloadAction<{ userId: State['impersonatedUserId'] }>) => {
    state.impersonatedUserId = action.payload.userId;
  },
  reset: () => {
    return initialState;
  },
};
