import styled from 'styled-components';
import type { BorderProps, BorderWidthProps, ColorProps, FlexProps, FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import { border, borderWidth, color, flex, flexbox, layout, space } from 'styled-system';
import type { ThemeSchema, ThemeTextStyles } from 'theme';

type LoaderProps = { textStyle?: ThemeTextStyles } & BorderProps<ThemeSchema> &
  BorderWidthProps &
  ColorProps<ThemeSchema> &
  FlexProps &
  FlexboxProps &
  LayoutProps<ThemeSchema> &
  SpaceProps<ThemeSchema>;

export const Loader = styled.span<LoaderProps>`
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${border}
  ${borderWidth}
  ${color}
  ${flex}
  ${flexbox}
  ${layout}
  ${space}
`;

Loader.defaultProps = {
  borderColor: 'main',
  borderBottomColor: 'transparent',
  height: '48px',
  width: '48px',
};

export default Loader;
