import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export const isAdminSelector = createSelector(
  (state: RootState) => state.identity.profile.authorities,
  (authorities) => {
    if (authorities !== null && authorities?.some((authority) => ['ROLE_ADMIN', 'ROLE_SWEDSEC_ADMIN'].indexOf(authority) >= 0)) {
      return true;
    } else {
      return false;
    }
  }
);

export const isSwedsecAdminSelector = createSelector(
  (state: RootState) => state.identity.profile.authorities,
  (authorities) => {
    if (authorities !== null) {
      if (authorities?.some((authority) => ['ROLE_SWEDSEC_ADMIN'].indexOf(authority) >= 0)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
);

export const isSysAdminSelector = createSelector(
  (state: RootState) => state.identity.profile.authorities,
  (authorities) => {
    if (authorities !== null) {
      if (authorities?.some((r) => ['ROLE_SYS_ADMIN'].indexOf(r) >= 0)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
);

export const isComplianceSelector = createSelector(
  (state: RootState) => state.identity.profile.authorities,
  (authorities) => {
    if (authorities !== null) {
      if (authorities?.some((r) => ['ROLE_COMPLIANCE'].indexOf(r) >= 0)) {
        return true;
      } else {
        return false;
      }
    }
  }
);

export const isTestLeaderSelector = createSelector(
  (state: RootState) => state.identity.profile.authorities,
  (authorities) => {
    if (authorities !== null && authorities?.some((authority) => ['ROLE_TEST_LEADER'].indexOf(authority) >= 0)) {
      return true;
    } else {
      return false;
    }
  }
);

export const impersonatedUser = createSelector(
  (state: RootState) => state.identity.impersonatedUserId,
  (impersonatedUserId) => impersonatedUserId
);
