import { useGetApplication } from 'api/applications/hooks/useGetApplication';
import { Box, Typography } from 'components/basic-components';
import { CircleCheck, CircleMinus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import { ApplicationHeader } from './ApplicationHeader';
import { DeregistrationApplication } from './DeregistrationApplication';
import { ExemptionApplication } from './ExemptionApplication';
import { LicenseApplication } from './LicenseApplication';

export const StyledUl = styled('ul')`
  list-style-type: disc;
  list-style-position: inside;
  max-width: 100%;

  li {
    padding-left: 24px;
    margin-left: 0.5em;
  }
`;

type ControlItemProps = {
  isTrue: boolean;
  text: string;
};

export const ControlItem = ({ isTrue, text }: ControlItemProps) => (
  <Box flexDirection="row" gap="base-tight" alignItems="center">
    {isTrue ? <CircleCheck color={theme.colors.success} /> : <CircleMinus color={theme.colors.error} />}
    <Typography.Paragraph alignSelf="center">{text}</Typography.Paragraph>
  </Box>
);

export const Application = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { application } = useGetApplication(parseInt(applicationId ?? ''));

  return (
    <>
      <ApplicationHeader />
      {application?.type === 'LICENSE_APPLICATION' && <LicenseApplication />}
      {application?.type === 'EXEMPTION_APPLICATION' && <ExemptionApplication />}
      {application?.type === 'DEREGISTRATION' && <DeregistrationApplication />}
    </>
  );
};
