import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetViolationsQuery } from '../violations';

export const useGetViolations = () => {
  const { activeUserId } = useGetActiveUserId();
  const { data: violations, ...rest } = useGetViolationsQuery({ userId: activeUserId }, { skip: !activeUserId });

  return {
    violations: violations?.entries ?? [],
    canPrintPdf: violations?.showPrintViolationsPDF ?? false,
    ...rest,
  };
};
