import { privateApi } from 'api/base';

export type TodoItem =
  | 'todo_change_password'
  | 'todo_add_email'
  | 'todo_confirm_license_application'
  | 'todo_book_diagnostic_test'
  | 'todo_book_licensing_test'
  | 'todo_do_diagnostic_test'
  | 'todo_prepare_for_licensing_test'
  | 'todo_start_licensing_test_digital'
  | 'todo_start_licensing_test_physical';

export type TodoObject = {
  todoItemKey: TodoItem;
  showFrom: string;
  showUntil: string;
};

type GetTodosRequest = {
  userId: number;
};

export const todosApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getTodos: builder.query<Array<TodoObject>, GetTodosRequest>({
      query: ({ userId }) => {
        return {
          url: `todoitems/user/${userId}`,
        };
      },
      providesTags: ['Todos'],
    }),
  }),
});

export const { useGetTodosQuery } = todosApi;
