import { jwtDecode } from 'jwt-decode';
import { actions, store } from 'store';
import type { UserRoles } from './state';

export type DecodedJWT = {
  aud: Array<string>;
  user_name: string;
  scope: Array<string>;
  iss: string;
  company: number;
  exp: number;
  iat: number;
  authorities: Array<UserRoles>;
  jti: string;
  client_id: string;
};

export const getLoggedInUser = () => {
  const userToken = sessionStorage.getItem('USER_TOKEN');
  const companyId = sessionStorage.getItem('COMPANY_ID');
  const userId = parseInt(sessionStorage.getItem('USER_ID') ?? '');

  if (userToken && companyId && userId) {
    const decodedToken = jwtDecode<DecodedJWT>(userToken);

    return {
      userToken,
      userId: isNaN(userId) ? undefined : userId,
      roles: decodedToken?.authorities,
      companyId: companyId ? Number(companyId) : undefined,
      decodedToken,
    };
  }
  return null;
};

export const saveLoggedInUser = (accessToken: string) => {
  const decoded = jwtDecode<DecodedJWT>(accessToken);
  const jti = decoded.jti.split('-');
  const userId = parseInt(jti[0]);
  const company = decoded.company;

  sessionStorage.setItem('USER_TOKEN', accessToken);
  sessionStorage.setItem('USER_ID', userId.toString());
  sessionStorage.setItem('COMPANY_ID', company.toString());

  store.dispatch(
    actions.identity['identity/fill']({
      profile: { ...decoded, username: decoded.user_name, userId, company },
    })
  );

  return { userId, company, decoded };
};

export const clearSession = () => {
  sessionStorage.setItem('USER_TOKEN', '');
  sessionStorage.setItem('USER_ID', '');
  sessionStorage.setItem('COMPANY_ID', '');
  sessionStorage.setItem('ACTIVE_USER_ID', '');
};
