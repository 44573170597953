import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetUserQuery } from 'api/users/users';

export const useGetUser = (userId?: number) => {
  const { activeUserId, setImpersonatedUserId } = useGetActiveUserId();
  const { data: user, ...rest } = useGetUserQuery({ userId: userId ?? activeUserId }, { skip: !userId && !activeUserId });

  return {
    isMember: user?.defaultCompany?.isMember,
    isPrivateTestTaker: user?.defaultCompany?.companyId === 3,
    user,
    activeUserId: userId ?? activeUserId,
    setImpersonatedUserId,
    ...rest,
  };
};
