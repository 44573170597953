import type * as CSS from 'csstype';

const borderWidths = {
  'extra-thin': '1px',
  thin: '2px',
  normal: '4px',
  thick: '8px',
};

export type ThemeColors = {
  main: CSS.Property.Color;
  'main-enhanced': CSS.Property.Color;
  'main-enhanced-2': CSS.Property.Color;
  info: CSS.Property.Color;
  'complementary-color-1': CSS.Property.Color;
  'complementary-color-2': CSS.Property.Color;
  'accent-color-1': CSS.Property.Color;
  'accent-color-1-enhanced': CSS.Property.Color;
  'neutral-color-1': CSS.Property.Color;
  'neutral-color-2': CSS.Property.Color;
  'neutral-color-enhanced-1': CSS.Property.Color;
  'neutral-color-enhanced-2': CSS.Property.Color;
  success: CSS.Property.Color;
  warning: CSS.Property.Color;
  error: CSS.Property.Color;
  'success-enhanced': CSS.Property.Color;
  'warning-enhanced': CSS.Property.Color;
  'error-enhanced': CSS.Property.Color;
  light: CSS.Property.Color;
  Bolån: CSS.Property.Color;
  Informationsgivare: CSS.Property.Color;
  LedningOchKontroll: CSS.Property.Color;
  Rådgivare: CSS.Property.Color;
  Specialister: CSS.Property.Color;
  'message-success-enhanced': CSS.Property.Color;
  'message-error-enhanced': CSS.Property.Color;
  'message-info-enhanced': CSS.Property.Color;
  'message-info-text': CSS.Property.Color;
  transparent: CSS.Property.Color;
};

export const colorsPalette: ThemeColors = {
  main: '#00263e',
  'main-enhanced': '#335165',
  'main-enhanced-2': '#5a8cbe',
  info: '#62c6c4',
  'complementary-color-1': '#c9def1',
  'complementary-color-2': '#8bb4d2',
  'accent-color-1': '#ea515a',
  'accent-color-1-enhanced': '#bb4148',
  'neutral-color-1': '#b1b4b6',
  'neutral-color-2': '#eaeff4',
  'neutral-color-enhanced-1': '#787d80',
  'neutral-color-enhanced-2': '#d7e0e9',
  success: '#02835a',
  warning: '#ffdc31',
  error: '#e52813',
  'success-enhanced': '#025c5c',
  'warning-enhanced': '#f27322',
  'error-enhanced': '#741c36',
  light: '#ffffff',
  Bolån: '#741c36',
  Informationsgivare: '#a6d1a5',
  LedningOchKontroll: '#efcd6e',
  Rådgivare: '#5a8cbe',
  Specialister: '#f27322',
  'message-success-enhanced': '#0A6B64B',
  'message-error-enhanced': '#AF1E0E',
  'message-info-enhanced': '#AA920E',
  'message-info-text': '#262626',
  transparent: 'transparent',
};

const adminModePalette: ThemeColors = {
  main: '#005C5C',
  'main-enhanced': '#357d7d',
  'main-enhanced-2': '#6db5b5',
  info: '#62c6c4',
  'complementary-color-1': '#d3e9e9',
  'complementary-color-2': '#a0d0d0',
  'accent-color-1': '#ea515a',
  'accent-color-1-enhanced': '#bb4149',
  'neutral-color-1': '#b1b4b6',
  'neutral-color-2': '#eaeff4',
  'neutral-color-enhanced-1': '#787d80',
  'neutral-color-enhanced-2': '#d7e0e9',
  success: '#02835a',
  warning: '#ffdc31',
  error: '#e52813',
  'success-enhanced': '#025c5c',
  'warning-enhanced': '#f27322',
  'error-enhanced': '#741c36',
  light: '#ffffff',
  Bolån: '#741c36',
  Informationsgivare: '#a6d1a5',
  LedningOchKontroll: '#efcd6e',
  Rådgivare: '#5a8cbe',
  Specialister: '#f27322',
  'message-success-enhanced': '#0A6B64B',
  'message-error-enhanced': '#AF1E0E',
  'message-info-enhanced': '#AA920E',
  'message-info-text': '#262626',
  transparent: 'transparent',
};

export const modesPalette = {
  licensee: colorsPalette,
  admin: adminModePalette,
};

const fontSizes = {
  'xx-small': '12px',
  'x-small': '14px',
  small: '16px',
  medium: '18px',
  large: '20px',
  'x-large': '24px',
  'xx-large': '32px',
};

const fontWeights = {
  thin: 100,
  'extra-light': 200,
  light: 300,
  regular: 400,
  medium: 500,
  'semi-bold': 600,
  bold: 700,
  'extra-bold': 800,
  black: 900,
};

const radii = {
  none: '0px',
  xs: '2px',
  s: '4px',
  m: '8px',
  l: '10px',
  xl: '16px',
  xxl: '32px',
};

const space = {
  none: '0',
  'extra-tight': '0.125em',
  tight: '0.25em',
  'base-tight': '0.5em',
  base: '0.625em',
  loose: '1em',
  'extra-loose': '2em',
  sidebar: '5.3em',
  negative: '-20px',
};

type BreakpointsProp = Array<string> & {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
};

export const breakpoints: BreakpointsProp = ['40em', '52em', '64em', '80em'];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const baseTheme = {
  borderWidths: {
    ...borderWidths,
  },
  colors: {
    ...colorsPalette,
  },
  fontSizes: {
    ...fontSizes,
  },
  fontWeights: {
    ...fontWeights,
  },
  radii: {
    ...radii,
  },
  space: {
    ...space,
  },
  breakpoints,
};

export const fontFamilies = {
  'Sans-Serif': 'Noto Sans Display, sans-serif',
};

const textStyles = {
  'body-light': {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeight: fontWeights['light'],
    fontSize: fontSizes['small'],
    textDecoration: 'none',
  },
  body: {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['small'],
    textDecoration: 'none',
  },
  'body-semi-bold': {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeight: fontWeights['semi-bold'],
    fontSize: fontSizes['small'],
    textDecoration: 'none',
  },
  'body-bold': {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeight: fontWeights['bold'],
    fontSize: fontSizes['small'],
  },
  'input-label': {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['small'],
    color: colorsPalette['main'],
  },
  anchor: {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeight: fontWeights['regular'],
    fontSize: fontSizes['small'],
    color: colorsPalette['main'],
    textDecoration: 'underline',
  },
  menuAnchor: {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeight: fontWeights['semi-bold'],
    fontSize: fontSizes['small'],
    color: colorsPalette['neutral-color-2'],
    textDecoration: 'none',
  },
  'place-holder': {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeights: fontWeights['regular'],
    fontSize: fontSizes['small'],
    color: colorsPalette['neutral-color-1'],
  },
  'text-input': {
    fontFamily: fontFamilies['Sans-Serif'],
    fontWeights: fontWeights['regular'],
    fontSize: fontSizes['small'],
    color: colorsPalette['main'],
  },
};

const selects = {
  default: {
    background: 'none',
    padding: baseTheme.space['base'],
  },
  language: {
    color: baseTheme.colors['neutral-color-2'],
    padding: 0,
    paddingRight: baseTheme.space['loose'],
    '&:hover': {
      color: baseTheme.colors['info'],
    },
  },
  year: {},
  aku: {
    color: baseTheme.colors.main,
    background: 'none',
    backgroundColor: baseTheme.colors.light,
    backgroundImage:
      "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxwb2x5Z29uIHBvaW50cz0iMCA1IDggMTMgMTYgNSIgZmlsbD0iIzMzMyIvPgo8L3N2Zz4K')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0px center',
    cursor: 'pointer',
    fontSize: baseTheme.fontSizes.small,
    outline: 'none',
    width: 'fit-content',
    minWidth: '70px',
    paddingRight: '25px',
  },
  akuExpanded: {
    color: baseTheme.colors.main,
    background: 'none',
    backgroundColor: baseTheme.colors['neutral-color-2'],
    backgroundImage:
      "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxwb2x5Z29uIHBvaW50cz0iMCA1IDggMTMgMTYgNSIgZmlsbD0iIzMzMyIvPgo8L3N2Zz4K')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0px center',
    cursor: 'pointer',
    fontSize: baseTheme.fontSizes.small,
    outline: 'none',
    width: 'fit-content',
    minWidth: '70px',
    paddingRight: '25px',
  },
  select: {
    maxWidth: '292px',
    background: 'none',
    cursor: 'pointer',
    padding: `${baseTheme.space['base-tight']} ${baseTheme.space.loose}`,
    border: `1px solid ${baseTheme.colors['neutral-color-1']}`,
    width: '100%',
    backgroundImage: `linear-gradient(45deg, transparent 50%, ${baseTheme.colors['neutral-color-1']} 50%), linear-gradient(135deg, ${baseTheme.colors['neutral-color-1']} 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)`,
    backgroundPosition: 'calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em',
    backgroundSize: '5px 5px, 5px 5px, 1px 1.5em',
    backgroundRepeat: 'no-repeat',
    ':focus': {
      backgroundImage: `linear-gradient(45deg, ${baseTheme.colors.main} 50%, transparent 50%), linear-gradient(135deg, transparent 50%,  ${baseTheme.colors.main} 50%), linear-gradient(to right, #ccc, #ccc)`,
      backgroundPosition: 'calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em',
      backgroundSize: '5px 5px, 5px 5px, 1px 1.5em',
      backgroundRepeat: 'no-repeat',
      borderColor: baseTheme.colors.main,
      outline: '0',
    },
  },
};

export const theme = {
  ...baseTheme,
  textStyles,
  selects,
};

export type ThemeSchema = typeof theme;
export type ThemeColor = keyof typeof colorsPalette;
export type ThemeSpace = keyof typeof theme.space;
export type ThemeTextStyles = keyof typeof theme.textStyles;
export type ThemeSelectVariants = keyof typeof theme.selects;
