import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box } from 'components/basic-components';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import { DiagnosticExams } from './DiagnosticExams';
import { LicensingExams } from './LicensingExams';

export const BookedTests = () => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isIntegrated = useIsIntegrated();
  const { activeUserId: userId } = useGetActiveUserId();

  const handleBack = () => {
    if (isIntegrated) {
      dispatch(actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId } }));
    } else {
      navigate('/');
    }
  };

  return (
    <Box width="100%">
      <PageHeader title={t('booking.myBookedTest.title')} onBack={handleBack} />
      <LicensingExams />
      <DiagnosticExams />
    </Box>
  );
};
