import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';
import { theme } from 'theme';

export const DatePicker = styled(ReactDatePicker)`
  padding: ${theme.space['base-tight']};
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  border-color: ${theme.colors.main};
  ${() => theme.textStyles['text-input']};
  :disabled {
    border-color: rgb(180, 180, 180);
    background-color: rgb(240, 240, 240);
    cursor: not-allowed;
  }
`;
