import { Box, Typography } from 'components/basic-components';
import type { Props } from 'components/basic-components/Box/Box';
import type { ReactNode } from 'react';

type SectionProps = {
  title?: string;
  children?: ReactNode;
  clickableIcon?: ReactNode;
} & Props;

export const Section = ({ title, children, clickableIcon, ...rest }: SectionProps) => {
  return (
    <>
      {title && (
        <Box
          backgroundColor="neutral-color-2"
          paddingX="base-tight"
          paddingY="tight"
          width="100%"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Typography.H4 marginY="tight">{title}</Typography.H4>
          {clickableIcon && (
            <Box maxWidth="24px" maxHeight="24px" alignSelf="center" $clickable>
              {clickableIcon}
            </Box>
          )}
        </Box>
      )}
      <Box borderStyle="solid" borderWidth="extra-thin" borderColor="neutral-color-1" width="100%" marginBottom="loose">
        {children && <Box {...rest}>{children}</Box>}
      </Box>
    </>
  );
};

export default Section;
