import { useGetApplicationQuery } from '../applications';

export const useGetApplication = (applicationId: number) => {
  const { data: application, ...rest } = useGetApplicationQuery({ applicationId }, { skip: !applicationId });

  const findValueFromField = (field: string) => {
    const value = application?.submittedValues?.[field];

    if (typeof value === 'string') {
      if (
        value?.toLowerCase() === 'true' ||
        value?.toLowerCase() === '1' ||
        value?.toLowerCase() === 'yes' ||
        value?.toLowerCase() === 'ja'
      ) {
        return true;
      } else if (
        value?.toLowerCase() === 'false' ||
        value?.toLowerCase() === '0' ||
        value?.toLowerCase() === 'no' ||
        value?.toLowerCase() === 'nej'
      ) {
        return false;
      }
    } else {
      if (value === 1) {
        return true;
      } else if (value === 0) {
        return false;
      }
    }

    return value;
  };

  return {
    application,
    findValueFromField,
    ...rest,
  };
};
