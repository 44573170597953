import styled from 'styled-components';
import { Box } from '../Box/Box';
import { Typography } from '../Typography/Typography';

const TooltipContainer = styled(Box)<{ $isVisible: boolean }>`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors['neutral-color-0']};
  border-radius: ${({ theme }) => theme.radii.s};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.space.tight};
  margin-top: ${({ theme }) => theme.space['extra-tight']};
  z-index: 1000;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  transition:
    opacity 0.2s,
    visibility 0.2s;
  white-space: nowrap;
`;

const TooltipWrapper = styled(Box)`
  position: relative;
  display: inline-flex;
`;

interface TooltipProps {
  content: string;
  children?: React.ReactNode;
  isOpen: boolean;
}

export const Tooltip = ({ content, children, isOpen }: TooltipProps) => {
  return (
    <TooltipWrapper>
      {children}
      <TooltipContainer $isVisible={isOpen}>
        <Typography.Paragraph color="neutral-color-2">{content}</Typography.Paragraph>
      </TooltipContainer>
    </TooltipWrapper>
  );
};
