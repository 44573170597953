/* eslint-disable prettier/prettier */
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { createPath } from 'router/consts';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import { featureFlags } from 'utilities/featureFlags';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

export default function GuardLayout() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [shouldRenderPhoenix, setShouldRenderPhoenix] = useState(false);
  const loggedInUserID = getLoggedInUser()?.userId;
  const loggedInRoles = getLoggedInUser()?.roles;
  const sideBarChanged = useAppSelector((state) => state.general.isSidebarChanged);
  const isIntegrated = useIsIntegrated();
  const userAgent = useAppSelector((state) => state.general.userAgent);
  const navigate = useNavigate();
  const { error } = useGetUser();
  const { activeUserId, loggedInUserId } = useGetActiveUserId();
  const redirectPath = useAppSelector((state) => state.general.redirectPath);
  const path = createPath(redirectPath);
  let redirectTo = '/login';

  useEffect(() => {
    if (error) {
      navigate(`/users/${loggedInUserId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (isIntegrated && loggedInUserID) {
    redirectTo = path;
  }

  useEffect(() => {
    if (isIntegrated && !loggedInUserID) {
      dispatch(
        actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntegrated, loggedInUserID]);

  useEffect(() => {
    const isAdminUser = (loggedInRoles ?? []).some((role) =>
      ['ROLE_ADMIN', 'ROLE_SWEDSEC_ADMIN', 'ROLE_SYS_ADMIN', 'ROLE_TEST_LEADER'].includes(role)
    );

    if (
      !isIntegrated &&
      !featureFlags.usePhoenix &&
      sessionStorage.getItem('USE_PHOENIX') !== 'true' &&
      featureFlags.usePhoenixForNormalUsers &&
      isAdminUser
    ) {
      window.location.href = process.env.REACT_APP_ASTRUM_HOST ?? '';
    } else {
      setShouldRenderPhoenix(true);
    }

    if (!sideBarChanged && userAgent === 'desktop' && !isIntegrated) {
      handlePinMenu().then();
      dispatch(actions.general['set-sidebar-changed']());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAgent]);

  const handlePinMenu = async () => {
    await dispatch(actions.general['pin-sidebar']());
  };

  if (!shouldRenderPhoenix) {
    return null;
  }

  return !loggedInUserID ? <Navigate to={redirectTo} replace={true} state={location.state} /> : <Outlet />;
}
