import { useGetUser } from 'api/users/hooks/useGetUser';
import { useAddPermissionMutation, useGetPermissionsQuery, useRemovePermissionMutation } from 'api/users/users';
import { Box, Typography } from 'components/basic-components';
import { Checkbox } from 'components/form-components';
import { Section } from 'components/misc';
import { Modal } from 'components/misc/Modal/Modal';
import { Form, Formik } from 'formik';
import { SquarePen } from 'lucide-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';

const StyledBox = styled(Box)`
  @media only screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const UserProfileBookingPermissions = () => {
  const [
    addPermission,
    {
      isError: isAddPermissionError,
      isSuccess: isSuccessAddPermission,
      isLoading: isLoadingAddPermission,
      isUninitialized: isUninitializedAddPermission,
    },
  ] = useAddPermissionMutation();
  const [
    removePermission,
    {
      isError: isRemovePermissionError,
      isSuccess: isSuccessRemovePermission,
      isLoading: isLoadingRemovePermission,
      isUninitialized: isUninitializedRemovePermission,
    },
  ] = useRemovePermissionMutation();
  const { t } = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const { activeUserId: userId } = useGetUser();
  const { data: permissions, isUninitialized: isPermissionsUninitialized, isFetching } = useGetPermissionsQuery({ userId });
  const userPermssions = (permissions ?? []).filter((permission) => permission.granted);

  const handleSubmit = async ({ userRights }: { userRights: Array<string> }) => {
    if (!userId) {
      return;
    }

    for (const userRight of userRights) {
      if (!permissions?.find((permission) => permission.art_no === userRight)?.granted) {
        addPermission({ userId, permission: { art_no: userRight, title: '', granted: true } });
      }
    }

    for (const permission of permissions ?? []) {
      if (permission.granted && !userRights.includes(permission.art_no)) {
        removePermission({ userId, permission });
      }
    }
  };

  useEffect(() => {
    if (isAddPermissionError) {
      publish(CustomEventName.API_ERROR, {
        message: 'ADD_PERMISSIONS_ERROR',
      });
    }
  }, [isAddPermissionError]);

  useEffect(() => {
    if (isRemovePermissionError) {
      publish(CustomEventName.API_ERROR, {
        message: 'REMOVE_PERMISSIONS_ERROR',
      });
    }
  }, [isRemovePermissionError]);

  useEffect(() => {
    if (
      (isSuccessAddPermission && isSuccessRemovePermission) ||
      (isSuccessAddPermission && isUninitializedRemovePermission) ||
      (isSuccessRemovePermission && isUninitializedAddPermission)
    ) {
      setIsEditing(false);
    }
  }, [isSuccessAddPermission, isSuccessRemovePermission, isUninitializedRemovePermission, isUninitializedAddPermission]);

  if (isPermissionsUninitialized || isFetching) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        userRights: userPermssions.map((permission) => permission.art_no),
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, errors, values, initialValues, submitForm, resetForm }) => (
        <Form>
          <Section title={t('profile.profile.bookingRights')} clickableIcon={<SquarePen onClick={() => setIsEditing(true)} />}>
            <Box padding="base-tight" gap="10px">
              {userPermssions.length === 0 && <Typography.Paragraph>{t('profile.profile.noPermissions')}</Typography.Paragraph>}
              {userPermssions.map((permission) => (
                <Typography.Paragraph key={`userRole_${permission.art_no}`}>{permission.title}</Typography.Paragraph>
              ))}
            </Box>
          </Section>
          <Modal
            draggable
            isShown={isEditing}
            headerText={t('profile.profile.bookingRights')}
            hide={() => {
              resetForm();
              setIsEditing(false);
            }}
            onCancel={() => {
              resetForm();
              setIsEditing(false);
            }}
            onConfirm={submitForm}
            disableConfirm={
              isLoadingAddPermission ||
              isLoadingRemovePermission ||
              isSubmitting ||
              JSON.stringify(values) === JSON.stringify(initialValues) ||
              Object.values(errors).some((error) => error)
            }
            confirmText={t('profile.profile.save')}
            cancelText={t('profile.profile.cancel')}
          >
            <StyledBox flexDirection="row" flexWrap="wrap" gap="20px">
              <Box flex={1} gap="5px" width="250px">
                <Typography.H3>{t('profile.profile.bookLicensingTest')}</Typography.H3>
                <Checkbox name="userRights" label={t('general.userRights.MORTGAGE')} value="TR-4751" />
                <Checkbox name="userRights" label={t('general.userRights.INFORMATION_PROVIDER')} value="TR-4962" />
                <Checkbox name="userRights" label={t('general.userRights.MANAGEMENT_AND_CONTROL')} value="TR-4101" />
                <Checkbox name="userRights" label={t('general.userRights.ADVISOR')} value="TR-3575" />
                <Checkbox name="userRights" label={t('general.userRights.SPECIALISTS')} value="TR-3830" />
              </Box>
              <Box flex={1} gap="5px" width="250px">
                <Typography.H3>{t('profile.profile.bookDiagnosticTest')}</Typography.H3>
                <Checkbox name="userRights" label={t('general.userRights.MORTGAGE')} value="TR-480x" />
                <Checkbox name="userRights" label={t('general.userRights.INFORMATION_PROVIDER')} value="TR-4963" />
                <Checkbox name="userRights" label={t('general.userRights.MANAGEMENT_AND_CONTROL')} value="TR-416x" />
                <Checkbox name="userRights" label={t('general.userRights.ADVISOR')} value="TR-357x" />
                <Checkbox name="userRights" label={t('general.userRights.SPECIALISTS')} value="TR-398x" />
              </Box>
            </StyledBox>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
