import { LandingPage } from 'components/layout-components/Public/LandingPage';
import ResetPasswordForm from 'components/misc/Login/ResetPasswordForm';
import { useTranslations } from 'translations/hooks/useTranslations';

const ResetPassword = () => {
  const { t } = useTranslations();

  return (
    <LandingPage title={t('onboarding.resetPassword.title')}>
      <ResetPasswordForm />
    </LandingPage>
  );
};
export default ResetPassword;
