import { Box } from 'components/basic-components';
import { TextField } from 'components/form-components';
import { useTranslations } from 'translations/hooks/useTranslations';

export const ChangePasswordForm = () => {
  const { t } = useTranslations();

  return (
    <Box flexDirection="row">
      <Box>
        <TextField
          type="password"
          name="oldPassword"
          placeholder={t('profile.changePassword.oldPassword')}
          label={t('profile.changePassword.oldPassword')}
          autoComplete="on"
        />
        <TextField
          type="password"
          name="newPassword"
          placeholder={t('profile.changePassword.newPassword')}
          label={t('profile.changePassword.newPassword')}
          autoComplete="on"
        />
        <TextField
          type="password"
          name="confirmPassword"
          placeholder={t('profile.changePassword.confirmNewPassword')}
          label={t('profile.changePassword.confirmNewPassword')}
          autoComplete="on"
        />
      </Box>
    </Box>
  );
};
