import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box, Typography } from 'components/basic-components';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

export const Unauthorized = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();
  const isIntegrated = useIsIntegrated();
  const { activeUserId } = useGetActiveUserId();

  const handleGoHome = () => {
    if (isIntegrated) {
      dispatch(
        actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
      );
    } else {
      dispatch(actions.general.GoToView(navigate, { route: '/', options: { replace: true } }));
    }
  };

  return (
    <Box height="100%" width="100%" alignContent="center" justifyContent="center" $wrap alignItems="center">
      <Typography.H3 textAlign="center" color="main-enhanced" marginBottom="negative">
        {t('errorPages.403.title')}
      </Typography.H3>
      <Typography.H1
        fontWeight="bold"
        color="main"
        letterSpacing="-40px"
        textShadow="-6px 0px #fff"
        fontSize="250px"
        marginLeft="negative"
        marginBottom="negative"
        marginTop="negative"
        width="320px"
      >
        <span>4</span>
        <span>0</span>
        <span>3</span>
      </Typography.H1>
      <Typography.H2 textAlign="center" marginTop="negative" color="main-enhanced">
        {t('errorPages.403.unauthorized')}
      </Typography.H2>
      <Typography.H3
        onClick={handleGoHome}
        marginTop="base"
        textAlign="center"
        color="main-enhanced-2"
        display="inline"
        style={{ cursor: 'pointer' }}
      >
        {t('errorPages.403.goHome')}
      </Typography.H3>
    </Box>
  );
};
