import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { isAdminSelector, isSwedsecAdminSelector, isSysAdminSelector } from 'store/selectors/identity';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';

const parseUserId = (userId: string | number | undefined): number | undefined => {
  const parsedId = parseInt((userId ?? '').toString());
  return isNaN(parsedId) ? undefined : parsedId;
};

export const useGetActiveUserId = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = useAppSelector(isAdminSelector);
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const isSysAdmin = useAppSelector(isSysAdminSelector);
  const impersonatedUserId = useAppSelector((state) => state.identity.impersonatedUserId);
  const { userId: paramsUserId } = useParams<{ userId: string }>();
  const loggedInUserId = getLoggedInUser()?.userId ?? 0;
  const parsedParamsUserId = parseUserId(paramsUserId);
  const returnedUserId = parsedParamsUserId ?? impersonatedUserId ?? loggedInUserId;

  const setImpersonatedUserId = useCallback(
    (userId?: number | string) => {
      let parsedUserId = parseUserId(userId);

      if (!isAdmin && !isSwedsecAdmin && !isSysAdmin) {
        parsedUserId = loggedInUserId;
      }

      dispatch(actions.identity['impersonate']({ userId: parsedUserId }));

      if (parsedUserId !== undefined) {
        sessionStorage.setItem('ACTIVE_USER_ID', parsedUserId.toString());
      } else {
        sessionStorage.removeItem('ACTIVE_USER_ID');
      }

      navigate(`/users/${userId ?? loggedInUserId}`);
    },
    [loggedInUserId, isAdmin, isSwedsecAdmin, isSysAdmin, dispatch, navigate]
  );

  useEffect(() => {
    if (impersonatedUserId !== returnedUserId) {
      if (impersonatedUserId && returnedUserId !== loggedInUserId) {
        setImpersonatedUserId(returnedUserId);
      } else if (impersonatedUserId) {
        setImpersonatedUserId();
      }
    }
  }, [loggedInUserId, isAdmin, isSwedsecAdmin, isSysAdmin, dispatch, setImpersonatedUserId, impersonatedUserId, returnedUserId]);

  return {
    activeUserId: !isAdmin && !isSwedsecAdmin && !isSysAdmin ? loggedInUserId : returnedUserId,
    setImpersonatedUserId,
    loggedInUserId,
  };
};
