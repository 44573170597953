import { useGetArticlesQuery } from 'api/exams/exams';
import { useGetUser } from 'api/users/hooks/useGetUser';

export const useGetArticles = () => {
  const { user, activeUserId, isLoading: userIsLoading, isError: userIsError } = useGetUser();
  const {
    data: articles,
    isLoading,
    isError,
    ...rest
  } = useGetArticlesQuery({ companyId: user?.defaultCompany?.companyId ?? 0, userId: activeUserId }, { skip: !user });

  return {
    subjects: articles?.subjects ?? [],
    monitoringTypes: articles?.monitoring_type ?? [],
    eligibleForExamFrom: articles?.eligible_for_exam_from,
    isLoading: isLoading || userIsLoading,
    isError: isError || userIsError,
    ...rest,
  };
};
