import { useGetUser } from 'api/users/hooks/useGetUser';
import type { Languages } from 'translations/i18next';
import { useGetTranslationsQuery } from '../translations';

type GetTranslationsProps = {
  lang: Languages;
  key: string;
};

export const useGetTranslations = ({ lang, key }: GetTranslationsProps) => {
  const { user } = useGetUser();
  const { data, ...rest } = useGetTranslationsQuery({ lang, key }, { skip: !user });

  return {
    translations: data ?? [],
    ...rest,
  };
};
