import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetSpar } from 'api/users/hooks/useGetSpar';
import { useGetUser } from 'api/users/hooks/useGetUser';
import type { UpdateUserRequest } from 'api/users/users';
import { usersApi, useUpdateUserMutation } from 'api/users/users';
import { images } from 'assets';
import { Box, Typography } from 'components/basic-components';
import { Checkbox, TextField } from 'components/form-components';
import { Section } from 'components/misc';
import { Modal } from 'components/misc/Modal/Modal';
import { Form, Formik, useFormikContext } from 'formik';
import { SquarePen } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { isAdminSelector, isSwedsecAdminSelector } from 'store/selectors/identity';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { useDebounce } from 'utilities/useDebounce';
import { updateUserValidationSchema } from 'utilities/validationSchema';
import { StyledFieldWrapper } from '../View';

interface EditProfileFormValues {
  username: string;
  phoneNumber: string;
  mobileNumber: string;
  socialNo: string;
  firstName: string;
  lastName: string;
  clearing: string;
  nationality: string;
  roles: Array<string>;
}

const UserInformationSection = () => {
  const { values, handleChange, setFieldValue } = useFormikContext<EditProfileFormValues>();
  const { activeUserId: userId, user } = useGetUser();
  const { loggedInUserId } = useGetActiveUserId();
  const { t } = useTranslations();
  const [socialNo, setSocialNo] = useState<string>(values?.socialNo);
  const debouncedSocialNo = useDebounce<string>(socialNo ?? '', 500);
  const isAdmin = useAppSelector(isAdminSelector);
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const { spar, isSuccess, isError } = useGetSpar({
    socialNo: debouncedSocialNo,
    skip: (!isAdmin && !isSwedsecAdmin) || debouncedSocialNo?.length <= 10 || debouncedSocialNo?.length > 13 || values.nationality !== 'SE',
  });
  const isUser = userId === getLoggedInUser()?.userId;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (spar && values.nationality === 'SE') {
      if (spar.firstName !== values.firstName || spar.lastName !== values.lastName) {
        setFieldValue('firstName', spar.firstName);
        setFieldValue('lastName', spar.lastName);
        setFieldValue('protectedIdentity', spar.protectedIdentity);

        dispatch(
          usersApi.util.updateQueryData('getUser', { userId }, (cachedUserResponse) => {
            cachedUserResponse = {
              ...cachedUserResponse,
              firstName: spar.firstName ?? cachedUserResponse.firstName,
              lastName: spar.lastName ?? cachedUserResponse.lastName,
              protectedIdentity: spar.protectedIdentity ?? cachedUserResponse.protectedIdentity,
            };

            return cachedUserResponse;
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spar]);

  useEffect(() => {
    if (isError) {
      publish(CustomEventName.API_ERROR, {
        message: 'SPAR_ERROR',
      });
    }
  }, [isError]);

  return (
    <Box flexDirection="row" flexWrap="wrap">
      <StyledFieldWrapper>
        {isSwedsecAdmin ? (
          <Box paddingY="base-tight">
            <Checkbox
              label={t('profile.profile.noSwedishSSN')}
              name="nationality"
              checked={values.nationality !== 'SE'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('nationality', e.target.checked ? 'Annan' : 'SE')}
            />
          </Box>
        ) : (
          isAdmin && (
            <Box paddingY="base-tight">
              <Checkbox disabled label={t('profile.profile.noSwedishSSN')} name="nationality" checked={values.nationality !== 'SE'} />
            </Box>
          )
        )}
        <TextField
          withErrorPlaceholder
          disabled={!isSwedsecAdmin}
          name="socialNo"
          required
          placeholder={t('profile.profile.socialNo')}
          label={t('profile.profile.socialNo')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSocialNo(event.target.value);
            handleChange(event);
          }}
        />
      </StyledFieldWrapper>
      <StyledFieldWrapper>
        <TextField
          withErrorPlaceholder
          width="100%"
          name="username"
          required
          placeholder={t('profile.profile.username')}
          label={t('profile.profile.username')}
          onFocusRender={
            loggedInUserId === userId && (
              <Box
                display="flex"
                flexDirection="column"
                gap="base-tight"
                position="relative"
                top="0"
                left="0"
                backgroundColor="main"
                padding="base-tight"
              >
                <Typography.Paragraph>{t('profile.profile.usernameWarning')}</Typography.Paragraph>
              </Box>
            )
          }
        />
      </StyledFieldWrapper>
      {values.nationality === 'SE' && isSuccess && (user?.firstName !== spar?.firstName || user?.lastName !== spar?.lastName) && (
        <Typography.SmallParagraph display="flex" width="100%">
          {t('profile.profile.fetchedWithSpar')} <Box marginLeft="base" width="74px" height="23px" backgroundImage={images.spar.logo} />
        </Typography.SmallParagraph>
      )}
      <StyledFieldWrapper>
        <TextField
          withErrorPlaceholder
          width="100%"
          disabled={values.nationality === 'SE' || isUser}
          name="firstName"
          required
          placeholder={t('profile.profile.firstName')}
          label={t('profile.profile.firstName')}
        />
      </StyledFieldWrapper>
      <StyledFieldWrapper>
        <TextField
          withErrorPlaceholder
          width="100%"
          disabled={values.nationality === 'SE' || isUser}
          name="lastName"
          required
          placeholder={t('profile.profile.lastName')}
          label={t('profile.profile.lastName')}
        />
      </StyledFieldWrapper>
      <StyledFieldWrapper>
        <TextField
          withErrorPlaceholder
          width="100%"
          type="tel"
          name="phoneNumber"
          placeholder={t('profile.profile.phone')}
          label={t('profile.profile.phone')}
        />
      </StyledFieldWrapper>
      <StyledFieldWrapper>
        <TextField
          withErrorPlaceholder
          width="100%"
          description={t('profile.profile.mobileNumberDescription')}
          type="tel"
          name="mobileNumber"
          placeholder={t('profile.profile.mobile')}
          label={t('profile.profile.mobile')}
        />
      </StyledFieldWrapper>
      {(isAdmin || isSwedsecAdmin) && (
        <>
          <StyledFieldWrapper>
            <TextField
              withErrorPlaceholder
              width="100%"
              name="clearing"
              placeholder={t('profile.profile.clearing')}
              label={t('profile.profile.clearing')}
            />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <TextField
              withErrorPlaceholder
              width="100%"
              name="department"
              placeholder={t('profile.profile.department')}
              label={t('profile.profile.department')}
            />
          </StyledFieldWrapper>
        </>
      )}
    </Box>
  );
};

export const UserProfile = () => {
  const [updateUser, { isSuccess, isError, error, isLoading }] = useUpdateUserMutation();
  const { t } = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const { user, activeUserId: userId } = useGetUser();
  const isAdmin = useAppSelector(isAdminSelector);
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);

  const handleSubmit = async ({
    username,
    phoneNumber,
    mobileNumber,
    socialNo,
    clearing,
    department,
    nationality,
    administrator,
    protectedIdentity,
    firstName,
    lastName,
  }: Omit<UpdateUserRequest, 'userId'>) => {
    if (!user) {
      return;
    }

    const baseUpdate = {
      userId,
      username,
      phoneNumber,
      mobileNumber,
      firstName: (isAdmin || isSwedsecAdmin) && nationality === 'Annan' ? firstName : undefined,
      lastName: (isAdmin || isSwedsecAdmin) && nationality === 'Annan' ? lastName : undefined,
    };

    if (isSwedsecAdmin) {
      updateUser({
        ...baseUpdate,
        socialNo,
        clearing,
        department,
        nationality,
        administrator,
        protectedIdentity,
      });
    } else if (isAdmin) {
      updateUser({
        ...baseUpdate,
        socialNo: user.socialNo,
        clearing,
        department,
        nationality,
        administrator,
        protectedIdentity,
      });
    } else {
      updateUser(baseUpdate);
    }
  };

  useEffect(() => {
    if (isError) {
      if ((error as FetchBaseQueryError).status === 409) {
        publish(CustomEventName.API_ERROR, {
          message: 'USERNAME_ALREADY_EXISTS',
        });
      } else {
        publish(CustomEventName.API_ERROR, {
          message: 'UPDATE_USER_ERROR',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  if (user) {
    return (
      <Formik
        initialValues={{
          username: user.username ?? '',
          phoneNumber: user.phonenr ?? '',
          mobileNumber: user.mobilephonenr ?? '',
          socialNo: user.socialNo ?? '',
          firstName: user.firstName ?? '',
          lastName: user.lastName ?? '',
          clearing: user.defaultCompany?.clearing ?? '',
          nationality: user.nationality !== 'SE' ? 'Annan' : 'SE',
          department: user.defaultCompany?.department ?? '',
        }}
        onSubmit={handleSubmit}
        validationSchema={updateUserValidationSchema({
          required: 'profile.profile.errors.required',
          notAPhoneNumber: 'profile.profile.errors.notAPhoneNumber',
        })}
        enableReinitialize
      >
        {({ errors, values, initialValues, submitForm, resetForm }) => (
          <Form>
            <Section title={t('profile.profile.title')} clickableIcon={<SquarePen onClick={() => setIsEditing(true)} />}>
              <Box padding="base-tight" gap="10px" flexDirection={['column', 'column', 'row']}>
                <Box width={['50%', '50%', '100%']} gap="10px">
                  <Typography.H4 margin="none">
                    {user.firstName} {user.lastName}
                    <Typography.Paragraph>{user.socialNo}</Typography.Paragraph>
                  </Typography.H4>
                  <Typography.Label>
                    {t('profile.profile.userId')}
                    <Typography.Paragraph>{user.id}</Typography.Paragraph>
                  </Typography.Label>
                  <Typography.Label>
                    {t('profile.profile.phone')}
                    <Typography.Paragraph>{user.phonenr || '-'}</Typography.Paragraph>
                  </Typography.Label>
                </Box>
                <Box width={['50%', '50%', '100%']} gap="10px">
                  <Typography.Label>
                    {t('profile.profile.mobile')}
                    <Typography.Paragraph>{user.mobilephonenr || '-'}</Typography.Paragraph>
                  </Typography.Label>
                  <Typography.Label>
                    {t('profile.profile.department')}
                    <Typography.Paragraph>{user.defaultCompany?.department || '-'}</Typography.Paragraph>
                  </Typography.Label>
                  <Typography.Label>
                    {t('profile.profile.clearing')}
                    <Typography.Paragraph>{user.defaultCompany?.clearing || '-'}</Typography.Paragraph>
                  </Typography.Label>
                </Box>
                {user.protectedIdentity && (
                  <Typography.Paragraph color="error-enhanced">{t('profile.profile.protectedIdentityWarning')}</Typography.Paragraph>
                )}
              </Box>
            </Section>
            <Modal
              draggable
              isShown={isEditing}
              headerText={t('profile.profile.title')}
              hide={() => {
                resetForm();
                setIsEditing(false);
              }}
              onCancel={() => {
                resetForm();
                setIsEditing(false);
              }}
              onConfirm={submitForm}
              disableConfirm={
                isLoading || JSON.stringify(values) === JSON.stringify(initialValues) || Object.values(errors).some((error) => error)
              }
              confirmText={t('profile.profile.save')}
              cancelText={t('profile.profile.cancel')}
            >
              <UserInformationSection />
            </Modal>
          </Form>
        )}
      </Formik>
    );
  }

  return null;
};
