export const queryStringGenerator = (params: Record<string, string | number | undefined>) => {
  const queryString = Object.keys(params).reduce((acc, key) => {
    const value = params[key];

    if (value === undefined || value === null || value === '') {
      return acc;
    }

    if (acc) {
      acc += '&';
    }

    acc += `${key}=${value}`;

    return acc;
  }, '');

  return queryString;
};
