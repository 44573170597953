import { images } from 'assets';
import { Box, Typography } from 'components/basic-components';
import type React from 'react';
import { useAppSelector } from 'store';
import styled from 'styled-components';
import { useTranslations } from 'translations/hooks/useTranslations';

type LandingPageProps = {
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  title?: string;
};

const StyledLandingBox = styled(Box)`
  margin-bottom: 200px;
`;

export const LandingPage = ({ children, headerContent, title }: LandingPageProps) => {
  const { t } = useTranslations();
  const userAgent = useAppSelector((state) => state.general.userAgent);

  return (
    <Box
      backgroundSize="cover"
      backgroundImage={images.welcome.background}
      backgroundPosition="center"
      justifyContent="flex-start"
      flex={1}
    >
      <Box paddingY="loose" marginY="extra-loose" $wrap>
        {userAgent === 'desktop' ? (
          <Typography.H1 color="neutral-color-2" textAlign="center">
            {t('onboarding.login.title')}
          </Typography.H1>
        ) : (
          <Typography.H1 color="neutral-color-2" textAlign="center">
            {t('onboarding.login.shortTitle')}
          </Typography.H1>
        )}
        <Typography.H3 color="neutral-color-2" textAlign="center">
          {t('onboarding.login.subtitle')}
        </Typography.H3>
      </Box>
      <StyledLandingBox
        width={['80%', 400]}
        backgroundColor="main"
        backgroundSize="cover"
        alignSelf="center"
        color="neutral-color-2"
        display="flex"
        flexDirection="column"
        justifyContent="left"
      >
        {(headerContent || title) && (
          <Box flexDirection="row" justifyContent="space-between" paddingY="loose" gap="16px" paddingX="extra-loose">
            {headerContent ? (
              <>{headerContent}</>
            ) : (
              <Typography.H4 color="neutral-color-2" marginY="base-tight">
                {title}
              </Typography.H4>
            )}
          </Box>
        )}
        <Box paddingX={['loose', 'extra-loose']} paddingY="loose" backgroundColor="neutral-color-2" flex={1} $wrap justifyContent="end">
          {children}
        </Box>
      </StyledLandingBox>
    </Box>
  );
};
