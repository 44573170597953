import { ContentSkeleton } from 'components/misc';

import { Box } from 'components/basic-components';
// import { theme } from 'theme';

const Suspenser = () => {
  return (
    <Box height="inherit" alignItems="center" justifyContent="center">
      <ContentSkeleton template="swedsecMark" />
    </Box>
  );
};

export default Suspenser;
