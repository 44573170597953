import { onlineApi, privateApi } from 'api/base';
import { actions } from 'store';
import { saveLoggedInUser } from 'store/stores/identity/tokenStorage';

type LoginRequest = {
  username?: string;
  password?: string;
  token?: string;
  integratedLogin?: boolean;
};

export type LoginApiResponse = {
  success: boolean;
  message: string;
  code: number;
  data?: {
    access_token: string;
  };
};

export const authApi = onlineApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<string, LoginRequest>({
      query: ({ username, password }) => ({
        url: 'api/auth/login',
        method: 'POST',
        body: `username=${username}&password=${encodeURIComponent(password ?? '')}`,
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await dispatch(actions.identity['login/start']());
          await queryFulfilled;
        } catch (error) {
          await dispatch(actions.identity['login/failed']());
        }
      },
      transformResponse: (data: LoginApiResponse) => data.data?.access_token ?? '',
    }),
    refresh: builder.mutation<string, void>({
      query: () => ({
        url: 'api/auth/refresh',
        method: 'POST',
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(privateApi.util.resetApiState());
        } catch (error) {
          await dispatch(actions.identity['login/failed']());
        }
      },
      transformResponse: (data: LoginApiResponse) => {
        if (data.data?.access_token) {
          saveLoggedInUser(data.data.access_token);
        }

        return data.data?.access_token ?? '';
      },
    }),
    logout: builder.mutation<string, void>({
      query: () => ({
        url: 'api/auth/logout',
        method: 'POST',
      }),
    }),
    token: builder.mutation<string, void>({
      query: () => ({
        url: 'api/auth/token',
        method: 'POST',
      }),
      transformResponse: (data: LoginApiResponse) => data.data?.access_token ?? '',
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useRefreshMutation, useTokenMutation } = authApi;
