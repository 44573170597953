import type { ColumnDef, Row } from '@tanstack/react-table';
import type { Matcher } from 'api/oauth2/oauth2';
import { useGetMatchersQuery, useGetRolesQuery } from 'api/oauth2/oauth2';
import { Box } from 'components/basic-components';
import { Input } from 'components/basic-components/Input/Input';
import { TableCell } from 'components/basic-components/Table/Body';
import { ExpandButton } from 'components/basic-components/Table/ExpandButton';
import { ExpandedTableRow } from 'components/basic-components/Table/ExpandedRow';
import { Table } from 'components/basic-components/Table/Table';
import { usePagination } from 'components/basic-components/Table/hooks/usePagination';
import { ContentLoader, Select } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';

type MatchersExpandedRowProps = {
  row: Row<Matcher>;
};

const MatchersExpandedRow = ({ row }: MatchersExpandedRowProps) => (
  <>
    {row.original.methods.map((method) => (
      <React.Fragment key={`${row.original.pattern}_method_${method.name}`}>
        {method.roles.map((role, roleIndex) => (
          <ExpandedTableRow noLine={roleIndex !== 0} key={`${row.original.pattern}_method_${method.name}_${role.name}`}>
            <TableCell>{roleIndex === 0 ? method.name : ''}</TableCell>
            <TableCell>{role.name}</TableCell>
          </ExpandedTableRow>
        ))}
      </React.Fragment>
    ))}
  </>
);

export const Matchers = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const [pattern, setPattern] = useState('');
  const [method, setMethod] = useState('');
  const [role, setRole] = useState('');
  const { pageSize, page, onPagination } = usePagination({ pageSize: 200 });
  const { data, isLoading, isError } = useGetMatchersQuery({ page, size: pageSize });
  const { data: rolesData } = useGetRolesQuery({ size: 20 });
  const [filteredData, setFilteredData] = useState<Array<Matcher>>([]);

  const columns = useMemo<ColumnDef<Matcher>[]>(
    () => [
      {
        accessorKey: 'pattern',
        header: () => t('oauth2.matcher.pattern'),
        size: undefined,
      },
      {
        accessorKey: 'expand',
        header: () => '',
        cell: ({ row }) => row.original.methods.length > 0 && <ExpandButton row={row} />,
        size: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (Array.isArray(data?.matchers)) {
      const newFilteredData = data?.matchers
        .filter((matcher) => matcher.pattern.includes(pattern))
        .filter((matcher) => !method || matcher.methods.find((filterMethod) => filterMethod.name === method))
        .filter(
          (matcher) => !role || matcher.methods.find((filterMethod) => filterMethod.roles.find((filterRole) => filterRole.name === role))
        )
        .sort((a, b) => (a.pattern > b.pattern ? 1 : -1));

      setFilteredData(newFilteredData);
    }
  }, [data, pattern, method, role]);

  return (
    <>
      <PageHeader title={t('oauth2.matcher.title')} onBack={() => navigate('/')} />
      <Box gap="base" flexDirection="row" marginBottom="loose">
        <Box flex="2">
          <Input placeholder={t('oauth2.matcher.pattern')} value={pattern} onChange={(value: string) => setPattern(value)} />
        </Box>
        <Box flex="2" alignItems="end" flexDirection="row" gap="base" justifyContent="end">
          <Select
            width="100% !important"
            height="100%"
            variant="select"
            defaultValue={role as string}
            onChange={(e) => setRole(e.currentTarget.value)}
          >
            <option value="">{t('oauth2.matcher.selectRole')}</option>
            {rolesData?.roles?.map((role) => (
              <option key={role.id} value={role.name}>
                {role.name}
              </option>
            ))}
          </Select>
          <Select
            width="100% !important"
            height="100%"
            variant="select"
            defaultValue={method}
            onChange={(e) => setMethod(e.currentTarget.value)}
          >
            <option value="">{t('oauth2.matcher.selectMethod')}</option>
            <option value="DELETE">DELETE</option>
            <option value="GET">GET</option>
            <option value="PATCH">PATCH</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
          </Select>
        </Box>
      </Box>
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={filteredData}
          rowCount={filteredData?.length}
          pageSize={pageSize}
          onPagination={onPagination}
          renderExpandedRow={(row) => <MatchersExpandedRow row={row} />}
          page={page}
          emptyText={t('oauth2.matcher.noResponse')}
          failedFetch={isError}
        />
      </ContentLoader>
    </>
  );
};
