import type { GetResponse, PaginationRequest } from 'api/base';
import { privateApi } from 'api/base';
import { paginationStringGenerator } from 'api/helpers/pagination';

export type Endpoint = {
  url: string;
  method: string;
};

type ApiClient = {
  id: number;
  client_id: string;
  resource_ids: string;
  client_secret: string;
  scope: string;
  authorized_grant_types: string;
  web_server_redirect_uri: string;
  access_token_validity: number;
  refresh_token_validity: number;
  additional_information: string;
  autoapprove: boolean;
  roles: Array<Role>;
};

export type Client = {
  id: number;
  clientId: string;
  resourceIds: string;
  clientSecret: string;
  scope: string;
  authorizedGrantTypes: string;
  webServerRedirectUri: string;
  accessTokenValidity: number;
  refreshTokenValidity: number;
  additionalInformation: string;
  autoapprove: boolean;
  roles: Array<Role>;
};

export type Matcher = {
  id: number;
  pattern: string;
  methods: Array<Method>;
};

export type Method = {
  id: number;
  name: string;
  roles: Array<Role>;
};

export type Role = {
  id: number;
  name: string;
};

type GetMethodsResponse = GetResponse & {
  methods: Array<Method>;
};

type PostMethodRequest = {
  methodId: number;
  name: string;
};

type PutMethodRequest = {
  methodId: number;
  name: string;
};

type DeleteMethodRequest = {
  methodId: number;
};

type GetClientRequest = {
  orgNo: string;
};

type GetMatchersResponse = GetResponse & {
  matchers: Array<Matcher>;
};

type GetRolesResponse = GetResponse & {
  roles: Array<Role>;
};

type GetClientsResponse = GetResponse & {
  clientDetails: Array<Client>;
};

type GetClientsApiResponse = GetResponse & {
  client_details: Array<ApiClient>;
};

type GetEndpointsResponse = GetResponse & {
  endpoints: Array<Endpoint>;
};

export const oauth2Api = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<GetRolesResponse, PaginationRequest>({
      query: ({ page, size }) => `/oauth2/role?${paginationStringGenerator({ page, size })}`,
      providesTags: ['OAuth2'],
    }),
    getMethods: builder.query<GetMethodsResponse, PaginationRequest>({
      query: ({ page, size }) => `/oauth2/method?${paginationStringGenerator({ page, size })}`,
      providesTags: ['OAuth2'],
    }),
    createMethod: builder.query<void, PostMethodRequest>({
      query: ({ name }) => ({
        url: '/oauth2/method',
        method: 'POST',
        body: {
          name,
        },
      }),
      providesTags: ['OAuth2'],
    }),
    updateMethod: builder.query<void, PutMethodRequest>({
      query: ({ methodId, name }) => ({
        url: `/oauth2/method/${methodId}`,
        method: 'PUT',
        body: {
          name,
        },
      }),
      providesTags: ['OAuth2'],
    }),
    deleteMethod: builder.query<void, DeleteMethodRequest>({
      query: ({ methodId }) => ({
        url: `/oauth2/method/${methodId}`,
        method: 'DELETE',
      }),
      providesTags: ['OAuth2'],
    }),
    getClients: builder.query<GetClientsResponse, GetClientRequest>({
      query: ({ orgNo }) => `/oauth2/client?orgNo=${orgNo}`,
      providesTags: ['OAuth2'],
      transformResponse: (response: GetClientsApiResponse) => {
        return {
          _links: response._links,
          count: response.count,
          total: response.total,
          clientDetails: response.client_details.map((clientDetail) => ({
            id: clientDetail.id,
            clientId: clientDetail.client_id,
            resourceIds: clientDetail.resource_ids,
            clientSecret: clientDetail.client_secret,
            scope: clientDetail.scope,
            authorizedGrantTypes: clientDetail.authorized_grant_types,
            webServerRedirectUri: clientDetail.web_server_redirect_uri,
            accessTokenValidity: clientDetail.access_token_validity,
            refreshTokenValidity: clientDetail.refresh_token_validity,
            additionalInformation: clientDetail.additional_information,
            autoapprove: clientDetail.autoapprove,
            roles: clientDetail.roles,
          })),
        };
      },
    }),
    getMatchers: builder.query<GetMatchersResponse, PaginationRequest>({
      query: ({ page, size }) => `/oauth2/matchers?${paginationStringGenerator({ page, size })}`,
      providesTags: ['OAuth2'],
    }),
    getEndpoints: builder.query<GetEndpointsResponse, PaginationRequest>({
      query: ({ page, size }) => `/oauth2/endpoints?${paginationStringGenerator({ page, size })}`,
      providesTags: ['OAuth2'],
    }),
  }),
});

export const { useGetRolesQuery, useGetClientsQuery, useGetEndpointsQuery, useGetMatchersQuery, useGetMethodsQuery } = oauth2Api;
