import { useUpdateTranslationsMutation, type Translation } from 'api/translations/translations';
import { Typography } from 'components/basic-components';
import { TextField } from 'components/form-components';
import { Modal } from 'components/misc/Modal/Modal';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslations } from 'translations/hooks/useTranslations';
import * as Yup from 'yup';

type UpdateTranslationProps = {
  translation: Translation;
  setTranslation: (translation: Translation | undefined) => void;
};

export const UpdateTranslation = ({ translation, setTranslation }: UpdateTranslationProps) => {
  const { t } = useTranslations();
  const [updateTranslation, { isSuccess }] = useUpdateTranslationsMutation();

  const handleSubmit = async (values: Translation) => {
    await updateTranslation(values);
  };

  useEffect(() => {
    if (isSuccess) {
      setTranslation(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Formik
      initialValues={translation}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        content: Yup.string().required('translations.modal.noContent'),
        key: Yup.string().required('translations.modal.noKey'),
        lang: Yup.string().required('translations.modal.noLang'),
      })}
      enableReinitialize
    >
      {({ isSubmitting, submitForm }) => (
        <Modal
          key="start"
          isShown={Boolean(translation)}
          hide={() => setTranslation(undefined)}
          onConfirm={submitForm}
          onCancel={() => setTranslation(undefined)}
          headerText={t('translations.modal.createHeader')}
          confirmText={t('translations.modal.save')}
          cancelText={t('translations.modal.cancel')}
          disableConfirm={isSubmitting}
        >
          <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', minWidth: '300px' }}>
            <Typography.H4 marginTop="none">
              {t('translations.language', { lang: t(`general.languages.${translation?.lang}`) })}
            </Typography.H4>
            <TextField
              required
              type="text"
              name="key"
              placeholder={t('translations.key')}
              label={t('translations.key')}
              autoComplete="on"
            />
            <TextField
              required
              type="text"
              name="content"
              placeholder={t('translations.content')}
              label={t('translations.content')}
              autoComplete="on"
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
