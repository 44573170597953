import type { ColumnDef, Row } from '@tanstack/react-table';
import type { Aku, AkuExam, UpdateAkuRequest } from 'api/aku/aku';
import { useUpdateAKUMutation } from 'api/aku/aku';
import { useGetAKU } from 'api/aku/hooks/useGetAKU';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { Box } from 'components/basic-components';
import { TableCell } from 'components/basic-components/Table/Body';
import { ExpandButton } from 'components/basic-components/Table/ExpandButton';
import { ExpandedTableRow } from 'components/basic-components/Table/ExpandedRow';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader, Select } from 'components/misc';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppSelector } from 'store';
import { isAdminSelector, isSwedsecAdminSelector } from 'store/selectors/identity';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { errorHandler, getErrorMessage } from 'utilities/errorHandling';

type CompletedCellProps = {
  completed: boolean;
  editable?: boolean;
  onClick?: (value: boolean) => void;
  status: Aku['status'];
  isExpanded?: boolean;
};

const CompletedCell = ({ editable, onClick, completed, status, isExpanded }: CompletedCellProps) => {
  const { t } = useTranslations();
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const isNotRequired = status === 'NOT_REQUIRED';
  const completedString = completed ? t('profile.aku.yes') : isNotRequired ? t('profile.aku.notRequired') : t('profile.aku.no');

  return (
    <Box
      alignItems="center"
      flexDirection="row"
      style={{
        cursor: editable ? 'pointer' : 'inherit',
        width: 'fit-content',
      }}
    >
      {editable && onClick ? (
        <Select
          onChange={(e) => onClick(e.currentTarget.value === 'null' ? false : e.currentTarget.value === '1')}
          variant={isExpanded ? 'akuExpanded' : 'aku'}
          value={isNotRequired ? '' : completed ? 1 : 0}
          backgroundColor={isExpanded ? 'neutral-color-2' : 'light'}
        >
          {isNotRequired && (
            <option value="" disabled>
              {t('profile.aku.notRequired')}
            </option>
          )}
          <option value="1">{t('profile.aku.yes')}</option>
          {(!isNotRequired || (isNotRequired && isSwedsecAdmin)) && <option value="0">{t('profile.aku.no')}</option>}
        </Select>
      ) : (
        <>{completedString}</>
      )}
    </Box>
  );
};

type UpdateAkuArguments = { completed?: 'TRUE' | 'FALSE'; exams?: Aku['exams']; year: Aku['year'] };

type AkuExpandedRowProps = {
  editable?: boolean;
  onClick?: (row: UpdateAkuArguments) => void;
  row: Row<Aku>;
};

const AkuExpandedRow = ({ row, onClick, editable }: AkuExpandedRowProps) => {
  const isAdmin = useAppSelector(isAdminSelector);
  const isSysAdmin = useAppSelector(isSwedsecAdminSelector);
  const twoYearsAgo = new Date().getFullYear() - 2;
  const { isMember } = useGetUser();
  const minEditableYear = 2024; // Its only specific ÅKU after this year you are able to edit
  const isEditable =
    isMember &&
    row.original.year >= minEditableYear &&
    editable &&
    onClick &&
    (isSysAdmin || (row.original.year >= twoYearsAgo && isAdmin));

  const onUpdate = (exam: AkuExam, value: boolean) => {
    if (!onClick) {
      return;
    }

    onClick({
      year: row.original.year,
      exams: row.original.exams.map((origianlExam) => ({
        ...origianlExam,
        completed: exam.exam !== origianlExam.exam ? origianlExam.completed : value,
      })),
    });
  };

  return (
    <>
      {row.original.exams.map((exam, index) => (
        <ExpandedTableRow key={`${exam.exam}_${index}`}>
          <TableCell />
          <TableCell>{exam.exam}</TableCell>
          <TableCell>
            <CompletedCell
              editable={isEditable}
              completed={exam.completed}
              onClick={(value) => onUpdate(exam, value)}
              status={row.original.status}
              isExpanded
            />
          </TableCell>
          <TableCell />
        </ExpandedTableRow>
      ))}
    </>
  );
};

type AkuCompletedCellProps = AkuExpandedRowProps;

const AkuCompletedCell = ({ row, editable, onClick }: AkuCompletedCellProps) => {
  const isAdmin = useAppSelector(isAdminSelector);
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const twoYearsAgo = new Date().getFullYear() - 2;
  const maxEditableYear = 2024; // Its only general ÅKU before this year you are able to edit
  const isEditable =
    row.original.year <= maxEditableYear && editable && onClick && (isSwedsecAdmin || (row.original.year >= twoYearsAgo && isAdmin));

  const onUpdate = (value: boolean) => {
    if (!onClick) {
      return;
    }

    onClick({
      completed: value ? 'TRUE' : 'FALSE',
      year: row.original.year,
    });
  };

  return <CompletedCell editable={isEditable} completed={row.original.completed} onClick={onUpdate} status={row.original.status} />;
};

type AkuTableProps = {
  editable?: boolean;
};

export const AkuTable = ({ editable }: AkuTableProps) => {
  const [updateAku, { error: updateError }] = useUpdateAKUMutation();
  const { aku, isLoading, error, isError } = useGetAKU();
  const { activeUserId } = useGetActiveUserId();
  const { isMember } = useGetUser();
  const { t } = useTranslations();
  const data = aku ?? [];
  const canEdit = isMember && editable;

  const onUpdateRow = useCallback(
    (aku: UpdateAkuArguments) => {
      const updatedAku: UpdateAkuRequest = {
        year: aku.year,
        userId: activeUserId,
      };

      if (aku.completed !== undefined) {
        updatedAku.generalAkuStatus = aku.completed;
      } else {
        updatedAku.exams = aku.exams;
      }

      updateAku(updatedAku);
    },
    [activeUserId, updateAku]
  );

  const columns = useMemo<ColumnDef<Aku>[]>(
    () => [
      {
        accessorKey: 'year',
        header: () => t('profile.aku.year'),
        size: 20,
      },
      {
        accessorKey: 'exams',
        header: () => null,
        cell: () => null,
        size: undefined,
      },
      {
        accessorKey: 'completed',
        header: () => t('profile.aku.completed'),
        cell: ({ row }) => (
          <AkuCompletedCell
            row={row}
            editable={!row.getIsExpanded() && canEdit && (!row.original.completed || !row.original.exams.some((exam) => exam.completed))}
            onClick={onUpdateRow}
          />
        ),
        size: 20,
      },
      {
        accessorKey: 'expand',
        header: () => '',
        cell: ({ row }) => row.original.exams.length > 0 && <ExpandButton row={row} />,
        size: 20,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canEdit, onUpdateRow]
  );

  // TODO: This needs a better implementation, its not in the API layer because different views using the same request handles errors in different ways.
  useEffect(() => {
    if (updateError) {
      if ('status' in updateError) {
        let errorMessage = '';
        switch (updateError.status) {
          case 400:
            errorMessage = getErrorMessage(updateError);
            if (errorMessage === 'USER_NOT_FOUND_OR_DOES_NOT_HAVE_ACTTIVE_OR_RESTING_LICENCE') {
              publish(CustomEventName.API_ERROR, { message: 'USER_NOT_FOUND_OR_DOES_NOT_HAVE_ACTTIVE_OR_RESTING_LICENCE' });
            } else if (errorMessage === 'USER_NOT_CONNECTED_TO_YOUR_COMPANY') {
              publish(CustomEventName.API_ERROR, { message: 'USER_NOT_CONNECTED_TO_YOUR_COMPANY' });
            } else if (errorMessage === 'REPORTING_AKU_OLDER_THAN_2_YEARS') {
              publish(CustomEventName.API_ERROR, { message: 'REPORTING_AKU_OLDER_THAN_2_YEARS' });
            } else if (errorMessage === 'REPORTING_AKU_OLDER_THAN_2024') {
              publish(CustomEventName.API_ERROR, { message: 'REPORTING_AKU_OLDER_THAN_2024' });
            } else if (errorMessage === 'REPORTING_AKU_IN_THE_FUTURE') {
              publish(CustomEventName.API_ERROR, { message: 'REPORTING_AKU_IN_THE_FUTURE' });
            } else if (errorMessage === 'AKU_NOT_FOUND') {
              publish(CustomEventName.API_ERROR, { message: 'AKU_NOT_FOUND' });
            } else if (errorMessage === 'NO_PASSED_TESTS') {
              publish(CustomEventName.API_ERROR, { message: 'NO_PASSED_TESTS' });
            } else if (errorMessage === 'REPORTING_YEAR_SHOULD_OLDER_THAN_OR_EQUAL_2024') {
              publish(CustomEventName.API_ERROR, { message: 'REPORTING_YEAR_SHOULD_OLDER_THAN_OR_EQUAL_2024' });
            } else if (errorMessage === 'USER_NOT_CONNECTED_TO_MEMBER_COMPANY') {
              publish(CustomEventName.API_ERROR, { message: 'USER_NOT_CONNECTED_TO_MEMBER_COMPANY' });
            } else {
              publish(CustomEventName.API_ERROR, { message: 'invalid_format' });
            }
            break;
          default:
            errorHandler(updateError);
            break;
        }
      }
      errorHandler(updateError);
    }
  }, [updateError]);

  // TODO: This needs a better implementation, its not in the API layer because different views using the same request handles errors in different ways.
  useEffect(() => {
    if (error) {
      if ('status' in error) {
        let errorMessage = '';
        switch (error.status) {
          case 400:
            errorMessage = getErrorMessage(error);
            if (errorMessage === 'USER_NOT_FOUND') {
              publish(CustomEventName.API_ERROR, { message: 'USER_NOT_FOUND' });
            } else if (errorMessage === 'USER_NOT_CONNECTED_TO_YOUR_COMPANY') {
              publish(CustomEventName.API_ERROR, { message: 'USER_NOT_CONNECTED_TO_YOUR_COMPANY' });
            } else {
              publish(CustomEventName.API_ERROR, { message: 'invalid_format' });
            }
            break;
          default:
            errorHandler(error);
            break;
        }
      }
    }
  }, [error]);

  return (
    <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
      <Table
        columns={columns}
        data={data}
        renderExpandedRow={(row) => <AkuExpandedRow row={row} onClick={onUpdateRow} editable={canEdit} />}
        emptyText={t('profile.aku.noAkus')}
        failedFetch={isError}
      />
    </ContentLoader>
  );
};
