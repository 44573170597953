import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { GetResponse } from 'api/base';
import { privateApi } from 'api/base';
import type { BookingDetails, DiagnosticExam, LicensingExam } from 'store/stores/reservation/state';
import { CustomEventName, publish } from 'utilities';
import { getErrorMessage } from 'utilities/errorHandling';

export type TestResult = {
  title: string;
  type: string;
  completed: string;
  result: number;
  passed: boolean;
  approved?: boolean; // DEPRECATED, still exists on some APIs
  valid: boolean;
  subject: string;
  pdf: {
    sv: {
      href: string;
    };
    en: {
      href: string;
    };
  };
  modules: Array<{
    description: string;
    result: number;
    passed: boolean;
  }>;
};

type ExamSpecificationContains = {
  art_no: string;
  description: string;
  id: number;
};

export type ExamSpecification = {
  art_no: string;
  price: {
    id: number;
    art_no: string;
    amount_excl_vat: number;
    sum_discounts: number;
    currency: string;
    vat: number;
    periodicity: string;
    applicable_from: Date;
    applicable_to: Date;
    discounts: [];
    vat_percent: number;
  };
  contains?: Array<ExamSpecificationContains>;
  subject: string;
  type: string;
  duration_minutes: number;
};

export type Subject = {
  title: string;
  exam_specifications: Array<ExamSpecification>;
};

/**
 * PE-001 - Digital
 * PE-002 - Physical
 */
export type MonitoringTypeArtNo = 'PE-001' | 'PE-002';

export type MonitoringType = {
  type: string;
  art_no: MonitoringTypeArtNo;
  price: {
    id: number;
    art_no: string;
    amount_excl_vat: number;
    sum_discounts: number;
    currency: string;
    vat: number;
    periodicity: string;
    applicable_from: Date;
    applicable_to: Date;
    discounts: [];
    vat_percent: number;
  };
  contains: Array<{
    art_no: string;
    description: string;
  }>;
};

export type Occasion = {
  id: number;
  startTimeUtc: string;
  spaces: number;
  booked: number;
  availableSeats: number;
  timeLength: number;
  location: string;
  locationId: number;
  address?: string;
};

export type Exam = {
  id: number;
  artNo: string;
  subject: string;
  title: string;
  start: string;
  duration: number;
  location: string;
  licenseTest: boolean;
  status: string;
};

export type Certificate = {
  certificateNumber: string;
  subject: string;
};

type GetTestResultResponse = GetResponse & {
  results: Array<TestResult>;
  detailedResultLink: string;
};

type GetTestResultApiResponse = GetResponse & {
  collected_results: Array<TestResult>;
  _links: {
    detailed_result_link: {
      href: string;
    };
  };
};

type GetTestResultRequest = {
  userId: number;
  page?: number;
  size?: number;
};

type GetArticlesResponse = {
  company_id: number;
  subjects: Array<Subject>;
  monitoring_type: Array<MonitoringType>;
  eligible_for_exam_from: Date;
};

type GetArticlesRequest = {
  userId: number;
  companyId: number;
};

type GetTestOccasionsRequest = {
  userId: number;
};

type ApiOccasion = {
  id: number;
  start_time_utc: string;
  spaces: number;
  booked: number;
  available_seats: number;
  time_length: number;
  location: string;
  location_id: number;
  address: string;
};

type GetTestOccasionsApiResponse = {
  occasions: Array<ApiOccasion>;
};

type ReserveExamRequest = {
  companyId: number;
  userId: number;
  diagnosticExam?: DiagnosticExam;
  licensingExam?: LicensingExam;
  bookingDetails: BookingDetails;
};

type CancelExamRequest = {
  examId: number;
  userId: number;
  companyId: number;
};

type GetExamsRequest = {
  userId: number;
  companyId: number;
};

type StartExamRequest = {
  examId: number;
};

type ApiTestBooking = {
  id: number;
  art_no: string;
  subject: string;
  title: string;
  start: string;
  duration: number;
  location: string;
  license_test: boolean;
  status: string;
};

type GetExamsApiResponse = {
  Exams: Array<ApiTestBooking>;
  company_id: number;
  eligible_for_exam_from: string;
  user_id: number;
};

type GetCertificatesRequest = {
  userId: number;
};

type ApiCertificate = {
  certificate_number: string;
  subject: string;
};

type GetCertificatesApiResponse = {
  certificates: Array<ApiCertificate>;
};

export const examsApi = privateApi.injectEndpoints({
  endpoints: (build) => ({
    getTestResults: build.query<GetTestResultResponse, GetTestResultRequest>({
      query: ({ userId, page, size }) => {
        const formdata = new URLSearchParams({});

        if (page === 0 || page) {
          formdata.set('page', page.toString());
        }

        if (size) {
          formdata.set('size', size.toString());
        }

        return {
          url: `exam/users/${userId}/test_results${formdata.toString()}`,
        };
      },
      transformResponse: (data: GetTestResultApiResponse) => {
        return {
          ...data,
          results: data.collected_results.map((result) => ({
            ...result,
            passed: result.passed ?? result.approved, // TODO: Remove this when SWS-396 is merge
          })),
          detailedResultLink: data._links?.detailed_result_link?.href,
        };
      },
      providesTags: ['TestResults'],
    }),
    getArticles: build.query<GetArticlesResponse, GetArticlesRequest>({
      query: ({ userId, companyId }) => {
        return {
          url: `exam/users/${userId}/${companyId}/exam_articles`,
        };
      },
      providesTags: ['Articles'],
    }),
    getTestOccasions: build.query<Array<Occasion>, GetTestOccasionsRequest>({
      query: ({ userId }) => {
        return {
          url: `exam/users/${userId}/testoccasions`,
        };
      },
      transformResponse: (data: GetTestOccasionsApiResponse) => {
        return data.occasions.map((occasion) => ({
          id: occasion.id,
          startTimeUtc: occasion.start_time_utc,
          spaces: occasion.spaces,
          booked: occasion.booked,
          availableSeats: occasion.available_seats,
          timeLength: occasion.time_length,
          location: occasion.location,
          locationId: occasion.location_id,
          address: occasion.address,
        }));
      },
      providesTags: ['Occasions'],
    }),
    getExams: build.query<Array<Exam>, GetExamsRequest>({
      query: ({ userId, companyId }) => {
        return {
          url: `exam/users/${userId}/${companyId}/test_bookings`,
        };
      },
      transformResponse: (data: GetExamsApiResponse) => {
        return data.Exams.map((exam) => ({
          id: exam.id,
          artNo: exam.art_no,
          subject: exam.subject,
          title: exam.title,
          start: exam.start,
          duration: exam.duration,
          location: exam.location,
          licenseTest: exam.license_test,
          status: exam.status,
        }));
      },
      providesTags: ['Exams'],
    }),
    getCertificates: build.query<Array<Certificate>, GetCertificatesRequest>({
      query: ({ userId }) => {
        return {
          url: `exam/users/${userId}/certificates`,
        };
      },
      transformResponse: (data: GetCertificatesApiResponse) => {
        return data.certificates.map((certificate) => ({
          certificateNumber: certificate.certificate_number,
          subject: certificate.subject,
        }));
      },
      providesTags: ['Certificates'],
    }),
    reserveExam: build.mutation<void, ReserveExamRequest>({
      query: ({ companyId, userId, diagnosticExam, licensingExam, bookingDetails }) => {
        let diagnostic;
        let licensing;

        if (diagnosticExam) {
          diagnostic = {
            art_no: diagnosticExam.examSpecification?.art_no,
          };
        }

        if (licensingExam) {
          if (
            bookingDetails.examMonitoringType?.art_no === 'PE-001' &&
            bookingDetails.selectedOccasion &&
            'jointZoomMeeting' in bookingDetails.selectedOccasion
          ) {
            licensing = {
              art_no: licensingExam.examSpecification?.art_no,
              proctor: {
                art_no: bookingDetails.examMonitoringType.art_no,
                session: {
                  location_id: bookingDetails.selectedOccasion.locationId,
                  start_time_utc: bookingDetails.selectedOccasion.startTimeUtc,
                  time_length: licensingExam.examSpecification?.duration_minutes,
                  joint_zoom_meeting: bookingDetails.selectedOccasion.jointZoomMeeting,
                },
              },
            };
          } else if (
            bookingDetails.examMonitoringType?.art_no === 'PE-002' &&
            bookingDetails.selectedOccasion &&
            'id' in bookingDetails.selectedOccasion
          ) {
            licensing = {
              art_no: licensingExam.examSpecification?.art_no,
              proctor: {
                art_no: bookingDetails.examMonitoringType.art_no,
                session: {
                  occasion_id: bookingDetails.selectedOccasion.id,
                  time_length: licensingExam.examSpecification?.duration_minutes,
                  joint_zoom_meeting: false,
                },
              },
            };
          }
        }

        return {
          url: 'exam/reservations',
          method: 'POST',
          body: {
            company_id: companyId,
            user_id: userId,
            exams: [diagnostic, licensing].filter(Boolean),
          },
        };
      },
      invalidatesTags: ['Exams'],
    }),
    cancelExam: build.mutation<void, CancelExamRequest>({
      query: ({ examId }) => {
        return {
          url: `exam/proctoredexams/${examId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted({ examId, userId, companyId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          examsApi.util.updateQueryData('getExams', { companyId, userId }, (testBookings) => {
            testBookings = testBookings.filter((booking) => booking.id !== examId);

            return testBookings;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    startExam: build.mutation<string, StartExamRequest>({
      query: ({ examId }) => {
        return {
          url: `exam/proctoredexams/${examId}/service`,
          method: 'GET',
        };
      },
      transformResponse: (_: void, { response }: any) => {
        return response.headers.get('location');
      },
    }),
  }),
});

export const {
  useGetTestResultsQuery,
  useGetArticlesQuery,
  useGetCertificatesQuery,
  useGetTestOccasionsQuery,
  useGetExamsQuery,
  useReserveExamMutation,
  useCancelExamMutation,
  useStartExamMutation,
} = examsApi;

const possibleExamErrors: {
  [key: string]: string;
} = {
  TEST_SESSION_CANCELLED: 'TEST_SESSION_CANCELLED',
  ILLEGAL_STATE_NOT_BOOKED: 'TEST_SESSION_ILLEGAL_STATE_NOT_BOOKED',
  TEST_SESSION_EXPIRED: 'TEST_SESSION_EXPIRED',
  TEST_SESSION_STARTED: 'TEST_SESSION_STARTED',
  TEST_SESSION_NOT_STARTED: 'TEST_SESSION_NOT_STARTED',
  NO_PROCTOR_REGISTERED: 'TEST_SESSION_NO_PROCTOR_REGISTERED',
  PROCTOR_ALREADY_REGISTERED: 'TEST_SESSION_PROCTOR_ALREADY_REGISTERED',
  TEST_ALREADY_COMPLETED: 'TEST_ALREADY_COMPLETED',
  TEST_SESSION_COMPLETED: 'TEST_SESSION_COMPLETED',
  CONCURRENT_RESERVATION: 'TEST_SESSION_CONCURRENT_RESERVATION',
  REMOTE_PROCTOR_CONFLICT: 'REMOTE_PROCTOR_CONFLICT',
  TEST_SESSION_RESULT_DOES_NOT_EXIST: 'TEST_SESSION_RESULT_DOES_NOT_EXIST',
  CANCELLATION_FAILURE_AT_REMOTE_PROCTOR: 'TEST_SESSION_CANCELLATION_FAILURE_AT_REMOTE_PROCTOR',
  SESSION_NOT_ACTIVATED: 'SESSION_NOT_ACTIVATED',
};

export const examErrorHandler = (error: FetchBaseQueryError | SerializedError) => {
  const errorMessage = getErrorMessage(error);

  if (possibleExamErrors[errorMessage] !== undefined) {
    publish(CustomEventName.API_ERROR, { message: possibleExamErrors[errorMessage] });
  } else {
    publish(CustomEventName.API_ERROR, { message: '409' });
  }
};
