import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { sentryConfig } from './sentry.config';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init(sentryConfig);
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW_MOCK === 'true') {
  require('./mocks/browser');
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </BrowserRouter>
);

process.env.REACT_APP_USE_REPORT_WEB_VITALS && reportWebVitals(console.log);
