import { Box, Typography } from 'components/basic-components';
import { useAppSelector } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';

export const DiagnosticTest = () => {
  const { t } = useTranslations();
  const subject = useAppSelector((state) => state.reservation.bookingDetails.selectedSubject);
  const diagnosticTest = useAppSelector((state) => state.reservation.diagnosticExam);

  return (
    diagnosticTest && (
      <Box alignItems="flex-start" justifyContent="flex-start">
        <Typography.H2 alignSelf="flex-start" color="main">
          {t('booking.booking.diagnostic-test-title', { topic: subject?.title ?? '' })}
        </Typography.H2>
        <Box variant="section" alignItems="flex-end" alignContent="flex-start" $wrap>
          <>
            {diagnosticTest.examSpecification?.contains?.map((contain) => (
              <Typography.Paragraph key={contain.art_no} marginY="base-tight">
                {contain.description}
              </Typography.Paragraph>
            ))}
          </>
          <Box backgroundColor="light" padding="base-tight" $wrap>
            <Typography.Paragraph>{t('booking.booking.diagnosticTestInfo')}</Typography.Paragraph>
          </Box>
        </Box>
      </Box>
    )
  );
};
