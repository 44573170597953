import { Box, Typography } from 'components/basic-components';
import type { StyledCheckboxProps } from 'components/form-components/Checkbox/Checkbox';
import {
  CheckboxContainer,
  CheckboxIcon,
  hiddenCheckBoxStyling,
  type CheckboxFieldProps,
} from 'components/form-components/Checkbox/Checkbox';
import styled from 'styled-components';

const HiddenCheckbox = styled<CheckboxFieldProps>('input')
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
  })
  .attrs({ type: 'checkbox' })`${hiddenCheckBoxStyling}`;

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  width: 28px;
  height: 28px;
  border-radius: 2px;
  transition: all 150ms;
  border: 1px solid ${(props) => props.theme.colors['main']};
  background-color: ${(props) => props.theme.colors.light};
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${(props) => props.theme.colors['complementary-color-1']};
  }

  ${HiddenCheckbox}:checked + & {
    background-color: ${(props) => props.theme.colors.main};
    border-color: ${(props) => props.theme.colors.main};
  }

  ${HiddenCheckbox}:checked + & ${CheckboxIcon} {
    visibility: visible;
  }

  ${HiddenCheckbox}:disabled + & {
    border-color: ${(props) => props.theme.colors['neutral-color-1']};
    background-color: ${(props) => props.theme.colors['main-enhanced']};
  }
`;

export const Checkbox = ({ label, secondLine, ...rest }: CheckboxFieldProps) => (
  <>
    <Box flexDirection="row" alignItems="center" alignContent="center" justifyContent="start">
      <Typography.Label alignSelf="center" display="contents">
        <CheckboxContainer>
          <HiddenCheckbox {...rest} />
          <StyledCheckbox>
            <CheckboxIcon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </CheckboxIcon>
          </StyledCheckbox>
        </CheckboxContainer>
        {label}
        {secondLine && (
          <>
            <br></br>
            {secondLine}
          </>
        )}
      </Typography.Label>
    </Box>
  </>
);
