import type { ColumnDef, Row } from '@tanstack/react-table';
import { BASE_URL, refreshToken } from 'api/base';
import type { TestResult } from 'api/exams/exams';
import { useGetTestResults } from 'api/exams/hooks/useGetTestResults';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box, Typography } from 'components/basic-components';
import { TableCell } from 'components/basic-components/Table/Body';
import { ExpandButton } from 'components/basic-components/Table/ExpandButton';
import { ExpandedTableRow } from 'components/basic-components/Table/ExpandedRow';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader } from 'components/misc';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { isAdminSelector, isSwedsecAdminSelector } from 'store/selectors/identity';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { errorHandler } from 'utilities/errorHandling';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

const TestResultExpandedRow = (row: Row<TestResult>) => (
  <>
    {row.original.modules
      .toSorted((a, b) => (a.description > b.description ? 1 : -1))
      .map((module) => (
        <ExpandedTableRow key={`${module.passed}_${module.result}`}>
          <TableCell>{module.passed}</TableCell>
          <TableCell style={{ whiteSpace: 'break-spaces' }}>{module.description}</TableCell>
          <TableCell>{module.result}%</TableCell>
          <TableCell colSpan={4} />
        </ExpandedTableRow>
      ))}
  </>
);

const downloadCertificate = async (certificateNumber: number, lang: string): Promise<void> => {
  const token = getLoggedInUser()?.userToken;
  const response = await fetch(`${BASE_URL}/exam/certificates/${certificateNumber}/${lang}`, {
    headers: {
      Accept: 'application/pdf',
      authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      const accessToken = await refreshToken();

      if (accessToken) {
        return await downloadCertificate(certificateNumber, lang);
      } else {
        publish(CustomEventName.AUTH_ERROR);
      }
    }

    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const blob = await response.blob();

  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.href = URL.createObjectURL(blob);
  link.download = `${certificateNumber}_${lang}.pdf`;
  link.click();

  URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
};

export const TestResults = () => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isIntegrated = useIsIntegrated();
  const { activeUserId: userId, loggedInUserId } = useGetActiveUserId();
  const { testResults, isLoading, error, detailedResultLink, isError } = useGetTestResults();
  const isAdmin = useAppSelector(isAdminSelector);
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const amIAnAdminForAnotherUser = isAdmin && !isSwedsecAdmin && loggedInUserId !== userId;
  const isLookingAtSelf = loggedInUserId === userId;

  const columns = useMemo<ColumnDef<TestResult>[]>(
    () => [
      {
        accessorKey: 'completed',
        header: () => t('booking.testResults.completed'),
        cell: ({ getValue }) => <>{getValue<string>()?.split('T')[0]}</>,
        size: 100,
      },
      {
        accessorKey: 'title',
        header: () => t('booking.testResults.name'),
        size: 200,
      },
      {
        id: 'result',
        accessorKey: 'result',
        header: () => t('booking.testResults.result'),
        cell: ({ getValue }) => <>{getValue<number>()}%</>,
        size: 75,
      },
      {
        accessorKey: 'passed',
        header: () => t('booking.testResults.passed'),
        cell: ({ getValue, row }) =>
          row.original.type !== 'Diagnostest' && <>{getValue() ? t('booking.testResults.yes') : t('booking.testResults.no')}</>,
        size: 75,
      },
      {
        accessorKey: 'valid',
        header: () => t('booking.testResults.valid'),
        cell: ({ getValue, row }) =>
          row.original.type !== 'Diagnostest' && <>{getValue() ? t('booking.testResults.yes') : t('booking.testResults.no')}</>,
        size: 75,
      },
      {
        id: 'pdf',
        accessorKey: 'pdf',
        header: () => '',
        cell: ({ getValue }) => {
          const certificateHrefSv = getValue<TestResult['pdf']>()?.sv?.href;
          const certificateHrefEn = getValue<TestResult['pdf']>()?.en?.href;
          const regex = /\/exam\/certificates\/(\d+)/;
          const match = (certificateHrefSv ?? certificateHrefEn ?? '').match(regex);
          let certificateNumber = 0;

          if (match) {
            certificateNumber = parseInt(match[1], 10);

            if (isNaN(certificateNumber) || !certificateNumber) {
              return null;
            }
          } else {
            return null;
          }

          return (
            <Box gap="10px" flexDirection="row">
              {certificateHrefSv && (
                <Typography.Anchor
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    downloadCertificate(certificateNumber, 'sv');
                  }}
                >
                  Intyg
                </Typography.Anchor>
              )}
              {certificateHrefEn && (
                <Typography.Anchor
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    downloadCertificate(certificateNumber, 'en');
                  }}
                >
                  Certificate
                </Typography.Anchor>
              )}
            </Box>
          );
        },
        size: 140,
      },
      {
        accessorKey: 'expand',
        header: () => '',
        cell: ({ row }) => Array.isArray(row.original.modules) && row.original.modules.length > 0 && <ExpandButton row={row} />,
        size: 50,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const redirectToCreateTestResults = () => {
    if (isIntegrated) {
      dispatch(actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'CREATE_TEST_RESULTS', userId } }));
    } else {
      window.location.href = `${process.env.REACT_APP_ASTRUM_HOST}/testresult/create/${userId}`;
    }
  };

  const handleBack = () => {
    if (isIntegrated === true) {
      dispatch(actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId } }));
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <Box width="100%">
      <PageHeader title={t('booking.testResults.title')} onBack={handleBack} />
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns.filter(Boolean)}
          data={testResults}
          renderExpandedRow={TestResultExpandedRow}
          emptyText={t('booking.testResults.noResponse')}
          columnVisibility={{
            pdf: isLookingAtSelf || !amIAnAdminForAnotherUser,
            result: isLookingAtSelf || !amIAnAdminForAnotherUser,
          }}
          failedFetch={isError}
        />
      </ContentLoader>
      {detailedResultLink && (
        <Box marginBottom="base" marginTop="base">
          <Typography.Anchor href={detailedResultLink} target="_blank">
            {t('booking.testResults.detailedResultLink')}
          </Typography.Anchor>
        </Box>
      )}
      {isSwedsecAdmin && (
        <ActionButtonRow primaryOnClick={redirectToCreateTestResults} primaryText={t('booking.testResults.createTestResult')} />
      )}
    </Box>
  );
};
