import type { ColumnDef } from '@tanstack/react-table';
import type { History } from 'api/history/history';
import { useGetHistory } from 'api/history/hooks/useGetHistory';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

export const View = () => {
  const { t } = useTranslations();
  const { history, isLoading, isError } = useGetHistory();
  const { activeUserId } = useGetActiveUserId();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isIntegrated = useIsIntegrated();

  const handleBack = () => {
    if (isIntegrated) {
      dispatch(
        actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
      );
    } else {
      navigate('/');
    }
  };

  const columns = useMemo<ColumnDef<History>[]>(
    () => [
      {
        accessorKey: 'createdAt',
        header: () => t('profile.history.createdAt'),
        size: 50,
        sortingFn: (a, b) => (a.original.createdAt > b.original.createdAt ? 1 : -1),
        enableSorting: true,
      },
      {
        accessorKey: 'description',
        header: () => t('profile.history.description'),
        size: undefined,
        cell: ({ getValue, row }) => (
          <>
            {getValue<string>()}
            {row.original.class === 'start'
              ? t('profile.history.registered')
              : row.original.class === 'stop'
                ? t('profile.history.deregistered')
                : ''}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageHeader title={t('profile.history.title')} onBack={handleBack} />
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table columns={columns} data={history} emptyText={t('profile.history.noResponse')} failedFetch={isError} />
      </ContentLoader>
    </>
  );
};
