import type { PaginationRequest } from 'api/base';

export const paginationStringGenerator = ({ page, size = 20 }: PaginationRequest) => {
  let paginationString = '';

  if (page) {
    paginationString = `page=${page}`;
  }

  if (size !== 20) {
    if (paginationString) {
      paginationString += '&';
    }

    paginationString += `size=${size}`;
  }

  return paginationString;
};
