import { Clients } from 'pages/private/admin/oauth2/Clients';
import { Endpoints } from 'pages/private/admin/oauth2/Endpoints';
import { Matchers } from 'pages/private/admin/oauth2/Matchers';
import { Methods } from 'pages/private/admin/oauth2/Methods';
import { Roles } from 'pages/private/admin/oauth2/Roles';
import { Route, Routes } from 'react-router-dom';
import { selectors, useAppSelector } from 'store';

export const OAuth2Routes = () => {
  const isSysAdmin = useAppSelector(selectors.identity.isSysAdminSelector);
  const isCompliance = useAppSelector(selectors.identity.isComplianceSelector);

  return (
    <Routes>
      {isCompliance && <Route path="clients" element={<Clients />} />}
      {isSysAdmin && (
        <>
          <Route path="endpoints" element={<Endpoints />} />
          <Route path="matchers" element={<Matchers />} />
          <Route path="methods" element={<Methods />} />
          <Route path="roles" element={<Roles />} />
        </>
      )}
    </Routes>
  );
};
