import { useGetMeta } from 'api/meta/hooks/useGetMeta';
import { useState } from 'react';
import { useTranslations } from 'translations/hooks/useTranslations';
import { Modal } from '../Modal/Modal';

export const SystemUpdate = () => {
  const { t } = useTranslations();
  const [hasPostponed, setHasPostponed] = useState(false);
  const [isPostponing, setIsPostponing] = useState(false);
  const { hasNewVersion } = useGetMeta();

  const onConfirm = () => window.location.reload();

  const onCancel = () => {
    setIsPostponing(true);
    setHasPostponed(true);
    setTimeout(
      () => {
        setIsPostponing(false);
      },
      2 * 60 * 1000
    );
  };

  return (
    <>
      {hasNewVersion && !isPostponing && (
        <Modal
          key="start"
          isShown={hasNewVersion && !isPostponing}
          hide={onCancel}
          onConfirm={onConfirm}
          onCancel={onCancel}
          message={hasPostponed ? t('general.system.postponedUpdateMessage') : t('general.system.updateMessage')}
          headerText={t('general.system.updateHeader')}
          confirmText={t('general.system.updateConfirm')}
          cancelText={t('general.system.updateCancel')}
          disableCancel={hasPostponed}
          disableCross={hasPostponed}
        />
      )}
    </>
  );
};
