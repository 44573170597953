import type { TimeSlot } from 'api/educateIT/educateIT';
import { useGetTimeSlotsQuery } from 'api/educateIT/educateIT';
import type { Occasion } from 'api/exams/exams';
import { useGetTestOccasionsQuery } from 'api/exams/exams';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useAppSelector } from 'store';
import type { OccasionsFilter } from 'store/stores/reservation/state';
import { useGetArticles } from './useGetArticles';

/**
 * PE-001, is a Digital test
 * PE-002, is a Physical test
 **/
export const useGetOccasions = (filter?: OccasionsFilter) => {
  const { activeUserId } = useGetActiveUserId();
  const bookingDetails = useAppSelector((state) => state.reservation.bookingDetails);
  const licensingTest = useAppSelector((state) => state.reservation.licensingExam);
  const { eligibleForExamFrom } = useGetArticles();
  const { data: physicalOccasions, ...restPhysical } = useGetTestOccasionsQuery(
    { userId: activeUserId },
    {
      skip: bookingDetails?.examMonitoringType?.art_no !== 'PE-002' && !activeUserId,
    }
  );

  const { data: digitalOccasions, ...restDigital } = useGetTimeSlotsQuery(
    { timeLength: licensingTest?.examSpecification?.duration_minutes ?? 0 },
    {
      skip: bookingDetails?.examMonitoringType?.art_no !== 'PE-001',
    }
  );

  const occassionsFilter = (occasion: Occasion | TimeSlot) => {
    if (eligibleForExamFrom) {
      const canBookFrom = new Date(eligibleForExamFrom);
      const occasionDate = new Date(occasion.startTimeUtc);

      if (canBookFrom > occasionDate) {
        return false;
      }
    }

    if (!filter) {
      return true;
    }

    if ('location' in occasion && filter.location && occasion.location !== filter.location) {
      return false;
    }

    if (filter.day && filter.year && (filter.month || filter.month === 0)) {
      const occasionDate = new Date(occasion.startTimeUtc);

      if (filter.day !== occasionDate.getDate() || filter.month !== occasionDate.getMonth() || filter.year !== occasionDate.getFullYear()) {
        return false;
      }
    }

    return true;
  };

  if (bookingDetails?.examMonitoringType?.art_no === 'PE-002') {
    return {
      occasions: physicalOccasions?.filter(occassionsFilter) ?? [],
      locations:
        physicalOccasions
          ?.map((occasion) => occasion.location)
          .filter(Boolean)
          .filter((location, index, array) => array.indexOf(location) === index) ?? [],
      ...restPhysical,
    };
  }

  return {
    occasions: digitalOccasions?.filter(occassionsFilter) ?? [],
    locations: [] as Array<string>,
    ...restDigital,
  };
};
