import { useGetUser } from 'api/users/hooks/useGetUser';
import { useGetUserRoles } from 'api/users/hooks/useGetUserRoles';
import type { UserProfile } from 'api/users/users';
import { useUpdateRolesMutation } from 'api/users/users';
import { Box, Typography } from 'components/basic-components';
import { Checkbox } from 'components/form-components';
import { Section } from 'components/misc';
import { Modal } from 'components/misc/Modal/Modal';
import { Form, Formik } from 'formik';
import { SquarePen } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { isComplianceSelector, isSwedsecAdminSelector, isSysAdminSelector } from 'store/selectors/identity';
import type { UserRoles } from 'store/stores/identity/state';
import styled from 'styled-components';
import { breakpoints } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { getErrorMessage } from 'utilities/errorHandling';

const StyledBox = styled(Box)`
  @media only screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const UserProfileRoles = () => {
  const [updateUserRoles, { isSuccess, isLoading, error }] = useUpdateRolesMutation();
  const { roles } = useGetUserRoles();
  const { t } = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const { user, activeUserId: userId } = useGetUser();
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const isSysAdmin = useAppSelector(isSysAdminSelector);
  const isCompliance = useAppSelector(isComplianceSelector);
  const userRolesAsArray = Object.keys(user?.roles ?? {})
    .filter((role) => user?.roles[role as UserRoles])
    .map((role) => role as UserRoles);

  const handleSubmit = async ({ roles }: { roles: Array<UserRoles> }) => {
    if (!user || !userId) {
      return;
    }

    if (JSON.stringify(userRolesAsArray.sort()) !== JSON.stringify(roles.sort())) {
      const removedRoles = userRolesAsArray.filter((role) => !roles.includes(role));
      const newRoles = roles.filter((role) => !userRolesAsArray.includes(role));

      updateUserRoles({
        userId,
        roles: newRoles.reduce(
          // Adding the roles that was checked from the form
          (roles, role) => ({
            ...roles,
            [role]: true,
          }),
          // Adding the roles that was removed
          removedRoles.reduce(
            (removedRoles, role) => ({
              ...removedRoles,
              [role]: false,
            }),
            {} as UserProfile['roles']
          )
        ),
      });
    }
  };

  useEffect(() => {
    if (error) {
      switch (getErrorMessage(error)) {
        case 'USER_IS_ADMIN_FOR_OTHER_USERS':
          publish(CustomEventName.API_ERROR, {
            message: 'USER_IS_ADMIN_FOR_OTHER_USERS',
          });
          break;
        default:
          publish(CustomEventName.API_ERROR, {
            message: 'UPDATE_USER_ROLES_ERROR',
          });
          break;
      }
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
    }
  }, [isSuccess]);

  if (user) {
    return (
      <Formik
        initialValues={{
          roles: userRolesAsArray,
        }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, errors, values, initialValues, submitForm, resetForm }) => (
          <Form>
            <Section
              title={t('profile.profile.roles')}
              clickableIcon={(isSwedsecAdmin || isSysAdmin || isCompliance) && <SquarePen onClick={() => setIsEditing(true)} />}
            >
              <Box padding="base-tight" gap="10px">
                {userRolesAsArray.length === 0 && <Typography.Paragraph>{t('profile.profile.noRoles')}</Typography.Paragraph>}
                {userRolesAsArray.map((role) => (
                  <Typography.Paragraph key={`userRole_${role}`}>{t(`general.roles.${role}`)}</Typography.Paragraph>
                ))}
              </Box>
            </Section>
            <Modal
              draggable
              isShown={isEditing}
              headerText={t('profile.profile.roles')}
              hide={() => {
                resetForm();
                setIsEditing(false);
              }}
              onCancel={() => {
                resetForm();
                setIsEditing(false);
              }}
              onConfirm={submitForm}
              disableConfirm={
                isLoading ||
                isSubmitting ||
                JSON.stringify(values) === JSON.stringify(initialValues) ||
                Object.values(errors).some((error) => error)
              }
              confirmText={t('profile.profile.save')}
              cancelText={t('profile.profile.cancel')}
            >
              <StyledBox flexDirection="row" flexWrap="wrap">
                <Box width="100%" minWidth="300px" gap="5px">
                  {roles
                    .filter((role) => role.assignable)
                    .map((role) => (
                      <Checkbox key={role.name} name="roles" label={t(`general.roles.${role.name}`)} value={role.name} />
                    ))}
                </Box>
              </StyledBox>
            </Modal>
          </Form>
        )}
      </Formik>
    );
  }

  return null;
};
