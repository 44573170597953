import type { ColumnDef, Row } from '@tanstack/react-table';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { useGetUsers } from 'api/users/hooks/useGetUsers';
import type { UserProfile } from 'api/users/users';
import { Box, Typography } from 'components/basic-components';
import { Input } from 'components/basic-components/Input/Input';
import { ExpandButton } from 'components/basic-components/Table/ExpandButton';
import { Table } from 'components/basic-components/Table/Table';
import { usePagination } from 'components/basic-components/Table/hooks/usePagination';
import { ContentLoader, Select } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { UserRoles } from 'store/stores/identity/state';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useDebounce } from 'utilities/useDebounce';

type UserExapndedRowValueProps = {
  title?: string;
  value: string | number;
};

const UserExapndedRowValue = ({ title, value }: UserExapndedRowValueProps) => (
  <Box flexDirection="row" gap="10px">
    {title && <Typography.Label>{title}</Typography.Label>}
    <Typography.Paragraph>{value}</Typography.Paragraph>
  </Box>
);

type UserExpandedRowProps = {
  row: Row<UserProfile>;
};

const UserExpandedRow = ({ row }: UserExpandedRowProps) => {
  const { t } = useTranslations();

  return (
    <tr>
      <td colSpan={4}>
        <Box backgroundColor="neutral-color-2" padding="base" aria-colspan={4} flexDirection="row" gap="20px">
          <Box flex={1}>
            <Typography.H4 marginY="none">
              {row.original.firstName} {row.original.lastName}
            </Typography.H4>
            <Typography.Paragraph marginBottom="loose">{row.original.socialNo}</Typography.Paragraph>
            <UserExapndedRowValue title={t('profile.search.mobile')} value={row.original.mobilephonenr} />

            <Typography.H4>{t('profile.search.testResults')}</Typography.H4>
            {row.original.certificates.map((certificate) => (
              <UserExapndedRowValue key={certificate.certififcateId} value={certificate.examFamily} />
            ))}
          </Box>
          <Box flex={1}>
            <Typography.H4 marginTop="none">{row.original.defaultCompany?.companyName}</Typography.H4>
            <UserExapndedRowValue title={t('profile.search.administrator')} value={row.original.administratorName} />
            <UserExapndedRowValue
              title={t('profile.search.connectedCompany')}
              value={row.original.defaultCompany?.isMember ? t('general.general.yes') : t('general.general.no')}
            />
            <UserExapndedRowValue title={t('profile.search.clearing')} value={row.original.defaultCompany?.clearing ?? ''} />
            <UserExapndedRowValue title={t('profile.search.department')} value={row.original.defaultCompany?.department ?? ''} />
            <Typography.H4>{t('profile.search.roles')}</Typography.H4>
            {Object.keys(row.original.roles).map((role) => (
              <UserExapndedRowValue
                key={role}
                title={t(`general.roles.${role}`)}
                value={row.original.roles[role as UserRoles] ? t('general.general.yes') : t('general.general.no')}
              />
            ))}
          </Box>
        </Box>
      </td>
    </tr>
  );
};

export const UserSearch = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { setImpersonatedUserId } = useGetUser();
  const [freeText, setFreeText] = useState('');
  const [licenseStatus, setLicenseStatus] = useState<string>();
  const [userRole, setUserRole] = useState<UserRoles>();
  const debouncedFreeText = useDebounce<string>(freeText, 200);
  const { pageSize, page, onPagination } = usePagination({});
  const { users, total, isLoading, isError } = useGetUsers({
    freeText: debouncedFreeText,
    licenseStatus,
    userRole,
    page,
    size: pageSize,
  });

  const columns = useMemo<ColumnDef<UserProfile>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => t('profile.search.name'),
        cell: ({ row }) => (
          <>
            {row.original.firstName} {row.original.lastName}
          </>
        ),
      },
      {
        accessorKey: 'companyName',
        header: () => t('profile.search.companyName'),
        cell: ({ row }) => <>{row.original.defaultCompany?.companyName}</>,
      },
      {
        accessorKey: 'social_no',
        header: () => t('profile.search.socialNo'),
      },
      {
        accessorKey: 'expand',
        header: () => '',
        cell: ({ row }) => <ExpandButton row={row} />,
        size: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onRowClick = (row: Row<UserProfile>) => {
    setImpersonatedUserId(row?.original?.id);
  };

  useEffect(() => {
    if (isError) {
      onPagination(0);
    }
  }, [isError, onPagination]);

  useEffect(() => {
    onPagination(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFreeText, licenseStatus, userRole]);

  return (
    <>
      <PageHeader title={t('profile.search.title')} onBack={() => navigate('/')} />
      <Box marginBottom="loose" flexDirection="row" gap="20px">
        <Input
          label={t('profile.search.freeText')}
          placeholder={t('profile.search.freeText')}
          value={freeText}
          onChange={(value: string) => setFreeText(value)}
        />
        <Select
          variant="select"
          label={t('profile.search.licenseStatus')}
          defaultValue=""
          onChange={(e: React.ChangeEvent<any>) => setLicenseStatus(e.currentTarget.value)}
        >
          <option value="">{t('profile.search.selectLicenseStatus')}</option>
          <option value="LICENSED">{t('profile.search.licensed')}</option>
          <option value="ACTIVE">{t('profile.search.active')}</option>
          <option value="RESTING">{t('profile.search.resting')}</option>
          <option value="UNLICENSED">{t('profile.search.unlicensed')}</option>
        </Select>
        <Select
          variant="select"
          label={t('profile.search.role')}
          defaultValue=""
          onChange={(e: React.ChangeEvent<any>) => setUserRole(e.currentTarget.value as UserRoles)}
        >
          <option value="">{t('profile.search.selectUserRole')}</option>
          <option value="ROLE_SYS_ADMIN">{t('general.roles.ROLE_SYS_ADMIN')}</option>
          <option value="ROLE_ADMIN">{t('general.roles.ROLE_ADMIN')}</option>
          <option value="ROLE_TEST_LEADER">{t('general.roles.ROLE_TEST_LEADER')}</option>
          <option value="ROLE_SUBCONTRACTOR">{t('general.roles.ROLE_SUBCONTRACTOR')}</option>
          <option value="ROLE_COMPLIANCE">{t('general.roles.ROLE_COMPLIANCE')}</option>
          <option value="ROLE_SWEDSEC_ADMIN">{t('general.roles.ROLE_SWEDSEC_ADMIN')}</option>
        </Select>
      </Box>
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={isError ? [] : users}
          onRowClick={onRowClick}
          rowCount={isError ? 0 : total}
          pageSize={isError ? 0 : pageSize}
          onPagination={onPagination}
          page={page}
          renderExpandedRow={(row) => <UserExpandedRow row={row} />}
          emptyText={t('profile.search.noResponse')}
          failedFetch={isError}
        />
      </ContentLoader>
    </>
  );
};
