import type { ColumnDef } from '@tanstack/react-table';
import { useGetAllSystemMessages } from 'api/system/hooks/useGetAllSystemMessages';
import { useDeleteSystemMessagesMutation, type SystemMessage } from 'api/system/system';
import { Box } from 'components/basic-components';
import { IconButton } from 'components/basic-components/IconButton/IconButton';
import { Input } from 'components/basic-components/Input/Input';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader, Modal, Select } from 'components/misc';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { Trash2 } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslations } from 'translations/hooks/useTranslations';
import { printLocaleString } from 'utilities/printLocaleString';
import { useDebounce } from 'utilities/useDebounce';
import { CreateSystemMessage } from './CreateSystemMessage';
import { UpdateSystemMessage } from './UpdateSystemMessage';

const StyledIconButton = styled(IconButton)`
  justify-content: end;
`;

export const SystemMessages = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const [systemMessageToDelete, setSystemMessageToDelete] = useState<SystemMessage>();
  const [body, setBody] = useState('');
  const [type, setType] = useState<SystemMessage['type']>();
  const [selectedSystemMessage, setSelectedSystemMessage] = useState<SystemMessage>();
  const [showCreateSystemMessage, setShowCreateSystemMessage] = useState(false);
  const debouncedKey = useDebounce<string>(body, 200);
  const { systemMessages, isLoading, isError } = useGetAllSystemMessages({ message: debouncedKey, type });
  const [deleteSystemMessage, { isSuccess }] = useDeleteSystemMessagesMutation();

  const columns = useMemo<ColumnDef<SystemMessage>[]>(
    () => [
      {
        accessorKey: 'type',
        header: () => t('systemMessage.type'),
        size: 50,
        cell: ({ row }) => <>{t(`systemMessage.${row.original.type}`)}</>,
      },
      {
        accessorKey: 'fromDate',
        header: () => t('systemMessage.fromDate'),
        size: 50,
        cell: ({ row }) => <>{printLocaleString(row.original.fromDate, true)}</>,
      },
      {
        accessorKey: 'toDate',
        header: () => t('systemMessage.toDate'),
        size: 50,
        cell: ({ row }) => <>{printLocaleString(row.original.toDate, true)}</>,
      },
      {
        accessorKey: 'swedishMessage',
        header: () => t('systemMessage.swedishMessage'),
        size: undefined,
      },
      {
        accessorKey: 'delete',
        header: () => '',
        cell: ({ row }) => (
          <Box flex="1">
            <StyledIconButton
              icon={<Trash2 />}
              onClick={(e: any) => {
                e.stopPropagation();
                setSystemMessageToDelete(row.original);
              }}
            />
          </Box>
        ),
        size: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isSuccess) {
      setSystemMessageToDelete(undefined);
    }
  }, [isSuccess]);

  return (
    <>
      <PageHeader title={t('systemMessage.title')} onBack={() => navigate('/')} />
      {systemMessageToDelete && (
        <Modal
          isShown={Boolean(systemMessageToDelete)}
          hide={() => setSystemMessageToDelete(undefined)}
          onConfirm={() => deleteSystemMessage(systemMessageToDelete.id)}
          onCancel={() => setSystemMessageToDelete(undefined)}
          message={t('systemMessage.modal.deleteMessage')}
          headerText={t('systemMessage.modal.deleteHeader')}
          confirmText={t('general.general.yes')}
          cancelText={t('general.general.no')}
          invertButtons
        />
      )}
      <Box gap="base" flexDirection="row" marginBottom="loose">
        <Box flex="2">
          <Input placeholder={t('systemMessage.searchInput')} value={body} onChange={(value: string) => setBody(value)} />
        </Box>
        <Box flex="1" alignItems="end">
          <Select
            width="100% !important"
            height="100%"
            variant="select"
            defaultValue={type}
            onChange={(e) => setType(e.currentTarget.value as SystemMessage['type'])}
          >
            <option value="">{t('systemMessage.selectType')}</option>
            <option value="ERROR">{t('systemMessage.ERROR')}</option>
            <option value="SUCCESS">{t('systemMessage.SUCCESS')}</option>
            <option value="INFO">{t('systemMessage.INFO')}</option>
          </Select>
        </Box>
      </Box>
      {showCreateSystemMessage && <CreateSystemMessage show={showCreateSystemMessage} onClose={() => setShowCreateSystemMessage(false)} />}
      {selectedSystemMessage && <UpdateSystemMessage systemMessage={selectedSystemMessage} setSystemMessage={setSelectedSystemMessage} />}
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={isError ? [] : systemMessages}
          onRowClick={(row) => setSelectedSystemMessage(row.original as SystemMessage)}
          emptyText={t('systemMessage.noResponse')}
        />
      </ContentLoader>
      <ActionButtonRow primaryOnClick={() => setShowCreateSystemMessage(true)} primaryText={t('systemMessage.create')} />
    </>
  );
};
