import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { educateITBaseApi, localApi, onlineApi, privateApi, publicApi } from 'api/base';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { rtkQueryErrorHandler } from 'utilities/errorHandling';
import { rootReducer } from './reducer';
import { actions as generalActions } from './stores/general';
import { actions as identityActions } from './stores/identity';
import { actions as reservationActions } from './stores/reservation';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: Partial<RootState>) => {
    const transformedState = {
      ...state,
      identity: {
        ...state.identity,
      },
      todo: {},
      aku: {},
    };

    return transformedState;
  },
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rtkQueryErrorHandler,
      publicApi.middleware,
      privateApi.middleware,
      educateITBaseApi.middleware,
      onlineApi.middleware,
      localApi.middleware
    ),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
  devTools: {
    trace: true,
    traceLimit: 20,
  },
});

export const actions = {
  general: generalActions,
  identity: identityActions,
  reservation: reservationActions,
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * as selectors from './selectors';
