import type { ExamSpecification } from 'api/exams/exams';
import { Box } from 'components/basic-components';
import { Checkbox } from 'components/form-components';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { priceCalculator } from 'utilities/priceCalculator';
import SectionWrapper from './SectionWrapper';

export const TestTypes = () => {
  const { t } = useTranslations();
  const selectedSubject = useAppSelector((state) => state.reservation.bookingDetails.selectedSubject);
  const diagnosticTest = useAppSelector((state) => state.reservation.diagnosticExam);
  const licensingTest = useAppSelector((state) => state.reservation.licensingExam);
  const selectedTests = [diagnosticTest, licensingTest].map((test) => test?.examSpecification?.art_no).filter(Boolean);
  const dispatch = useAppDispatch();

  const onChange = async (examSpecification: ExamSpecification) => {
    const isDiagnosticTest = examSpecification.type === 'Diagnostest';

    if (isDiagnosticTest) {
      if (diagnosticTest) {
        await dispatch(actions.reservation['remove-diagnostic-reservation']());
      } else {
        await dispatch(actions.reservation['add-diagnostic-reservation'](examSpecification));
      }
    } else {
      if (licensingTest) {
        await dispatch(actions.reservation['remove-license-reservation']());
      } else {
        await dispatch(
          actions.reservation['add-license-reservation']({
            examSpecification,
          })
        );
      }
    }
  };

  return (
    selectedSubject && (
      <Box alignItems="flex-start">
        <SectionWrapper title={t('booking.booking.t-categories-title')} status="finished">
          {selectedSubject.exam_specifications
            .filter((examSpecification) => examSpecification.type !== 'Audio')
            .map((examSpecification, index) => (
              <Checkbox
                key={index}
                name={examSpecification.art_no}
                value={examSpecification.art_no}
                label={examSpecification.type}
                secondLine={`${priceCalculator(examSpecification.price)} ${t('booking.booking.currency-sek')}`}
                onChange={() => onChange(examSpecification)}
                checked={Boolean(selectedTests.find((test) => test === examSpecification.art_no))}
              />
            ))}
        </SectionWrapper>
      </Box>
    )
  );
};
