import { useGetTranslations } from 'api/translations/hooks/useGetTranslations';
import type { Translation } from 'api/translations/translations';
import { useTranslation } from 'react-i18next';
import type { Languages, NestedTranslationObject } from 'translations/i18next';
import { flattenTranslationObject } from 'translations/i18next';
import en from '../en/en.json';
import sv from '../sv/sv.json';

export const useTranslations = () => {
  const { t, i18n } = useTranslation();
  const { translations: dbTranslationsSv } = useGetTranslations({ lang: 'sv', key: '' });
  const { translations: dbTranslationsEn } = useGetTranslations({ lang: 'en', key: '' });

  const findDifferencesInTranslations = (translationArray: Array<Translation>, real: NestedTranslationObject) => {
    const database: NestedTranslationObject = translationArray.reduce((acc, { key, content }) => {
      acc[key] = content;
      return acc;
    }, {} as NestedTranslationObject);
    const missingKeys: NestedTranslationObject = {};
    const changedKeys: NestedTranslationObject = {};
    const deletedKeys: NestedTranslationObject = {};

    for (const key in real) {
      if (!database[key]) {
        missingKeys[key] = real[key];
      } else if (database[key] !== real[key]) {
        changedKeys[key] = real[key];
      }
    }

    for (const key in database) {
      if (!real[key]) {
        deletedKeys[key] = database[key];
      }
    }

    return {
      missingKeys,
      changedKeys,
      deletedKeys,
    };
  };

  const getMissmatchingTranslations = () => {
    const flattenSv = flattenTranslationObject(sv);
    const flattenEn = flattenTranslationObject(en);

    return {
      en: findDifferencesInTranslations(dbTranslationsEn, flattenEn),
      sv: findDifferencesInTranslations(dbTranslationsSv, flattenSv),
      svEnDifferences: {
        missingKeysInEn: Object.keys(flattenSv).filter((key) => !flattenEn[key]),
        missingKeysInSv: Object.keys(flattenEn).filter((key) => !flattenSv[key]),
      },
    };
  };

  return {
    t,
    changeLanguage: i18n.changeLanguage,
    language: i18n.language as Languages,
    getMissmatchingTranslations,
  };
};
