import { ContentLoader } from 'components/misc';
import DateWrapper from 'components/misc/DatePicker/DatePickerWrapper';
import { parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import { sv } from 'date-fns/locale/sv';
import { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAppSelector } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { errorHandler } from 'utilities/errorHandling';
import FailedToLoad from './FailedToLoad';
import { useGetOccasions } from './hooks/useGetOccasions';

registerLocale('sv', sv);
registerLocale('en', enGB);

type OccasionsCalendarProps = {
  handleDateChange: (date: Date) => void;
  startDate: Date;
  selectedDate: Date;
};

export const OccasionsCalendar = ({ handleDateChange, startDate, selectedDate }: OccasionsCalendarProps) => {
  const { t, language } = useTranslations();
  const licensingTest = useAppSelector((state) => state.reservation.licensingExam);
  const filter = useAppSelector((state) => state.reservation.bookingDetails.filter);
  const { occasions, isLoading, isError, error } = useGetOccasions({
    location: filter?.location,
    day: undefined,
    month: undefined,
    year: undefined,
  });
  const availableDates = occasions.map((occasion) => new Date(occasion.startTimeUtc));
  const date = new Date();
  const status = isLoading ? 'loading' : isError ? 'error' : 'idle';

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <ContentLoader
      status={status}
      template="calendar"
      errorComponent={<FailedToLoad errorMessage={t('booking.booking.failedToLoadCal')} />}
    >
      <DateWrapper>
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          adjustDateOnChange
          open
          minDate={date}
          maxDate={new Date(date.getFullYear() + 1, date.getMonth() + 1, 0)}
          includeDates={availableDates}
          disabledKeyboardNavigation
          locale={language}
          monthsShown={1}
          openToDate={licensingTest?.proctor?.session?.startTimeUtc ? parseISO(licensingTest.proctor?.session?.startTimeUtc) : selectedDate}
        />
      </DateWrapper>
    </ContentLoader>
  );
};
