const IMG: string = process.env.REACT_APP_IMG ? process.env.REACT_APP_IMG : '';
if (IMG === '') throw new Error('REACT_APP_IMG is not defined');

export const images = {
  welcome: {
    background: `url(${IMG}/background.jpg)`,
    logo: `url(${IMG}/swedsec_logga.png)`,
    'logo-light': `url(${IMG}/swedsec_logotype_white_big.png)`,
    'logo-typ': `url(${IMG}/Swedsec_logotyp.svg)`,
  },
  certificates: {
    Bolån: `url(${IMG}/Bolan_liten.svg)`,
    Informationsgivare: `url(${IMG}/Informationsgivare_liten.svg)`,
    Ledning_och_kontroll: `url(${IMG}/Ledning-kontroll_liten.svg)`,
    Rådgivare: `url(${IMG}/Radgivare_liten.svg)`,
    Specialister: `url(${IMG}/Specialister_liten.svg)`,
    AKU: `url(${IMG}/aku.webp)`,
    Värdepappersmarknaden: `url(${IMG}/VP-test.jpg)`,
  },
  bankId: {
    logo: `url(${IMG}/bankid.svg)`,
  },
  spar: {
    logo: `url(${IMG}/spar.png)`,
  },
};

export type CertificateSubjects = keyof typeof images.certificates;
