import type { SubscriptionOptions } from '@reduxjs/toolkit/query';
import type { SystemMessage } from '../system';
import { useGetSystemMessagesQuery } from '../system';

export const useGetSystemMessages = ({ type, options }: Partial<Omit<SystemMessage, 'id'>> & { options?: SubscriptionOptions }) => {
  const { data, ...rest } = useGetSystemMessagesQuery(undefined, options);

  const systemMessages = (data ?? []).filter((message) => {
    if (type && message.type !== type) {
      return false;
    }

    return true;
  });

  return {
    systemMessages,
    ...rest,
  };
};
