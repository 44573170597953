import type React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import type {
  BorderRadiusProps,
  ColorProps,
  DisplayProps,
  FlexProps,
  FlexboxProps,
  FontFamilyProps,
  FontSizeProps,
  FontStyleProps,
  FontWeightProps,
  LetterSpacingProps,
  LineHeightProps,
  PositionProps,
  SizeProps,
  SpaceProps,
  TextAlignProps,
  TextShadowProps,
  TextStyleProps,
  WidthProps,
  styleFn,
} from 'styled-system';
import {
  borderRadius,
  color,
  display,
  flex,
  flexbox,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  letterSpacing,
  lineHeight,
  position,
  size,
  space,
  system,
  textAlign,
  textShadow,
  textStyle,
  width,
} from 'styled-system';
import type { ThemeSchema } from '../theme';

const textDecoration = system({
  textDecoration: { property: 'textDecoration', properties: ['textDecoration'] },
});

export type StyledSystemProps =
  | {
      style: React.CSSProperties;
    }
  | {
      onClick: React.MouseEventHandler<HTMLBaseElement>;
    }
  | {
      as?: keyof JSX.IntrinsicElements | React.ComponentType<unknown>;
      children?: ReactNode;
    }
  | SpaceProps<ThemeSchema>
  | FontSizeProps
  | FontStyleProps
  | SizeProps
  | PositionProps
  | TextStyleProps<ThemeSchema>
  | LetterSpacingProps
  | FontFamilyProps
  | FontWeightProps<ThemeSchema>
  | BorderRadiusProps<ThemeSchema>
  | ColorProps<ThemeSchema>
  | LineHeightProps
  | TextAlignProps
  | TextShadowProps
  | WidthProps
  | DisplayProps
  | FlexProps
  | FlexboxProps
  | styleFn;

export const DynamicStyledSystemComponent = styled.span<StyledSystemProps>`
  ${space}
  ${fontSize}
  ${fontStyle}
  ${size}
  ${color}
  ${textStyle}
  ${letterSpacing}
  ${fontFamily}
  ${fontWeight}
  ${borderRadius}
  ${lineHeight}
  ${position}
  ${textAlign}
  ${textDecoration}
  ${textStyle}
  ${textShadow}
  ${flex}
  ${flexbox}
  ${display}
  ${width}
`;
