import { privateApi, publicApi } from 'api/base';

export type SystemMessage = {
  swedishMessage: string;
  englishMessage: string;
  type: 'ERROR' | 'SUCCESS' | 'INFO';
  fromDate: string;
  toDate: string;
  id: number;
};

export type CreateSystemMessageRequest = {
  swedishMessage: string;
  englishMessage: string;
  type: 'ERROR' | 'SUCCESS' | 'INFO';
  fromDate: Date;
  toDate: Date;
};

export type UpdateSystemMessageRequest = CreateSystemMessageRequest & { id: number };

type ApiSystemMessage = {
  swedish_message: string;
  english_message: string;
  type: 'ERROR' | 'SUCCESS' | 'INFO';
  from_date: string;
  to_date: string;
  id: number;
};

export const publicSystemsApi = publicApi.injectEndpoints({
  endpoints: (build) => ({
    getSystemMessages: build.query<Array<SystemMessage>, void>({
      query: () => `/systemmessages`,
      providesTags: ['SystemMessages'],
      transformResponse: (data: Array<ApiSystemMessage>) =>
        data.map((message) => ({
          swedishMessage: message.swedish_message,
          englishMessage: message.english_message,
          type: message.type,
          fromDate: message.from_date,
          toDate: message.to_date,
          id: message.id,
        })),
    }),
  }),
});

export const systemsApi = privateApi.injectEndpoints({
  endpoints: (build) => ({
    getAllSystemMessages: build.query<Array<SystemMessage>, void>({
      query: () => `/systemmessages/all`,
      providesTags: ['SystemMessages'],
      transformResponse: (data: Array<ApiSystemMessage>) =>
        data.map((message) => ({
          swedishMessage: message.swedish_message,
          englishMessage: message.english_message,
          type: message.type,
          fromDate: message.from_date,
          toDate: message.to_date,
          id: message.id,
        })),
    }),
    createSystemMessages: build.mutation<void, CreateSystemMessageRequest>({
      query: ({ swedishMessage, englishMessage, type, fromDate, toDate }) => ({
        url: `/systemmessages`,
        method: 'POST',
        body: {
          swedish_message: swedishMessage,
          english_message: englishMessage,
          message_type: type,
          show_from: `${fromDate.toISOString().split('T')[0]}T00:00:00Z`,
          show_to: `${toDate.toISOString().split('T')[0]}T21:59:59Z`,
        },
      }),
      invalidatesTags: () => [{ type: 'SystemMessages' as const }],
    }),
    updateSystemMessages: build.mutation<void, UpdateSystemMessageRequest>({
      query: ({ swedishMessage, englishMessage, type, fromDate, toDate, id }) => ({
        url: `/systemmessages/${id}`,
        method: 'PUT',
        body: {
          swedish_message: swedishMessage,
          english_message: englishMessage,
          message_type: type,
          show_from: `${fromDate.toISOString().split('T')[0]}T00:00:00Z`,
          show_to: `${toDate.toISOString().split('T')[0]}T21:59:59Z`,
        },
      }),
      async onQueryStarted({ swedishMessage, englishMessage, type, fromDate, toDate, id }, { dispatch, queryFulfilled }) {
        const allSystemMessagePatchResult = dispatch(
          systemsApi.util.updateQueryData('getAllSystemMessages', undefined, (cachedSystemMessages) =>
            cachedSystemMessages.map((systemMessage) =>
              systemMessage.id === id
                ? {
                    swedishMessage,
                    englishMessage,
                    type,
                    fromDate: `${fromDate.toISOString().split('T')[0]}T00:00:00Z`,
                    toDate: `${toDate.toISOString().split('T')[0]}T21:59:59Z`,
                    id,
                  }
                : systemMessage
            )
          )
        );
        const systemMessagePatchResult = dispatch(
          publicSystemsApi.util.updateQueryData('getSystemMessages', undefined, (cachedSystemMessages) =>
            cachedSystemMessages.map((systemMessage) =>
              systemMessage.id === id
                ? {
                    swedishMessage,
                    englishMessage,
                    type,
                    fromDate: `${fromDate.toISOString().split('T')[0]}T00:00:00Z`,
                    toDate: `${toDate.toISOString().split('T')[0]}T21:59:59Z`,
                    id,
                  }
                : systemMessage
            )
          )
        );

        try {
          await queryFulfilled;
        } catch {
          allSystemMessagePatchResult.undo();
          systemMessagePatchResult.undo();
        }
      },
    }),
    deleteSystemMessages: build.mutation<void, number>({
      query: (id) => ({
        url: `/systemmessages/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const allSystemMessagePatchResult = dispatch(
          systemsApi.util.updateQueryData('getAllSystemMessages', undefined, (cachedSystemMessages) =>
            cachedSystemMessages.filter((systemMessage) => systemMessage.id !== id)
          )
        );
        const systemMessagePatchResult = dispatch(
          publicSystemsApi.util.updateQueryData('getSystemMessages', undefined, (cachedSystemMessages) =>
            cachedSystemMessages.filter((systemMessage) => systemMessage.id !== id)
          )
        );

        try {
          await queryFulfilled;
        } catch {
          allSystemMessagePatchResult.undo();
          systemMessagePatchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetAllSystemMessagesQuery,
  useCreateSystemMessagesMutation,
  useDeleteSystemMessagesMutation,
  useUpdateSystemMessagesMutation,
} = systemsApi;

export const { useGetSystemMessagesQuery } = publicSystemsApi;
