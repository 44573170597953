export enum CustomEventName {
  API_ERROR = 'API_ERROR',
  SUCCESS = 'SUCCESS',
  AUTH_ERROR = 'AUTH_ERROR',
  RESET_PASS = 'RESET_PASS',
  NETWORK_ERROR = 'NETWORK_ERROR',
}

export const unknown = new CustomEvent('UNKNOWN_ERROR', {
  detail: {
    message: 'unknown_error',
  },
});

export const missingField = new CustomEvent('MISSING_FIELD');

export const passwordResetSuccess = new CustomEvent('SUCCESS', {
  detail: {
    message: 'password_reset_success',
  },
});

export const CustomEvents = {
  missingField,
  passwordResetSuccess,
  unknown,
};
