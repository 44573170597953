import { useGetExamsQuery } from 'api/exams/exams';
import { useGetUser } from 'api/users/hooks/useGetUser';

export const useGetExams = () => {
  const { user, activeUserId, isLoading: userIsLoading, isError: userIsError } = useGetUser();
  const {
    data: exams,
    isLoading,
    isError,
    ...rest
  } = useGetExamsQuery({ companyId: user?.defaultCompany?.companyId ?? 0, userId: activeUserId }, { skip: !user });

  return {
    licensingExams: (exams ?? []).filter((exam) => exam.licenseTest),
    diagnosticExams: (exams ?? []).filter((exam) => !exam.licenseTest),
    isLoading: isLoading || userIsLoading,
    isError: isError || userIsError,
    ...rest,
  };
};
