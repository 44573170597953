import type { TimeSlot } from 'api/educateIT/educateIT';
import type { ExamSpecification, MonitoringType, Occasion, Subject } from 'api/exams/exams';

export type BookingDetails = {
  address?: string;
  audio?: boolean;
  examMonitoringType?: MonitoringType;
  selectedSubject?: Subject;
  selectedOccasion?: Occasion | TimeSlot;
  filter?: OccasionsFilter;
};

export type OccasionsFilter = {
  day?: number;
  month?: number;
  year?: number;
  location?: string;
};

export type LicensingExam = {
  examSpecification?: ExamSpecification;
  proctor?: {
    artNo?: string;
    session?: {
      startTimeUtc?: string;
      occasionId?: number;
      locationId?: number;
    };
  };
};

export type DiagnosticExam = {
  examSpecification?: ExamSpecification;
};

export type State = {
  licensingExam?: LicensingExam;
  diagnosticExam?: DiagnosticExam;
  bookingDetails: BookingDetails;
};

export const initialState: State = {
  bookingDetails: {
    filter: {
      day: undefined,
      month: undefined,
      year: undefined,
      location: undefined,
    },
  },
};
