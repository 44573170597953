export const subscribe = (eventName: string, listener: () => void) => {
  addEventListener(eventName, listener);
};

export const unsubscribe = (eventName: string, listener: () => void) => {
  removeEventListener(eventName, listener);
};

export const publish = (eventName: string, data?: { [key: string]: unknown }) => {
  const event = new CustomEvent(eventName, { detail: data });
  dispatchEvent(event);
};
