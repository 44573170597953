import type { ColumnDef } from '@tanstack/react-table';
import { BASE_URL, refreshToken } from 'api/base';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetViolations } from 'api/violations/hooks/useGetViolations';
import { useDeleteViolationsMutation, type Violation } from 'api/violations/violations';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader, Modal } from 'components/misc';
import { ActionButtonRow } from 'components/misc/ButtonRow/ActionButtonRow';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

export const View = () => {
  const { t } = useTranslations();
  const { violations, canPrintPdf, isLoading } = useGetViolations();
  const { activeUserId } = useGetActiveUserId();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSwedsecAdmin = useAppSelector(selectors.identity.isSwedsecAdminSelector);
  const isIntegrated = useIsIntegrated();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteViolation, { isSuccess }] = useDeleteViolationsMutation();
  const [rowSelection, setRowSelection] = useState({});

  const handleBack = () => {
    if (isIntegrated) {
      dispatch(
        actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId: activeUserId } })
      );
    } else {
      navigate('/');
    }
  };

  const columns = useMemo<ColumnDef<Violation>[]>(
    () => [
      {
        accessorKey: 'type',
        header: () => t('profile.violations.type'),
        size: undefined,
      },
      {
        accessorKey: 'date',
        header: () => t('profile.violations.date'),
        size: undefined,
        sortingFn: (a, b) => (a.original.date > b.original.date ? 1 : -1),
        enableSorting: true,
        cell: ({ row }) => <>{new Date(row.original.date).toISOString().split('T')[0]}</>,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmit = (selectedRows: number[]) => {
    for (const violationId of selectedRows) {
      deleteViolation({ violationId, userId: activeUserId });
    }
  };

  const downloadPdf = async (): Promise<void> => {
    const token = getLoggedInUser()?.userToken;
    const response = await fetch(`${BASE_URL}/violations/users/${activeUserId}/pdf`, {
      headers: {
        Accept: 'application/pdf',
        authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        const accessToken = await refreshToken();

        if (accessToken) {
          return await downloadPdf();
        } else {
          publish(CustomEventName.AUTH_ERROR);
        }
      }

      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const blob = await response.blob();

    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.href = URL.createObjectURL(blob);
    link.download = `registerutdrag_${activeUserId}_${new Date().toISOString().split('T')[0]}.pdf`;
    link.click();

    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (isSuccess) {
      setShowDeleteModal(false);
    }
  }, [isSuccess]);

  return (
    <>
      <PageHeader
        title={t('profile.violations.title')}
        onBack={handleBack}
        buttonOnClick={isSwedsecAdmin ? () => setShowDeleteModal(true) : undefined}
        buttonText={t('profile.violations.delete')}
        disabledButton={Object.keys(rowSelection).length === 0}
      />
      {showDeleteModal && (
        <Modal
          isShown={showDeleteModal}
          hide={() => setShowDeleteModal(false)}
          onConfirm={() => {
            const selectedCostIndexes = Object.entries(rowSelection)
              .filter(([, selected]) => selected)
              .map(([key]) => key);

            handleSubmit(violations.filter((_, index) => selectedCostIndexes.includes(index.toString())).map((violation) => violation.id));

            setRowSelection({});
          }}
          onCancel={() => setShowDeleteModal(false)}
          message={t('profile.violations.modal.deleteMessage')}
          headerText={t('profile.violations.modal.deleteHeader')}
          confirmText={t('general.general.delete')}
          cancelText={t('profile.violations.modal.cancel')}
        />
      )}
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={violations}
          emptyText={t('profile.violations.noResponse')}
          enableRowSelection={isSwedsecAdmin}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </ContentLoader>
      <ActionButtonRow
        primaryText={t('profile.violations.printPdf')}
        disabled={!canPrintPdf && !isSwedsecAdmin}
        primaryOnClick={downloadPdf}
      />
    </>
  );
};
