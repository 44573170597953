import type { SubscriptionOptions } from '@reduxjs/toolkit/query';
import type { SystemMessage } from '../system';
import { useGetAllSystemMessagesQuery } from '../system';

export const useGetAllSystemMessages = ({
  message,
  type,
  options,
}: Partial<Pick<SystemMessage, 'type'>> & { options?: SubscriptionOptions; message?: string }) => {
  const { data, ...rest } = useGetAllSystemMessagesQuery(undefined, options);

  const systemMessages = (data ?? []).filter((systemMessage) => {
    if (type && systemMessage.type !== type) {
      return false;
    }

    if (
      message &&
      (!systemMessage.englishMessage.toLocaleLowerCase().includes(message.toLocaleLowerCase()) ||
        !systemMessage.swedishMessage.toLocaleLowerCase().includes(message.toLocaleLowerCase()))
    ) {
      return false;
    }

    return true;
  });

  return {
    systemMessages,
    ...rest,
  };
};
