import { privateApi } from 'api/base';
import { printLocaleString } from 'utilities/printLocaleString';

type GetHistoryRequest = {
  userId: number;
};

export type History = {
  createdAt: string;
  class: 'stop' | 'start' | 'comment';
  description: string;
};

type ApiHistory = {
  short_description: string;
  create_date: string;
  history_class: 'stop' | 'start' | 'comment';
};

export const historyApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    getHistory: builder.query<Array<History>, GetHistoryRequest>({
      query: ({ userId }) => `history/users/${userId.toString()}`,
      transformResponse: (data: ApiHistory[]) =>
        data.map((history) => ({
          description: history.short_description,
          class: history.history_class,
          createdAt: printLocaleString(history.create_date),
        })),
      providesTags: ['History'],
    }),
  }),
});

export const { useGetHistoryQuery } = historyApi;
