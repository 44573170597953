import { Box, Typography } from 'components/basic-components';
import { Icon } from 'components/misc';
import { CircleAlert } from 'lucide-react';
import { theme } from 'theme';

const FailedToLoad = ({ errorMessage }: { errorMessage?: string }) => {
  return (
    <Box height="100%" justifyContent="center" alignItems="center" padding="extra-loose">
      <Icon color="complementary-color-2">
        <CircleAlert color={theme.colors['complementary-color-2']} />
      </Icon>
      <Typography.Paragraph textAlign="center" color="complementary-color-2" marginTop="base-tight">
        {errorMessage}
      </Typography.Paragraph>
    </Box>
  );
};

export default FailedToLoad;
