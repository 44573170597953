import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { Typography } from 'components/basic-components';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { actions, useAppDispatch } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import { AkuTable } from './AkuTable';

export const Edit = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeUserId: userId } = useGetActiveUserId();
  const { isMember } = useGetUser();
  const [searchParams] = useSearchParams();
  const isIntegrated = useIsIntegrated();
  // TODO: (fromView) Needed to know where the user came from (viewAKU or from Online), to know how to handle back button, should be removed whenever online is discontinued
  const fromView = searchParams?.get('fromView');

  const handleBack = () => {
    if (isIntegrated) {
      if (fromView) {
        navigate(-1);
      } else {
        dispatch(actions.general.GoToView(navigate, { isIntegrated: true, postMessageOptions: { target: 'VIEW_PROFILE', userId } }));
      }
    } else {
      navigate(`/users/${userId}/aku`);
    }
  };

  return (
    <>
      <PageHeader title={t('profile.aku.editTitle')} onBack={handleBack} />
      <AkuTable editable />
      {!isMember && (
        <Typography.H3 color="error-enhanced" textAlign="end">
          {t('profile.aku.nonMemberCantEdit')}
        </Typography.H3>
      )}
    </>
  );
};
