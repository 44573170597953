import { Box, Typography } from 'components/basic-components';
import type { FieldAttributes } from 'formik';
import { Field } from 'formik';
import styled from 'styled-components';
import type {
  BackgroundProps,
  BorderProps,
  BorderWidthProps,
  ColorProps,
  DisplayProps,
  FlexProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
} from 'styled-system';
import { background, border, borderWidth, color, display, flex, flexbox, layout, space } from 'styled-system';
import type { ThemeSchema } from 'theme';

export const hiddenCheckBoxStyling = `
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  ${background}
  ${border}
  ${borderWidth}
  ${color}
  ${display}
  ${flex}
  ${flexbox}
  ${layout}
  ${space}
`;

export type HiddenCheckboxProps = FieldAttributes<any>;

export type CheckboxFieldProps = {
  label: string;
  secondLine?: string;
  name: string;
  value: boolean;
} & HiddenCheckboxProps;

export const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  visibility: hidden;
  display: block;
  margin: auto;
`;

export const CheckboxContainer = styled.div`
  vertical-align: middle;
  margin-right: 10px;
`;

const HiddenCheckbox = styled<CheckboxFieldProps>(Field)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
  })
  .attrs({ type: 'checkbox' })`${hiddenCheckBoxStyling}`;

export type StyledCheckboxProps = {
  checked?: boolean;
} & BackgroundProps &
  BorderProps<ThemeSchema> &
  BorderWidthProps &
  ColorProps<ThemeSchema> &
  DisplayProps &
  FlexProps &
  FlexboxProps &
  LayoutProps<ThemeSchema> &
  SpaceProps<ThemeSchema>;

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  width: 28px;
  height: 28px;
  border-radius: 2px;
  transition: all 150ms;
  border: 1px solid ${(props) => props.theme.colors['main']};
  background-color: ${(props) => props.theme.colors.light};
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${(props) => props.theme.colors['complementary-color-1']};
  }

  ${HiddenCheckbox}:checked + & {
    background-color: ${(props) => props.theme.colors.main};
    border-color: ${(props) => props.theme.colors.main};
  }

  ${HiddenCheckbox}:checked + & ${CheckboxIcon} {
    visibility: visible;
  }

  ${HiddenCheckbox}:disabled + & {
    border-color: ${(props) => props.theme.colors['neutral-color-1']};
    background-color: ${(props) => props.theme.colors['main-enhanced']};
  }
`;

export const Checkbox = ({ label, secondLine, ...rest }: CheckboxFieldProps) => (
  <>
    <Box flexDirection="row" alignItems="center" alignContent="center" justifyContent="start">
      <Typography.Label alignSelf="center" display="contents">
        <CheckboxContainer>
          <HiddenCheckbox {...rest} />
          <StyledCheckbox>
            <CheckboxIcon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </CheckboxIcon>
          </StyledCheckbox>
        </CheckboxContainer>
        {label}
        {secondLine && (
          <>
            <br></br>
            {secondLine}
          </>
        )}
      </Typography.Label>
    </Box>
  </>
);
