import { useConfirmApplicationMutation } from 'api/applications/applications';
import { useGetApplication } from 'api/applications/hooks/useGetApplication';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box, Button, Typography } from 'components/basic-components';
import { Checkbox } from 'components/form-components';
import { ContentLoader, Section } from 'components/misc';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { printLocaleString } from 'utilities/printLocaleString';
import { useIsIntegrated } from 'utilities/useIsIntegrated';
import * as Yup from 'yup';
import { ControlItem, StyledUl } from './Application';

export const LicenseApplication = () => {
  const { t } = useTranslations();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { application, findValueFromField, isLoading } = useGetApplication(parseInt(applicationId ?? ''));
  const [confirmApplication, { isSuccess, isLoading: isConfirmApplicationLoading, error }] = useConfirmApplicationMutation();
  const navigate = useNavigate();
  const isIntegrated = useIsIntegrated();
  const { activeUserId, loggedInUserId } = useGetActiveUserId();
  const isMember = application?.companyIsMember ?? findValueFromField('recruitment') === 'yes';

  const handleBack = () => {
    if (isIntegrated) {
      navigate(-1);
    } else {
      navigate(`/users/${activeUserId}/applications`);
    }
  };

  const handleSubmit = () => {
    if (!application) {
      return;
    }

    confirmApplication({ applicationId: application?.applicationId });
  };

  useEffect(() => {
    if (error) {
      console.log(error);
      publish(CustomEventName.API_ERROR, { message: '400' });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      handleBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{
        confirm: Boolean(application?.confirmDate),
        suitability: true,
        employment: true,
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        confirm: Yup.boolean().required('profile.applications.confirmRequired'),
      })}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <Section title={t('profile.applications.control')}>
            <ContentLoader status={isLoading ? 'loading' : 'idle'} template="applications">
              <Box padding="base-tight" gap="base-tight">
                <ControlItem
                  isTrue={isMember}
                  text={
                    isMember
                      ? t('profile.applications.userBelongsToMemberCompany')
                      : t('profile.applications.userDoesNotBelongToMemberCompany')
                  }
                />
                {findValueFromField('d_license') && <ControlItem isTrue text={t('profile.applications.userHasRestingExperienceLicense')} />}
                {findValueFromField('has_passed_admission_test') && (
                  <ControlItem
                    isTrue={Boolean(findValueFromField('has_passed_admission_test'))}
                    text={t('profile.applications.userHasAdmissionTest')}
                  />
                )}
                {application?.viewInfo?.validLicenseTests && application.viewInfo.validLicenseTests.length > 0 ? (
                  <>
                    <ControlItem
                      isTrue={application?.viewInfo?.validLicenseTests?.length > 0}
                      text={t('profile.applications.userValidTests')}
                    />
                    {application?.viewInfo?.validLicenseTests?.length > 0 && (
                      <StyledUl>
                        {application.viewInfo.validLicenseTests.map((validLicenseTest) => (
                          <li key={validLicenseTest.id}>
                            <Typography.Paragraph display="inline">
                              <>
                                {validLicenseTest.testResource} {new Date(validLicenseTest.testDate).toLocaleDateString()}
                              </>
                            </Typography.Paragraph>
                          </li>
                        ))}
                      </StyledUl>
                    )}
                  </>
                ) : (
                  ''
                )}
                {findValueFromField('has_licensetest_exemption') && (
                  <ControlItem
                    isTrue={Boolean(findValueFromField('has_licensetest_exemption'))}
                    text={t('profile.applications.hasLicenseTestExemption')}
                  />
                )}

                {findValueFromField('knowledge_updates') && (
                  <ControlItem
                    isTrue={Boolean(findValueFromField('knowledge_updates'))}
                    text={
                      findValueFromField('knowledge_updates')
                        ? t('profile.applications.userHasAKU', { year: findValueFromField('knowledge_updates') })
                        : t('profile.applications.useHasNoAKU')
                    }
                  />
                )}

                {application?.viewInfo?.hasNoLicenseCheck && (
                  <ControlItem
                    isTrue={Boolean(application?.viewInfo?.hasNoLicenseCheck)}
                    text={t('profile.applications.userHasNoActiveLicense')}
                  />
                )}

                {application?.viewInfo?.checkErrors.map((error) => (
                  <ControlItem key={error} isTrue={false} text={t(`profile.applications.${error}`)} />
                ))}
              </Box>
            </ContentLoader>
          </Section>
          <Section title={t('profile.applications.status')}>
            <Box padding="base-tight" gap="base-tight">
              {application?.createDate && (
                <Typography.Paragraph>
                  {printLocaleString(application.createDate)}{' '}
                  {t('profile.applications.applicationCreated', { name: application.createdBy })}
                </Typography.Paragraph>
              )}
              {!application?.confirmDate && (
                <Typography.Paragraph color="error">{t('profile.applications.waitingForUserConfirm')}</Typography.Paragraph>
              )}
              {application?.confirmDate && (
                <Typography.Paragraph>
                  {printLocaleString(application.confirmDate)} {t('profile.applications.applicationConfirmed')}
                </Typography.Paragraph>
              )}
              {!application?.approveDate && !application?.rejectDate && (
                <Typography.Paragraph color="error">{t('profile.applications.applicationPending')}</Typography.Paragraph>
              )}
              {application?.approveDate && (
                <Typography.Paragraph>
                  {printLocaleString(application.approveDate)} {t('profile.applications.applicationApproved')}
                </Typography.Paragraph>
              )}
              {application?.rejectDate && (
                <Typography.Paragraph>
                  {printLocaleString(application.rejectDate)} {t('profile.applications.applicationRejected')}
                </Typography.Paragraph>
              )}
            </Box>
          </Section>
          {application?.viewInfo?.checkErrors.length === 0 && (
            <>
              <Section title={t('profile.applications.employment')}>
                <Box padding="base-tight" flexDirection="row" gap="base-tight">
                  <Box width="24px">
                    <Checkbox disabled name="employment" checked={true} height="15px" />
                  </Box>
                  <Typography.Paragraph alignSelf="center">{t('profile.applications.employmentDescription')}</Typography.Paragraph>
                </Box>
              </Section>
              <Section title={t('profile.applications.suitability')}>
                <Box padding="base-tight" flexDirection="row" gap="base-tight">
                  <Box width="24px">
                    <Checkbox disabled name="suitability" checked={true} />
                  </Box>
                  <Typography.Paragraph alignSelf="center">{t('profile.applications.suitabilityDescription')}</Typography.Paragraph>
                </Box>
              </Section>
              {(application?.confirmDate || loggedInUserId === activeUserId) && (
                <Section title={t('profile.applications.signature')}>
                  <Box padding="base-tight">
                    <Box flexDirection="row" gap="base-tight">
                      <Box width="24px">
                        <Checkbox
                          disabled={application?.confirmDate}
                          name="confirm"
                          checked={Boolean(application?.confirmDate) || values.confirm}
                        />
                      </Box>
                      <Typography.Paragraph alignSelf="center">{t('profile.applications.signatureDescription.0')}</Typography.Paragraph>
                    </Box>
                    <StyledUl>
                      <Typography.Paragraph>
                        <li>{t('profile.applications.signatureDescription.1')}</li>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <li>{t('profile.applications.signatureDescription.2')}</li>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <li>{t('profile.applications.signatureDescription.3')}</li>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <li>{t('profile.applications.signatureDescription.4')}</li>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <li>
                          {t('profile.applications.signatureDescription.5')}
                          <Typography.Anchor href="https://www.swedsec.se/personuppgifter" target="_blank">
                            www.swedsec.se/personuppgifter
                          </Typography.Anchor>
                        </li>
                      </Typography.Paragraph>
                    </StyledUl>
                  </Box>
                </Section>
              )}
              {!application?.confirmDate && loggedInUserId === activeUserId && (
                <Box paddingY="loose">
                  <Button
                    width="50%"
                    maxWidth="200px"
                    justifyContent="center"
                    alignSelf="flex-end"
                    disabled={!values.confirm || isConfirmApplicationLoading}
                    type="submit"
                  >
                    {t('profile.applications.confirm')}
                  </Button>
                </Box>
              )}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
