import type { Meta } from 'api/meta/meta';
import { useGetMetaQuery } from 'api/meta/meta';
import { useEffect, useState } from 'react';

export const useGetMeta = () => {
  const [hasNewVersion, setHasNewVersion] = useState(false);
  const { data } = useGetMetaQuery(undefined, { pollingInterval: 60000 });
  const [meta, setMeta] = useState<Meta>();

  useEffect(() => {
    if (data) {
      if (!meta) {
        setMeta(data);
      } else if (meta.hash !== data.hash) {
        setHasNewVersion(true);
      }
    }
  }, [data, meta]);

  return {
    meta,
    latestMeta: data?.hash,
    hasNewVersion,
  };
};
