import { Box, Typography } from 'components/basic-components';
import type { FieldAttributes } from 'formik';
import { Field, useFormikContext } from 'formik';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import type {
  BackgroundProps,
  BorderProps,
  BorderWidthProps,
  ColorProps,
  DisplayProps,
  FlexProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
} from 'styled-system';
import { background, border, borderWidth, color, display, flex, flexbox, layout, space } from 'styled-system';
import type { ThemeSchema } from 'theme';
import { theme } from 'theme';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

export type HiddenRadioProps = FieldAttributes<any>;

const HiddenRadio = styled<HiddenRadioProps>(Field)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
  })
  .attrs({ type: 'radio' })`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  ${background}
  ${border}
  ${borderWidth}
  ${color}
  ${display}
  ${flex}
  ${flexbox}
  ${layout}
  ${space}
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  visibility: hidden;
  display: block;
  margin: auto;
`;

type StyledRadioProps = {
  selected?: boolean;
} & BackgroundProps &
  BorderProps<ThemeSchema> &
  BorderWidthProps &
  ColorProps<ThemeSchema> &
  DisplayProps &
  FlexProps &
  FlexboxProps &
  LayoutProps<ThemeSchema> &
  SpaceProps<ThemeSchema>;

const StyledRadio = styled.div<StyledRadioProps>`
  //display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  transition: all 150ms;
  border: 1px solid ${(props) => props.theme.colors['main']};
  background-color: ${theme.colors.light};
  ${HiddenRadio}:focus + & {
    box-shadow: 0 0 0 3px ${(props) => props.theme.colors['complementary-color-1']};
  }

  ${HiddenRadio}:checked + & {
    background-color: ${(props) => props.theme.colors.main};
    border-color: ${(props) => props.theme.colors.main};
  }

  ${HiddenRadio}:checked + & ${Icon} {
    visibility: visible;
  }

  ${HiddenRadio}:disabled + & {
    border-color: ${(props) => props.theme.colors['neutral-color-1']};
    background-color: ${(props) => props.theme.colors['main-enhanced']};
  }
`;

const RadioContainer = styled.div`
  vertical-align: middle;
  margin-right: 10px;
`;

type RadioFieldProps = {
  label: string | ReactNode;
  secondLine?: string;
  name: string;
  value: any;
} & HiddenRadioProps;

export const RadioButton = ({ label, secondLine, name, value, ...rest }: RadioFieldProps) => {
  const { errors } = useFormikContext<{ [key: string]: string }>();
  const error = errors[name];

  return (
    <>
      <Box flexDirection="row" alignItems="center" alignContent="center" justifyContent="start">
        <Typography.Label alignSelf="center" display="contents">
          <RadioContainer>
            <HiddenRadio name={name} value={value} {...rest} />
            <StyledRadio>
              <Icon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
              </Icon>
            </StyledRadio>
          </RadioContainer>
          {label}
          {secondLine && (
            <>
              <br></br>
              {secondLine}
            </>
          )}
        </Typography.Label>
      </Box>
      {error && <ErrorMessage error={error} />}
    </>
  );
};
