import { createSlice } from '@reduxjs/toolkit';
import { reducers } from './reducers';
import { initialState } from './state';

export const slice = createSlice({
  name: 'reservation',
  initialState,
  reducers,
});

export const actions = { ...slice.actions };
export const reducer = slice.reducer;
