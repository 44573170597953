import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import { theme } from 'theme';

const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 90%;
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
    text-align: center;
    justify-content: center;
    padding: 0;
    white-space: nowrap;
  }
  .Toastify__toast-body > div:last-child {
    flex: 0;
  }
  .Toastify__toast--error {
    background-color: ${theme.colors.error};
  }
  .Toastify__toast--warning {
    background-color: ${theme.colors.warning};
  }
  .Toastify__toast--success {
    background-color: ${theme.colors.success};
  }
  .Toastify__close-button > svg {
    height: 100%;
  }
  .Toastify__close-button {
  }
`;

const Notifier = () => (
  <StyledToastContainer position="bottom-center" transition={Slide} hideProgressBar={true} draggable={false} pauseOnFocusLoss={false} />
);

export default Notifier;
