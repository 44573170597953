import type { TimeSlot } from 'api/educateIT/educateIT';
import type { Occasion } from 'api/exams/exams';
import { Box, Typography } from 'components/basic-components';
import { ContentLoader, Icon } from 'components/misc';
import { CircleAlert } from 'lucide-react';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { errorHandler } from 'utilities/errorHandling';
import FailedToLoad from './FailedToLoad';
import OccasionOverview from './OccasionOverview';
import { useGetOccasions } from './hooks/useGetOccasions';

type OccasionsListProps = {
  onSelect: (occasion: Occasion | TimeSlot) => Promise<void>;
};

export const OccasionsList = ({ onSelect }: OccasionsListProps) => {
  const { t } = useTranslations();
  const licensingTest = useAppSelector((state) => state.reservation.licensingExam);
  const selectedOccasion = useAppSelector((state) => state.reservation.bookingDetails.selectedOccasion);
  const filter = useAppSelector((state) => state.reservation.bookingDetails.filter);
  const { occasions, isLoading, isError, error } = useGetOccasions(filter);
  const status = isLoading ? 'loading' : isError ? 'error' : 'idle';

  const isSelected = (occasion: Occasion | TimeSlot) => {
    if (occasion && selectedOccasion && 'id' in occasion && 'id' in selectedOccasion) {
      return occasion.id === selectedOccasion?.id;
    }

    return occasion.locationId === selectedOccasion?.locationId && occasion.startTimeUtc === selectedOccasion?.startTimeUtc;
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <ContentLoader
      status={status}
      template="occasions"
      errorComponent={<FailedToLoad errorMessage={t('booking.booking.failedToLoadOcc')} />}
    >
      <Box flex={1} overflowY="scroll" paddingRight="tight">
        {occasions.length === 0 ? (
          <Box height="100%" justifyContent="center" alignItems="center" padding="base-tight">
            <Icon color="complementary-color-2">
              <CircleAlert color={theme.colors['complementary-color-2']} />
            </Icon>
            <Typography.Paragraph textAlign="center" color="complementary-color-2" marginTop="base-tight">
              {t('booking.booking.noOccasions')}
            </Typography.Paragraph>
          </Box>
        ) : (
          occasions.map((occasion, index) => {
            return (
              <OccasionOverview
                key={index}
                date={occasion.startTimeUtc}
                occasion={occasion}
                timeLength={licensingTest?.examSpecification?.duration_minutes ?? 0}
                onSelect={onSelect}
                selected={isSelected(occasion)}
              />
            );
          })
        )}
      </Box>
    </ContentLoader>
  );
};
