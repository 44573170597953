import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetHistoryQuery } from '../history';

export const useGetHistory = () => {
  const { activeUserId } = useGetActiveUserId();
  const { data: history, ...rest } = useGetHistoryQuery({ userId: activeUserId }, { skip: !activeUserId });

  return {
    history: (history ?? []).toSorted((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
    ...rest,
  };
};
