import type { CSSProp } from 'styled-components';
import styled from 'styled-components';
import { breakpoints } from 'theme';

export const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Side nav is hidden on mobile */
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    'header'
    'main';
  /* Non-mobile styles, 750px breakpoint */
  @media only screen and (min-device-width: ${breakpoints.sm}) {
    grid-template-columns: auto 1fr; /* Show the side nav for non-mobile screens */
    grid-template-areas:
      'sidenav header'
      'sidenav main'
      'sidenav footer';
  }

  @media only screen and (max-device-width: ${breakpoints.md}) {
    > div,
    > main {
      max-width: 100%;
      overflow: auto;
      word-wrap: break-word;
    }

    height: 100%;
  }

  > * {
    max-width: 100%;
    word-wrap: break-word;
  }
`;

export const Grid = styled.div``;
export const Row = styled.div`
  display: flex;
`;

const media = {
  xs: (styles: CSSProp) => `
  @media only screen and (max-device-width:${breakpoints.sm}) {
    ${styles}
  }
  `,
};

export type ColProps = { size: number; collapse: keyof typeof media };
export const Col = styled.div<ColProps>`
  flex: ${(props: ColProps) => props.size};
  ${(props) => props.collapse && media[props.collapse](`dispaly: none`)}
`;
