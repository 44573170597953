import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box, Button } from 'components/basic-components';
import { Icon, Select, Sidebar } from 'components/misc';
import {
  BookType,
  CircleArrowLeft,
  CircleArrowRight,
  CircleHelp,
  Database,
  Factory,
  House,
  KeyRound,
  LogOut,
  Medal,
  User,
  Users,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { isAdminSelector, isComplianceSelector, isSwedsecAdminSelector, isSysAdminSelector } from 'store/selectors/identity';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import styled from 'styled-components';
import { useTranslations } from 'translations/hooks/useTranslations';
import { featureFlags } from 'utilities/featureFlags';
import type { LinkGroup } from './MenuGroup';
import { MenuGroup } from './MenuGroup';
import { MenuGroupHeader } from './MenuGroupHeader';
import { MenuLink } from './MenuLink';

const StyledSelect = styled(Select)`
  margin-left: 25px;
`;

export const Menu = () => {
  const dispatch = useAppDispatch();
  const isSidebarToggled = useAppSelector((state) => state.general.isSidebarToggled);
  const isSidebarPinned = useAppSelector((state) => state.general.isSidebarPinned);
  const { t, language, changeLanguage } = useTranslations();
  const { loggedInUserId, activeUserId } = useGetActiveUserId();
  const userAgent = useAppSelector((state) => state.general.userAgent);
  const isSysAdmin = useAppSelector(isSysAdminSelector);
  const isCompliance = useAppSelector(isComplianceSelector);
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const navigate = useNavigate();
  const hasRoles = (getLoggedInUser()?.roles?.filter((role) => role !== 'ROLE_USER')?.length ?? 0) > 0;

  const handleLogout = async () => {
    dispatch(actions.identity.logout(navigate));
  };

  const handleToggleMenu = async (toggleState: boolean) => {
    await dispatch(actions.general['toggle-sidebar'](toggleState));
  };

  const handlePinMenu = async () => {
    await dispatch(actions.general['pin-sidebar']());
    handleToggleMenu(!isSidebarPinned);
  };

  const handleSelectLanguage = (event: React.ChangeEvent<any>) => {
    changeLanguage(event.target.value);
  };

  const profileGroup: LinkGroup = {
    name: t('general.menu.profile'),
    icon: <User />,
    links: [
      {
        identifier: 'userInfo',
        name: t('general.menu.userInformation'),
        to: `/users/${activeUserId}/profile`,
        enable: true,
      },
      {
        identifier: 'history',
        name: t('general.menu.history'),
        to: `/users/${activeUserId}/history`,
        enable: true,
      },
      {
        identifier: 'costs',
        name: t('general.menu.costs'),
        to: `/users/${activeUserId}/costs`,
        enable: true,
      },
      {
        identifier: 'aku',
        name: t('general.menu.aku'),
        to: `/users/${activeUserId}/aku`,
        enable: true,
      },
      {
        identifier: 'violations',
        name: t('general.menu.violations'),
        to: `/users/${activeUserId}/violations`,
        enable: (isAdmin && loggedInUserId === activeUserId) || !isAdmin || isSwedsecAdmin,
      },
      {
        identifier: 'applications',
        name: t('general.menu.applications'),
        to: `/users/${activeUserId}/applications`,
        enable: featureFlags.applications,
      },
    ],
    enable: true,
  };

  const testGroup: LinkGroup = {
    name: t('general.menu.test'),
    icon: <Medal />,
    links: [
      {
        identifier: 'booking',
        name: t('general.menu.bookTest'),
        to: `/users/${activeUserId}/tests/book`,
        enable: true,
      },
      {
        identifier: 'myBookedTests',
        name: t('general.menu.myBookedTests'),
        to: `/users/${activeUserId}/tests/booked`,
        enable: true,
      },
      {
        identifier: 'startTest',
        name: t('general.menu.startTest'),
        to: `/users/${activeUserId}/tests/booked`,
        enable: true,
      },
      {
        identifier: 'results',
        name: t('general.menu.testResults&Certificates'),
        to: `/users/${activeUserId}/tests/results`,
        enable: true,
      },
    ],
    enable: true,
  };

  const adminGroups: Array<LinkGroup> = [
    {
      name: t('general.menu.oauth2'),
      icon: <KeyRound />,
      links: [
        {
          identifier: 'endpoints',
          name: t('general.menu.endpoints'),
          to: `/oauth2/endpoints`,
          enable: isSysAdmin,
        },
        {
          identifier: 'client',
          name: t('general.menu.client'),
          to: `/oauth2/clients`,
          enable: isCompliance,
        },
        {
          identifier: 'matchers',
          name: t('general.menu.matchers'),
          to: `/oauth2/matchers`,
          enable: isSysAdmin,
        },
        {
          identifier: 'method',
          name: t('general.menu.method'),
          to: `/oauth2/methods`,
          enable: isSysAdmin,
        },
        {
          identifier: 'role',
          name: t('general.menu.role'),
          to: `/oauth2/roles`,
          enable: isSysAdmin,
        },
      ],
      enable: isSysAdmin || Boolean(isCompliance),
    },
    {
      name: t('general.menu.translations'),
      icon: <BookType />,
      links: [
        {
          identifier: 'translations',
          name: t('general.menu.translations'),
          to: `/translations`,
          enable: isSysAdmin,
        },
      ],
      enable: isSysAdmin,
    },
    {
      name: t('general.menu.systemMessages'),
      icon: <BookType />,
      links: [
        {
          identifier: 'systemMessages',
          name: t('general.menu.systemMessages'),
          to: `/system-messages`,
          enable: isSysAdmin,
        },
      ],
      enable: isSysAdmin,
    },
    {
      name: t('general.menu.users'),
      icon: <Users />,
      links: [
        {
          identifier: 'users',
          name: t('general.menu.users'),
          to: '/users',
          enable: isSysAdmin || isSwedsecAdmin || isAdmin,
        },
      ],
      enable: isSysAdmin || isSwedsecAdmin || isAdmin,
    },
    {
      name: t('general.menu.companies'),
      icon: <Factory />,
      links: [
        {
          identifier: 'companies',
          name: t('general.menu.companies'),
          to: '/companies',
          enable: featureFlags.useCompanies && (isSysAdmin || isSwedsecAdmin || isAdmin),
        },
      ],
      enable: featureFlags.useCompanies && (isSysAdmin || isSwedsecAdmin || isAdmin),
    },
  ];

  return (
    <Sidebar isToggled={isSidebarToggled} isPinned={isSidebarPinned}>
      <Box>
        {userAgent === 'desktop' ? (
          <Box flexDirection="row" flex="1" marginY="base">
            <Button variant="transparent" onClick={handlePinMenu}>
              <Icon size="24">{isSidebarPinned ? <CircleArrowLeft /> : <CircleArrowRight />}</Icon>
            </Button>
          </Box>
        ) : (
          <Box flexDirection="row" flex="" marginY="base" />
        )}
        <Box backgroundColor="main" paddingY="tight" paddingX="loose">
          <Box alignItems="flex-start" overflowX="hidden" height="100%">
            <MenuLink to={`/users/${activeUserId}`} name={t('general.menu.start')} icon={<House />} />
            <MenuGroup group={profileGroup} />
            <MenuGroup group={testGroup} />
            {adminGroups.some((group) => group.enable && group.links.some((link) => link.enable)) && (
              <>
                <MenuGroupHeader icon={<Database />} name={t('general.menu.sysAdmin')} />
                <Box alignItems="flex-start">
                  {adminGroups.map((group, index) => (
                    <MenuGroup key={`menu_admin_group_${index}`} group={group} />
                  ))}
                </Box>
              </>
            )}
            <MenuGroupHeader icon={<Database />} name={t('general.menu.language')} />
            <StyledSelect variant="language" defaultValue={language} onChange={handleSelectLanguage}>
              <option value="sv">{t('general.languages.sv')}</option>
              <option value="en">{t('general.languages.en')}</option>
              {isSysAdmin && <option value="cimode">{t('general.languages.cimode')}</option>}
            </StyledSelect>
            {hasRoles && (
              <Box marginTop="loose" width="initial">
                <MenuLink to="/help" name={t('general.menu.helpAndManual')} icon={<CircleHelp />} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {isSidebarToggled ? (
        <Button variant="logout" marginX="loose" marginY="base" onClick={handleLogout}>
          <Icon paddingLeft="tight">
            <LogOut style={{ rotate: '180deg' }} />
          </Icon>
          {t('general.menu.logOut')}
        </Button>
      ) : (
        <Button variant="logout-mini" marginX="loose" marginY="base" onClick={handleLogout}>
          <Icon paddingLeft="none">
            <LogOut style={{ rotate: '180deg' }} />
          </Icon>
        </Button>
      )}
    </Sidebar>
  );
};
