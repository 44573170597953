import styled from 'styled-components';

const StyledDatePickerWrapper = styled.div`
  max-width: fit-content;
  .react-datepicker-wrapper {
    display: none;
  }

  .react-datepicker__input-container {
    display: none;
  }

  .react-datepicker__tab-loop {
    display: inline;
  }

  .react-datepicker__tab-loop__start {
    display: none;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding: 0px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    background-color: transparent;
    border: 0px;
  }

  .react-datepicker-popper {
    display: contents !important;
  }

  .react-datepicker__day {
    border: black 1px solid;
    border-radius: 2px;
    background: white;
    border-color: ${(props) => props.theme.colors.main};
    color: ${(props) => props.theme.colors.main};
    &:hover {
      border-radius: 2px;
      background-color: ${(props) => props.theme.colors['neutral-color-2']};
      border-radius: 2px;
    }
    &:focus {
      background-color: ${(props) => props.theme.colors['main']};
    }
  }

  .react-datepicker__day--selected {
    background-color: ${(props) => props.theme.colors['main']};
    color: ${(props) => props.theme.colors['neutral-color-2']};
    &:hover {
      background-color: ${(props) => props.theme.colors['main']};
    }
  }

  .react-datepicker__day--keyboard-selected {
  }

  .react-datepicker__header {
    border-bottom: 0px;
    background-color: transparent;
  }

  .react-datepicker__month {
    margin: 0px;
  }

  .react-datepicker__day-names {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    color: ${(props) => props.theme.colors.main};
  }

  .react-datepicker__day--keyboard-selected {
    color: black;
  }

  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.colors['neutral-color-1']};
    border: 1px solid transparent;

    &:focus {
      background-color: ${(props) => props.theme.colors['neutral-color-2']};
    }
  }

  .react-datepicker__day--outside-month {
    opacity: 0;
  }

  .disabled-date {
    pointer-events: none;
  }

  .react-datepicker__current-month {
    color: ${(props) => props.theme.colors.main};
  }
`;

export default StyledDatePickerWrapper;
