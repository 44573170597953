/**
 * Is this used?
 * @param featureList List of features separated by ','
 * @param defaultValue Value to be assigned to the flags in flag list
 * @returns Feature flag object containing the feature flag name as key and
 * boolean value.
 */
export const getFeatureFlagsFromFeatureList = (featureList: string, defaultValue: boolean) =>
  featureList?.split(',').reduce(
    (flags, flagName) => {
      const flag = flagName.trim();
      if (flag) {
        flags[flag] = defaultValue;
      }
      return flags;
    },
    {} as Record<string, boolean>
  );

export const featureFlags = {
  applications: process.env.REACT_APP_FEATURE_APPLICATIONS === 'true',
  sideMenu: process.env.REACT_APP_FEATURE_SIDE_MENU === 'true',
  systemMessage: process.env.REACT_APP_FEATURE_SYSTEM_MESSAGE === 'true',
  usePhoenix: process.env.REACT_APP_FEATURE_USE_PHOENIX === 'true',
  usePhoenixForNormalUsers: process.env.REACT_APP_FEATURE_USE_PHOENIX_FOR_NORMAL_USERS === 'true',
  useCompanies: process.env.REACT_APP_FEATURE_USE_COMPANIES === 'true',
  useConnectCompanies: process.env.REACT_APP_FEATURE_USE_CONNECT_COMPANIES === 'true',
};
