import { createSlice } from '@reduxjs/toolkit';

import * as asyncActions from './asyncActions';
import { reducers } from './reducers';
import { initialState } from './state';

export const slice = createSlice({
  name: 'general',
  initialState,
  reducers,
});

export const actions = { ...slice.actions, ...asyncActions };
export const reducer = slice.reducer;
