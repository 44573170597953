import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Notifier, Suspenser } from 'components/misc';
import Fallback from 'components/misc/ErrorBoundary/Fallback';
import { jwtDecode } from 'jwt-decode';
import { Unauthorized } from 'pages/ErrorPages/Unauthorized';
import { GuardLayout, PrivateLayout } from 'pages/Layout';
import { Help } from 'pages/private/Help';
import { CompanySearch } from 'pages/private/admin/companies/CompanySearch';
import { SystemMessages } from 'pages/private/admin/systemMessages/SystemMessages';
import { Translations } from 'pages/private/admin/translations/Translations';
import { UserSearch } from 'pages/private/admin/users/UserSearch';
import { Edit } from 'pages/private/aku/Edit';
import { View as Aku } from 'pages/private/aku/View';
import { Application } from 'pages/private/applications/Application';
import { View as Applications } from 'pages/private/applications/View';
import { View as Costs } from 'pages/private/costs/View';
import { View as History } from 'pages/private/history/View';
import { ChangePassword } from 'pages/private/profile';
import { View as Profile } from 'pages/private/profile/View';
import { Book, BookedTests, Confirm, TestResults } from 'pages/private/tests';
import { View as Violations } from 'pages/private/violations/View';
import Logout from 'pages/public/onboarding/Logout';
import { Suspense, lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import type { DecodedJWT } from 'store/stores/identity/tokenStorage';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName } from 'utilities';
import { featureFlags } from 'utilities/featureFlags';
import { OAuth2Routes } from './routes/oauth2Routes';
import { PasswordRoutes } from './routes/passwordRoutes';

const Home = lazy(() => import('pages/private/home/Home'));
const WIP = lazy(() => import('pages/WIP/WIP'));
const NotFound = lazy(() => import('pages/ErrorPages/NotFound'));
const InternalServer = lazy(() => import('pages/ErrorPages/InternalServerError'));
const BadRequest = lazy(() => import('pages/ErrorPages/BadRequest'));
const Login = lazy(() => import('pages/public/onboarding/Login'));
const Online = lazy(() => import('pages/public/onboarding/Online'));

export const RootRouter = () => {
  const { t } = useTranslations();
  const location = useLocation();
  const username = useAppSelector((state) => state.identity.profile.username);
  Sentry.setUser({ username: username ?? '' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(selectors.identity.isAdminSelector);
  const isSysAdmin = useAppSelector(selectors.identity.isSysAdminSelector);
  const isSwedsecAdmin = useAppSelector(selectors.identity.isSwedsecAdminSelector);
  const isCompliance = useAppSelector(selectors.identity.isComplianceSelector);
  const { activeUserId, loggedInUserId } = useGetActiveUserId();

  useEffect(() => {
    const loggedInUser = getLoggedInUser();
    if (loggedInUser) {
      const userId = loggedInUser.userId;
      const companyId = loggedInUser.companyId;
      const decoded = jwtDecode<DecodedJWT>(loggedInUser.userToken);
      dispatch(
        actions.identity['identity/fill']({
          profile: { ...decoded, username: decoded.user_name, userId, company: companyId },
        })
      );
    }

    const screenWidth = window.screen.width;
    if (screenWidth <= 480) {
      dispatch(actions.general['set-user-agent']('mobile'));
    } else if (screenWidth <= 768) {
      dispatch(actions.general['set-user-agent']('tablet'));
    } else if (screenWidth > 768) {
      dispatch(actions.general['set-user-agent']('desktop'));
    }
  }, [dispatch]);

  useEffect(() => {
    const handleSuccessEvent = (e: CustomEventInit) => {
      toast.success(t(`general.apiResponses.${e.detail.message}`), {
        theme: 'colored',
      });
    };

    const handelAPIErrorEvent = (e: CustomEventInit) => {
      toast.error(t(`general.apiResponses.${e.detail.message}`, { defaultValue: t(`general.apiResponses.${e.detail.code}`) }), {
        theme: 'colored',
      });
    };

    const handleAuthErrorEvent = (e: CustomEventInit) => {
      const loggedInUser = getLoggedInUser();

      if (!loggedInUser) {
        return;
      }

      if (e.detail) {
        toast.error(t(`general.apiResponses.${e.detail.message}`), {
          theme: 'colored',
        });
      } else {
        toast.error(t('general.apiResponses.SESSION_EXPIRED'), {
          theme: 'colored',
        });
      }

      dispatch(actions.identity.logout(navigate, location.pathname));
    };

    const handleNetworkErrorEvent = () => {
      toast.error(t('general.apiResponses.NETWORK_ERROR'), {
        theme: 'colored',
      });
    };

    const handleResetPassEvent = () => {
      toast.error(t('general.apiResponses.412'), {
        theme: 'colored',
        onClick: () => navigate('/password/forgot-password'),
        autoClose: false,
      });
    };

    addEventListener(CustomEventName.SUCCESS, handleSuccessEvent);
    addEventListener(CustomEventName.API_ERROR, handelAPIErrorEvent);
    addEventListener(CustomEventName.AUTH_ERROR, handleAuthErrorEvent);
    addEventListener(CustomEventName.NETWORK_ERROR, handleNetworkErrorEvent);
    addEventListener(CustomEventName.RESET_PASS, handleResetPassEvent);
    return () => {
      removeEventListener(CustomEventName.SUCCESS, handleSuccessEvent);
      removeEventListener(CustomEventName.API_ERROR, handelAPIErrorEvent);
      removeEventListener(CustomEventName.AUTH_ERROR, handleAuthErrorEvent);
      removeEventListener(CustomEventName.NETWORK_ERROR, handleNetworkErrorEvent);
      removeEventListener(CustomEventName.RESET_PASS, handleResetPassEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    addEventListener('UNKNOWN', () => {
      toast.error('Unknown error!', {
        theme: 'colored',
      });
    });

    addEventListener('MISSING_FIELD', () => {
      toast.error(t('general.apiResponses.missing_field'), { theme: 'colored' });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleErrorReset = () => {
    dispatch(actions.identity.logout(navigate));
  };

  return (
    <Suspense fallback={<Suspenser />}>
      <SentryReact.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('ErrorBoundary', 'RootRouter');
        }}
        fallback={({ resetError }) => (
          <Fallback
            reset={() => {
              handleErrorReset();
              resetError();
            }}
          />
        )}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/online" element={<Online />} />
          <Route path="password/*" element={<PasswordRoutes />} />

          <Route path="/" element={<GuardLayout />}>
            <Route path="/" element={<PrivateLayout />}>
              {(isSysAdmin || isAdmin || isSwedsecAdmin) && <Route path="/users" element={<UserSearch />} />}
              {featureFlags.useCompanies && (isSysAdmin || isAdmin || isSwedsecAdmin) && (
                <Route path="/companies" element={<CompanySearch />} />
              )}
              {isSysAdmin && <Route path="/translations" element={<Translations />} />}
              {isSysAdmin && <Route path="/system-messages" element={<SystemMessages />} />}
              {(isSysAdmin || isCompliance) && <Route path="oauth2/*" element={<OAuth2Routes />} />}
              <Route path="/help" element={<Help />} />
              <Route path="/users/:userId">
                <Route path="" element={<Home />} />
                {featureFlags.applications && (
                  <Route path="applications/*">
                    {activeUserId === loggedInUserId || isAdmin || isSwedsecAdmin ? (
                      <>
                        <Route path="" element={<Applications />} />
                        <Route path=":applicationId" element={<Application />} />
                      </>
                    ) : (
                      <Route path="*" element={<Unauthorized />} />
                    )}
                  </Route>
                )}
                <Route path="profile">
                  <Route path="" element={<Profile />} />
                  <Route path="change-password" element={<ChangePassword />} />
                </Route>
                <Route path="tests">
                  <Route path="booked" element={<BookedTests />} />
                  <Route path="results" element={<TestResults />} />
                  <Route path="book" element={<Book />} />
                  <Route path="confirm" element={<Confirm />} />
                </Route>
                <Route path="aku">
                  <Route path="" element={<Aku />} />
                  {isAdmin && <Route path="edit" element={<Edit />} />}
                </Route>
                <Route path="costs" element={<Costs />} />
                <Route path="history" element={<History />} />
                <Route path="violations" element={activeUserId === loggedInUserId || isSwedsecAdmin ? <Violations /> : <Unauthorized />} />
              </Route>
              <Route path="/wip" element={<WIP />} />
              <Route path="/" element={<Navigate to={`/users/${activeUserId}`} />} />
            </Route>
          </Route>

          <Route path="*" element={<NotFound />} />
          <Route path="/400" element={<BadRequest />} />
          <Route path="/500" element={<InternalServer />} />
        </Routes>
      </SentryReact.ErrorBoundary>
      <Notifier />
    </Suspense>
  );
};
