import * as Sentry from '@sentry/react';
import { images } from 'assets';
import { ReactComponent as Logo } from 'assets/SwedsecMark.svg';
import { Box } from 'components/basic-components';
import { IconButton } from 'components/basic-components/IconButton/IconButton';
import { Header, Main, StyledLayout } from 'components/layout-components';
import { Menu } from 'components/layout-components/Menu/Menu';
import Fallback from 'components/misc/ErrorBoundary/Fallback';
import { Menu as Hamburger } from 'lucide-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import styled from 'styled-components';
import { theme } from 'theme';
import { featureFlags } from 'utilities/featureFlags';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

const StyledIconButton = styled(IconButton)`
  width: 48px;
  height: 48px;
`;

export default function PrivateLayout() {
  const dispatch = useAppDispatch();
  const isSidebarToggled = useAppSelector((state) => state.general.isSidebarToggled);
  const isSidebarPinned = useAppSelector((state) => state.general.isSidebarPinned);
  const isIntegrated = useIsIntegrated();
  const loggedInUserID = getLoggedInUser()?.userId;
  const userAgent = useAppSelector((state) => state.general.userAgent);
  const navigate = useNavigate();

  const handleToggleMenu = async () => {
    await dispatch(actions.general['toggle-sidebar'](!isSidebarToggled));
  };

  function handleErrorBoundaryReset() {
    dispatch(
      actions.general.GoToView(navigate, {
        route: '/',
        options: {
          replace: true,
        },
        postMessageOptions: {
          userId: loggedInUserID,
          target: 'VIEW_PROFILE',
        },
      })
    );
  }

  return (
    <StyledLayout>
      {!isIntegrated && featureFlags.sideMenu && (
        <>
          <Header>
            {userAgent !== 'desktop' && (
              <Box width="50px" minHeight="37px" backgroundSize="contain" marginLeft="base">
                <Logo fill="#fff" width="100%" height="50" />
              </Box>
            )}
            <StyledIconButton
              onClick={handleToggleMenu}
              size="48px"
              icon={<Hamburger color={theme.colors.light} width="48px" height="48px" />}
            />

            {userAgent === 'desktop' && (
              <Box
                backgroundImage={images.welcome['logo-typ']}
                width="120px"
                minHeight="50px"
                backgroundSize="contain"
                marginRight="base"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
              />
            )}
          </Header>
          <Menu />
        </>
      )}
      <Main isPinned={isSidebarPinned && featureFlags.sideMenu}>
        <Sentry.ErrorBoundary
          beforeCapture={(scope) => scope.setTags({ Location: 'PrivateLayout' })}
          fallback={({ resetError }) => (
            <Fallback
              reset={() => {
                handleErrorBoundaryReset();
                resetError();
              }}
            />
          )}
        >
          <Outlet />
        </Sentry.ErrorBoundary>
      </Main>
    </StyledLayout>
  );
}
