import type React from 'react';
import { TableRow } from './Body';

type ExpandedTableRowProps = {
  children: React.ReactNode;
  noLine?: boolean;
};

export const ExpandedTableRow = ({ children, noLine }: ExpandedTableRowProps) => (
  <TableRow variant={noLine ? 'noLine' : 'default'} backgroundColor="neutral-color-2">
    {children}
  </TableRow>
);
