import { Box, Typography } from 'components/basic-components';
import styled from 'styled-components';
import type {
  ColorProps,
  FlexDirectionProps,
  FlexProps,
  JustifyContentProps,
  LayoutProps,
  SpaceProps,
  TextStyleProps,
  VariantArgs,
} from 'styled-system';
import { color, flex, flexDirection, justifyContent, layout, space, textStyle } from 'styled-system';
import type { ThemeSchema, ThemeSelectVariants } from 'theme';
import { theme } from 'theme';

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement>;

type Props = {
  label?: string;
  variant?: ThemeSelectVariants;
  hover?: ThemeSelectVariants;
  value?: string | number;
} & SelectProps &
  ColorProps<ThemeSchema> &
  FlexProps &
  FlexDirectionProps &
  LayoutProps<ThemeSchema> &
  SpaceProps<ThemeSchema> &
  TextStyleProps<ThemeSchema> &
  VariantArgs<ThemeSchema> &
  JustifyContentProps;

const StyledSelect = styled.select<Props>`
  border: 0;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  white-space: nowrap;
  ${color}
  ${flex}
  ${flexDirection}
  ${layout}
  ${space}
  ${textStyle}
  ${justifyContent}
  font-weight: 400;
  ${(props) => theme.selects[props.variant ?? 'default']}
  &:hover {
    cursor: pointer;
  }
`;

StyledSelect.defaultProps = {
  variant: 'default',
  backgroundColor: 'main',
  textStyle: 'body',
  overflowX: 'hidden',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Select = ({ label, children, size, ...props }: Props) => (
  <>
    {label ? (
      <Box marginBottom="tight" display="flex" width="100%" maxWidth="292px">
        <Typography.Label width="100%" position="relative">
          {label}
          <br />
          <StyledSelect {...props}>{children}</StyledSelect>
        </Typography.Label>
      </Box>
    ) : (
      <StyledSelect {...props}>{children}</StyledSelect>
    )}
  </>
);
