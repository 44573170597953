import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { actions, useAppDispatch } from 'store';
import { featureFlags } from 'utilities/featureFlags';

export const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams?.get('redirectTo');

  useEffect(() => {
    localStorage.removeItem('USING_BANK_ID');
    const usePhoenix = sessionStorage.getItem('USE_PHOENIX') !== 'true';
    if (redirectTo === 'phoenix' || featureFlags.usePhoenix || usePhoenix) {
      dispatch(actions.identity.logout(navigate));
    } else {
      window.location.href = process.env.REACT_APP_ASTRUM_HOST ?? '';
      dispatch(actions.identity.logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Logout;
