import styled from 'styled-components';
import type { BorderProps, ColorProps, JustifySelfProps, LayoutProps, SpaceProps } from 'styled-system';
import { border, color, justifySelf, layout, space } from 'styled-system';
import type { ThemeSchema } from 'theme';

type IconStylerProps = {
  size?: string;
} & BorderProps<ThemeSchema> &
  ColorProps<ThemeSchema> &
  LayoutProps &
  SpaceProps<ThemeSchema> &
  JustifySelfProps;

const IconStyler = styled.span<IconStylerProps>`
  justify-content: center;
  margin: 0 5px 0 0; // to get a straight alignment with text
  display: inline-flex;
  ${border}
  ${color}
  ${layout}
  ${space}
  ${justifySelf}
  & svg {
    width: ${(props) => props.size || `30`}px;
    height: ${(props) => props.size || `30`}px;
    vertical-align: top;
  }
`;

export default IconStyler;
