import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetApplicationsQuery } from '../applications';

export const useGetApplications = () => {
  const { activeUserId } = useGetActiveUserId();
  const { data: applications, ...rest } = useGetApplicationsQuery({ userId: activeUserId }, { skip: !activeUserId });

  return {
    applications: applications ?? [],
    ...rest,
  };
};
