import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useGetUser } from 'api/users/hooks/useGetUser';
import type { Email } from 'api/users/users';
import {
  useAddUserEmailMutation,
  useGetUserEmailsQuery,
  useRemoveUserEmailMutation,
  useSetDefaultUserEmailMutation,
  useUpdateUserEmailMutation,
} from 'api/users/users';
import { Box, Typography } from 'components/basic-components';
import { TextField } from 'components/form-components';
import { Modal, Section } from 'components/misc';
import { Form, Formik } from 'formik';
import { Circle, CircleCheck, CirclePlus, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import { isSwedsecAdminSelector } from 'store/selectors/identity';
import { getLoggedInUser } from 'store/stores/identity/tokenStorage';
import styled from 'styled-components';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { updateEmailsValidationSchema } from 'utilities/validationSchema';

const EmailWrapper = styled(Box)`
  & > :not(:last-child) {
    border-bottom: 1px solid ${theme.colors['neutral-color-1']};
  }
`;

export const UserProfileEmails = () => {
  const [updateUserEmail, { isSuccess: isUpdateUserEmailSuccess, isLoading: isUpdateUserEmailLoading, isError: isUpdateUserEmailError }] =
    useUpdateUserEmailMutation();
  const [
    addUserEmail,
    { isSuccess: isAddUserEmailSuccess, isLoading: isAddUserEmailLoading, isError: isAddUserEmailError, error: addUserEmailError },
  ] = useAddUserEmailMutation();
  const [
    setDefaultUserEmail,
    { isSuccess: isSetDefaultUserEmailSuccess, isLoading: isSetDefaultUserEmailLoading, isError: isSetDefaultUserEmailError },
  ] = useSetDefaultUserEmailMutation();
  const [removeUserEmail, { isSuccess: isRemoveUserEmailSuccess, isLoading: isRemoveUserEmailLoading, isError: isRemoveUserEmailError }] =
    useRemoveUserEmailMutation();
  const { t } = useTranslations();
  const { activeUserId: userId } = useGetUser();
  const { data: emails } = useGetUserEmailsQuery({ userId });
  const [selectedEmail, setSelectedEmail] = useState<Email>();
  const [isCreatingEmail, setIsCreatingEmail] = useState(false);
  const [newDefaultEmail, setNewDefaultEmail] = useState<Email>();
  const [emailToDelete, setEmailToDelete] = useState<Email>();
  const isSwedsecAdmin = useAppSelector(isSwedsecAdminSelector);
  const isUser = userId === getLoggedInUser()?.userId;
  const isLoading = isUpdateUserEmailLoading || isAddUserEmailLoading || isSetDefaultUserEmailLoading || isRemoveUserEmailLoading;

  useEffect(() => {
    if (isUpdateUserEmailError) {
      publish(CustomEventName.API_ERROR, {
        message: 'UPDATE_USER_EMAIL_ERROR',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUserEmailError]);

  useEffect(() => {
    if (isAddUserEmailError) {
      if ((addUserEmailError as FetchBaseQueryError).status === 409) {
        publish(CustomEventName.API_ERROR, {
          message: 'EMAIL_ALREADY_EXISTS',
        });
      } else {
        publish(CustomEventName.API_ERROR, {
          message: 'ADD_USER_EMAIL_ERROR',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddUserEmailError]);

  useEffect(() => {
    if (isSetDefaultUserEmailError) {
      publish(CustomEventName.API_ERROR, {
        message: 'UPDATE_USER_EMAIL_ERROR',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetDefaultUserEmailError]);

  useEffect(() => {
    if (isRemoveUserEmailError) {
      publish(CustomEventName.API_ERROR, {
        message: 'UPDATE_USER_EMAIL_ERROR',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemoveUserEmailError]);

  useEffect(() => {
    if (isUpdateUserEmailSuccess || isAddUserEmailSuccess || isRemoveUserEmailSuccess || isSetDefaultUserEmailSuccess) {
      resetState();
    }
  }, [isUpdateUserEmailSuccess, isAddUserEmailSuccess, isRemoveUserEmailSuccess, isSetDefaultUserEmailSuccess]);

  const handleSubmit = ({ email }: { email: string }) => {
    if (!userId) {
      return;
    }

    if (selectedEmail) {
      updateUserEmail({ emailId: selectedEmail.id, email, userId });
    } else {
      addUserEmail({ email, userId });
    }
  };

  const resetState = () => {
    setIsCreatingEmail(false);
    setSelectedEmail(undefined);
    setNewDefaultEmail(undefined);
    setEmailToDelete(undefined);
  };

  if (!emails) {
    return null;
  }

  return (
    <>
      <Modal
        headerText={t('profile.profile.changeDefaultEmail')}
        hide={resetState}
        isShown={Boolean(newDefaultEmail)}
        message={t('profile.profile.changeDefaultEmailDescription', { email: newDefaultEmail?.address })}
        onCancel={resetState}
        onConfirm={() => setDefaultUserEmail({ emailId: newDefaultEmail?.id ?? 0, userId })}
        confirmText={t('general.general.yes')}
        cancelText={t('general.general.no')}
        invertButtons
      />
      <Modal
        headerText={t('profile.profile.deleteEmail')}
        hide={resetState}
        isShown={Boolean(emailToDelete)}
        message={t('profile.profile.deleteEmailDescription', { email: emailToDelete?.address })}
        onCancel={resetState}
        onConfirm={() => removeUserEmail({ email: emailToDelete?.address ?? '', userId })}
        confirmText={t('general.general.yes')}
        cancelText={t('general.general.no')}
        invertButtons
      />
      <Section
        title={t('profile.profile.emails')}
        clickableIcon={(isUser || isSwedsecAdmin) && <CirclePlus onClick={() => setIsCreatingEmail(true)} />}
      >
        <EmailWrapper>
          {emails.map((email) => (
            <Box key={email.id} padding="base-tight" flexDirection="row">
              <Box onClick={() => setSelectedEmail(email)} $clickable>
                <Typography.Paragraph>{email.address}</Typography.Paragraph>
              </Box>
              <Box flexDirection="row" gap="loose" width="fit-content">
                {email.default ? (
                  <>
                    <Typography.Paragraph marginRight="extra-loose" width="100px">
                      {t('profile.profile.defaultEmail')}
                    </Typography.Paragraph>
                    <Box width="24px" justifyContent="center">
                      <CircleCheck />
                    </Box>
                    <Box minWidth="24px" width="24px" />
                  </>
                ) : (
                  <>
                    <Box justifyContent="center" width="24px" onClick={() => setNewDefaultEmail(email)} $clickable>
                      <Circle />
                    </Box>
                    <Box justifyContent="center" width="24px" onClick={() => setEmailToDelete(email)} $clickable>
                      {!email.default && <Trash2 color={theme.colors['error-enhanced']} />}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ))}
        </EmailWrapper>
      </Section>
      <Formik
        initialValues={{
          email: selectedEmail?.address ?? '',
          confirmEmail: '',
        }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={updateEmailsValidationSchema({
          required: 'profile.profile.errors.required',
          emailMustMatch: 'onboarding.resetPassword.errors.emailMustMatch',
          mustBeValidEmail: 'profile.profile.errors.mustBeValidEmail',
        })}
      >
        {({ errors, values, initialValues, submitForm, resetForm }) => (
          <Form>
            <Modal
              draggable
              isShown={Boolean(selectedEmail) || isCreatingEmail}
              headerText={selectedEmail ? t('profile.profile.updateEmail') : t('profile.profile.createEmail')}
              hide={() => {
                resetForm();
                resetState();
              }}
              onCancel={() => {
                resetForm();
                resetState();
              }}
              onConfirm={submitForm}
              disableConfirm={
                !values.email ||
                isLoading ||
                JSON.stringify(values) === JSON.stringify(initialValues) ||
                Object.values(errors).some((error) => error)
              }
              confirmText={t('profile.profile.save')}
              cancelText={t('profile.profile.cancel')}
            >
              <TextField
                withErrorPlaceholder
                width="100%"
                minWidth="300px"
                name="email"
                required
                placeholder={t('profile.profile.email')}
                label={t('profile.profile.email')}
              />
              <TextField
                withErrorPlaceholder
                width="100%"
                minWidth="300px"
                name="confirmEmail"
                required
                placeholder={t('profile.profile.confirmEmail')}
                label={t('profile.profile.confirmEmail')}
              />
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
};
