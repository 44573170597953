import * as Yup from 'yup';

const PASSWORD_MINIMUM_LENGTH = process.env.REACT_APP_USER_PASSWORD_MINIMUM_LENGTH;
const PASSWORD_MAXIMUM_LENGTH = process.env.REACT_APP_USER_PASSWORD_MAXIMUM_LENGTH;
const PASSWORD_REQUIRE_UPPERCASE = process.env.REACT_APP_USER_PASSWORD_REQUIRE_UPPERCASE;
const PASSWORD_REQUIRE_LOWERCASE = process.env.REACT_APP_USER_PASSWORD_REQUIRE_LOWERCASE;
const PASSWORD_REQUIRE_NUMERICAL = process.env.REACT_APP_USER_PASSWORD_REQUIRE_NUMERICAL;
const PASSWORD_REQUIRE_SPECIAL = process.env.REACT_APP_USER_PASSWORD_REQUIRE_SPECIAL;

const isUpperCaseRequired = PASSWORD_REQUIRE_UPPERCASE?.toLowerCase() === 'true';
const isLowerCaseRequired = PASSWORD_REQUIRE_LOWERCASE?.toLowerCase() === 'true';
const isNumericalRequired = PASSWORD_REQUIRE_NUMERICAL?.toLowerCase() === 'true';
const isSpecialRequired = PASSWORD_REQUIRE_SPECIAL?.toLowerCase() === 'true';

type ResetPasswordValidationSchemaErrorMessage = {
  required: string;
  tooShort: string;
  tooLong: string;
  smallLetter: string;
  capitalLetter: string;
  passwordMustMatch: string;
  number: string;
  specialCharacter: string;
  whiteSpaces: string;
};

const passwordValidation = (errorMessages: ResetPasswordValidationSchemaErrorMessage) =>
  Yup.string()
    .required(errorMessages.required)
    .min(Number(PASSWORD_MINIMUM_LENGTH), errorMessages.tooShort)
    .max(Number(PASSWORD_MAXIMUM_LENGTH), errorMessages.tooLong)
    .matches(isLowerCaseRequired ? /^(?=.*[abcdefghijklmnopqrstuvwxyzåäö])/ : /./, errorMessages.smallLetter)
    .matches(isUpperCaseRequired ? /^(?=.*[ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ])/ : /./, errorMessages.capitalLetter)
    .matches(isNumericalRequired ? /^(?=.*[0123456789])/ : /./, errorMessages.number)
    .matches(
      isSpecialRequired
        ? /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬\u00ad®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾])/
        : /./,
      errorMessages.specialCharacter
    )
    .matches(/^\S*$/, errorMessages.whiteSpaces);

export const resetPasswordValidationSchema = (errorMessages: ResetPasswordValidationSchemaErrorMessage) => {
  const passwordResetValidationSchema = Yup.object({
    newPassword: passwordValidation(errorMessages),
    confirmNewPassword: Yup.string()
      .required(errorMessages.required)
      .oneOf([Yup.ref('newPassword'), ''], errorMessages.passwordMustMatch),
  });
  return passwordResetValidationSchema;
};

type ChangePasswordValidationErrorMessages = {
  required: string;
  tooShort: string;
  tooLong: string;
  smallLetter: string;
  capitalLetter: string;
  passwordMustMatch: string;
  number: string;
  specialCharacter: string;
  whiteSpaces: string;
};

export const changePasswordValidationSchema = (errorMessages: ChangePasswordValidationErrorMessages) => {
  const changePasswordValidationSchema = Yup.object({
    oldPassword: Yup.string().required(errorMessages.required),
    newPassword: passwordValidation(errorMessages),
    confirmPassword: Yup.string()
      .required(errorMessages.required)
      .oneOf([Yup.ref('newPassword'), ''], errorMessages.passwordMustMatch),
  });
  return changePasswordValidationSchema;
};

type UpdateUserValidationErrorMessages = {
  required: string;
  notAPhoneNumber: string;
};

export const updateUserValidationSchema = (errorMessages: UpdateUserValidationErrorMessages) => {
  return Yup.object({
    username: Yup.string().required(errorMessages.required),
    phoneNumber: Yup.string()
      .matches(/(^\+?[0-9()\- ]{7,25}$)/, errorMessages.notAPhoneNumber)
      .nullable(),
    mobileNumber: Yup.string()
      .matches(/(^\+?[0-9()\- ]{7,25}$)/, errorMessages.notAPhoneNumber)
      .nullable(),
  });
};

type UpdateEmailsValidationErrorMessages = {
  required: string;
  emailMustMatch: string;
  mustBeValidEmail: string;
};

export const updateEmailsValidationSchema = (errorMessages: UpdateEmailsValidationErrorMessages) => {
  return Yup.object({
    email: Yup.string().email(errorMessages.mustBeValidEmail).required(),
    confirmEmail: Yup.string().when('email', (email, schema, context) => {
      if (context?.parent?.originalEmail?.trim().toLowerCase() !== email[0]?.trim().toLowerCase()) {
        return schema.oneOf([Yup.ref('email')], errorMessages.emailMustMatch).required(errorMessages.required);
      }

      return schema.notRequired();
    }),
  });
};

type UserConnectCompanyValidationErrorMessages = {
  required: string;
};

export const userConnectCompanyValidationSchema = (errorMessages: UserConnectCompanyValidationErrorMessages) => {
  return Yup.object({
    companyId: Yup.string().required(errorMessages.required),
    administratorId: Yup.string().required(errorMessages.required),
    role: Yup.string().required(errorMessages.required),
  });
};
