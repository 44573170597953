import { educateITBaseApi } from 'api/base';

type GetTimeSlotsRequest = {
  timeLength: number;
};

type Schedule = {
  date: string;
  timezone: string;
  timeSlots: Array<TimeSlot>;
};

export type TimeSlot = {
  startTimeUtc: string;
  availableSeats: number;
  locationId: number;
  locationType: number;
  jointZoomMeeting: boolean;
  startTime: string;
};

type GetTimeSlotsApiResponse = {
  totalSchedules: number;
  totalTimeSlots: number;
  schedules: Array<Schedule>;
};

export const educateITApi = educateITBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimeSlots: builder.query<Array<TimeSlot>, GetTimeSlotsRequest>({
      query: ({ timeLength }) => {
        return {
          url: `Bookings/AvailableTimeSlots?timeLength=${timeLength}`,
        };
      },
      transformResponse: (data: GetTimeSlotsApiResponse) => {
        return data.schedules.reduce((timeSlots, schedule) => [...timeSlots, ...schedule.timeSlots], [] as Array<TimeSlot>);
      },
      providesTags: ['TimeSlots'],
    }),
  }),
});

export const { useGetTimeSlotsQuery } = educateITApi;
