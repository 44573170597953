import { Typography } from 'components/basic-components';
import { Icon } from 'components/misc';
import type { ReactElement } from 'react';

type MenuGroupHeaderProps = {
  icon?: ReactElement;
  name: string;
};

export const MenuGroupHeader = ({ icon, name }: MenuGroupHeaderProps) => (
  <Typography.Paragraph color="neutral-color-2" textStyle="body-semi-bold" marginTop="loose">
    {icon && <Icon size="20">{icon}</Icon>}
    <span style={{ position: 'relative', display: 'inline-block', bottom: '3px' }}>{name}</span>
  </Typography.Paragraph>
);
