/* eslint-disable prettier/prettier */
import type React from 'react';
import styled from 'styled-components';
import type {
  AlignSelfProps,
  BorderRadiusProps,
  ButtonStyleProps,
  ColorProps,
  FlexDirectionProps,
  FlexProps,
  JustifyContentProps,
  LayoutProps,
  SpaceProps,
  TextStyleProps,
  VariantArgs,
} from 'styled-system';
import {
  alignSelf,
  borderRadius,
  buttonStyle,
  color,
  flex,
  flexDirection,
  justifyContent,
  layout,
  space,
  textStyle,
  variant,
} from 'styled-system';
import type { ThemeSchema } from 'theme';

export type ButtonProps = React.HTMLAttributes<HTMLButtonElement>;

type Props = {
  variant?: 'primary' | 'secondary' | 'transparent' | 'logout' | 'logout-mini';
} & AlignSelfProps &
  ButtonProps &
  BorderRadiusProps<ThemeSchema> &
  ButtonStyleProps<ThemeSchema> &
  ColorProps<ThemeSchema> &
  FlexProps &
  FlexDirectionProps &
  LayoutProps<ThemeSchema> &
  SpaceProps<ThemeSchema> &
  TextStyleProps<ThemeSchema> &
  VariantArgs<ThemeSchema> &
  JustifyContentProps;

export const Button = styled.button<Props>`
  appearance: 'none';
  border: 0;
  webkitappearance: 'none';
  mozappearance: 'none';
  white-space: nowrap;
  cursor: pointer;
  min-width: 40px;
  ${alignSelf}
  ${borderRadius}
  ${buttonStyle}
  ${color}
  ${flex}
  ${flexDirection}
  ${layout}
  ${space}
  ${textStyle}
  ${justifyContent}
  font-weight: 400;
  align-items: center;
  ${variant({
    variants: {
      primary: {
        color: (theme: ThemeSchema) => theme.colors['neutral-color-2'],
        backgroundColor: (theme: ThemeSchema) => theme.colors['main'],
        '&:hover': {
          backgroundColor: (theme: ThemeSchema) => theme.colors['main-enhanced'],
          borderColor: (theme: ThemeSchema) => theme.colors['main-enhanced'],
        },
        '&:disabled': {
          color: (theme: ThemeSchema) => theme.colors['neutral-color-2'],
          backgroundColor: (theme: ThemeSchema) => theme.colors['neutral-color-1'],
          borderColor: (theme: ThemeSchema) => theme.colors['neutral-color-1'],
        },
        borderColor: (theme: ThemeSchema) => theme.colors['main'],
        borderWidth: (theme: ThemeSchema) => theme.borderWidths['thin'],
        borderStyle: 'solid',
        borderRadius: (theme: ThemeSchema) => theme.radii['s'],
        padding: (theme: ThemeSchema) => `${theme.space['base-tight']} ${theme.space['loose']}`,
        justifyContent: 'center',
      },
      secondary: {
        color: (theme: ThemeSchema) => theme.colors['main'],
        backgroundColor: (theme: ThemeSchema) => theme.colors['light'],
        '&:hover': {
          backgroundColor: (theme: ThemeSchema) => theme.colors['main-enhanced'],
          borderColor: (theme: ThemeSchema) => theme.colors['main-enhanced'],
          color: (theme: ThemeSchema) => theme.colors['neutral-color-2'],
        },
        '&:disabled': {
          color: (theme: ThemeSchema) => theme.colors['neutral-color-1'],
          backgroundColor: (theme: ThemeSchema) => theme.colors['neutral-color-2'],
          borderColor: (theme: ThemeSchema) => theme.colors['neutral-color-1'],
        },
        borderColor: (theme: ThemeSchema) => theme.colors['main'],
        borderWidth: (theme: ThemeSchema) => theme.borderWidths['thin'],
        borderStyle: 'solid',
        borderRadius: (theme: ThemeSchema) => theme.radii.s,
        padding: (theme: ThemeSchema) => `${theme.space['base-tight']} ${theme.space['loose']}`,
        justifyContent: 'center',
      },
      transparent: {
        backgroundColor: 'transparent',
        color: (theme: ThemeSchema) => theme.colors['neutral-color-2'],
        padding: (theme: ThemeSchema) => theme.space['none'],
      },
      logout: {
        color: (theme: ThemeSchema) => theme.colors['neutral-color-2'],
        backgroundColor: (theme: ThemeSchema) => theme.colors['main'],
        '&:hover': {
          color: (theme: ThemeSchema) => theme.colors['info'],
        },
        borderRadius: (theme: ThemeSchema) => theme.radii['s'],
        padding: (theme: ThemeSchema) => theme.space['base'],
        space: (theme: ThemeSchema) => theme.space['base'],
      },
      'logout-mini': {
        color: (theme: ThemeSchema) => theme.colors['neutral-color-2'],
        backgroundColor: (theme: ThemeSchema) => theme.colors['main'],
        borderRadius: (theme: ThemeSchema) => theme.radii['s'],
        'padding-inline': (theme: ThemeSchema) => theme.space['tight'],
        'padding-block': (theme: ThemeSchema) => theme.space['base'],
        space: (theme: ThemeSchema) => theme.space['base'],
      },
    },
  })}
  color: ${(props) => props.color && props.theme.colors[props.color]}
`;

Button.defaultProps = {
  variant: 'primary',
  borderRadius: 's',
  textStyle: 'body',
  overflowX: 'hidden',
  minWidth: 'min-content',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
};
