import styled from 'styled-components';
import { theme } from 'theme';

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 700;
  transform: translate(-50%, -50%);
  user-select: none;
  max-height: 100vh;
`;

export const DraggableWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 700;
  user-select: none;
  max-height: 100vh;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0b1c2970;
  z-index: 500;
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: #eceae6;
  position: relative;
  margin: auto;
  border-radius: 8px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  background-color: ${({ theme }) => theme.colors.main};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  user-select: none;
`;

export const HeaderText = styled.div`
  color: #fff;
  align-self: center;
  color: lightgray;
`;

export const Footer = styled.div`
  border-radius: 0 0 8px 8px;
  background-color: #eceae6;
  display: flex;
  padding: 1rem;
`;

export const CloseButton = styled.button`
  display: flex;
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  margin-left: 0.5rem;
  background: none;
  color: #fff;
  &:hover {
    cursor: pointer;
    background-color: ${theme.colors['main-enhanced']};
  }
`;

export const Content = styled.div`
  padding: 1rem;
  max-height: 32rem;
  overflow-x: hidden;
  overflow-y: auto;
`;
