import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { useGetCostsQuery } from '../cost';

export const useGetCosts = () => {
  const { activeUserId } = useGetActiveUserId();
  const { data: costs, ...rest } = useGetCostsQuery({ userId: activeUserId }, { skip: !activeUserId });

  return {
    costs: (costs ?? []).toSorted((a, b) => (a.invoiceDate > b.invoiceDate ? -1 : 1)),
    ...rest,
  };
};
