import type * as CSS from 'csstype';
import type React from 'react';
import styled from 'styled-components';
import type {
  BackgroundImageProps,
  BackgroundProps,
  BorderColorProps,
  BorderProps,
  BorderStyleProps,
  BorderWidthProps,
  ColorProps,
  FlexProps,
  FlexboxProps,
  GridAreaProps,
  LayoutProps,
  PositionProps,
  ResponsiveValue,
  SpaceProps,
} from 'styled-system';
import {
  background,
  backgroundImage,
  border,
  borderColor,
  borderStyle,
  borderWidth,
  color,
  flex,
  flexbox,
  gridArea,
  layout,
  position,
  space,
  style,
  variant,
} from 'styled-system';
import type { ThemeSchema, ThemeSpace } from '../../../theme';
import { theme } from '../../../theme';

const contain = style({
  cssProperty: 'contain',
  prop: 'contain',
});

const gap = style({
  cssProperty: 'gap',
  prop: 'gap',
  key: 'space',
});

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  contain?: ResponsiveValue<CSS.Property.Contain>;
  gap?: ThemeSpace | ResponsiveValue<CSS.Property.Contain>;
  isOwner?: boolean;
  visibility?: CSS.Property.Visibility;
  $wrap?: boolean;
  $clickable?: boolean;
}
export type Props = {
  variant?: 'transparent' | 'section' | 'card' | 'sectionWrapper';
} & BoxProps &
  BackgroundProps &
  BackgroundImageProps &
  BorderProps<ThemeSchema> &
  BorderColorProps<ThemeSchema> &
  BorderStyleProps &
  BorderWidthProps &
  ColorProps<ThemeSchema> &
  FlexProps<ThemeSchema> &
  FlexboxProps<ThemeSchema> &
  GridAreaProps &
  LayoutProps<ThemeSchema> &
  PositionProps &
  SpaceProps<ThemeSchema>;

export const Box: React.ComponentType<Props> = styled.div<Props>`
  webkitappearance: 'none';
  mozappearance: 'none';
  visibility: ${(props) => props.visibility};
  &::-webkit-scrollbar {
    background: transparent;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.main};
    border-radius: 3px;
  }
  ${background}
  ${backgroundImage} 
  ${border} 
  ${borderColor}
  ${borderStyle}
  ${borderWidth} 
  ${color} 
  ${contain}
  ${gap}
  ${flex} 
  ${flexbox} 
  ${gridArea}
  ${layout}
  ${position} 
  ${space}
  ${variant({
    variants: {
      transparent: {
        backgroundColor: 'transparent',
      },
      section: {
        backgroundColor: theme.colors['neutral-color-2'],
        padding: theme.space.base,
        borderRadius: theme.radii.m,
        width: ['100%', '100%', '33.625em', '45.625em'],
      },
      sectionWrapper: {
        width: ['100%', '100%', '33.625em', '45.625em'],
        marginTop: theme.space.loose,
      },
      card: {
        backgroundColor: theme.colors['neutral-color-2'],
        borderRadius: theme.radii.s,
      },
    },
  })}
  white-space: ${({ $wrap }) => ($wrap === true ? 'pre-wrap' : 'nowrap')};
  ${({ $clickable }) => ($clickable === true ? 'cursor: pointer' : '')};
`;

Box.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export default Box;
