import type { GetCompaniesRequest } from '../companies';
import { useGetCompaniesQuery } from '../companies';

export const useGetCompanies = (props: GetCompaniesRequest) => {
  const { data, isError, ...rest } = useGetCompaniesQuery(props);

  return {
    companies: isError ? [] : data?.companies ?? [],
    total: isError ? 0 : data?.total ?? 0,
    count: isError ? 0 : data?.count ?? 0,
    isError,
    ...rest,
  };
};
