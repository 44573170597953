import type { Row } from '@tanstack/react-table';
import { ChevronDown, ChevronUp } from 'lucide-react';
import styled from 'styled-components';
import { IconButton } from '../IconButton/IconButton';

const StyledIconButton = styled(IconButton)`
  width: 100%;
  justify-content: end;
`;

type ExpandButtonProps<T> = {
  row: Row<T>;
};

export const ExpandButton = <T,>({ row }: ExpandButtonProps<T>) => (
  <StyledIconButton
    onClick={(e) => {
      e.stopPropagation();
      row.toggleExpanded();
    }}
    icon={row.getIsExpanded() ? <ChevronUp /> : <ChevronDown />}
  />
);
