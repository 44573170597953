import { Box, GridBox, Typography } from 'components/basic-components';
import { ContentLoader } from 'components/misc';
import type { AsyncObject } from 'store/utilities';

export type SectionWrapperProps = {
  children?: React.ReactNode;
  externalLinkLabel?: string;
  loading?: boolean;
  title?: string;
  externalLink?: string;
} & AsyncObject;

const SectionWrapper = ({ children, externalLinkLabel, externalLink, title, loading, status }: SectionWrapperProps) => {
  return (
    <>
      {title && <Typography.H2>{title}</Typography.H2>}
      <Box
        variant="section"
        flexDirection={['row', 'row', 'column', 'column']}
        alignItems={loading ? 'flex-start' : 'flex-end'}
        marginBottom="loose"
      >
        <ContentLoader template="radioButton" status={status}>
          <GridBox
            gridTemplateColumns="17.8125em auto"
            gridAutoRows="auto auto auto"
            gridGap={10}
            gridAutoFlow="column"
            marginBottom="loose"
            alignSelf="start"
          >
            {children}
          </GridBox>
          <Typography.Anchor href={externalLink || 'https://www.swedsec.se/'} target="_blank">
            {externalLinkLabel || ''}
          </Typography.Anchor>
        </ContentLoader>
      </Box>
    </>
  );
};

export default SectionWrapper;
