import type { ColumnDef, Row } from '@tanstack/react-table';
import type { Client } from 'api/oauth2/oauth2';
import { useGetClientsQuery } from 'api/oauth2/oauth2';
import { useGetUser } from 'api/users/hooks/useGetUser';
import { Typography } from 'components/basic-components';
import { TableCell } from 'components/basic-components/Table/Body';
import { ExpandButton } from 'components/basic-components/Table/ExpandButton';
import { ExpandedTableRow } from 'components/basic-components/Table/ExpandedRow';
import { Table } from 'components/basic-components/Table/Table';
import { ContentLoader } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';

type ClientsExpandedRowProps = {
  row: Row<Client>;
};

const ClientsExpandedRow = ({ row }: ClientsExpandedRowProps) => {
  const { t } = useTranslations();
  return (
    <>
      <ExpandedTableRow>
        <TableCell>
          <Typography.H4>{t('oauth2.client.accessTokenValidity')}</Typography.H4>
        </TableCell>
        <TableCell>
          <Typography.H4>{t('oauth2.client.refreshTokenValidity')}</Typography.H4>
        </TableCell>
        <TableCell>
          <Typography.H4>{t('oauth2.client.clientSecret')}</Typography.H4>
        </TableCell>
        <TableCell>
          <Typography.H4>{t('oauth2.client.webServerRedirectUri')}</Typography.H4>
        </TableCell>
        <TableCell>
          <Typography.H4>{t('oauth2.client.additionalInformation')}</Typography.H4>
        </TableCell>
      </ExpandedTableRow>
      <ExpandedTableRow>
        <TableCell>{row.original.accessTokenValidity}</TableCell>
        <TableCell>{row.original.refreshTokenValidity}</TableCell>
        <TableCell>{row.original.clientSecret}</TableCell>
        <TableCell>{row.original.webServerRedirectUri}</TableCell>
        <TableCell>{row.original.additionalInformation}</TableCell>
      </ExpandedTableRow>
    </>
  );
};

export const Clients = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { user } = useGetUser();
  const { data, isLoading, isError } = useGetClientsQuery(
    { orgNo: user?.defaultCompany?.orgNo ?? '' },
    { skip: !user?.defaultCompany?.orgNo }
  );
  const clients = data?.clientDetails ?? [];

  const columns = useMemo<ColumnDef<Client>[]>(
    () => [
      {
        accessorKey: 'clientId',
        header: () => t('oauth2.client.clientId'),
        size: undefined,
      },
      {
        accessorKey: 'resourceIds',
        header: () => t('oauth2.client.resourceIds'),
        size: undefined,
      },
      {
        accessorKey: 'scope',
        header: () => t('oauth2.client.scope'),
        size: undefined,
      },
      {
        accessorKey: 'authorizedGrantTypes',
        header: () => t('oauth2.client.authorizedGrantTypes'),
        size: undefined,
      },
      {
        accessorKey: 'expand',
        header: () => '',
        cell: ({ row }) => <ExpandButton row={row} />,
        size: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageHeader title={t('oauth2.client.title')} onBack={() => navigate('/')} />
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={clients}
          renderExpandedRow={(row) => <ClientsExpandedRow row={row} />}
          emptyText={t('oauth2.client.noResponse')}
          failedFetch={isError}
        />
      </ContentLoader>
    </>
  );
};
