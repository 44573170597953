import type { BoxProps } from 'components/basic-components/Box/Box';
import styled, { css } from 'styled-components';
import type { BackgroundProps, ColorProps } from 'styled-system';
import { background, color } from 'styled-system';
import type { ThemeSchema } from 'theme';
import { breakpoints, theme } from 'theme';

export type SidebarProps = {
  isPinned?: boolean;
  isToggled?: boolean;
} & BackgroundProps &
  ColorProps<ThemeSchema> &
  BoxProps;

const StyledSidebar = styled.div<SidebarProps>`
  grid-area: sidenav;
  padding: 50px 0 0 0;
  height: 100%;
  width: 100%;
  position: fixed !important;
  z-index: 3;
  top: 0;
  left: 0;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: ${theme.space['sidebar']};
  transform: translateX(-100%);
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.isToggled &&
    css`
      transform: translateX(0);
    `}

  overflow-x: hidden;
  /* Move everything to the right when toggled */
  div:nth-child(1) {
    justify-content: ${(props) => (props.isToggled ? 'flex-end' : 'center')};
  }
  /* Hide navlink text when collapsed */
  div:nth-child(2) > div:first-child {
    visibility: ${(props) => (props.isToggled === true ? 'visible' : 'hidden')};
  }

  /* Laptop screen */
  @media only screen and (min-device-width: ${breakpoints.md}) {
    position: relative; /* Fixed position on mobile or tablet */
    transform: translateX(0);
    width: 4.3em;
    padding: 0;
    z-index: 4;

    ${(props) =>
      props.isPinned
        ? css`
            width: 16.25em;
          `
        : css``}
  }
  ${background}
  ${color}
`;

const Sidebar = ({ isPinned, isToggled, ...rest }: SidebarProps) => {
  return <StyledSidebar backgroundColor="main" isPinned={isPinned} isToggled={isToggled} {...rest} />;
};

export default Sidebar;
