import type { Languages } from 'translations/i18next';

export interface State {
  redirectPath: {
    path: string;
    params?: { [key: string]: string };
  };
  isSidebarToggled: boolean;
  isSidebarPinned: boolean;
  isSidebarChanged: boolean;
  userAgent?: 'mobile' | 'tablet' | 'desktop';
  language: Languages;
}

export const initialState: State = {
  redirectPath: {
    path: '',
  },
  isSidebarToggled: false,
  isSidebarPinned: false,
  isSidebarChanged: false,
  userAgent: undefined,
  language: 'sv',
};
