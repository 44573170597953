import { useGetUsers } from 'api/users/hooks/useGetUsers';
import type { UserProfile } from 'api/users/users';
import { Box, Typography } from 'components/basic-components';
import { Input } from 'components/basic-components/Input/Input';
import Loader from 'components/basic-components/Loader/Loader';
import { Icon } from 'components/misc';
import { Backdrop } from 'components/misc/Modal/Modal.style';
import { useFormikContext } from 'formik';
import { X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useInView } from 'react-intersection-observer';
import type { UserRoles } from 'store/stores/identity/state';
import styled from 'styled-components';
import { theme } from 'theme';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useDebounce } from 'utilities/useDebounce';

interface UserPickerValues {
  userId: number;
  userName: string;
}

const UsersWrapper = styled(Box)`
  & > :not(:last-child) {
    border-bottom: 1px solid ${theme.colors['neutral-color-1']};
  }
`;

const StyledUserPicker = styled(Box)`
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
`;

const BoldSpan = styled('span')`
  font-weight: bold;
`;

type UserPickerProps = {
  userRole?: UserRoles;
  userCompanyId?: number;
  defaultUserId?: number;
  defaultUserName?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

type UserRowProps = {
  setSelectedAdminstrator: (administrator: SelectedAdministrator) => void;
  user: UserProfile;
  onClose: () => void;
};

type SelectedAdministrator = { id?: number; name?: string };

type UserRowFieldProps = {
  label?: string;
  value?: string;
};

const UserRowField = ({ label, value }: UserRowFieldProps) => (
  <Typography.SmallParagraph>
    <BoldSpan>{label}: </BoldSpan>
    {value}
  </Typography.SmallParagraph>
);

const UserRow = ({ setSelectedAdminstrator, user, onClose }: UserRowProps) => {
  const { t } = useTranslations();

  return (
    <Box
      padding="base-tight"
      $clickable
      onClick={() => {
        setSelectedAdminstrator({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        });
        onClose();
      }}
    >
      <Typography.Paragraph marginBottom="base">
        <BoldSpan>{user.defaultCompany?.companyName}</BoldSpan> - {user.firstName} {user.lastName}
      </Typography.Paragraph>
      <Typography.SmallParagraph>{user.socialNo}</Typography.SmallParagraph>
      <Box flexDirection={['row-reverse', 'row']} flexWrap="wrap">
        <Box width={['100%', '50%']}>
          <UserRowField
            label={t('profile.userPicker.exemption')}
            value={user.exemptions.length > 0 ? t('general.general.yes') : t('general.general.no')}
          />
          <UserRowField
            label={t('profile.userPicker.AKU', { year: user.latestAku?.year ?? '' })}
            value={user.latestAku?.completed ? t('general.general.yes') : t('general.general.no')}
          />
          <UserRowField
            label={t('profile.userPicker.activeLicense')}
            value={user.licenseStatus === 'ACTIVE' ? t('general.general.yes') : t('general.general.no')}
          />
          <UserRowField
            label={t('profile.userPicker.restingLicense')}
            value={user.licenseStatus === 'RESTING' ? t('general.general.yes') : t('general.general.no')}
          />
        </Box>
        <Box width={['100%', '50%']}>
          <UserRowField label={t('profile.userPicker.admin')} value={user.administratorName} />
          <UserRowField
            label={t('profile.userPicker.connectedCompany')}
            value={user.defaultCompany?.isMember ? t('general.general.yes') : t('general.general.no')}
          />
          <UserRowField label={t('profile.userPicker.clearing')} value={user.defaultCompany?.clearing} />
          <UserRowField label={t('profile.userPicker.department')} value={user.defaultCompany?.department} />
        </Box>
      </Box>
    </Box>
  );
};

export const UserPicker = ({ userRole, userCompanyId, defaultUserId, defaultUserName, isOpen, setIsOpen }: UserPickerProps) => {
  const { t } = useTranslations();
  const { values, setFieldValue } = useFormikContext<UserPickerValues>();
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebounce<string>(searchString ?? '', 500);
  const [page, setPage] = useState(0);
  const { ref: bottomRef, inView } = useInView();
  const [selectedAdministrator, setSelectedAdminstrator] = useState<SelectedAdministrator>({
    id: defaultUserId,
    name: defaultUserName,
  });
  const { users, isLoading, total, count } = useGetUsers({
    freeText: debouncedSearchString,
    userRole: userRole,
    companyId: userCompanyId,
    page,
  });
  const [paginatedUsers, setPaginatedUsers] = useState(users);
  const hasMore = total > count;

  useEffect(() => {
    if (values.userId !== selectedAdministrator.id) {
      setFieldValue('userId', selectedAdministrator.id);
      setFieldValue('userName', selectedAdministrator.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdministrator, setFieldValue]);

  useEffect(() => {
    if (inView && !isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, isLoading]);

  useEffect(() => {
    setPage(0);
    setPaginatedUsers([]);
  }, [searchString]);

  useEffect(() => {
    if (users && users.length > 0) {
      setPaginatedUsers([...paginatedUsers, ...users]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <>
      <Box maxWidth="24px" maxHeight="24px" alignSelf="center" $clickable>
        {isOpen &&
          createPortal(
            <>
              <Backdrop onClick={() => setIsOpen(false)} />
              <StyledUserPicker
                position="fixed"
                top="50px"
                left="50%"
                minWidth="200px"
                maxWidth="750px"
                maxHeight="80vh"
                backgroundColor="light"
                borderRadius="s"
                borderWidth="normal"
                borderColor="neutral-color-enhanced-1"
                borderStyle="solid"
                zIndex={1000}
              >
                <Box padding="base" height="100%" display="flex" flexDirection="column" overflow="hidden">
                  <Box width="100%" height="20px" flexDirection="row-reverse" marginBottom="base">
                    <Icon onClick={() => setIsOpen(false)} size="20" marginRight="none">
                      <X />
                    </Icon>
                  </Box>
                  <Input placeholder={t('profile.userPicker.searchForUser')} onChange={setSearchString} />
                  <Box flex={1} overflowY="auto">
                    <UsersWrapper>
                      {paginatedUsers.map((user) => (
                        <UserRow
                          key={`userPicker_userRow_${user.id}`}
                          user={user}
                          onClose={() => setIsOpen(false)}
                          setSelectedAdminstrator={setSelectedAdminstrator}
                        />
                      ))}
                      <span ref={bottomRef}>
                        <Box padding="extra-loose" justifyContent="center" alignItems="center">
                          {isLoading && <Loader width="64px" height="64px" />}
                          {!hasMore && users.length > 0 && (
                            <Typography.SmallParagraph color="main">{t('profile.userPicker.noMoreResults')}</Typography.SmallParagraph>
                          )}
                          {!isLoading && users.length === 0 && (
                            <Typography.SmallParagraph color="main">{t('profile.userPicker.noResults')}</Typography.SmallParagraph>
                          )}
                        </Box>
                      </span>
                    </UsersWrapper>
                  </Box>
                </Box>
              </StyledUserPicker>
            </>,
            document.body
          )}
      </Box>
    </>
  );
};
