import type { TimeSlot } from 'api/educateIT/educateIT';
import type { MonitoringType, Occasion } from 'api/exams/exams';
import { Box, GridBox, Typography } from 'components/basic-components';
import { Checkbox, RadioButton } from 'components/form-components';
import { Select } from 'components/misc';
import { parseISO } from 'date-fns';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { useTranslations } from 'translations/hooks/useTranslations';
import { errorHandler } from 'utilities/errorHandling';
import { OccasionsCalendar } from './Calendar';
import { OccasionsList } from './OccasionsList';
import { useGetArticles } from './hooks/useGetArticles';
import { useGetOccasions } from './hooks/useGetOccasions';

const LocationSelector = () => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.reservation.bookingDetails.filter);
  const { locations, error } = useGetOccasions(filter);

  const handleLocationChange = (e: React.ChangeEvent<any>) => {
    dispatch(
      actions.reservation['set-occasion-filter']({
        location: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <Field component={Select} name="location" onChange={handleLocationChange}>
      <option key="all" label={t('booking.booking.allLocations')}>
        {null}
      </option>
      {locations.map((location, index) => (
        <option key={index} value={location}>
          {location}
        </option>
      ))}
    </Field>
  );
};

const AudioCheckbox = () => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const licensingTest = useAppSelector((state) => state.reservation.licensingExam);
  const selectedSubject = useAppSelector((state) => state.reservation.bookingDetails.selectedSubject);
  const audioExamSpecification = selectedSubject?.exam_specifications.find((examSpecification) => examSpecification.type === 'Audio');
  const licensingExamSpecification = selectedSubject?.exam_specifications.find(
    (examSpecification) => examSpecification.type === 'Licensieringstest'
  );
  const isSwedsecAdmin = useAppSelector(selectors.identity.isSwedsecAdminSelector);

  const handleAudioChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;

    if (checked) {
      await dispatch(
        actions.reservation['add-license-reservation']({
          examSpecification: audioExamSpecification,
        })
      );
    } else if (checked === false) {
      await dispatch(
        actions.reservation['add-license-reservation']({
          examSpecification: licensingExamSpecification,
        })
      );
    }
  };

  return (
    audioExamSpecification &&
    isSwedsecAdmin && (
      <>
        <Typography.Label fontWeight="semi-bold">{t('booking.booking.audio')}</Typography.Label>
        <Checkbox
          name="audio"
          label={t('booking.booking.audioCheckbox')}
          onChange={handleAudioChange}
          checked={licensingTest?.examSpecification?.type === audioExamSpecification?.type}
        />
        <Typography.Label fontWeight="semi-bold">{t('booking.booking.chooseCounty')}</Typography.Label>
      </>
    )
  );
};

const ExamMonitoringTypeRadioButtons = () => {
  const { monitoringTypes, error } = useGetArticles();
  const examMonitoringType = useAppSelector((state) => state.reservation.bookingDetails.examMonitoringType);
  const dispatch = useAppDispatch();

  const handleExamMonitoringTypeChange = async (monitoringType: MonitoringType) => {
    await dispatch(
      actions.reservation['edit-reservation']({
        examMonitoringType: monitoringType,
      })
    );
    await dispatch(
      actions.reservation['add-license-reservation']({
        proctor: {
          artNo: monitoringType.art_no,
        },
      })
    );
    await dispatch(actions.reservation['remove-audio']());
    await dispatch(actions.reservation['reset-occasion-filter']());
  };

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <GridBox gridTemplateColumns="auto auto" marginBottom="base" gridGap={10}>
      {monitoringTypes.map((monitoringType, index) => (
        <RadioButton
          key={index}
          name="examMonitoringType"
          value={monitoringType.art_no}
          label={monitoringType.type}
          onChange={() => handleExamMonitoringTypeChange(monitoringType)}
          checked={monitoringType.art_no === examMonitoringType?.art_no}
        />
      ))}
    </GridBox>
  );
};

export const LicensingTest = () => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const subject = useAppSelector((state) => state.reservation.bookingDetails.selectedSubject);
  const examMonitoringType = useAppSelector((state) => state.reservation.bookingDetails.examMonitoringType);
  const hasLicensingTestReservation = useAppSelector(selectors.articles.hasLicensingTestReservation);
  const licensingTest = useAppSelector((state) => state.reservation.licensingExam);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = async (date: Date) => {
    setSelectedDate(date);
    await dispatch(
      actions.reservation['set-occasion-filter']({
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      })
    );
  };

  const onSelect = async (occasion: Occasion | TimeSlot) => {
    await dispatch(actions.reservation['set-occasion'](occasion));
    await handleDateChange(new Date(occasion.startTimeUtc));
  };

  return (
    hasLicensingTestReservation && (
      <Box alignItems="flex-start">
        <Typography.H2 alignSelf="flex-start" color="main">
          {t('booking.booking.license-test-title', { topic: subject?.title ?? '' })}
        </Typography.H2>
        <Box variant="section" flexDirection={['column', 'row', 'row', 'row']} alignItems="flex-start">
          <Box alignItems="start">
            <Typography.Label fontWeight="semi-bold">{t('booking.booking.choosePlace')}</Typography.Label>
            <ExamMonitoringTypeRadioButtons />
            <Typography.Label fontWeight="semi-bold">{t('booking.booking.chooseDate')}</Typography.Label>
            <GridBox gridAutoColumns={['auto auto', 'auto auto', 'auto auto', 'auto']} gridGap={10} maxWidth="fit-content" width="100%">
              <OccasionsCalendar
                startDate={selectedDate || parseISO(licensingTest?.proctor?.session?.startTimeUtc ?? '')}
                handleDateChange={(date: Date) => handleDateChange(date)}
                selectedDate={selectedDate}
              />
            </GridBox>
          </Box>
          <Box alignSelf="stretch" contain={['none', 'size', 'size', 'size']}>
            {examMonitoringType?.art_no === 'PE-002' && (
              <>
                <AudioCheckbox />
                <LocationSelector />
              </>
            )}
            <Typography.Label fontWeight="semi-bold">{t('booking.booking.testOccasion')}</Typography.Label>
            <OccasionsList onSelect={onSelect} />
          </Box>
        </Box>
      </Box>
    )
  );
};
