import type { ColumnDef, Row } from '@tanstack/react-table';
import type { Company } from 'api/companies/companies';
import { useGetCompanies } from 'api/companies/hooks/useGetCompanies';
import { Box, Typography } from 'components/basic-components';
import { Input } from 'components/basic-components/Input/Input';
import { ExpandButton } from 'components/basic-components/Table/ExpandButton';
import { Table } from 'components/basic-components/Table/Table';
import { usePagination } from 'components/basic-components/Table/hooks/usePagination';
import { ContentLoader } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useDebounce } from 'utilities/useDebounce';

type CompanyExpandedRowProps = {
  row: Row<Company>;
};

const CompanyExpandedRow = ({ row }: CompanyExpandedRowProps) => {
  const { t } = useTranslations();

  return (
    <tr>
      <td colSpan={4}>
        <Box backgroundColor="neutral-color-2" padding="base" aria-colspan={4} flex={1}>
          {row.original.ceo?.firstName ? (
            <>
              <Typography.H4 marginY="none">{t('company.search.ceo')}</Typography.H4>
              <Typography.Paragraph marginBottom="loose">
                {row.original.ceo.firstName} {row.original.ceo.lastName}
              </Typography.Paragraph>
              <Typography.SmallParagraph>{row.original.ceo.phone}</Typography.SmallParagraph>
            </>
          ) : (
            <>
              <Typography.H4 marginY="none">{t('company.search.compliance')}</Typography.H4>
              <Typography.Paragraph marginBottom="loose">
                {row.original.compliance?.firstName} {row.original.compliance?.lastName}
              </Typography.Paragraph>
              <Typography.SmallParagraph>{row.original.compliance?.phone}</Typography.SmallParagraph>
            </>
          )}
        </Box>
      </td>
    </tr>
  );
};

export const CompanySearch = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const [freeText, setFreeText] = useState('');
  const debouncedFreeText = useDebounce<string>(freeText, 200);
  const { pageSize, page, onPagination } = usePagination({});
  const { companies, total, isLoading, isError } = useGetCompanies({
    freeText: debouncedFreeText,
    page,
    size: pageSize,
  });

  const columns = useMemo<ColumnDef<Company>[]>(
    () => [
      {
        accessorKey: 'name',
        header: () => t('company.search.name'),
      },
      {
        accessorKey: 'orgNo',
        header: () => t('company.search.orgNo'),
      },
      {
        accessorKey: 'city',
        header: () => t('company.search.city'),
      },
      {
        accessorKey: 'expand',
        header: () => '',
        cell: ({ row }) => <ExpandButton row={row} />,
        size: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isError) {
      onPagination(0);
    }
  }, [isError, onPagination]);

  useEffect(() => {
    onPagination(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFreeText]);

  return (
    <>
      <PageHeader title={t('company.search.title')} onBack={() => navigate('/')} />
      <Box marginBottom="loose" flexDirection="row" gap="20px">
        <Input
          label={t('company.search.freeText')}
          placeholder={t('company.search.freeText')}
          value={freeText}
          onChange={(value: string) => setFreeText(value)}
        />
      </Box>
      <ContentLoader template="examBooking" status={isLoading ? 'loading' : 'idle'}>
        <Table
          columns={columns}
          data={isError ? [] : companies}
          rowCount={isError ? 0 : total}
          pageSize={isError ? 0 : pageSize}
          onPagination={onPagination}
          page={page}
          renderExpandedRow={(row) => <CompanyExpandedRow row={row} />}
          emptyText={t('company.search.noResponse')}
          failedFetch={isError}
        />
      </ContentLoader>
    </>
  );
};
