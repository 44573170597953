import { useResetPasswordMutation } from 'api/users/users';
import { Box, Button, Typography } from 'components/basic-components';
import { TextField } from 'components/form-components';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslations } from 'translations/hooks/useTranslations';
import { CustomEventName, publish } from 'utilities';
import { errorHandler, getErrorMessage } from 'utilities/errorHandling';
import { resetPasswordValidationSchema } from 'utilities/validationSchema';

export const ResetPasswordForm = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const [hasContinued, setHasContinued] = useState(false);
  const [resetPassword, { isSuccess, isError, isLoading, error }] = useResetPasswordMutation();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token') ?? '';

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('onboarding.resetPassword.resetSuccess'), {
        theme: 'colored',
      });

      setHasContinued(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // TODO: This needs a better implementation, its not in the API layer because different views using the same request handles errors in different ways.
  useEffect(() => {
    if (error) {
      if ('status' in error) {
        let errorMessage = '';
        switch (error.status) {
          case 400:
            errorMessage = getErrorMessage(error);
            if (errorMessage === 'INVALID_PASSWORD') {
              publish(CustomEventName.API_ERROR, { message: 'INVALID_PASSWORD' });
            } else if (errorMessage === 'PASSWORD_HAS_BEEN_USED_PREVIOUSLY') {
              publish(CustomEventName.API_ERROR, { message: 'PASSWORD_HAS_BEEN_USED_PREVIOUSLY' });
            } else {
              publish(CustomEventName.API_ERROR, { message: 'invalid_format' });
            }
            break;
          default:
            errorHandler(error);
            break;
        }

        if (errorMessage !== 'PASSWORD_HAS_BEEN_USED_PREVIOUSLY') {
          setHasContinued(true);
        }
      }
    }
  }, [error]);

  return (
    <>
      {!hasContinued || isLoading ? (
        <Formik
          initialValues={{ newPassword: '', confirmNewPassword: '' }}
          onSubmit={async ({ newPassword }) => await resetPassword({ newPassword, token })}
          validationSchema={resetPasswordValidationSchema({
            required: 'onboarding.resetPassword.errors.required',
            passwordMustMatch: 'onboarding.resetPassword.errors.passwordMustMatch',
            tooShort: 'onboarding.resetPassword.errors.tooShort',
            tooLong: 'onboarding.resetPassword.errors.tooLong',
            capitalLetter: 'onboarding.resetPassword.errors.capitalLetter',
            smallLetter: 'onboarding.resetPassword.errors.smallLetter',
            number: 'onboarding.resetPassword.errors.number',
            specialCharacter: 'onboarding.resetPassword.errors.specialCharacter',
            whiteSpaces: 'onboarding.resetPassword.errors.whiteSpaces',
          })}
        >
          {({ isSubmitting, errors, values, initialValues }) => (
            <Form>
              <Box marginBottom="base">
                <Typography.Paragraph color="main" fontWeight="bold">
                  {t('onboarding.resetPassword.passwordMust')}
                </Typography.Paragraph>
                <Typography.Paragraph color="main"> &bull; {t('onboarding.resetPassword.8to20')}</Typography.Paragraph>
                <Typography.Paragraph color="main"> &bull; {t('onboarding.resetPassword.oneSmall')}</Typography.Paragraph>
                <Typography.Paragraph color="main"> &bull; {t('onboarding.resetPassword.oneCapital')}</Typography.Paragraph>
                <Typography.Paragraph color="main"> &bull; {t('onboarding.resetPassword.oneNumber')}</Typography.Paragraph>
                <Typography.Paragraph color="main"> &bull; {t('onboarding.resetPassword.oneSpecial')}</Typography.Paragraph>
                <Typography.Paragraph color="main"> &bull; {t('onboarding.resetPassword.noWhiteSpaces')}</Typography.Paragraph>
              </Box>
              <TextField
                type="password"
                name="newPassword"
                placeholder={t('onboarding.resetPassword.newPassword')}
                label={t('onboarding.resetPassword.newPassword')}
                autoComplete="on"
              />
              <TextField
                type="password"
                name="confirmNewPassword"
                placeholder={t('onboarding.resetPassword.confirmNewPassword')}
                label={t('onboarding.resetPassword.confirmNewPassword')}
                autoComplete="on"
              />
              <Box display="flex" flexDirection="row" justifyContent="end" paddingTop="loose">
                <Button
                  type="submit"
                  disabled={
                    isSubmitting === true || errors['newPassword'] || errors['confirmNewPassword'] || values == initialValues ? true : false
                  }
                >
                  {t('onboarding.resetPassword.save')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          {isError ? (
            <>
              <Typography.H2 color="main">{t('onboarding.resetPassword.error')}</Typography.H2>
              <Typography.Paragraph color="main">{t('onboarding.resetPassword.requestPasswordAgain')}</Typography.Paragraph>
              <Box display="flex" flexDirection="row" justifyContent="end" paddingTop="loose">
                <Button justifyContent="center" marginTop="loose" onClick={() => navigate('/password/forgot-password')}>
                  {t('onboarding.resetPassword.resend')}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography.H2 color="main">{t('onboarding.resetPassword.success')}</Typography.H2>
              <Typography.Paragraph color="main">{t('onboarding.resetPassword.successInfo')}</Typography.Paragraph>
              {/* Currently this view is used in online, therefore this button has to redirect there */}
              <Box display="flex" flexDirection="row" justifyContent="end" paddingTop="loose">
                <Button
                  justifyContent="center"
                  marginTop="loose"
                  onClick={() => (window.location.href = process.env.REACT_APP_ASTRUM_HOST ?? '')}
                >
                  {t('onboarding.resetPassword.login')}
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ResetPasswordForm;
