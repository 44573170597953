import type { GetUsersRequest } from '../users';
import { useGetUsersQuery } from '../users';

export const useGetUsers = (props: GetUsersRequest) => {
  const { data, isError, ...rest } = useGetUsersQuery(props);

  return {
    users: isError ? [] : data?.licensees ?? [],
    total: isError ? 0 : data?.total ?? 0,
    count: isError ? 0 : data?.count ?? 0,
    isError,
    ...rest,
  };
};
