import { Typography } from 'components/basic-components';
import { useTranslations } from 'translations/hooks/useTranslations';
type ErrorMessageProps = {
  error: string;
};

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { t } = useTranslations();

  return <Typography.SmallParagraph color="error-enhanced">{t(error)}</Typography.SmallParagraph>;
};
