import { useGetApplication } from 'api/applications/hooks/useGetApplication';
import { useGetActiveUserId } from 'api/users/hooks/useGetActiveUserId';
import { Box, Typography } from 'components/basic-components';
import { ContentLoader, Section } from 'components/misc';
import { PageHeader } from 'components/misc/PageHeader/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'translations/hooks/useTranslations';
import { useIsIntegrated } from 'utilities/useIsIntegrated';

export const ApplicationHeader = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { application, isLoading, findValueFromField } = useGetApplication(parseInt(applicationId ?? ''));
  const { activeUserId } = useGetActiveUserId();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const isIntegrated = useIsIntegrated();
  const title =
    application?.type === 'DEREGISTRATION'
      ? t('profile.applications.deregistrationTitle')
      : application?.type === 'EXEMPTION_APPLICATION'
        ? t('profile.applications.exemptionTitle')
        : application?.type === 'LICENSE_APPLICATION'
          ? t('profile.applications.licenseTitle')
          : t('profile.applications.title');
  const sectionTitle =
    application?.type === 'DEREGISTRATION'
      ? t('profile.applications.deregistrationSubtitle')
      : application?.type === 'EXEMPTION_APPLICATION'
        ? t('profile.applications.exemptionSubtitle')
        : application?.type === 'LICENSE_APPLICATION'
          ? t('profile.applications.licenseSubtitle')
          : t('profile.applications.user');

  const handleBack = () => {
    if (isIntegrated) {
      navigate(-1);
    } else {
      navigate(`/users/${activeUserId}/applications`);
    }
  };

  if (!application) {
    return null;
  }

  return (
    <>
      <PageHeader title={title} onBack={handleBack} />
      <Section title={sectionTitle}>
        <ContentLoader status={isLoading ? 'loading' : 'idle'} template="applications">
          <Box padding="base-tight" gap="base-tight" flexDirection="row">
            <Box>
              <Typography.H4 margin="none">
                {application.viewInfo?.fullName ??
                  application.viewInfo?.userFullName ??
                  `${findValueFromField('user_first_name') ?? ''} ${findValueFromField('user_last_name') ?? ''}`}
                <Typography.Paragraph marginBottom="base-tight">{findValueFromField('user_social_no')}</Typography.Paragraph>
              </Typography.H4>
              {findValueFromField('user_phone') && (
                <Typography.Anchor href="tel:user?.phonenr">{findValueFromField('user_phone')}</Typography.Anchor>
              )}
              {findValueFromField('user_mobile') && (
                <Typography.Anchor href="tel:user?.mobilephonenr">{findValueFromField('user_mobile')}</Typography.Anchor>
              )}
              <Typography.Anchor href={`mailto:${findValueFromField('user_email')}`}>{findValueFromField('user_email')}</Typography.Anchor>
            </Box>
            <Box>
              <Typography.H4 margin="none">
                {findValueFromField('company_name')}
                <Typography.Paragraph marginBottom="base-tight">{findValueFromField('company_org_no')}</Typography.Paragraph>
              </Typography.H4>
              <Typography.Paragraph>{findValueFromField('company_street') ?? ''}</Typography.Paragraph>
              <Typography.Paragraph>
                {findValueFromField('company_postal_code') ?? ''} {findValueFromField('company_city') ?? ''}
              </Typography.Paragraph>
              <Typography.Paragraph>{findValueFromField('company_country') ?? ''}</Typography.Paragraph>
            </Box>
          </Box>
        </ContentLoader>
      </Section>
    </>
  );
};
